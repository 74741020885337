import React from 'react';

import { Portal } from '../Portal';

import { Toast as ToastType, ToastCtx } from './context';
import { Toast } from './Toast';
import css from './Toast.module.css';

interface ToastListProps {
  toasts: ToastCtx['toasts'];
  onRequestClose: (id: ToastType['id']) => void;
}

export const ToastList = React.memo<ToastListProps>(({ toasts, onRequestClose }) => {
  if (toasts.length === 0) {
    return null;
  }

  return (
    <Portal>
      <section className={css.list}>
        {toasts.map(toast => (
          <Toast key={toast.id} toast={toast} onRequestClose={onRequestClose} />
        ))}
      </section>
    </Portal>
  );
});
