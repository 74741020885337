import React from 'react';

import { COOKIE_REGION_KEY } from 'const';
import { getClusterRegionByAcceptLanguage } from 'containers/ModalClusterRegion/helpers';
import { withApollo } from 'utils/apollo';
import { dynamic } from 'utils/dynamic';
import {
  getAcceptLanguageFromCtx,
  getPreferredLanguageFromCtxOrCookie,
  getQueryLanguageFromCtx,
} from 'utils/language';
import { cookie, serializeCookie } from 'utils/main';

import { ContextAppProvider } from './ContextApp';
import { AppContextInitialProps, isMobileBrowser } from './helpers';

import type { NextPageContext } from 'next';
import type { PageApolloProps, NextPage } from 'utils/next';

const Online = dynamic(() => import('./Online').then(mod => mod.Online));

// eslint-disable-next-line @typescript-eslint/ban-types
type AppProps<P = {}> = P & { _app: AppContextInitialProps };

interface PageParams {
  disableScripts?: boolean;
  disableSsr?: boolean;
  withApollo?: boolean;
}

// saving initial client state
let app: AppContextInitialProps | undefined;

// eslint-disable-next-line @typescript-eslint/ban-types
export function withApp<Props = {}>(
  Container: NextPage<Props>,
  params: PageParams = {},
): NextPage<AppProps<Props>> {
  // Separated from withApollo to access all contexts
  const PageComponent: NextPage<Props> = ({
    isMobile,
    hasRenderedOnServer,
    hydratedFetchPolicy,
    ...props
  }) => {
    return (
      <>
        <Container
          {...(props as Props & PageApolloProps)}
          hasRenderedOnServer={hasRenderedOnServer}
          hydratedFetchPolicy={hydratedFetchPolicy}
          isMobile={isMobile}
        />
        {/* TEMPORARY HERE  */}
        {/* {params.withApollo && !isMobile ? (
          <ModalAuthOnboarding
            hasRenderedOnServer={hasRenderedOnServer}
            hydratedFetchPolicy={hydratedFetchPolicy}
            isMobile={isMobile}
            {...props}
          />
        ) : null} */}
      </>
    );
  };

  const WithApp: NextPage<AppProps<Props>> = ({ _app, ...props }) => {
    // Calculate once during SSR
    const isMobile = React.useMemo(() => {
      // if building mobile app, always use mobile layout
      if (process.env.NEXT_PUBLIC_MOBILE_APP === 'true') {
        return true;
      }
      const isMobile = isMobileBrowser(_app.userAgent);
      // console.info(`[ContextApp]: Mobile layout", ${isMobile}`);

      return isMobile;
    }, []);

    React.useEffect(() => {
      if (!app) {
        console.info('[ContextApp]: Hydrating _app state');
        app = _app;
      }
    }, []);

    return (
      <ContextAppProvider
        acceptLanguage={_app.acceptLanguage}
        acceptLanguageRaw={_app.acceptLanguageRaw}
        disableScripts={params.disableScripts || false}
        isMobile={isMobile}
        preferredLanguage={_app.preferredLanguage}
        queryLanguage={_app.queryLanguage}
        userAgent={_app.userAgent}
      >
        <PageComponent
          {...(props as Props & PageApolloProps)}
          hasRenderedOnServer={_app.hasRenderedOnServer}
          hydratedFetchPolicy={
            _app.hasRenderedOnServer ? 'cache-first' : 'cache-and-network'
          }
          isMobile={isMobile}
        />
        {params.withApollo && <Online.Component />}
      </ContextAppProvider>
    );
  };

  WithApp.getInitialProps = async (
    ctx: NextPageContext,
  ): Promise<AppProps<Props>> => {
    const pageProps: Props = Container.getInitialProps
      ? await Container.getInitialProps(ctx)
      : ({} as Props);

    const appProps = await getAppContextInitialProps(ctx);
    const result: AppProps<Props> = { ...pageProps, _app: appProps };

    return result;
  };

  if (params?.withApollo) {
    const ssrEnabled = () => {
      // disable ssr while building mobile app
      if (process.env.NEXT_PUBLIC_MOBILE_APP === 'true') {
        return false;
      }

      return params.disableSsr ? false : true;
    };

    return withApollo({ ssr: ssrEnabled() })(WithApp) as any;
  }

  return WithApp;
}

async function getAppContextInitialProps(
  ctx: NextPageContext,
): Promise<AppContextInitialProps> {
  const req = ctx.req;

  const preferredLanguage = getPreferredLanguageFromCtxOrCookie(ctx);
  if (!req) {
    return {
      queryLanguage: undefined,
      preferredLanguage: preferredLanguage,
      acceptLanguage: undefined,
      userAgent: undefined,
      acceptLanguageRaw: undefined,
      ...(app || {}),
      hasRenderedOnServer: false,
    };
  }

  // insert cluster cookie if absent
  if (ctx.res && !ctx.res.headersSent) {
    const acceptLanguage = ctx.req?.headers['accept-language'];
    const cluster = cookie.get(COOKIE_REGION_KEY, ctx.req?.headers.cookie);
    ctx.res.setHeader(
      'Set-Cookie',
      serializeCookie(
        COOKIE_REGION_KEY,
        cluster ||
          (acceptLanguage ? getClusterRegionByAcceptLanguage(acceptLanguage) : '-'),
        { path: '/' },
      ),
    );
  }

  const userAgent = req.headers['user-agent'];

  const acceptLanguageRaw = ctx.req?.headers['accept-language'];
  const acceptLanguage = getAcceptLanguageFromCtx(ctx);
  const queryLanguage = getQueryLanguageFromCtx(ctx);

  return {
    queryLanguage,
    preferredLanguage,
    acceptLanguage,
    acceptLanguageRaw,
    userAgent,
    hasRenderedOnServer: true,
  };
}
