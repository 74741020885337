import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  ClientLanguage,
  getClientLanguageFromRequest,
  setPreferredLanguageCookie,
} from 'utils/language';

import { AppContent } from './App';

interface App {
  request: {
    userAgent: string | undefined;
    preferredLanguage: string | undefined;
    acceptLanguageRaw: string | undefined;
    acceptLanguage: string | undefined;
  };

  language: ClientLanguage | undefined;
  isMobile: boolean | undefined;

  setLanguage: (language: ClientLanguage) => void;
}

const ContextApp = createContext<App>({
  request: {
    userAgent: undefined,
    preferredLanguage: undefined,
    acceptLanguageRaw: undefined,
    acceptLanguage: undefined,
  },
  language: undefined,
  isMobile: undefined,
  setLanguage: () => {},
});

export function useApp(): App {
  const app = useContext(ContextApp);

  // useEffect(() => {
  //   if (typeof window === 'undefined') {
  //     return;
  //   }
  //   if ((window as any).app) {
  //     return;
  //   }
  //   (window as any).app = app;
  // }, []);

  return app;
}

export interface AppProviderProps {
  children: React.ReactNode;
  // IMPORTANT: set request values initially, otherwise it may cause
  // full redraw of the app on rehydration (language shift, etc.)
  userAgent: string | undefined;
  preferredLanguage: string | undefined;
  acceptLanguageRaw: string | undefined;
  acceptLanguage: string | undefined;
  queryLanguage: string | undefined;
  isMobile: boolean;

  // Disables all analytics, cookies and other head scripts
  disableScripts: boolean;
}
export const ContextAppProvider: React.FC<AppProviderProps> = ({
  children,

  userAgent,
  preferredLanguage,
  acceptLanguageRaw,
  acceptLanguage,
  isMobile,
  queryLanguage,

  disableScripts,
}) => {
  // preserve initial request values via references
  const initialIsMobile = useRef(isMobile);
  const initialUserAgent = useRef(userAgent);
  const initialPreferredLanguage = useRef(preferredLanguage);
  const initialAcceptLanguage = useRef(acceptLanguage);

  const [language, setLanguageState] = useState<ClientLanguage | undefined>(
    getClientLanguageFromRequest(acceptLanguage, preferredLanguage, queryLanguage),
  );

  const setLanguage = useCallback((language: ClientLanguage) => {
    setLanguageState(language);
    setPreferredLanguageCookie(language);
  }, []);

  const value: App = useMemo(() => {
    return {
      request: {
        userAgent: initialUserAgent.current,
        preferredLanguage: initialPreferredLanguage.current,
        acceptLanguage: initialAcceptLanguage.current,
        acceptLanguageRaw: acceptLanguageRaw,
      },
      isMobile: initialIsMobile.current,

      language,
      setLanguage,
    };
  }, [language]);

  useEffect(() => {
    (window as any).app = value;
  }, [value]);

  return (
    <ContextApp.Provider value={value}>
      <AppContent disableScripts={disableScripts} isMobile={initialIsMobile.current}>
        {children}
      </AppContent>
    </ContextApp.Provider>
  );
};
