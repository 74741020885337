import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from 'react';

import { Reference } from '@apollo/client';
import Router, { NextRouter } from 'next/router';

import { useApp } from 'components/ContextApp';

export const BETA_API_ENDPOINT = 'devapi.lf.group';
import { api, multipleCharactersGameIds, ALL_GAME_ID, useMe } from '../api';

import { filterLfgByGame } from './filterGame';

import type { SupportedGameId } from 'api/types';

export function useConsoleCommand(
  cmd: string,
  callback: () => any,
  deps: any[] = [],
): void {
  useEffect(() => {
    (window as any)[cmd] = callback;

    return () => {
      (window as any)[cmd] = null;
    };
  }, deps);
}
export function deepAssign<T extends Record<any, unknown>>(obj: T, child: T): T {
  return Object.keys(child).reduce(
    (acc, keyStr) => {
      const key = keyStr as keyof T;
      const childValue = child[key];
      const objValue = obj[key];

      if (
        !Array.isArray(childValue) &&
        typeof childValue === 'object' &&
        typeof objValue === 'object' &&
        childValue
      ) {
        acc[key] = deepAssign<any>(objValue || {}, childValue);
      } else {
        acc[key] = childValue;
      }

      return acc;
    },
    { ...obj },
  );
}

export function delay<T>(ms: number): Promise<T> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function createArray<T>(length: number, value: T): T[] {
  return new Array(length).fill(value);
}

export function unique<T, K>(array: T[], cb: (selector: T) => K): T[] {
  const unique: K[] = [];

  return array.reduce((acc, value) => {
    const identifier = cb(value);
    if (unique.includes(identifier)) {
      return acc;
    }

    unique.push(identifier);

    return [...acc, value];
  }, [] as T[]);
}

export function uniqueNodes<T, K extends Reference>(
  array: T[],
  cb: (selector: T) => K,
): T[] {
  const unique: K['__ref'][] = [];

  return array.reduce((acc, value) => {
    const identifier = cb(value);

    if (unique.includes(identifier.__ref)) {
      return acc;
    }

    unique.push(identifier.__ref);

    return [...acc, value];
  }, [] as T[]);
}

export function isBeta(): boolean {
  return process.env.NEXT_PUBLIC_API === 'devapi.lf.group';
}

export function isMultipleCharactersGame(gameId: api.GameId): boolean {
  return multipleCharactersGameIds.includes(gameId);
}

export function modifyObj<T extends Record<string, unknown>>(
  from: T,
  callback: (data: T) => T,
): T {
  return callback({ ...from });
}

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
    ? useLayoutEffect
    : useEffect;

export const cookie = {
  get: function getCookie(
    name: string,
    cookieStr: string = typeof document !== 'undefined' ? document.cookie : '',
  ): string | undefined {
    const v = cookieStr.match('(^|;) ?' + name + '=([^;]*)(;|$)');

    return v ? v[2] : undefined;
  },
  delete: (name: string): void => {
    return cookie.set(name, '', -1);
  },
  set: function setCookie(key: string, value: string, days = 100): void {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = key + '=' + value + expires + '; path=/';
  },
};

// from https://github.com/juliangruber/is-mobile/blob/master/index.js
const mobileRE =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;

export const metaGetters = {
  isMobile: {
    client: (): boolean => {
      if (typeof window !== 'undefined') {
        return mobileRE.test(window.navigator.userAgent);
      }

      return false;
    },
    // server: (req: IncomingMessage): boolean => {
    //   if (req.headers && typeof req.headers['user-agent'] === 'string') {
    //     return mobileRE.test(req.headers['user-agent']);
    //   }
    //   return false;
    // },
  },
};

export const ID_CLICKABLE = 'clickable_';

export function useOnClickOutside(
  ref: React.MutableRefObject<HTMLElement | null>,
  handler: (event: MouseEvent | TouchEvent) => void,
): void {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const target = event.target as HTMLElement | null;

      if (
        !ref.current ||
        ref.current.contains(target) ||
        target?.id === ID_CLICKABLE
      ) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export function useOnHoverBlock(
  ref: React.MutableRefObject<HTMLElement | null>,
  popupRef: React.MutableRefObject<HTMLElement | null>,
): void {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current) {
        return;
      }

      if (
        !popupRef.current ||
        popupRef.current.contains(event.target as Node | null)
      ) {
        return;
      }

      const x = event.clientX;
      const y = event.clientY;

      popupRef.current.style.top = y + 20 + 'px';
      popupRef.current.style.left = x + 20 + 'px';
    };

    if (!ref.current) {
      return;
    }

    ref.current.addEventListener('mousemove', listener);

    // document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousemove', listener);
      // document.removeEventListener('touchstart', listener);
    };
  }, [ref, popupRef]);
}

export function useThrottledLoading(
  loading: boolean,
  loadingDelayMs: number,
  forceLoading: any[] = [],
): boolean {
  const [_loading, setIsLoading] = useState<boolean>(loading);

  const promiseLoad = useCallback(async () => {
    setIsLoading(true);
    await delay(loadingDelayMs);
    setIsLoading(false);
  }, []);

  // useEffect(() => {
  //   promiseLoad();
  // }, forceLoading);

  forceLoading;

  useEffect(() => {
    if (loading) {
      promiseLoad();
    }
  }, [loading]);

  return _loading || loading;
}

export function getScrollParent(node: HTMLElement | null): HTMLElement | null {
  if (node === null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode as HTMLElement);
  }
}

export function useScrollableParent(
  node: HTMLElement | null,
  deps: any[],
): HTMLElement | null {
  return useMemo(() => getScrollParent(node), deps);
}

export function copyToClipboard(text: string): void {
  const temp = document.createElement('textarea');
  temp.value = text;
  temp.setAttribute('readonly', '');
  temp.style.position = 'absolute';
  temp.style.left = '-9999px';
  document.body.appendChild(temp);
  temp.select();
  document.execCommand('copy');
  document.body.removeChild(temp);
}

export function useTempElement<K extends keyof HTMLElementTagNameMap>(
  tag: K,
  onMount: (element: HTMLElementTagNameMap[K]) => void = () => undefined,
): HTMLElementTagNameMap[K] | null {
  const ref = useRef<HTMLElementTagNameMap[K] | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const element = document.createElement(tag);
    element.style.zIndex = '-9999999999';
    element.style.position = 'absolute';
    element.style.top = '0px';
    element.style.opacity = '0';
    ref.current = element;
    document.body.appendChild(element);
    onMount(element);

    return () => {
      if (ref.current) {
        document.body.removeChild(ref.current);
        ref.current = null;
      }
    };
  }, [tag]);

  return ref.current;
}

export function capitalize(str: string): string {
  return str
    .split(' ')
    .map(slice => {
      return slice.slice(0, 1).toUpperCase() + slice.slice(1, slice.length);
    })
    .join(' ');
}

export function join(str: string, separator = ' '): string {
  return str
    .split(separator)
    .map(slice => {
      return slice.slice(0, 1).toUpperCase() + slice.slice(1, slice.length);
    })
    .join('');
}

export function filterGames<T extends api.Game>(
  gameId: api.GameId,
  myGames: api.Game[],
  includeHidden = false,
): T[] {
  if (!includeHidden) {
    return myGames.filter(game => game?.gameId === gameId && !game.hidden) as T[];
  }

  return myGames.filter(game => game?.gameId === gameId) as T[];
}

export function parseGameTypenameToId(typename: api.Game['__typename']): api.GameId {
  if (!typename) {
    throw new Error('Non viable typename');
  }
  switch (typename) {
    case 'Wow':
      return api.GameId.WorldOfWarcraft;
    case 'Hearthstone':
      return api.GameId.Hearthstone;
    case 'CSGO':
      return api.GameId.Csgo;
    case 'LostArk':
      return api.GameId.LostArkEn;
    case 'GameLol':
      return api.GameId.LeagueOfLegends;
    case 'GameAny':
    default:
      return api.GameId.Custom;
  }
}

export function parseGameIdToTypename(gameId: api.GameId): api.Game['__typename'] {
  switch (gameId) {
    case api.GameId.WorldOfWarcraft:
      return 'Wow';
    case api.GameId.WorldOfWarcraftBurningCrusade:
      return 'WowBurningCrusade';
    case api.GameId.Hearthstone:
      return 'Hearthstone';
    case api.GameId.Csgo:
      return 'CSGO';
    case api.GameId.Warzone:
      return 'Warzone';
    case api.GameId.LostArkEn:
      return 'LostArk';
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Func = (...args: any[]) => any;

export function throttle<T extends Func>(callback: T, ms: number): T {
  let isIdle = false;

  const wrapper = (...params: Parameters<T>) => {
    if (isIdle) {
      return;
    }

    isIdle = true;

    setTimeout(() => {
      isIdle = false;
    }, ms);

    return callback(...params);
  };

  return wrapper as T;
}

export const smoothScroll = (
  ref: React.MutableRefObject<HTMLDivElement | HTMLFormElement | null>,
  additionalTop = 0,
): void => {
  if (!ref.current) {
    return;
  }

  window.scrollTo({
    top:
      ref.current.offsetTop -
      (window.innerHeight - ref.current.clientHeight) / 2 +
      additionalTop,

    left: 0,
    behavior: 'smooth',
  });
};

export function getQueryInt(router: NextRouter, key: string): number {
  return parseInt(router.query[key]?.toString()) || 0;
}

export function areArraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  return (
    arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index])
  );
}

function convertCase(incomingElement: any, convert: any): any {
  const convertedObject: Record<string, any> = {};

  const convertArray = (array: any[]): any[] => {
    const convertedArray = array.map(element => convertCase(element, convert));

    return convertedArray;
  };

  const checkForObject = (element: any): boolean => {
    return typeof element === 'object' && element.length === undefined;
  };

  const checkForArray = (element: any): boolean =>
    typeof element === 'object' && element.length !== undefined;

  if (checkForObject(incomingElement)) {
    for (const property in incomingElement) {
      const snakeKey: string = convert(property);

      if (checkForObject(incomingElement[property])) {
        const convertedValues = convertCase(incomingElement[property], convert);

        convertedObject[snakeKey] = convertedValues;
        continue;
      }

      if (checkForArray(incomingElement[property])) {
        const convertedArray = convertArray(incomingElement[property]);

        convertedObject[snakeKey] = convertedArray;
        continue;
      }

      convertedObject[snakeKey] = incomingElement[property];
    }

    return convertedObject;
  }

  if (checkForArray(incomingElement)) {
    return convertArray(incomingElement);
  }

  return incomingElement;
}

export function camelToSnake(camel: Record<string, any>): Record<string, any> {
  return convertCase(camel, (word: string): string => {
    return convertWordToSnakeCase(word);
  });
}
export function convertWordToSnakeCase(word: string): string {
  return word.replace(/([A-Z])/g, '_$1').toLowerCase();
}

interface DeepEqualOptions<T extends Record<any, any>> {
  omitKeys?: Array<keyof T>;
}

export function deepEqual<T extends Record<any, any>, D extends Record<any, any>>(
  firstObject: T,
  secondObject: D,
  options?: DeepEqualOptions<T>,
): firstObject is D {
  if (firstObject === (secondObject as unknown)) {
    return true;
  }

  if (!(firstObject instanceof Object) || !(secondObject instanceof Object)) {
    return false;
  }

  for (const p in firstObject) {
    if (options?.omitKeys?.includes(p)) continue;

    if (!firstObject.hasOwnProperty(p)) continue;
    // other properties were tested using firstObject.constructor === secondObject.constructor

    if (!secondObject.hasOwnProperty(p)) return false;
    // allows to compare firstObject[ p ] and secondObject[ p ] when set to undefined

    if ((firstObject as any)[p] === (secondObject as any)[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof (firstObject as any)[p] !== 'object' && Array.isArray(firstObject))
      return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!deepEqual((firstObject as any)[p], (secondObject as any)[p])) return false;
  }

  for (const p in secondObject) {
    if (secondObject.hasOwnProperty(p) && !firstObject.hasOwnProperty(p))
      return false;
  }

  return true;
}

export const getRecentGameIdsByGame = (gameId: SupportedGameId) => {
  return `lfg/recentCharacters/${gameId}`;
};

export const getGameRecentCharactersId = (
  gameId: api.Maybe<SupportedGameId>,
): string[] => {
  if (!gameId) {
    return [];
  }

  if (typeof window === 'undefined') {
    return [];
  }

  const recentCharactersKey = getRecentGameIdsByGame(gameId);

  if (!recentCharactersKey) {
    return [];
  }

  const recentCharactersJson = localStorage.getItem(recentCharactersKey);
  const recentCharacters: string[] = recentCharactersJson
    ? JSON.parse(recentCharactersJson)
    : [];

  return recentCharacters;
};

export const updateGameLastActive = (
  gameId: SupportedGameId,
  characterId: api.Maybe<string>,
): void => {
  if (!characterId) {
    return;
  }
  const recentCharacters = getGameRecentCharactersId(gameId);
  const recentCharactersKey = getRecentGameIdsByGame(gameId);

  const foundGameId = recentCharacters.findIndex(element => element === characterId);

  if (foundGameId !== -1) {
    recentCharacters.splice(foundGameId, 1);
  }

  recentCharacters.push(characterId);
  localStorage.setItem(recentCharactersKey, JSON.stringify(recentCharacters));
};

export const sortGames = <T extends api.Game>(
  games: T[],
  gameId: SupportedGameId,
  rating: keyof T,
): T[] => {
  const recent = getGameRecentCharactersId(gameId);

  const res = games.sort((a, b) => {
    const idxA = recent.findIndex(x => x === a.id);
    const idxB = recent.findIndex(x => x === b.id);

    if (idxA !== -1 && idxB === -1) {
      return -1;
    }

    if (idxB !== -1 && idxA === -1) {
      return 1;
    }

    if (idxA !== -1 && idxB !== -1) {
      return idxA > idxB ? -1 : 1;
    }

    if (a[rating] < b[rating]) {
      return 1;
    }

    return -1;
  });

  return res;
};

export const getUserGames = (lfg: api.Maybe<api.Lfg[]>): SupportedGameId[] => {
  if (!lfg) {
    return [];
  }

  const unique: SupportedGameId[] = [];
  const profileLfgs = filterProfileLfgs(lfg) || [];

  return (
    profileLfgs
      .map(lfg => lfg.gameId)
      // map only supported games
      .filter((game): game is SupportedGameId =>
        ALL_GAME_ID.includes(game as SupportedGameId),
      )
      // unique (may be several lfgs by game-mode)
      .filter(game => {
        if (unique.includes(game)) {
          return false;
        }
        unique.push(game);

        return true;
      }) || []
  );
};

export const checkForUrl = (url: string): boolean => {
  const urlRegex = new RegExp(
    '(http|https)://((\\w)*|([0-9]*)|([-|_])*)+([\\.|/]((\\w)*|([0-9]*)|([-|_])*))+',
  );

  return urlRegex.test(url);
};

export const copyGameLink = (username: api.Maybe<string>, gameId: string) => {
  if (!username) {
    return;
  }

  const gameLink = `/${username}?v=${gameId}`;

  copyToClipboard(location.origin + gameLink);
};

export const getBotInviteLink = (data: { serverId?: string }): string => {
  const url = new URL(
    'https://discord.com/api/oauth2/authorize?scope=bot+applications.commands',
  );

  url.searchParams.append('client_id', '776514408578940969');
  url.searchParams.append('permissions', '395423607889');

  if (data.serverId) {
    url.searchParams.append('guild_id', data.serverId);
  }

  return url.toString();
};

export const shouldOpenOnboarding = (
  me: api.Maybe<api.User>,
  gameId: api.Maybe<SupportedGameId>,
  lfgs: api.Maybe<api.Lfg[]>,
  onAddGame: (initialGame: api.Maybe<SupportedGameId>) => void,
  isOnboardingOpened: React.MutableRefObject<boolean>,
) => {
  if (!me || !gameId || !lfgs) {
    return;
  }

  if (isOnboardingOpened.current) {
    return;
  }

  const profile = filterLfgByGame(gameId, filterProfileLfgs(lfgs) || []);
  if (!profile) {
    isOnboardingOpened.current = true;
    onAddGame(gameId);
  }
};

export function generateIdByDate(): string {
  return new Date().toISOString();
}

export function modify<T>(input: T, onChange: (newInput: T) => void) {
  return (callback: (newInput: T) => T) => {
    const newInput = callback(input);
    onChange(newInput);
  };
}

export function useIntersectionObserver(
  options: IntersectionObserverInit,
  target: React.MutableRefObject<HTMLDivElement | null>,
  onRequestIntersect?: () => void | Promise<void>,
  after = true,
  deps: any[] = [],
) {
  const handler = useMemo(
    () => (onRequestIntersect ? throttle(onRequestIntersect, 350) : undefined),
    [onRequestIntersect],
  );

  useEffect(() => {
    if (!target.current || !handler) {
      return;
    }

    const listenIntersections = async (entries: IntersectionObserverEntry[]) => {
      if (!after) {
        return;
      }

      const entry = entries[0];

      if (entry.isIntersecting && entry.target === target.current) {
        await handler();
      }
    };

    const observer = new IntersectionObserver(listenIntersections, options);
    observer.observe(target.current);

    return () => observer.disconnect();
  }, [target.current, deps]);
}

export function getApiUrl(): string {
  return `${process.env.NEXT_PUBLIC_PROTOCOL}://${process.env.NEXT_PUBLIC_API}`;
}

export function filterProfileLfgs(lfg: api.Maybe<api.Lfg[]> = []): api.Lfg[] {
  return lfg?.filter(lfg => {
    const isTravelingMerchant = lfg.modes?.includes(
      api.GameMode.LostArkTravelingMerchants,
    );

    const isLfgMerchant = [api.LfgType.MerchantWeb, api.LfgType.Merchant].includes(
      lfg.type,
    );

    // Hide all travelling merchants from the list
    return !isTravelingMerchant && !isLfgMerchant;
  });
}

export function handleUrl(initialUrl?: string) {
  const url = initialUrl?.trim();

  if (!url) {
    return '';
  }

  if (!url.startsWith('https://') && !url.startsWith('http://')) {
    return 'https://' + url;
  }

  return url;
}

function saveScrollPos(asPath: string, isAuth: boolean) {
  sessionStorage.setItem(
    `scrollPos:${asPath}`,
    JSON.stringify({ y: window.scrollY, isAuth }),
  );
}

function restoreScrollPos(asPath: string, isAuth: boolean) {
  if (typeof sessionStorage === 'undefined') {
    return;
  }
  const json = sessionStorage.getItem(`scrollPos:${asPath}`);
  const scrollPos = json ? JSON.parse(json) : undefined;
  if (scrollPos) {
    if (scrollPos.isAuth !== isAuth) {
      return;
    }

    window.scrollTo(0, scrollPos.y);
  }
}

export function useScrollRestoration(router: NextRouter) {
  const me = useMe();
  const isAuth = Boolean(me);

  useEffect(() => {
    if (!('scrollRestoration' in window.history)) return;
    let shouldScrollRestore = false;
    window.history.scrollRestoration = 'manual';
    restoreScrollPos(router.asPath, isAuth);
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      saveScrollPos(router.asPath, isAuth);
      delete event['returnValue'];
    };

    const onRouteChangeStart = () => {
      saveScrollPos(router.asPath, isAuth);
    };

    const onRouteChangeComplete = (url: string) => {
      if (shouldScrollRestore) {
        shouldScrollRestore = false;

        restoreScrollPos(url, isAuth);
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    Router.beforePopState(() => {
      shouldScrollRestore = true;

      return true;
    });

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
      Router.beforePopState(() => true);
    };
  }, [router]);
}

export function useScrollToTop() {
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setIsTop(window.scrollY < 600);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return { isTop, scrollToTop };
}

interface CookieOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
}

export function serializeCookie(
  name: string,
  value: string,
  options: CookieOptions = {},
): string {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (options.path) {
    cookieString += `; Path=${options.path}`;
  }
  if (options.expires) {
    cookieString += `; Expires=${options.expires.toUTCString()}`;
  }

  if (options.maxAge) {
    cookieString += `; Max-Age=${options.maxAge}`;
  }

  if (options.domain) {
    cookieString += `; Domain=${options.domain}`;
  }

  if (options.secure) {
    cookieString += '; Secure';
  }

  if (options.httpOnly) {
    cookieString += '; HttpOnly';
  }

  if (options.sameSite) {
    cookieString += `; SameSite=${options.sameSite}`;
  }

  return cookieString;
}

export type MobileType = 'iOS' | 'Android' | 'Huawei';

export function useGetMobileOperatingSystem(): MobileType | null {
  const app = useApp();
  const userAgent = app.request.userAgent;

  if (!userAgent) {
    return null;
  }

  if (/windows phone/i.test(userAgent)) {
    return null;
  }

  if (/android/i.test(userAgent)) {
    if (/HUAWEI/i.test(userAgent)) {
      return 'Huawei';
    }

    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return 'iOS';
  }

  return null;
}
