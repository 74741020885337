import React, { useEffect, useState } from 'react';

import { createPortal } from 'react-dom';

interface PortalProps {
  children: React.ReactNode;
  root?: HTMLElement | null;
}

export const Portal: React.FC<PortalProps> = ({ children, root }) => {
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null | undefined>(root);

  useEffect(() => {
    if (!root) {
      setPortalRoot(document.getElementById('modal-root'));

      return;
    }

    setPortalRoot(root);
  }, []);

  if (!portalRoot) {
    return null;
  }

  return createPortal(children, portalRoot);
};
