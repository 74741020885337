import type { api } from '../api';
import type { SupportedGameId } from 'api/types';

export const filterLfgByGame = (
  gameId: SupportedGameId,
  lfg: api.Maybe<api.Lfg[]>,
): api.Lfg | undefined => {
  const gameLfgs = lfg?.find(lfg => lfg.gameId === gameId);

  if (!gameLfgs) {
    return;
  }

  return gameLfgs;
};
