import { useEffect } from 'react';

import {
  PureQueryOptions,
  WatchQueryFetchPolicy,
  ApolloClient,
  FetchResult,
} from '@apollo/client';

import * as api from 'graphql/generated';
import { getIsOnboardingCompletedKey } from 'utils/location';
import { cookie } from 'utils/main';

import * as optimistic from './optimistic';

import type { SupportedGameId } from './types';
export { optimistic };

export type GameWithMultipleChars = api.Wow | api.WowBurningCrusade | api.LostArk;

export enum PubgMobileRank {
  NoRank = 'NoRank',
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',
  Platinum = 'Platinum',
  Diamond = 'Diamond',
  Crown = 'Crown',
  As = 'As',
  AsMaster = 'AsMaster',
  AsDominator = 'AsDominator',
  Conqueror = 'Conqueror',
}
export enum CodMobileRank {
  NoRank = 'NoRank',
  // I-V ranks
  Rookie = 'Rookie',
  Veteran = 'Veteran',
  Elite = 'Elite',
  Pro = 'Pro',

  Master = 'Master',
  Grandmaster = 'Grandmaster',
  Legendary = 'Legendary',
}
export enum Standoff2Rank {
  NoRank = 'NoRank',
  // I-V ranks
  Bronze = 'Bronze',
  Silver = 'Silver',
  Gold = 'Gold',

  Phoenix = 'Phoenix',
  Ranger = 'Ranger',
  Champion = 'Champion',
  Master = 'Master',
  Elite = 'Elite',
  Legendary = 'Legendary',
}

export const multipleCharactersGameIds: Array<api.GameId> = [
  api.GameId.WorldOfWarcraft,
  api.GameId.WorldOfWarcraftBurningCrusade,
  api.GameId.LostArkEn,
];

export enum LostArkContinents {
  Rethramis = 'Rethramis',
  Yudia = 'Yudia',
  WestLuterra = 'West Luterra',
  EastLuterra = 'East Luterra',
  Tortoyk = 'Tortoyk',
  Anikka = 'Anikka',
  Arthetine = 'Arthetine',
  NorthVern = 'North Vern',
  Shushire = 'Shushire',
  Rohendel = 'Rohendel',
  Yorn = 'Yorn',
  Feiton = 'Feiton',
  Punika = 'Punika',
  SouthVern = 'South Vern',
  Rowen = 'Rowen',
}

export enum LostArkGifts {
  Legendary = 'Legendary',
  Epic = 'Epic',
}

export type Response<T = void> = Promise<FetchResult<T>> | FetchResult<T>;

export { api };

export type Iterator<T> = api.Maybe<{
  data: T;
  after?: boolean;
  count: number;
  limit?: number;
}>;

export enum BossRush {
  HallOfSilence = 'Hall of Silence',
  HallOfTheSun = 'Hall of the Sun',
}

export enum PlatinumFieldsField {
  NahunsDomain = "Nahun's Domain",
  OldYudianCanal = 'Old Yudian Canal',
}
export enum OtherActivities {
  SecretMap = 'Secret Map',
  Misc = 'Misc.',
}

export enum LfgTag {
  TwoByTwo = 'TwoByTwo',
  ThreeByThree = 'ThreeByThree',
  RBG = 'RBG',
  MythicPlus = 'MythicPlus',
  PvERaid = 'PvERaid',
  LookingForGuild = 'LookingForGuild',
  RaidLeader = 'RaidLeader',
  Learning = 'Learning',
  Casual = 'Casual',
  Pro = 'Pro',
  Argos = 'Argos',
  Valtan = 'Valtan',
  Brelshaza = 'Brelshaza',
  Vykas = 'Vykas',
  KakulSaydon = 'KakulSaydon',
  Hellmode = 'Hellmode',
  GvG = 'GvG',
  AbyssDungeons = 'AbyssDungeons',
  OrehaWell = 'OrehaWell',
  SemiHardcore = 'SemiHardcore',
  Hardcore = 'Hardcore',
  De = 'DE',
  Uk = 'UK',
  Us = 'US',
  Es = 'ES',
  Mx = 'MX',
  Fr = 'FR',
  Br = 'BR',
  En = 'EN',
  LookingForStaticGroup = 'LookingForStaticGroup',
  LfTeam = 'LookingForTeam',
  LfFriends = 'LookingForFriends',
  LookingForRaid = 'LookingForRaid',
  LookingForClan = 'LookingForClan',
  LookingForTeam = 'LookingForTeam',
  LookingForCodriver = 'LookingForCodriver',
  Tryhard = 'TryHard',
  AWC = 'AWC',
  MDI = 'MDI',
  Voice = 'Voice',
  NoVoice = 'NoVoice',
  Streamer = 'Streamer',
  Mentor = 'Mentor',
  Duo = 'Duo',
  Flex = 'Flex',
  Rift = 'Rift',
  ARAM = 'ARAM',
  Normal = 'Normal',
  Ranked = 'Ranked',
  Clash = 'Clash',
  TeamCaptain = 'TeamCaptain',
  SemiPro = 'SemiPro',
  PvP = 'PvP',
  PvE = 'PvE',
  Tournaments = 'Tournaments',
  Tft = 'TFT',
  LoadsFreeTime = 'LoadsFreeTime',
  FamilyPerson = 'FamilyPerson',
  Teenager = 'Teenager',
  Age2030 = 'Age2030',
  Age3040 = 'Age3040',
  Age40Plus = 'Age40Plus',
  Coach = 'Coach',
  Male = 'Male',
  Female = 'Female',
  LookingForMale = 'LookingForMale',
  LoogingForFemale = 'LookingForFemale',
  LookingForGirl = 'LookingForGirl',
  LookingForMan = 'LookingForMan',
  LookingForFriends = 'LookingForFriends',
  Coaching = 'Coaching',
  Fun = 'Fun',
  NightGames = 'NightGames',
  CasualTournaments = 'CasualTournaments',
  TwentyThirtyYears = 'TwentyThirtyYears',
  ThirtyFourtyYears = 'ThirtyFourtyYears',
  FourtyPlusYears = 'FourtyPlusYears',
  ModdedServers = 'ModdedServers',
  VanillaServers = 'VanillaServers',
  SkilledShooter = 'SkilledShooter',
  Builder = 'Builder',
  Girl = 'Girl',
  Man = 'Man',
  FullSupport = 'FullSupport',
  SemiSupport = 'SemiSupport',
  Offlane = 'Offlane',
  Mid = 'Mid',
  Carry = 'Carry',
  Smurf = 'Smurf',
  Turbo = 'Turbo',
  It = 'It',
  Pl = 'Pl',
  Swe = 'Swe',
  Tr = 'Tr',
  Lurker = 'Lurker',
  Sniper = 'Sniper',
  Support = 'Support',
  Faceit = 'Faceit',
  NonBinary = 'NonBinary',
  FreeAgent = 'FreeAgent',
  Fps30Plus = 'Fps30Plus',
  Fps60Plus = 'Fps60Plus',
  Fps90Plus = 'Fps90Plus',
  TDM = 'TDM',
  CustomGames = 'CustomGames',
  Captain = 'Captain',
  IGL = 'IGL',
  Manager = 'Manager',
  Newbie = 'Newbie',
  MorningPrimeTime = 'MorningPrimeTime',
  DaytimePrimeTime = 'DaytimePrimeTime',
  EveningPrimeTime = 'EveningPrimeTime',
  Raid = 'Raid',
  Arena = 'Arena',
  LookingForPlayers = 'LookingForPlayers',
  Age1114 = 'Age1114',
  Age1519 = 'Age1519',
  Age2024 = 'Age2024',
  Age2530 = 'Age2530',
  Age30Plus = 'Age30Plus',
}

export function fetchPolicySSR(isSsr: boolean): WatchQueryFetchPolicy {
  return isSsr ? 'cache-first' : 'network-only';
}

export const COOKIE_AUTH_TOKEN_KEY = 'x-token';
export const MY_ID = 'my-id';
export const MY_NAME = 'my-name';
export const ALL_GAME_ID: SupportedGameId[] = [
  api.GameId.Csgo,
  api.GameId.PubgMobile,
  api.GameId.Standoff2,
  api.GameId.LeagueOfLegends,
  api.GameId.LostArkEn,
  api.GameId.WorldOfWarcraft,
  // Bulk
  api.GameId.CodMobile,
  api.GameId.GooseGooseDuck,
  api.GameId.Hearthstone,

  api.GameId.ShadowFight3,
  api.GameId.ShadowFight4Arena,
  api.GameId.DawnOfZombiesSurvival,
  api.GameId.Crossfire,
  api.GameId.NewStateMobile,
  api.GameId.RiseOfEmpires,
  api.GameId.ModernStrikeOnlinePvPfps,
  api.GameId.RiseOfKingdomsLostCrusade,
  api.GameId.IdleHeroes,
  api.GameId.LastDayOnEarthSurvival,
  api.GameId.MobileLegendsAdventure,
  api.GameId.Stalker2HeartOfChornobyl,
  api.GameId.Highrise,
  api.GameId.AfkArena,

  api.GameId.Custom,
];

const clearLocalStorage = (): void => {
  const isCompleted = getIsOnboardingCompletedKey(undefined);
  const gamesCompleteStatus = ALL_GAME_ID.map(gameId => {
    const isCompletedKey = getIsOnboardingCompletedKey(gameId);

    return {
      key: isCompletedKey,
      isCompleted: localStorage.getItem(isCompletedKey) === 'true',
    };
  });
  localStorage.clear();
  if (isCompleted) {
    localStorage.setItem(isCompleted, 'true');
  }
  gamesCompleteStatus.forEach(status => {
    if (!status.isCompleted) {
      return;
    }
    localStorage.setItem(status.key, 'true');
  });
};

export const logout = async (client: ApolloClient<any>): Promise<void> => {
  cookie.delete(COOKIE_AUTH_TOKEN_KEY);
  clearLocalStorage();
  await client.resetStore();
  client.cache.gc();
  location.replace('/');
};

export const getToken = (
  cookieHeader: string | undefined = undefined,
): string | undefined => {
  // while server side get cookie header
  return cookie.get(COOKIE_AUTH_TOKEN_KEY, cookieHeader);
};

export const useMe = (): api.Maybe<api.User> => {
  const data = api.useGetMeQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });
  // TODO: To Layour

  useEffect(() => {
    async function handleKeyPress(event: KeyboardEvent) {
      if (event.keyCode === 113) {
        const { toggleScreenParams } = await import('../utils/screenParams');
        toggleScreenParams();
      }
    }

    document.addEventListener('keydown', handleKeyPress);

    return () => document.removeEventListener('keydown', handleKeyPress);
  }, []);

  useEffect(() => {
    (window as any).getMe = () => data.data?.getMe.user;
    (window as any).setMe = (token: string) =>
      cookie.set(COOKIE_AUTH_TOKEN_KEY, token);
    (window as any).getMyToken = getToken;

    (window as any).logout = () => logout(data.client);
  }, [data]);

  if (typeof window !== 'undefined') {
    const me = data.data?.getMe.user;
    const myId = me?.id;
    const myLocalStorageId = localStorage.getItem(MY_ID);

    if (myId && !myLocalStorageId) {
      localStorage.setItem(MY_ID, myId);
      if (me) {
        localStorage.setItem(MY_NAME, me.username);
      }
    } else if (!myId && myLocalStorageId) {
      localStorage.removeItem(MY_ID);
      localStorage.removeItem(MY_NAME);
    }
  }

  return data.data?.getMe.user as api.User;
};

export const useMeFull = (): api.Maybe<api.Me> => {
  const data = api.useGetMeQuery({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  if (typeof window !== 'undefined') {
    const me = data.data?.getMe.user;
    const myId = me?.id;
    const myLocalStorageId = localStorage.getItem(MY_ID);

    if (myId && !myLocalStorageId) {
      localStorage.setItem(MY_ID, myId);
      if (me) {
        localStorage.setItem(MY_NAME, me.username);
      }
    } else if (!myId && myLocalStorageId) {
      localStorage.removeItem(MY_ID);
      localStorage.removeItem(MY_NAME);
    }
  }

  return data.data?.getMe as api.Me;
};

export const useMyLfgs = (params?: { skip?: boolean }): api.Maybe<api.Lfg[]> => {
  const data = api.useGetMyLfgQuery({
    skip: params?.skip,
  });
  const profileLfgs = data.data?.getMe.user?.lfg;

  useEffect(() => {
    (window as any).getMyLfgs = () => profileLfgs;
    (window as any).promiseMyLfgs = data.refetch;
  }, [data]);

  return data.data?.getMe?.user?.lfg as api.Maybe<api.Lfg[]>;
};

export const useMyLfgsWithoutGames = (params?: {
  skip?: boolean;
}): api.Maybe<api.Lfg[]> => {
  const data = api.useGetMyLfgWithoutGamesQuery({
    skip: params?.skip,
  });
  useEffect(() => {
    (window as any).getMyLfgs = () => data.data?.getMe?.user?.lfg;
    (window as any).promiseMyLfgs = data.refetch;
  }, [data]);

  return data.data?.getMe?.user?.lfg as api.Maybe<api.Lfg[]>;
};

export const useMyGames = (params?: { skip?: boolean }): api.Maybe<api.Game[]> => {
  const data = api.useGetMyGamesQuery({
    skip: params?.skip,
  });

  useEffect(() => {
    (window as any).getMyGames = () => data.data?.getMyGames;
    (window as any).promiseMe = data.refetch;
  }, [data]);

  return data.data?.getMyGames as api.Maybe<api.Game[]>;
};

// Used for refetchQueries
export const refetchMe: PureQueryOptions = { query: api.GetMeDocument };
export const refetchMyGames: PureQueryOptions = { query: api.GetMyGamesDocument };
export const refetchMyLfg: PureQueryOptions = { query: api.GetMyLfgDocument };
export const refetchMyCounters: PureQueryOptions = {
  query: api.GetMyFollowingCountDocument,
};
export const refetchMyEvents: PureQueryOptions = {
  query: api.GetMyEventsDocument,
};
export const refetchEvents = (
  from: Date,
): PureQueryOptions<api.SearchEventsQueryVariables> => ({
  query: api.SearchEventsDocument,
  variables: {
    filter: {
      from: from.toISOString(),
      platform: api.Platform.Web,
    },
    iteratorOptions: { limit: 100 },
  },
});
export const refetchFollowSuggestions = {
  query: api.GetFollowSuggestionsDocument,
  variables: {
    filter: {
      platform: api.Platform.Web,
    },
    iteratorOptions: { limit: 10 },
  },
};

type MeState = {
  me: api.Maybe<api.User>;
};

export const useGetMeState = (): MeState => {
  const me = useMe();

  useEffect(() => {
    (window as any).me = { me };
  }, [me]);

  return { me };
};

type MeFullState = {
  me: api.Maybe<api.Me>;
};

export const useGetMeFullState = (): MeFullState => {
  const me = useMeFull();

  useEffect(() => {
    (window as any).me = { me };
  }, [me]);

  return { me };
};
