import ReactGA from 'react-ga4';

import { MY_ID, MY_NAME } from 'api';
import { IS_BETA } from 'const';
import { setEventPage } from 'utils/analytics';
import { camelToSnake, convertWordToSnakeCase } from 'utils/main';

export const TRACKING_ID = IS_BETA ? `G-3C84622HEC` : `G-F9NMWC1MEJ`;

export const pageview = (url: string): void => {
  ReactGA.gtag('config', TRACKING_ID, {
    page_path: url,
  });
};

export type EventPage =
  | 'mainPage'
  | 'gamePage'
  | 'orgPage'
  | 'profilePage'
  | 'myGroupPage'
  | 'notificationsPage'
  | 'playersPage'
  | 'settingsPage'
  | 'gameSettingsPage'
  | 'createGroupPage'
  | 'loginPopup'
  | 'groupPage'
  | 'waitlistPage'
  | 'welcomePage'
  | 'teamsPage'
  | 'vacanciesPage';

export type EventGame = 'wow' | 'wowbc' | 'nw' | 'la' | 'hs' | 'csgo' | 'wz' | 'wr';

type UaEventOptions = {
  action: string;
  category?: string;
  eventPage?: EventPage;
  additionalParams?: Record<string, string>;
};

export type Event = Omit<UaEventOptions, 'action' | 'category'>;

export const event = ({
  action,
  category,
  eventPage,
  additionalParams,
}: UaEventOptions): void => {
  try {
    const page = eventPage || setEventPage();
    const params = additionalParams || {};
    const myId = localStorage.getItem(MY_ID);
    const myName = localStorage.getItem(MY_NAME);

    const authParam = myId
      ? { eventUserId: myId, eventUserNickname: myName }
      : { notAuthorised: true };

    const convertedParams = camelToSnake({
      eventCategory: category ? convertWordToSnakeCase(category) : undefined,
      eventPage: convertWordToSnakeCase(page),
      ...authParam,
      ...params,
    });

    ReactGA.gtag('event', convertWordToSnakeCase(action), {
      ...convertedParams,
    });
  } catch (error) {
    console.warn('GAnalytics error:', error);
  }
};
