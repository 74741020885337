import { api } from 'api';

import { GAME_SLUGS } from './location';

import type { EventPage } from 'analyticsHelpers';

function parseRouteToGame(gameSlug: string) {
  switch (gameSlug) {
    case 'wow':
      return api.GameId.WorldOfWarcraft;
    case 'lol':
      return api.GameId.LeagueOfLegends;
    case 'hs':
      return api.GameId.Hearthstone;
    case 'csgo':
      return api.GameId.Csgo;
    case 'lost-ark':
      return api.GameId.LostArkEn;
    case 'pubg-mobile':
      return api.GameId.PubgMobile;
    case 'cod-mobile':
      return api.GameId.CodMobile;
    case 'standoff2':
      return api.GameId.Standoff2;
    case 'ggd':
      return api.GameId.GooseGooseDuck;
    case 'shadow-fight-3':
      return api.GameId.ShadowFight3;
    case 'shadow-fight-4-arena':
      return api.GameId.ShadowFight4Arena;
    case 'dawn-of-zombies-survival':
      return api.GameId.DawnOfZombiesSurvival;
    case 'crossout-mobile':
      return api.GameId.Crossfire;
    case 'new-state-mobile':
      return api.GameId.NewStateMobile;
    case 'rise-of-empires':
      return api.GameId.RiseOfEmpires;
    case 'modern-strike':
      return api.GameId.ModernStrikeOnlinePvPfps;
    case 'rise-of-kingdoms':
      return api.GameId.RiseOfKingdomsLostCrusade;
    case 'idle-heroes':
      return api.GameId.IdleHeroes;
    case 'last-day-on-earth-survival':
      return api.GameId.LastDayOnEarthSurvival;
    case 'mobile-legends-adventure':
      return api.GameId.MobileLegendsAdventure;
    case 'stalker-2-hoc':
      return api.GameId.Stalker2HeartOfChornobyl;
    case 'highrise':
      return api.GameId.Highrise;
    case 'afk-arena':
      return api.GameId.AfkArena;
    case 'other':
      return api.GameId.Custom;
    default:
      return api.GameId.Custom;
  }
}

const usualPages = ['/my', '/notifications'];
const gamePages = ['players'];

function checkForUsualPage(pathname: string): EventPage | undefined {
  if (pathname.includes('/g/') || pathname.includes('/groups')) {
    return 'groupPage';
  }
  if (pathname.includes('/players')) {
    return 'playersPage';
  }
  if (pathname.includes('/teams')) {
    return 'teamsPage';
  }
  if (pathname.includes('/vacancies')) {
    return 'vacanciesPage';
  }

  switch (pathname) {
    case '/':
      return 'mainPage';
    case '/my':
      return 'myGroupPage';
    case '/notifications':
      return 'notificationsPage';

    default:
      return undefined;
  }
}

export function setEventPage(): string {
  if (typeof window === 'undefined') {
    return '';
  }

  const pathname = window.location.pathname;

  const usualPageUrl = usualPages.find(page => pathname.includes(page));

  if (usualPageUrl) {
    const usualPage = checkForUsualPage(usualPageUrl);

    if (usualPage) {
      return usualPage;
    }
  }

  const pathnameParts = pathname.slice(1).split('/');

  const gamePageUrl = Object.values(GAME_SLUGS).find(
    gameSlug => gameSlug === pathnameParts[0],
  );

  if (gamePageUrl) {
    const game = parseRouteToGame(gamePageUrl);
    const gamePage = pathnameParts[1]
      ? gamePages.find(page => page === pathnameParts[1])
      : 'events';

    return `${gamePage + game}Page`;
  }

  if (pathname === '/') {
    return 'mainPage';
  }

  if (pathnameParts[1] === 'events') {
    return 'groupPage';
  }

  return 'profilePage';
}
