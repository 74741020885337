export function isMobileBrowser(userAgent: string | undefined): boolean {
  return Boolean(userAgent?.match(/Android/i) || userAgent?.match(/iPhone/i));
}

export interface AppContextInitialProps {
  hasRenderedOnServer: boolean;

  acceptLanguage: string | undefined;
  preferredLanguage: string | undefined;
  queryLanguage: string | undefined;
  userAgent: string | undefined;
  acceptLanguageRaw: string | undefined;
}
