export const PRODUCTION_API_ENDPOINT = 'api.lf.group';
export const BETA_API_ENDPOINT = 'devapi.lf.group';
export const IS_BETA = process.env.NEXT_PUBLIC_API === BETA_API_ENDPOINT;
export const API_ENDPOINT = IS_BETA ? BETA_API_ENDPOINT : PRODUCTION_API_ENDPOINT;
export const COOKIE_REGION_KEY = 'cluster-region';

export const ADD_LFG_BOT_LINK = 'https://lf.group/bot/invite';
export type SocialMedia = 'Twitter' | 'Discord' | 'TikTok' | 'YouTube';

export const SOCIAL_MEDIA_LINKS: Record<SocialMedia, string> = {
  Discord: 'https://discord.gg/lfgroup',
  Twitter: 'https://twitter.com/lfgtool',
  TikTok: 'https://www.tiktok.com/@lfgrouppubgmobile',
  YouTube: 'https://youtube.com/@lfgroup_pubg_mobile',
};

export const SITE_DOMAIN = IS_BETA ? 'beta.lf.group' : 'lf.group';
