//<autogenerated/>
// Generated. Do not edit directly

export const translations = {
  english: {
    bpRules: {
      pubg: {
        title: 'PUBG Mobile Battle Pass rules | LF.Group',
        ogTitle: 'PUBG Mobile custom rooms Leaderboard Battle Pass',
        ogDescription:
          'Join our PUBG Mobile custom rooms leaderboard for a chance to win a share of 15,000 UC in weekly prizes. Show off your skills, beat the competition, and take home the ultimate prize!',
        ogImage: 'https://preview.lf.group/search/pubg-mobile',
        goToLeaderboard: 'Go to leaderboard',
        joinParagraph:
          'Invite your friends to join LF.Group and earn points to advance on the PUBG Mobile custom rooms Leaderboard.',
        prizesParagraph:
          'The rewards for inviting your friends to LF.Group are as follows:',
        prize1st: 'One invited friend will grant you +5 Leaderboard points.',
        prize2nd:
          'Two invited friends will add +10 Leaderboard points for you.',
        prize6th:
          'Three invited friends will provide you with +15 Leaderboard points.',
        paragraph2:
          'To initiate this process, please navigate to the Battle Pass panel and copy the unique invite link. Then, share this link with those who havenʼt previously registered for an LF.Group account and ensure that they complete their PUBG Mobile profile description and tags.',
        paragraph3:
          'Once your friend successfully registers with LF.Group, youʼll receive a +1 in the LF.Group Battle Pass progress. Please note that your friendʼs PUBG ID must not have been used on LF.Group previously to be eligible for the rewards.',
        paragraph4:
          'Your rewards will be added every time you invite a friend. If you manage to invite three friends, youʼll get 30 Leaderboard points in total, which is a significant step towards winning the weekly prizes.',
      },
    },
    calendar: {
      title: 'Timetable',
      addModalTitle: 'New free slot/playing time',
      actions: {
        addEvent: 'Add event',
        add: 'Add',
        cancel: 'Cancel',
        save: 'Save',
      },
      forms: {
        title: 'Title',
        pickDay: 'Pick day',
        from: 'From',
        to: 'To',
        recurring: 'Recurring',
      },
      weekDays: {
        monday: {
          full: 'Monday',
          short: 'Mon',
          extraShort: 'Mo',
        },
        tuesday: {
          full: 'Tuesday',
          short: 'Tue',
          extraShort: 'Tu',
        },
        wednesday: {
          full: 'Wednesday',
          short: 'Wed',
          extraShort: 'We',
        },
        thursday: {
          full: 'Thursday',
          short: 'Thu',
          extraShort: 'Th',
        },
        friday: {
          full: 'Friday',
          short: 'Fri',
          extraShort: 'Fr',
        },
        saturday: {
          full: 'Saturday',
          short: 'Sat',
          extraShort: 'Sa',
        },
        sunday: {
          full: 'Sunday',
          short: 'Sun',
          extraShort: 'Su',
        },
      },
      errors: {
        title: 'Required field',
      },
    },
    chat: {
      title: 'Chat',
      networkStatus: {
        typing: 'typing',
        connecting: 'connecting',
        updating: 'updating',
      },
      errors: {
        authRequired: 'Create an account to chat and play with others',
        loadingNewChat: 'Preparing your chat...',
        notSelected: 'Select a chat to start chatting...',
        userBanned: {
          title: 'You are banned',
          subtitle:
            'Admin of this chat blocked you, and you cannot stay in this chat anymore',
        },
        selectRegion: {
          title: 'Select a region and server',
          subtitle:
            'You can chat with other players, once you select your region and server',
        },
        noAccess: {
          title: 'Become a member to start chatting',
          subtitle:
            'Once you are a member of this group you will be able to chat here',
        },
        oldGroup: {
          title: 'There is no chat here',
          subtitle:
            'This group was created before we made chats. \nYou may find chats in a newer group',
        },
      },
      private: {
        invite: {
          title: 'This is invite only chat',
          subtitle:
            'You are joining the private chat. Here are just members who were invited by the owner',
        },
      },
      writebox: {
        loginToStartChatting: 'Log In and start chatting',
        placeholder: 'Type a message...',
        editingMessage: 'Editing message',
        placeholderAddComment: 'Add comment...',
        messageMaxLengthOverflow: 'Max message length is 3000 symbols',
        popupDelete: 'Are you sure you want to delete this message?',
      },
      header: {
        memberCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'members'
              : amount > 1
              ? 'members'
              : 'member'
          }`,
        typingCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0 ? 'are' : amount > 1 ? 'are' : 'is'
          } typing`,
        userTyping: (value: string): string => `${value} is typing`,
      },
      message: {
        popup: {
          reply: 'Reply',
          copyLink: 'Copy Link',
          report: 'Report',
          forward: 'Forward',
          deleteMessage: 'Delete',
          openThread: 'Open thread',
          edit: 'Edit',
        },
        comment: 'Comment',
        repliesCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'replies'
              : amount > 1
              ? 'replies'
              : 'reply'
          }`,
        viewsCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0 ? 'views' : amount > 1 ? 'views' : 'view'
          }`,
        noMessages:
          'Write a first message or invite friends to make chat even better',
        fetchBefore: 'Load before',
        fetchAfter: 'Load next',
      },
    },
    controls: {
      errors: {
        404: {
          text: 'Not found',
          action: 'Refresh',
        },
        noActiveGroups: 'No active events yet',
        mustAuth: 'Log in first',
      },
      loading: 'Loading...',
      actions: {
        yes: 'Yes',
        no: 'No',
        ok: 'Ok 👌',
        cancel: 'Cancel',
        close: 'Close',
        send: 'Send',
        save: 'Save',
        share: 'Share',
        add: 'Add',
        delete: 'Delete',
        edit: 'Change',
        showMore: 'Show more',
        showLess: 'Show less',
        leave: 'Leave',
        later: 'Later',
        create: 'Create',
        up: 'Up',
        addGames: 'Add games',
        addLinks: 'Edit links',
        findFriends: 'Update profile',
        clickToUploadCover: 'Open settings to upload cover',
        updateFeed: 'Update feed',
        selectAnOption: 'Select an option',
        editProfile: 'Edit profile',
      },
      followers: {
        badge: (amount: number) => `Followers ${amount}+`,
        actionFollow: 'Follow',
        actionUnfollow: 'Following',
      },
      invites: {
        actionInvite: 'Invite',
        successStatus: 'Invite sent',
      },
      list: {
        more: (amount: number) => `& ${amount} more`,
      },
      modal: {
        closeWhilensaved: {
          title:
            'There is an unsaved progress. Are you sure you want to leave?',
          yes: 'Close',
          no: 'Stay',
        },
      },
      filters: {
        actionMore: {
          showMore: 'All filters',
          showLess: 'Hide filters',
        },
      },
      navigation: {
        searchGroups: 'Find an event',
        myGroups: 'My events',
        notifications: 'Notifications',
        players: 'Players',
        profile: 'Profile',
        home: 'Home',
        actionCreateGroup: 'Create an event',
        yourGames: 'Your games',
        lfgGames: 'LF.Games',
        chats: 'Chats',
        pendingRequests: (value: string): string =>
          `You have ${value} pending request to join your team`,
      },
      loginModal: {
        title: 'Continue with',
        reconnection: (value: string): string => `Connect ${value} again`,
        action: 'Log in',
        signUp: 'Sign up',
        signUpTitle: 'LFG will help you find some good teammates',
        createGroupTitle:
          'Want to create an event? \nLog in to join and unlock all features',
        joinGroupTitle:
          'Want to join the event? \nLog in to join and unlock all features',
        maybeLater: 'Maybe later',
        bestTeammates: 'Best \nteammates',
        favGames: 'All your \nfav games',
        biggestCommunity: 'Biggest \ncommunity',
        inControl: 'Everything \nin your control',
        gameSettings: {
          checkWhatsHappeningNow: 'Check what’s happening now',
          battlenetConnectionError:
            'We were not able to find a character in your account. \nIf you cannot see your character try to connect Battle.net again',
          loading: 'Please wait. Loading.. \nIt can take up to a few minutes',
          gameAdded: 'Game added to you profile',
          canShareGame:
            'Your game profile is now all set. \nYou may join different events from other players and influencers. \nYour profile can now be found in search, people can follow and message you.',
          yourCharacters: 'Your characters',
          region: 'Region',
          addComment: 'Add a comment..',
          gameCommentPlaceholder:
            'Tell people what activities you are looking for',
          browseGroups: 'Browse events',
          goToProfile: 'Go to profile',
          addMoreGames: 'Add another game',
          createGroup: 'Create event',
          findTeammates: 'Find new teammates',
        },
        actions: {
          back: 'Back',
          continue: 'Continue',
          close: 'Close',
          finish: 'Finish',
        },
      },
      footer: {
        copyright: '© 2022 lf.group',
        joinDiscord: 'join us in',
        joinChannels: 'join our channels',
        menu: {
          about: 'About',
          help: 'Help',
          tos: 'Terms of Service',
          privacy: 'Privacy Policy',
          addBot: 'Add Discord Bot',
        },
      },
      language: {
        english: 'English',
        russian: 'Russian',
      },
      date: {
        today: 'Today',
        tomorrow: 'Tomorrow',
        yesterday: 'Yesterday',
        days: {
          0: {
            nomShort: 'Sun',
          },
          1: {
            nomShort: 'Mon',
          },
          2: {
            nomShort: 'Tue',
          },
          3: {
            nomShort: 'Wed',
          },
          4: {
            nomShort: 'Thu',
          },
          5: {
            nomShort: 'Fri',
          },
          6: {
            nomShort: 'Sat',
          },
        },
        months: {
          0: {
            nom: 'January',
            dat: (value: string): string => `${value} January`,
            datShort: (value: string): string => `${value} Jan`,
          },
          1: {
            nom: 'February',
            dat: (value: string): string => `${value} February`,
            datShort: (value: string): string => `${value} Feb`,
          },
          2: {
            nom: 'March',
            dat: (value: string): string => `${value} March`,
            datShort: (value: string): string => `${value} Mar`,
          },
          3: {
            nom: 'April',
            dat: (value: string): string => `${value} April`,
            datShort: (value: string): string => `${value} Apr`,
          },
          4: {
            nom: 'May',
            dat: (value: string): string => `${value} May`,
            datShort: (value: string): string => `${value} May`,
          },
          5: {
            nom: 'June',
            dat: (value: string): string => `${value} June`,
            datShort: (value: string): string => `${value} Jun`,
          },
          6: {
            nom: 'July',
            dat: (value: string): string => `${value} July`,
            datShort: (value: string): string => `${value} Jul`,
          },
          7: {
            nom: 'August',
            dat: (value: string): string => `${value} August`,
            datShort: (value: string): string => `${value} Aug`,
          },
          8: {
            nom: 'September',
            dat: (value: string): string => `${value} September`,
            datShort: (value: string): string => `${value} Sep`,
          },
          9: {
            nom: 'October',
            dat: (value: string): string => `${value} October`,
            datShort: (value: string): string => `${value} Oct`,
          },
          10: {
            nom: 'November',
            dat: (value: string): string => `${value} November`,
            datShort: (value: string): string => `${value} Nov`,
          },
          11: {
            nom: 'December',
            dat: (value: string): string => `${value} December`,
            datShort: (value: string): string => `${value} Dec`,
          },
        },
      },
    },
    errors: {
      network: {
        timeout: 'Request timed out :(',
      },
      unknown: 'Unknown error occured. Please, retry',
      codes: {
        GroupJoinSlotHasUser: 'Slot already occupied',
        AuthRequired: 'Log in first',
        InvalidUsername: 'Login is not valid',
        GroupDeleted: 'Group has been archived',
        GroupFull: 'Group is full',
        GroupRoleFull: (value: string): string =>
          `All slots for role ${value} are occupied`,
        GroupNameEmpty: 'Group title cannot be empty',
        GroupOwnerSlotRequried: 'You need to join a slot to create a group',
        GroupNameTooLong: 'Title is too big',
        GroupSlotsLimitExceeded: 'Too much slots, man',
        GroupNotOwner: 'Only group owner can do this',
        DeleteSocialAccountForbidden:
          'You cannot delete your last connected account',
        NotFound: 'Not found',
        PermissionDenied: 'Permission denied',
        ValidationError: 'Validation error',
        RuntimeError: 'Runtime error',
        RiotNotFound: 'Riot Profile with such game name not found',
        ThirdPartyTimeout: 'External API timed out',
        GroupJoinInvalidRole: 'Character role does not match with this slot',
        GroupJoinInvalidWowFaction:
          'Your faction does not match the requirements',
        GroupJoinInvalidRegion: 'Your region does not match the requirements',
        GroupJoinNoSocial:
          'You need to connect your Battle.net to join this group',
        GroupJoinLostArkLowGs: 'Your Gearscore is too low',
        GroupScheduledInPast: 'You cannot schedule an event in the past :(',
        AliasAlreadyExists: 'This URL already exists',
      },
      default: {
        createGroup: {
          title: 'Failed to create event',
        },
        updateGroup: {
          title: 'Failed to save edits',
        },
        connectBattlenet: {
          title: 'Failed to connect Battle.net',
        },
        connectTwitch: {
          title: 'Failed to connect Twitch',
        },
        connectDiscord: {
          title: 'Failed to connect Discord',
        },
        connectSteam: {
          title: 'Failed to connect Steam',
        },
        connectTwitter: {
          title: 'Failed to connect Twitter',
        },
        deleteTwitch: {
          title: 'Failed to disconnect Twitch',
        },
        deleteBattlenet: {
          title: 'Failed to disconnect Battle.net',
        },
        deleteDiscord: {
          title: 'Failed to disconnect Discord',
        },
        deleteSteam: {
          title: 'Failed to disconnect Steam',
        },
        deleteTwitter: {
          title: 'Failed to disconnect Twitter',
        },
        deleteUserGame: {
          title: 'Failed to hide a character',
        },
        restoreUserGame: {
          title: 'Failed to restore a character',
        },
        updateUserGame: {
          title: 'Failed to update a game info',
        },
        followUser: {
          title: 'Failed to follow a user',
        },
        unfollowUser: {
          title: 'Failed to unfollow from a user',
        },
      },
      clientErrors: {
        maxLegacyExceed: 'Maximum Roster lvl is 250',
        mustConnectBattlenet:
          'Connect your Battle.net account to create a group',
        mustHaveCharacter: (value: string): string =>
          `Add ${value} character to create a group`,
        riot: {
          mustSelectRegion: 'Choose the Riot region first',
          invalidGameName: 'Invalid game name',
        },
        incorrectNumber: 'Incorrect number',
        required: 'Requred',
        urlValidation: 'Url is not valid',
        russianSymbols:
          'Your group title contains russian, which may be misunderstood by others',
        inputRussianSymbols:
          'Your param contains russian, which may be misunderstood by others',
        gameRequiredFields:
          'If a character contains empty fields, it will not be updated',
        invalidCharacters: 'Invalid characters',
        isReserved: 'This value is reserved',
        tooShort: 'Too short',
      },
    },
    event: {
      title: 'Upcoming Event',
      host: 'Host',
      leaderboard: {
        label: 'Play custom rooms to win 15 000 UC',
        action: 'Check your score in Leaderboard',
      },
      layout: {
        progressBarFilling: (value: string, value2: string): string =>
          `${value} out of ${value2} members joined`,
      },
      actions: {
        standoff2: {
          playDescription: 'Play\nto double your chances to win!',
          playAction: 'Launch Standoff 2',
        },
        shop: {
          shopAction: 'Open Shop',
          shopBannerTitle: (value: string, value2: string): string =>
            `${value} ${value2} Shop`,
          shopBannerDescription: (value: string): string =>
            `A new way to buy in-game items and support ${value}`,
          pubgShopBannerDescription: (value: string): string =>
            `A new way to buy UC codes and support ${value}`,
          authTitle: 'Sign in to get access to the shop',
        },
        join: {
          joinAction: 'Join',
          joined: 'Joined',
        },
        play: {
          playAction: 'Play',
          tooEarly: {
            playSoon: (value: string): string => `Starts in ${value}`,
            hour: (amount: number) =>
              `${amount} ${
                amount > 5 || amount === 0 ? 'hrs' : amount > 1 ? 'hrs' : 'hour'
              }`,
            minute: (amount: number) =>
              `${amount} ${
                amount > 5 || amount === 0
                  ? 'mins'
                  : amount > 1
                  ? 'mins'
                  : 'minute'
              }`,
            second: (amount: number) =>
              `${amount} ${
                amount > 5 || amount === 0
                  ? 'secs'
                  : amount > 1
                  ? 'secs'
                  : 'sec'
              }`,
          },
          tooLate: {
            playAction: 'Event has ended',
          },
        },
        leave: {
          leaveAction: 'Leave',
        },
        yesAction: 'Yes',
        viewAction: 'View',
        maybeAction: 'Maybe',
        noAction: 'No',
        giveaway: {
          join: 'Take part',
          joined: 'Joined',
          notify: 'Notify',
          subscribed: 'Subscribed',
          followHost: 'Follow host',
          unfollowHost: 'Unfollow host',
        },
        link: {
          open: 'Open link',
        },
        edit: {
          editAction: 'Edit',
        },
        shuffle: {
          shuffleAction: 'Shuffle',
        },
      },
      tabs: {
        members: (amount: number) => `${amount} joined`,
        people: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'members'
              : amount > 1
              ? 'members'
              : 'member'
          }`,
        waitlist: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0 ? 'want' : amount > 1 ? 'want' : 'wants'
          } to join`,
        maybe: (amount: number) => `${amount} maybe`,
        declined: (amount: number) => `${amount} declined`,
        winners: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'winners'
              : amount > 1
              ? 'winners'
              : 'winner'
          }`,
      },
      contextActions: {
        approve: 'Approve',
        makeWinner: 'Select a winner',
        reject: 'Reject',
        kick: 'Kick',
        ban: 'BAN',
      },
      emptyState: {
        noMembers: {
          waitlist: {
            title: 'No one has joined this waitlist just yet',
          },
          members: {
            title: 'No one has joined this event just yet',
          },
          giveaway: {
            title: 'No one has joined this giveaway just yet',
            noWinners: 'Winners have not been selected yet',
            noWinnersDescription:
              'Winners will be selected randomly after the giveaway ends',
            viewJoinersAction: 'View joined players',
          },
        },
      },
      pubg: {
        copyIdAction: 'Copy ID',
        copyPasswordAction: 'Copy password',
        successToast: 'Copied to clipboard 👌',
        server: 'Server',
        password: 'Password',
        notPublished: 'Lobby password has not been published yet',
      },
      card: {
        remainingDays: (amount: number) =>
          `Available in ${amount} ${
            amount > 5 || amount === 0 ? 'days' : amount > 1 ? 'days' : 'day'
          }`,
        remainingHours: 'Available in',
        notification:
          'We will send a discord notification. Check discord is connected to your profile.',
      },
    },
    filters: {
      anyNumberValue: '0+',
      tagsPLaceholder: 'Choose tags',
      tags: 'Tags',
    },
    formAddGame: {
      addNewGamesDesc:
        'Add games to your profile to help people to find and play with you or just to browse your groups.',
      actionAddGames: 'Add games',
      actionRefresh: 'Refresh',
      actionDisconnect: 'Disconnect',
      wow: {
        noSocial: {
          description:
            'Connect your Battle.net account to add your character to your profile. It will be easier to join the group and find the right one',
          check1: 'Your Battle.net Account ID and BattleTag (Required)',
          check2: 'Your World of Warcraft profile',
        },
        params: {
          battlenetId: 'Battle.net ID',
          region: 'Region',
          descriptionPlaceholder:
            'Tell people what activities youre looking for',
        },
        charactersEdit: {
          title: 'Choose characters you want to display',
          yourCharactersTitle: 'Your characters',
          hidePlaceholder: 'Hide character',
          showPlaceholder: 'Show character again',
          loadingDesc: 'Please wait. Loading.. \nIt takes around 10 seconds',
          notFoundDesc:
            'We were not able to find a character in your account. \nIf you cannot see your character try to connect Battle.net again',
          actionReconnect: 'Reconnect Battle.net',
        },
      },
      lol: {
        description1:
          'Add your League of Legends profile via Riot Games token. Pop-up window will redirect you to',
        description2:
          'and when you login there we will receive a token that contains your profile nickname, KDA, game stats and most played champions.',
        description3:
          'All that will empower your personal LoL profile and will make it much easier to find amazing teammates.',
        description4:
          'If you are curious or cautious about the Riot Games token and Riot Sign On method please read this',
        article: 'article',
      },
      wildRift: {
        noSocial: {
          description:
            'Connect your Riot ID to get a personal stats card that will help you to find the right group. We daily update your account statistics',
          actionConnect: 'Connect Riot',
          actionConnectLoading: 'Checking details...',
        },
        characterEdit: {
          cardLoading: 'Please wait\nLoading...',
          notFound: {
            title: 'Summoner not found',
            description:
              'We have not found your LoL profile.\nTry to refresh Riot or contact us via',
          },
        },
        params: {
          riotId: 'Riot ID',
          region: 'Region',
          descriptionPlaceholder:
            'Tell people what activities youre looking for',
        },
      },
      lostArk: {
        description:
          'Enter information about your Lost Ark game profile that will help you to find the group.',
        createdSuccessfully: (value: string): string =>
          `${value} create success`,
        updatedSuccessfully: (value: string): string =>
          `${value} update success`,
        deletedSuccessfully: (value: string): string =>
          `${value} delete success`,
        character: 'character',
        build: 'build',
        account: 'account',
      },
    },
    gameData: {
      event: {
        anyTitle: 'Any type',
        allEvents: 'All Events',
        teamPlay: 'Team Play',
        game: 'Game',
        type: 'Type',
        title: 'Title',
        description: 'Description',
        dateStart: 'Date Start',
        from: 'From',
        until: 'Until',
        privacy: 'Privacy',
        customButtonUrl: 'Custom button URL',
        postButtonUrl: 'Message button URL',
        customButtonLabel: 'Button label',
        host: 'Host',
        Bet: 'Bet',
        Event: 'Event',
        Giveaway: 'Giveaway',
        Link: 'Link',
        Post: 'Message',
        Public: 'Public',
        Waitlist: 'Waitlist',
        Bets: 'Bets',
        Events: 'Events',
        Giveaways: 'Giveaways',
        Links: 'Links',
        membersGoal: 'Members count goal',
        membersGoalDescription: 'Members goal description',
        forApp: 'For the application',
      },
      gameMode: {
        anyTitle: 'Any mode',
        CSGO2v2: '2v2',
        CSGO5v5: '5v5',
        WowArena2v2: 'Arena 2x2',
        WowArena3v3: 'Arena 3x3',
        WowMythicPlus: 'Mythic+',
        WowRaid: 'Raid',
        WowRBG: 'RBG',
        HearthstoneBattlegrounds: 'Battlegrounds',
        HearthstoneBGLeague: 'Battlegrounds League',
        HearthstoneCustom: 'Custom',
        WowCustom: 'Custom',
        WarzoneBattleRoyale: 'Battle Royale',
        WarzonePlunder: 'Plunder',
        WowTBCArena5v5: 'Arena 5x5',
        WowTBCDungeon: 'Dungeon',
        HearthstoneLeagueSilvername: 'Silvername League',
        HearthstoneLeagueTricksterinoKsedden: 'Tricksterino & Ksedden League',
        HearthstoneLeagueFriendlyTournament: 'Hearthstone Friendly Tournament',
        LostArkRaidsOnGuardians: 'Guardian Raids',
        LostArkRaidsOnTheLordsOfFetrania: 'Raids On The Lords Of Fetrania',
        LostArkRaidsAbyss: 'Abyssal Raids',
        LostArkTrialsOfTheAbyss: 'Challenge Abyss Dungeons',
        LostArkGuardianHeroicRaids: 'Challenge/Trial Guardian Raids',
        LostArkAbyssDungeons: 'Abyss Dungeons',
        LostArkCube: 'Cube',
        LostArkBossRush: 'Boss Rush',
        LostArkOther: 'Other Activities',
        LostArkTestPath: 'Test Path',
        LostArkPlatinumFields: 'Platinum Fields',
        LostArkArena: 'Arena',
        LostArkAbyssalRaids: 'Abyssal Raids',
        LostArkLegionRaids: 'Legion Raids',
        PUBGMobileTournament: 'Tournament',
        PUBGMobileScrims: 'Scrims',
        PUBGMobileClassic: 'Classic',
        PUBGMobileArcade: 'Arcade',
        PUBGMobileGiveaway: 'Giveaway',
        PUBGMobileCustom: 'Custom Rooms',
        PUBGMobilePractice: 'Practice',
        CODMobileTournament: 'Tournament',
        CODMobileScrims: 'Scrims',
        CODMobileClassic: 'Classic',
        CODMobileArcade: 'Arcade',
        CODMobileGiveaway: 'Giveaway',
        CODMobileCustom: 'Custom',
        CODMobilePractice: 'Practice',
        WildRiftLFG: 'LFG',
        WildRiftRanked: 'Ranked',
        WildRiftPVP: 'PvP',
        WildRiftARAM: 'ARAM',
        WildRiftAI: 'AI',
        WildRiftUrf: 'U.R.F.',
        WildRiftEsportsTeam: 'Esports Team',
        LolNormal: 'Normal Draft',
        LolNormalBlind: 'Normal Blind',
        LolAram: 'ARAM',
        LolDuo: 'Ranked Duo',
        LolFlex: 'Ranked Flex',
        LolClash: 'Clash',
        LolCustom: 'Custom 5x5',
        LolCustomAram: 'Custom 5x5 ARAM',
        LolFeatured: 'Featured Mode (U.R.F. etc)',
      },
      gameModeTitle: {
        CSGO2v2: '2v2',
        CSGO5v5: '5v5',
        WowArena2v2: 'Arena',
        WowArena3v3: 'Arena',
        WowMythicPlus: 'Mythic Plus',
        WowRaid: 'Raid',
        WowRBG: 'RBG',
        WowCustom: '',
        HearthstoneBattlegrounds: 'Battlegrounds',
        HearthstoneBGLeague: 'Battlegrounds League',
        HearthstoneCustom: 'Custom',
        WarzoneBattleRoyale: 'Battle Royale',
        WarzonePlunder: 'Plunder',
        WowTBCArena5v5: 'Arena 5x5',
        WowTBCDungeon: 'Dungeon',
        HearthstoneLeagueSilvername: 'Silvername League',
        HearthstoneLeagueTricksterinoKsedden: 'Tricksterino & Ksedden League',
        HearthstoneLeagueFriendlyTournament: 'Hearthstone Friendly Tournament',
        LostArkRaidsOnGuardians: 'Guardian Raids',
        LostArkRaidsOnTheLordsOfFetrania: 'Raids On The Lords Of Fetrania',
        LostArkRaidsAbyss: 'Abyssal Raids',
        LostArkTrialsOfTheAbyss: 'Challenge Abyss Dungeons',
        LostArkGuardianHeroicRaids: 'Challenge, Trial Guardian Raids',
        LostArkAbyssDungeons: 'Abyss Dungeons',
        LostArkCube: 'Cube',
        LostArkBossRush: 'Boss Rush',
        LostArkOther: 'Other Activities',
        LostArkTestPath: 'Test Path',
        LostArkPlatinumFields: 'Platinum Fields',
        LostArkArena: 'Arena',
        LostArkAbyssalRaids: 'Abyssal Raids',
        LostArkLegionRaids: 'Legion Raids',
        PUBGMobileTournament: 'Tournament',
        PUBGMobileScrims: 'Scrims',
        PUBGMobileClassic: 'Classic',
        PUBGMobileArcade: 'Arcade',
        PUBGMobileGiveaway: 'Giveaway',
        PUBGMobilePractice: 'Practice',
        PUBGMobileCustom: '',
        CODMobileTournament: 'Tournament',
        CODMobileScrims: 'Scrims',
        CODMobileClassic: 'Classic',
        CODMobileArcade: 'Arcade',
        CODMobileGiveaway: 'Giveaway',
        CODMobilePractice: 'Practice',
        CODMobileCustom: '',
        WildRiftLFG: 'LFG',
        WildRiftRanked: 'Ranked',
        WildRiftPVP: 'PvP',
        WildRiftARAM: 'ARAM',
        WildRiftAI: 'AI',
        WildRiftUrf: 'U.R.F.',
        WildRiftEsportsTeam: 'Esports Team',
        LolNormal: 'Normal',
        LolAram: 'ARAM',
        LolDuo: 'Duo',
        LolFlex: 'Flex',
        LolClash: 'Clash',
        LolFeatured: 'Featured',
        LolCustom: 'Custom 5x5',
        LolCustomAram: 'Custom 5x5 ARAM',
      },
      slotRole: {
        anyTitle: 'Any role',
        Unset: 'Any role',
        WowTank: 'Tank',
        WowHeal: 'Healer',
        WowDamage: 'DPS',
        CsgoSupport: 'Support',
        CsgoawPer: 'AWP',
        CsgoLurker: 'Lurker',
        CsgoEntryFragger: 'Fragger',
        CsgoLeader: 'Leader',
        LolSolo: 'Top',
        LolJungle: 'Jungle',
        LolMid: 'Mid',
        LolDuo: 'Bot (ADC)',
        LolSupport: 'Support',
        LostArkDamage: 'DPS',
        LostArkSupport: 'Support',
      },
      wow: {
        alias: {
          title: 'Battletag',
        },
        region: {
          US: 'US',
          Europe: 'EU',
          APAC: 'APAC',
        },
        activities: {
          title: 'Activities',
          anyTitle: 'Any activity',
          placeholder: 'Choose activities',
        },
        playstyle: {
          title: 'Playstyle',
          anyTitle: 'Any playstyle',
        },
        minMythicRating: {
          title: 'Min Mythic+',
        },
        minRbgRating: {
          title: 'Min RBG rating',
        },
        minArenaRating: {
          title: 'Min arena rating',
        },
        wowRegion: {
          anyTitle: 'Any region',
          title: 'Region',
          US: 'US',
          Europe: 'EU',
          APAC: 'APAC',
          China: 'China',
          Korea: 'Korea',
          Taiwan: 'Taiwan',
        },
        server: {
          title: 'Server',
          anyForm: 'Any server',
        },
        role: {
          title: 'Role',
          placeholder: '',
          tank: 'Tank',
          heal: 'Healer',
          damage: 'DPS',
        },
        level: {
          title: 'Level',
          placeholder: 'any',
        },
        mythicPlusScore: {
          title: 'M+',
          placeholder: 'any',
        },
        sfoNormalKilled: {
          title: 'SFO N killed',
          placeholder: 'any',
        },
        sfoHeroicKilled: {
          title: 'SFO H killed',
          placeholder: 'any',
        },
        sfoMythicKilled: {
          title: 'SFO M killed',
          placeholder: 'any',
        },
        arena2x2: {
          title: '2x2',
          placeholder: 'any',
        },
        arena3x3: {
          title: '3x3',
          placeholder: 'any',
        },
        class: {
          title: 'Class',
          any: 'Any class',
          placeholder: 'some',
          unknown: 'Сlass not set',
          Paladin: 'Paladin',
          Warrior: 'Warrior',
          DeathKnight: 'Death knight',
          Hunter: 'Hunter',
          Shaman: 'Shaman',
          Druid: 'Druid',
          Rogue: 'Rogue',
          Monk: 'Monk',
          DemonHunter: 'Demon hunter',
          Priest: 'Priest',
          Mage: 'Mage',
          Warlock: 'Warlock',
        },
        race: {
          title: 'Race',
          placeholder: 'some',
          human: 'Human',
          dwarf: 'Dwarf',
          nightElf: 'Night elf',
          gnome: 'Gnome',
          draenei: 'Draenei',
          worgen: 'Worgen',
          pandaren: 'Pandaren',
          orc: 'Orc',
          undead: 'Undead',
          tauren: 'Tauren',
          troll: 'Troll',
          bloodElf: 'Blood elf',
          goblin: 'Goblin',
        },
        specialization: {
          title: 'Specialization',
          any: 'Any spec',
          unknown: 'Spec not set',
          DeathKnightBlood: 'Blood Death Knight',
          DeathKnightFrost: 'Frost Death Knight',
          DeathKnightUnholy: 'Unholy Death Knight',
          DemonHunterHavoc: 'Havoc Demon Hunter',
          DemonHunterVengeance: 'Vengeance Demon Hunter',
          DruidBalance: 'Balance Druid',
          DruidFeral: 'Feral Druid',
          DruidGuardian: 'Guardian Druid',
          DruidRestoration: 'Restoration Druid',
          HunterBeastMastery: 'Mastery Beast Hunter',
          HunterMarksmanship: 'Marksmanship Hunter',
          HunterSurvival: 'Survival Hunter',
          MageArcane: 'Arcane Mage',
          MageFire: 'Fire Mage',
          MageFrost: 'Frost Mage',
          MonkBrewmaster: 'Brewmaster Monk',
          MonkMistweaver: 'Mistweaver Monk',
          MonkWindwalker: 'Windwalker Monk',
          PaladinHoly: 'Holy Paladin',
          PaladinProtection: 'Protection Paladin',
          PaladinRetribution: 'Retribution Paladin',
          PriestDiscipline: 'Discipline Priest',
          PriestHoly: 'Holy Priest',
          PriestShadow: 'Shadow Priest',
          RogueAssassination: 'Assassination Rogue',
          RogueOutlaw: 'Outlaw Rogue',
          RogueSubtlety: 'Subtlety Rogue',
          ShamanElemental: 'Elemental Shaman',
          ShamanEnhancement: 'Enhancement Shaman',
          ShamanRestoration: 'Restoration Shaman',
          WarlockAffliction: 'Affliction Warlock',
          WarlockDemonology: 'Demonology Warlock',
          WarlockDestruction: 'Destruction Warlock',
          WarriorArms: 'Arms Warrior',
          WarriorFury: 'Fury Warrior',
          WarriorProtection: 'Protection Warrior',
        },
        faction: {
          any: 'Any faction',
          title: 'Faction',
          horde: 'Horde',
          alliance: 'Alliance',
        },
        raid: {
          title: 'Raid',
          anyTitle: 'Any raid',
          SepulcherOfTheFirstOnes: 'Sepulcher of the First Ones',
          SanctumOfDomination: 'Sanctum of Domination',
        },
        raidDifficulty: {
          title: 'Difficulty',
          anyTitle: 'Any difficulty',
          Normal: 'Normal',
          Heroic: 'Heroic',
          Mythic: 'Mythic',
        },
        dungeons: {
          anyTitle: 'Any dungeon',
          HallsOfAtonement: 'Halls Of Atonement',
          SanguineDepths: 'Sanguine Depths',
          Plaguefall: 'Plaguefall',
          TheNecroticWake: 'The Necrotic Wake',
          DeOtherSide: 'De Other Side',
          MistsOfTirnaScithe: 'Mists Of Tirna Scithe',
          SpiresOfAscension: 'Spires Of Ascension',
          TheaterOfPain: 'Theater Of Pain',
          TazaveshSoleahsGambit: 'Tazavesh: So’leah’s Gambit',
          TazaveshStreetsOfWonder: 'Tazavesh: Streets of Wonder',
          TBCHellfireRamparts: 'Hellfire Ramparts',
          TBCTheBloodFurnace: 'The Blood Furnace',
          TBCTheShatteredHalls: 'The Shattered Halls',
          TBCTheSlavePens: 'The Slave Pens',
          TBCTheUnderbog: 'The Underbog',
          TBCTheSteamvault: 'The Steamvault',
          TBCOldHillsbradFoothills: 'Old Hillsbrad Foothills',
          TBCTheBlack: 'TheBlack',
          TBCMorass: 'Morass',
          TBCTheMechanar: 'The Mechanar',
          TBCTheBotanica: 'The Botanica',
          TBCTheArcatraz: 'The Arcatraz',
          TBCManaTombs: 'Mana Tombs',
          TBCAuchenaiCrypts: 'Auchenai Crypts',
          TBCSethekkHalls: 'Sethekk Halls',
          TBCShadowLabyrinth: 'Shadow Labyrinth',
          OperationMechagonJunkyard: 'Operation Mechagon: Junkyard',
          OperationMechagonWorkshop: 'Operation Mechagon: Workshop',
          ReturnToKarazhanLower: 'Return to Karazhan: Lower',
          ReturnToKarazhanUpper: 'Return to Karazhan: Upper',
          GrimrailDepot: 'Grimrail Depot',
          IronDocks: 'Iron Docks',
        },
        fields: {
          login: 'Character name',
          description: 'Description',
          level: 'Level',
          rating: 'Rating',
          region: 'Region',
          realm: 'Realm',
          arenaScore: 'Arena score',
          guild: 'Guild',
          comment: 'Comment',
          specialization: 'Specialization',
          covenant: 'Covenant',
          raidSepulcher: 'SFO',
          arena2x2: '2x2',
          arena3x3: '3x3',
          mPlusPrevious: 'Mythic+ s2',
          mPlusCurrent: 'Mythic+',
        },
      },
      hearthstone: {
        alias: {
          title: 'Battletag',
        },
        region: {
          title: 'Region',
          any: 'Any region',
          Unset: 'Unset',
          Asia: 'Asia',
          China: 'China',
          Europe: 'Europe',
          US: 'US',
          Americas: 'Americas',
        },
        fields: {
          description: 'Описание',
          ratingBg: 'Рейтинг',
        },
      },
      pubg: {
        nickname: {
          title: 'Nickname',
        },
        kd: {
          title: 'K/D',
        },
        region: {
          title: 'Region',
          any: 'Any region',
          CIS: 'CIS',
          EU: 'EU',
          Turkey: 'Turkey',
          SEA: 'SEA',
          SouthAsia: 'South Asia',
          SA: 'South America',
          Arabian: 'Arabian',
        },
        rank: {
          title: 'Rank',
          any: 'Any rank',
          NoRank: 'No rank',
          Bronze: 'Bronze',
          Silver: 'Silver',
          Gold: 'Gold',
          Platinum: 'Platinum',
          Diamond: 'Diamond',
          Crown: 'Crown',
          Ace: 'Ace',
          AceMaster: 'Ace-Master',
          AceDominator: 'Ace-Dominator',
          Conqueror: 'Conqueror',
        },
      },
      cod: {
        nickname: {
          title: 'Nickname',
        },
        kd: {
          title: 'K/D',
        },
        region: {
          title: 'Region',
          any: 'Any region',
          NA: 'North America',
          SA: 'South America',
          SEA: 'South East Asia',
          SAM: 'South Asia & Middle East',
          EU: 'Europe',
          OC: 'Oceania',
          AF: 'Africa',
        },
        rank: {
          title: 'Rank',
          any: 'Any rank',
          NoRank: 'No rank',
          Rookie: 'Rookie I-V',
          Veteran: 'Veteran I-V',
          Elite: 'Elite I-V',
          Pro: 'Pro I-V',
          Master: 'Master',
          Grandmaster: 'Grandmaster',
          Legendary: 'Legendary',
        },
      },
      standoff2: {
        nickname: {
          title: 'Nickname',
        },
        gameId: {
          title: 'ID',
        },
        region: {
          title: 'Region',
          any: 'Any region',
          RU: 'RU',
        },
        rank: {
          title: 'Rank',
          any: 'Any rank',
          NoRank: 'No rank',
          Bronze: 'Bronze I-V',
          Silver: 'Silver I-V',
          Gold: 'Gold I-V',
          Phoenix: 'Phoenix',
          Ranger: 'Ranger',
          Champion: 'Champion',
          Master: 'Master',
          Elite: 'Elite',
          Legendary: 'Legendary',
        },
      },
      custom: {
        region: {
          title: 'Region',
          any: 'Any region',
          CIS: 'CIS',
          EU: 'EU',
          NA: 'NA',
          Asia: 'Asia',
        },
      },
      ggd: {
        region: {
          title: 'Region',
          any: 'Any region',
          CIS: 'CIS',
          EU: 'EU',
          NA: 'NA',
          Asia: 'Asia',
        },
        alias: {
          title: 'Nickname',
        },
      },
      steam: {
        region: {
          title: 'Region',
          any: 'Any region',
          EU: 'EU',
          NA: 'NA',
          SA: 'SA',
          CIS: 'CIS',
          Asia: 'Asia',
          WesternEurope: 'Western Europe',
          EasternEurope: 'Eastern Europe',
          NorthAmerica: 'North America',
          SouthAmerica: 'South America',
        },
      },
      warzone: {
        region: {
          any: 'Any region',
          USAndCanada: 'US and Canada',
          LatinAmerica: 'Latin America and Caribbean',
          EU: 'European Economic Area',
          Germany: 'Germany',
          Russia: 'Russia',
          MiddleEast: 'Middle East',
          Africa: 'Africa',
          Australia: 'Australia',
          Asia: 'New Zealand and other Asia',
          Pacific: 'Pacific',
          Japan: 'Japan',
        },
        fields: {
          login: 'Login',
          description: 'Description',
          region: 'Region',
        },
      },
      lol: {
        region: {
          title: 'Region',
          any: 'Any region',
          Brazil1: 'BR (Brazil)',
          EuNorth1: 'EUNE (Europe Nordic & East)',
          EuWest1: 'EUW (Europe West)',
          Japan1: 'JP (Japan)',
          Korea: 'KR (Korea)',
          NorthAmerica1: 'NA (North America)',
          LatinAmerica1: 'LAN (Latin America 1)',
          LatinAmerica2: 'LAS (Latin America 2)',
          Oceania1: 'OC (Oceania)',
          Russia: 'RU (Russia)',
          Turkey1: 'TR (Turkey)',
        },
        activities: {
          title: 'Activities',
          anyTitle: 'Any activity',
          placeholder: 'Choose activities',
        },
        playstyle: {
          title: 'Playstyle',
          anyTitle: 'Any playstyle',
        },
        rank: {
          solo: {
            short: 'SoloQ',
            full: 'SoloQ Rank',
          },
          flex: {
            short: 'FlexQ',
            full: 'FlexQ Rank',
          },
        },
        category: {
          title: 'Category',
          any: 'Any category',
          Controller: 'Controller',
          Fighter: 'Fighter',
          Mage: 'Mage',
          Marksman: 'Marksman',
          Slayer: 'Slayer',
          Specialist: 'Specialist',
          Tank: 'Tank',
        },
        kda: 'Avg KDA',
        creepScore: 'Avg CS',
        minWinRate: {
          title: 'Min winrate',
        },
        winRate: {
          title: {
            short: (amount: number) => `Winrate ${amount}%`,
            full: 'Games',
          },
          wins: {
            short: (amount: number) => `W${amount}`,
            full: (amount: number) => `Win ${amount}`,
          },
          loses: {
            short: (amount: number) => `L${amount}`,
            full: (amount: number) => `Los ${amount}`,
          },
        },
      },
      wildRift: {
        region: {
          title: 'Region',
          Europe: 'EU',
          Americas: 'Americas',
          Asia: 'Asia',
        },
        riotId: {
          title: 'Riot ID',
        },
        tagLine: {
          title: 'Tagline',
        },
        preferredLine: {
          title: 'Role',
          primaryTitle: 'Primary role',
          secondaryTitle: 'Secondary role',
          placeholder: 'Any role',
        },
        rank: {
          title: 'Rank',
          any: 'Any rank',
          None: 'Unranked',
          Iron4: 'Iron IV',
          Iron3: 'Iron III',
          Iron2: 'Iron II',
          Iron1: 'Iron I',
          Bronze4: 'Bronze IV',
          Bronze3: 'Bronze III',
          Bronze2: 'Bronze II',
          Bronze1: 'Bronze I',
          Silver4: 'Silver IV',
          Silver3: 'Silver III',
          Silver2: 'Silver II',
          Silver1: 'Silver I',
          Gold4: 'Gold IV',
          Gold3: 'Gold III',
          Gold2: 'Gold II',
          Gold1: 'Gold I',
          Platinum4: 'Platinum IV',
          Platinum3: 'Platinum III',
          Platinum2: 'Platinum II',
          Platinum1: 'Platinum I',
          Emerald4: 'Emerald IV',
          Emerald3: 'Emerald III',
          Emerald2: 'Emerald II',
          Emerald1: 'Emerald I',
          Diamond4: 'Diamond IV',
          Diamond3: 'Diamond III',
          Diamond2: 'Diamond II',
          Diamond1: 'Diamond I',
          Master: 'Master',
          Grandmaster: 'Grandmaster',
          Challenger: 'Challenger',
        },
      },
      csgo: {
        alias: {
          title: 'Steam ID',
        },
        faceitLvl: {
          title: 'FACEIT Lvl',
          any: 'No lvl',
        },
        region: {
          title: 'Region',
          EuEast: 'EU East',
          EuWest: 'EU West',
          Eu: 'EU',
          Asia: 'Asia',
          US: 'US',
        },
        rank: {
          any: 'No rank',
          title: 'Rank',
          Silver1: 'Silver I',
          Silver2: 'Silver II',
          Silver3: 'Silver III',
          Silver4: 'Silver IV',
          SilverElite: 'Silver Elite',
          SilverEliteMaster: 'Silver Elite Master',
          GoldNova1: 'Gold Nova I',
          GoldNova2: 'Gold Nova II',
          GoldNova3: 'Gold Nova III',
          GoldNovaMaster: 'Gold Nova Master',
          MasterGuardian1: 'Master Guardian I',
          MasterGuardian2: 'Master Guardian II',
          MasterGuardianElite: 'Master Guardian Elite',
          DistinguishedMasterGuardian: 'Distinguished Master Guardian',
          LegendaryEagle: 'Legendary Eagle',
          LegendaryEagleMaster: 'Legendary Eagle Master',
          SupremeMasterFirstClass: 'Supreme Master First Class',
          GlobalElite: 'Global Elite',
        },
        role: {
          Support: 'Support',
          AWPer: 'AWPer',
          Lurker: 'Lurker',
          EntryFragger: 'Entry Fragger',
          IGL: 'IGL',
          Rifler: 'Rifler',
        },
        fields: {
          description: 'Description',
          region: 'Region',
          rank: 'Rank',
          role: 'Role',
        },
      },
      lostArk: {
        region: {
          title: 'Region',
          any: 'Any region',
          USWest: 'US West',
          USEast: 'US East',
          EUCentral: 'EU Central',
          RU: 'Russia',
          uknown: 'Unknown region',
        },
        activities: {
          title: 'Activities',
          anyTitle: 'Any activity',
          placeholder: 'Choose activities',
        },
        playstyle: {
          title: 'Playstyle',
          anyTitle: 'Any playstyle',
        },
        guardian: {
          title: 'Guardian',
          any: 'Any guardian',
          Urnil: 'UrNil',
          Lumerus: 'Lumerus',
          IcyLegoros: 'Icy Legoros',
          Vertus: 'Vertus',
          Chromanium: 'Chromanium',
          Nacrasena: 'Nacrasena',
          FlameFoxYoho: 'Flame Fox Yoho',
          Tytalos: 'Tytalos',
          DarkLegoros: 'Dark Legoros',
          Helgaia: 'Helgaia',
          Calventus: 'Calventus',
          Achates: 'Achates',
          FrostHelgaia: 'Frost Helgaia',
          LavaChromanium: 'Lava Chromanium',
          Levanos: 'Levanos',
          Alberhastic: 'Alberhastic',
          ArmoredNacrasena: 'Armored Nacrasena',
          Igrexion: 'Igrexion',
          NightFoxYoho: 'Night Fox Yoho',
          Velganos: 'Velganos',
          Descaluda: 'Descaluda',
        },
        abyssDungeonsAbyss: {
          title: 'Abyss',
          any: 'Any abyss',
          RuinsOfElberia: 'Ancient Elveria',
          AbodeOfDreams: 'Phantom Palace',
          CrucibleOfSouls: 'Ark of Arrogance',
          GreatGate: 'Gate of Paradise',
          HeartOfOreya: 'Orehas Well',
        },
        abyssDungeonsDungeon: {
          title: 'Abyss Dungeon',
          any: 'Any abyss dungeon',
          DemonBeastCanyon: 'Demon Beast Canyon',
          NecromancersOrigin: 'Necromancers Origin',
          HallOfTheTwistedWarlord: 'Hall of the Twisted Warlord',
          HildebrandtPalace: 'Hildebrandt Palace',
          RoadOfLament: 'Road of Lament',
          ForgottenForge: 'Forge of Fallen Pride',
          SeaOfIndolence: 'Sea of Indolence',
          TranquilKarkosa: 'Tranquil Karkosa',
          AlaricsSanctuary: 'Alarics Sanctuary',
          AirasOculus: 'Airas Oculus',
          OrehaPreveza: 'Oreha Preveza',
        },
        cubeDungeon: {
          title: 'Cube Dungeon',
          any: 'Any cube dungeon',
          Cube: 'Cube',
          EliteCube: 'Elite Cube',
          DimensionalCube: 'Dimensional Cube',
        },
        bossRush: {
          title: 'Boss Rush',
          any: 'Any boss rush',
          HallOfSilence: 'Hall Of Silence',
          HallOfTheSun: 'Hall Of The Sun',
        },
        platinumFieldsField: {
          title: 'Platinum Field',
          any: 'Any platinum field',
          NahunsDomain: 'Nahuns Domain',
          OldYudianCanal: 'Old Yudian Canal',
        },
        difficulty: {
          title: 'Difficulty',
          any: 'Any',
          anyForm: 'Any difficulty',
          Normal: 'Normal',
          Hard: 'Hard',
          Hell: 'Hell',
          Dejavu: 'Dejavu',
        },
        otherActivities: {
          title: 'Activities',
          anyForm: 'Any activities',
          any: 'Any',
          SecretMap: 'Secret Map',
          Misc: 'Misc.',
        },
        alias: {
          title: 'Username',
        },
        server: {
          title: 'Server',
          anyForm: 'Any server',
        },
        legacyLvl: {
          title: 'Roster lvl',
          anyForm: 'Any Roster lvl',
        },
        gearScore: {
          title: 'ilvl',
          anyForm: 'Any ilvl',
        },
        arena: {
          title: 'Arena',
          anyForm: 'Any arena',
          any: 'Any',
          NormalMatch: 'Normal Match',
          CoopBattle: 'Co-op Battle',
          CustomGame: 'Custom Game',
        },
        arenaMode: {
          title: 'Arena Mode',
          anyForm: 'Any arena mode',
          any: 'Any',
          TeamDeathmatch: 'Team Deathmatch',
          TeamElimination: 'Team Elimination',
        },
        raid: {
          title: 'Raid',
          anyForm: 'Any raid',
          any: 'Any',
          Argos: 'Argos',
          Valtan: 'Valtan',
          Brelshaza: 'Brelshaza',
          Vykas: 'Vykas',
          KakulSaydon: 'Kakul-Saydon',
        },
        phase: {
          title: 'Phase',
          anyForm: 'Any phase',
          any: 'Any',
          Phase1: 'Phase 1',
          Phase2: 'Phase 2',
          Phase3: 'Phase 3',
        },
        class: {
          title: 'Class',
          anyForm: 'Any class',
          any: 'Any',
          Berserker: 'Berserker',
          Paladin: 'Paladin',
          Gunlancer: 'Gunlancer',
          Striker: 'Striker',
          Wardancer: 'Wardancer',
          Scrapper: 'Scrapper',
          Soulfist: 'Soulfist',
          Glaivier: 'Glaivier',
          Gunslinger: 'Gunslinger',
          Machinist: 'Machinist',
          Destroyer: 'Destroyer',
          Artillerist: 'Artillerist',
          Deadeye: 'Deadeye',
          Sharpshooter: 'Sharpshooter',
          Bard: 'Bard',
          Arcanist: 'Arcanist',
          Summoner: 'Summoner',
          Sorceress: 'Sorceress',
          Shadowhunter: 'Shadowhunter',
          Deathblade: 'Deathblade',
          Reaper: 'Reaper',
        },
        build: {
          name: 'Build name',
          link: 'Link',
        },
        card: {
          title: 'Card',
          anyForm: 'Any card',
          placeholder: 'Choose cards',
          any: 'Choose card',
        },
        zone: {
          title: 'Zone',
          anyForm: 'Any zone',
          any: 'Any',
        },
        gift: {
          title: 'Gift',
          anyForm: 'Any gift',
          any: 'Choose gift',
          placeholder: 'Choose gifts',
        },
        continent: {
          title: 'Continent',
          anyForm: 'Any continent',
          any: 'Any',
        },
      },
      lfg: {
        tags: {
          twoByTwo: '2v2',
          threeByThree: '3v3',
          rbg: 'RBG',
          mythicPlus: 'Mythic +',
          pveRaid: 'PvE Raid',
          lookingForGuild: 'Looking for Guild',
          raidLeader: 'Raid Leader',
          learning: 'Learning',
          casual: 'Casual',
          tryhard: 'Tryhard',
          awc: 'AWC',
          mdi: 'MDI',
          voice: 'Voice',
          streamer: 'Streamer',
          mentor: 'Mentor',
          duo: 'Duo',
          flex: 'Flex',
          rift: 'Rift',
          aram: 'ARAM',
          normal: 'Normal',
          ranked: 'Ranked',
          clash: 'Clash',
          teamCaptain: 'Team Captain',
          semiPro: 'Semi-Pro',
          pve: 'PvE',
          pvp: 'PvP',
          de: 'DE',
          uk: 'UK',
          us: 'US',
          es: 'ES',
          mx: 'MX',
          fr: 'FR',
          br: 'BR',
          en: 'EN',
          it: 'IT',
          pl: 'PL',
          swe: 'SWE',
          tr: 'TR',
          argos: 'Argos',
          valtan: 'Valtan',
          brelshaza: 'Brelshaza',
          vykas: 'Vykas',
          KakulSaydon: 'Kakul-Saydon',
          orehasWell: 'Oreha`s Well',
          lookingForStaticGroup: 'Looking for Static group',
          semiHardcore: 'Semi-hardcore',
          hardcore: 'Hardcore',
          LoadsFreeTime: 'Loads of free time',
          LookingForFriends: 'Looking for friends',
          LookingForTeam: 'Looking for team',
          Pro: 'Pro player',
          TFT: 'TFT',
          FamilyPerson: 'Family Person',
          Teenager: 'Teenager',
          Tournaments: 'Casual Tournaments',
          Age2030: '20-30 years old',
          Age3040: '30-40 years old',
          Age40Plus: '40+ years old',
          NoVoice: 'No voice',
          Coach: 'Coach',
          LookingForMale: 'Looking for man',
          LookingForFemale: 'Looking for girl',
          Female: 'Girl',
          Male: 'Man',
          coaching: 'Coaching',
          fun: 'Fun',
          nightGames: 'NightGames',
          casualTournaments: 'Casual Tournaments',
          twentyThirtyYears: '20-30 years old',
          thirtyFourtyYears: '30-40 years old',
          fourtyPlusYears: '40+ years old',
          hellmode: 'Hellmode',
          gvg: 'GvG',
          abyssDungeons: 'Abyss Dungeons',
          lookingForRaid: 'Looking for Raid',
          lookingForCodriver: 'Looking for Co-driver',
          lookingForClan: 'Looking For Clan',
          lookingForGirl: 'Looking For Girl',
          lookingForMan: 'Looking For Man',
          moddedServers: 'Modded Servers',
          vanillaServers: 'Vanilla Servers',
          skilledShooter: 'Skilled Shooter',
          builder: 'Builder',
          girl: 'Girl',
          man: 'Man',
          fullSupport: 'Full-Support',
          semiSupport: 'Semi-Support',
          offlane: 'Offlane',
          mid: 'Mid',
          carry: 'Carry',
          smurf: 'Smurf',
          turbo: 'Turbo',
          lurker: 'Lurker',
          sniper: 'Sniper',
          support: 'Support',
          faceit: 'Faceit',
          nonBinary: 'Non-binary',
          freeAgent: 'Free Agent',
          tdm: 'TDM',
          customGames: 'Custom Games',
          captain: 'Captain',
          igl: 'IGL',
          manager: 'Manager',
          newbie: 'Newbie',
          morningPrimeTime: 'Morning prime time',
          daytimePrimeTime: 'Daytime prime time',
          eveningPrimeTime: 'Evening prime time',
          raid: 'Raid',
          arena: 'Arena',
          fps30Plus: '30+ fps',
          fps60Plus: '60+ fps',
          fps90Plus: '90+ fps',
          lookingForPlayers: 'Looking for players',
          age1114: '11-14 years old',
          age1519: '15-19 years old',
          age2024: '20-24 years old',
          age2530: '25-30 years old',
          ge30Plus: '30+ years old',
        },
      },
    },
    group: {
      slots: 'Slots',
      pasha: {
        info: {
          when: 'When',
          memberSince: (value: string): string => `Member since ${value}`,
          waitlist: {
            title: 'Waitlist',
            yes: 'Yes',
            no: 'No',
          },
        },
        title: (value: string, value2: string): string =>
          `Group ${value} of ${value2}`,
      },
      event: {
        createdTitle: (value: string): string => `Created a group ${value}.`,
        card: {
          slots: {
            joinAs: 'Join as:',
            team: 'Team:',
          },
        },
      },
      meta: {
        title: (value: string, value2: string): string =>
          `${value} in ${value2} | LF.Group`,
        description: (value: string): string =>
          `${value} is waiting for players. Open the link to check out more info`,
      },
      slot: {
        noAccounts: 'No accounts',
        actionInvite: 'Invite',
        actionJoin: 'Join',
        actionViewCustomAnswers: 'Answers',
        waitingToApprove: 'Waiting to approve',
        waitingToAccept: 'Waiting to accept',
        waitingToChooseSlote: 'Waiting to choose slot',
        lostArkSlot: {
          seeBuilds: 'See builds',
        },
      },
      balance: {
        action: 'Balance',
        notAllowed: {
          title: 'To balance teams fill all the slots',
        },
        success: {
          toast: 'Slots are now balanced',
        },
      },
      password: {
        example: '********',
        toastSuccess: 'Password is in your buffer 👌',
      },
      card: {
        status: {
          started: 'Started',
          ended: 'Finished',
        },
        typeWaitlist: (amount: number) => `Waitlist (${amount})`,
        customFilters: 'form',
        minKarma: (amount: number) => `karma: ${amount}`,
        joined: 'Joined',
        actionView: 'View',
        actionJoin: 'Join',
        actionLeave: 'Leave',
        actionApproveInvite: 'Approve',
        actionDeclineInvite: 'Decline',
      },
      controls: {
        actionRatePlayers: 'Rate teammates',
        actionLeave: 'Leave group',
        actionSaveChanges: 'Save',
        actionEditGroup: 'Edit',
        actionDelete: 'Delete group',
        actionStart: 'Start',
        actionFinish: 'Finish',
      },
      removeGroupModal: {
        text: 'Are you sure you want to delete this group?',
      },
      ratePlayersModal: {
        title: 'Rate your teammates',
        toastSuccess: 'Rates are saved',
        actionSkip: 'Skip',
        actionSubmit: 'Submit & finish',
      },
      customFilters: {
        title: 'Questions',
        actionAddQuestion: 'Add a question',
        checkboxIsRequired: 'Mandatory questions',
        questionPlaceholder: 'Your question...',
        optionPlaceholder: (amount: number) => `Option ${amount}`,
        actionAddOption: 'Add option',
        fillInputs: 'Fill all the inputs',
        questionType: {
          input: 'Text input',
          selectMany: 'Multiple choice',
          select: 'Single choice',
          text: 'Long answer',
        },
      },
      waitlist: {
        title: 'Requests',
        count: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'requests'
              : amount > 1
              ? 'requests'
              : 'request'
          }`,
        invites: 'Invited',
        usersInTheSlots: 'Players are already in the slots',
        actions: {
          viewAll: 'View All',
          shuffle: 'Shuffle',
          accept: 'Approve',
          decline: 'Remove',
          viewForm: 'Answers',
          deleteInvite: 'Remove',
          pickRandomly: 'Pick randomly',
        },
      },
      editing: {
        title: 'Edit group',
        groupInfo: 'Group info',
        fields: {
          name: 'Name',
        },
        actions: {
          delete: 'Delete group',
          save: 'Save',
          cancel: 'Cancel',
        },
      },
      actionDiscordInvite: 'Server invite',
      actionCardLink: 'Card link',
      actionChannelLink: 'Channel link',
      actionGoBack: 'Go back to list',
      actionEditGroup: 'Edit',
      errors: {
        400: {
          text: 'Failed to open the group',
        },
        404: {
          text: 'Group not found',
        },
        errorNoGame: 'Choose a game',
        errorNoTitle: 'Give group a name',
        errorJoinNoChar: 'You need to add a character to join the event',
        errorCreateNoChar:
          'You need to add or choose a character to create the event',
        wow: {
          errHighIlvl: 'ilvl exceeds the max treshold - 300',
        },
      },
      status: {
        gameStarted: 'Game started',
        gameEnded: 'Game ended',
      },
      params: {
        header: 'Group settings',
        titlePlaceholder: 'Title',
        description: 'Description',
        waitlistCheckbox: 'Waitlist',
        scheduledDateTime: 'Date and time',
        minKarma: 'Min karma',
        actionAddCustomFilters: 'Custom filters',
        game: 'Game',
        gameMode: 'Mode',
        region: 'Region',
        language: 'Language',
        anyValue: 'Any',
        wow: {
          mythicPlusRating: 'Min M+ rating',
          dungeon: 'Dungeon',
          ilvl: 'ilvl',
          keyLevel: 'Key',
          faction: 'Faction',
          arenaRating: 'Arena rating',
          tbcHeroic: 'Heroic',
        },
        lostArk: {
          guardianRaidsGuardian: 'Guardian',
          abyssDungeonsAbyss: 'Abyss',
          bossRush: 'Boss Rush',
          platinumFieldsField: 'Platinum Field',
          otherActivity: 'Other activity',
          difficulty: 'Difficulty',
          dungeon: 'Dungeon',
          arena: 'Arena',
          arenaServer: 'Server',
          arenaMode: 'Arena mode',
        },
        hearthstone: {
          minBgRating: 'Min rating',
        },
        csgo: {
          rank: 'Rank',
        },
        lol: {
          password: 'Password',
        },
      },
    },
    home: {
      pasha: {
        tabs: {
          groups: 'Groups',
          users: 'Users',
        },
        search: {
          placeholder: 'Search players',
        },
      },
      meta: {
        routeTitle: 'All groups',
        activities: 'activities',
        title: 'LFG — find players for your team | LF Group',
        titleTemplate: (
          value: string,
          value2: string,
          value3: string
        ): string => `${value} ${value2}LFG - ${value3} Team Finder | LF.Group`,
        description:
          'LF.Group is the ultimate way to find new teammates and create events in any game. We also have our own LFG bot, check it out!',
        descriptionTemplate: (value: string, value2: string): string =>
          `Find new teammates and plan ${value} together in ${value2}. Search for players who match your in-game goals, character progress and available time to play. Website + Discord bot. Try it out!`,
      },
      page404: {
        title: 'Is this a page?',
        actionGoToMain: 'Go home!',
        gif: 'https://media1.giphy.com/media/QTDNiVcupJGy2Ur0pY/giphy.gif?cid=ecf05e471k8d08a7b422q197nziehje0mgn4diva3anul6ue&rid=giphy.gif',
      },
      navigation: {
        events: 'Events',
        players: 'Players',
        vacancies: 'Vacancies',
        teams: 'Teams',
        suggestions: 'Recommendations for you',
        howToCreateGroup: 'How to create an event?',
      },
      errors: {
        errorNoGroups: 'No events match your request',
        subtext: 'Try another search or pick a game to find all active groups',
      },
      filters: {
        more: 'More',
        less: 'Less',
        when: {
          title: 'When',
          any: 'Any time',
          today: 'Upcoming',
          thisWeek: 'This week',
          thisMonth: 'This month',
        },
        language: {
          title: 'Language',
          placeholder: 'Any language',
          english: 'English',
          russian: 'Russian',
          turkish: 'Turkish',
          swedish: 'Swedish',
          polish: 'Polish',
          german: 'German',
          french: 'French',
          spanish: 'Spanish',
          italian: 'Italian',
          dutch: 'Dutch',
          portuguese: 'Portuguese',
        },
        actionReset: 'Reset filters',
        selectGame: {
          title: 'Game',
          placeholder: 'Any game',
        },
        selectTag: {
          title: 'Tag',
          placeholder: 'Any tag',
        },
        selectGameMode: {
          title: 'Mode',
          placeholder: 'Any mode',
        },
        region: {
          title: 'Region',
          placeholder: 'Any region',
        },
        server: {
          title: 'Server',
          placeholder: 'Any server',
        },
        role: {
          title: 'Role',
          placeholder: 'Any role',
        },
        hearthstone: {
          ratingBg: {
            title: 'Rating',
            placeholder: 'Any rating',
          },
        },
        wow: {
          dungeon: {
            title: 'Dungeon',
            placeholder: 'Any dungeon',
          },
          faction: {
            title: 'Faction',
            placeholder: 'Any faction',
          },
          ilvl: {
            title: 'ilvl',
            placeholder: 'Any ilvl',
          },
          rioScore: {
            title: 'RIO',
            placeholder: 'Any RIO',
          },
          arenaRating: {
            title: 'Arena rating',
            placeholder: 'Any rating',
          },
          mythicPlusRating: {
            title: 'M+ Rating',
            placeholder: 'Any rating',
          },
          keyLevel: {
            title: 'Key Level',
            placeholder: 'Any lvl',
          },
          character: {
            title: 'Character',
            placeholder: 'No char',
          },
          tbcHeroicCheckbox: 'Heroic',
        },
        csgo: {
          rank: {
            title: 'Rank',
            placeholder: 'Any rank',
          },
          faceitLvl: {
            title: 'FACEIT Lvl',
            placeholder: 'Any lvl',
          },
        },
      },
    },
    homeFeed: {
      title: 'Your personal gaming calendar',
      followingsTitle: 'You following',
      recommendationsTitle: 'Recommendation',
      meta: {
        title: 'Home | LF.Group',
        og: {
          title: 'Mobile game launcher',
          description:
            'LF.Group is designed to help you quickly find what to do and whom to play with in their favourite games.',
        },
      },
      tabs: {
        feed: 'Feed',
      },
      emptyState: {
        title: 'Here will be players looking to play with you',
        description:
          'Connect your game profiles, so we can help you find new teammates for you',
        action: 'Connect your games',
      },
      errors: {
        noGames:
          'Add your personal gaming preferences for finding out about new events',
        nothingHappening: 'There is nothing happening right now.',
        nothingHappeningBut:
          'But you can find with whom to play today! Take a look',
        nothingHappeningButWithoutGame:
          'But you can find with whom to play today',
        nothingHappeningBut2: 'or create an event',
      },
      actions: {
        addGameProfile: 'Add game profile',
        createEvent: 'Create event',
      },
    },
    landings: {
      lfg: {
        title:
          'LF.Group will help you find new teammates and plan aсtivities together in your favorite online games',
        firstSection: {
          firstDescription:
            'Search for players who match your in-game goals, skill level and available time to play',
          secondDescription:
            'Schedule groups for specific game modes, each comes with a private Disc text & voice chat',
          activePlayers: 'Active players',
          groupsEveryDay: 'Groups every day',
          findMates: 'Find mates',
          browseAllGroups: 'Browse all LFG games',
        },
      },
      wow: {
        title:
          'LF.Group will help you find new teammates and plan aсtivities together in WoW',
        tryIt: 'Try it out',
        advantages: {
          battlenetData:
            'Connect the account to Battle.net API and LF.Group will fetch all data about your characters',
          searchForPlayers:
            'Search for players who match your in-game goals, skill level and available time to play',
          shedule:
            'Schedule groups for specific game modes, each comes with a private Disc text & voice chat',
          streamers:
            'Popular streamers use LF.Group to play with their viewers. Every player has a chance to get in!',
        },
      },
      lostArk: {
        tryIt: 'Try it out',
        title:
          'LF.Group will help you find new teammates and plan aсtivities together in Lost Ark',
        advantages: {
          partnership:
            'Easily update your profile to apply to groups/guilds or share your it with others in Web & Disc',
          connect:
            'Set up your profile once and use it both on LF.Group website and all partnered Disc servers',
          searchForPlayers:
            'Search for players who match your in-game goals, skill level and available time to play',
          shedule:
            'Schedule groups for specific game modes, each comes with a private chat',
          recruting:
            'Recruiting toolkit for everyone! Players will be able to search for a suitable static or guild',
        },
        firstSection: {
          firstDescription:
            'Search for players who match your in-game goals, skill level and available time to play',
          secondDescription:
            'Schedule groups for specific game modes, each comes with a private chat',
          activePlayers: 'Active players',
          groupsEveryDay: 'Groups every day',
          findMates: 'Find mates',
          browseAllGroups: 'Browse all LFG games',
        },
        mainPart: {
          title: 'It is more than a website',
          description:
            'LF.Group also features an LFG Disc bot that serves as a multi-functional extension to the website. Everyone is welcome to add the bot to their server.',
          steps: {
            profiles: {
              title: 'Profiles feed',
              description:
                'Each actively looking for groups profile can be posted on connected to the bot servers. Any player can post his card by using /lfg command',
            },
            groups: {
              title: 'Groups feed',
              description:
                'You can create a group on your Disc server through the bot /create command.s',
            },
            setup: {
              title: 'Easy setup',
              description:
                'Just add the bot to your server, do not remove any permissions (!) and follow the instructions. Add LFG bot to your server',
              link: 'Add LFG bot to your server',
            },
          },
          footer: {
            title: 'All our toolkit is free to use!',
            description:
              'Sign in to LF.Group to unlock its full potential. Stay in touch with the team: we are constantly adding new features and support more games.',
            startExploring: 'Explore LF.Group',
          },
        },
      },
      mainPart: {
        title: 'It is more than a website',
        description:
          'LF.Group also features an LFG Disc bot that serves as a multi-functional extension to the website. Everyone is welcome to add the bot to their server.',
        steps: {
          profiles: {
            title: 'Profiles feed',
            description:
              'Each actively looking for groups profile can be posted on connected to the bot servers. Any player can post his card by using /lfg command',
          },
          groups: {
            title: 'Groups feed',
            description:
              'You can create a group on your Disc server through the bot /create command.s',
          },
          setup: {
            title: 'Easy setup',
            description:
              'Just add the bot to your server, do not remove any permissions (!) and follow the instructions. Add LFG bot to your server',
            link: 'Add LFG bot to your server',
          },
        },
        footer: {
          title: 'All our toolkit is free to use!',
          description:
            'Sign in to LF.Group to unlock its full potential. Stay in touch with the team: we are constantly adding new features and support more games.',
          startExploring: 'Explore LF.Group',
        },
      },
      csgo: {
        actions: {
          continue: 'Continue',
          setUpProfile: 'Set up your CS:GO profile',
          joinCommunity: 'Join Skin.Club community',
        },
        title:
          'Find new CS:GO teammates \n& join the Skin.Club community on LF.Group!',
        aboutTitle: 'What is \nLF.Group about?',
        aboutDescription1:
          'LF.Group is a social gaming calendar that helps CS:GO players find who to play with and what to do in the game.',
        aboutDescription2:
          'The project was created by one of the Skin.Club co-founders.',
        aboutDescription3:
          'It is growing quickly and the development plan is completely community-driven.',
        giveawayTitle:
          'By joining Skin.Club page on \nLF.Group you automatically take \npart in a Giveaway.',
        giveawayDescription:
          'The winners will be randomly selected March, 31 on the live stream. \nThe results will be posted across all Skin.Club social media, including brand \nnew LF.Group page!',
        conclusionTitle: 'Keep track of all important \nSkin.Club updates',
        conclusionDescription:
          'After signing in with Discord and following the Skin.Club page on LF.Group you will automatically receive notifications in Discord DM about all important calendar events. \n\nNever miss the free case reset, promocode giveaways, new cases and other cool stuff!',
        instructions: {
          first: 'Set up your CS:GO player profile',
          second: 'Follow Skin.Club page on LF.Group',
          third: 'Win one of 50 skins in our giveaway!',
        },
        form: {
          title: 'Set up your LF.Group \nCS:GO profile',
          actions: {
            first: 'Pick your tags',
            second: 'Pick your region',
            third: 'Tell us your MM rating',
          },
        },
        header: {
          whatIs: 'What is LF.Group',
          setUpProfile: 'Set up your LFG profile',
          giveaway: 'Giveaway',
          calendar: 'Skin.Club calendar',
        },
        footer: {
          communityMatters: 'Your community matters.',
          termsAndPrivacy: 'Terms & privacy',
          place: '© 2020 - 2022 LF.Group, London UK.',
        },
      },
      pubg: {
        title:
          'Partnership program \nPUBG Mobile \nGrow your channel and get bonuses \nTogether with LF.Group',
        beginNow: 'Start now',
        description: {
          first: ' PUBG Mobile players are already registered on LF.Group',
          second:
            ' custom rooms are created every month as events on LF.Group across all regions',
          third: ' players participate in custom game events daily',
        },
        streamers: {
          title: 'these streamers already benefited from partnership with us',
          withoutUs: 'Before LF.Group',
        },
        partners: {
          cards: {
            title: 'Our partners get:',
            first: {
              title: 'Promotion',
              description:
                'We have famous streamers as partners from your region. \n Part of their audience can become yours!',
            },
            second: {
              title: 'UC codes',
              description:
                'We will provide you UC codes for every custom room you run via LF.Group',
            },
            third: {
              title: 'Unique way to grow',
              description:
                'LF.Group events can be found in google and it will give you unique users',
            },
            fourth: {
              title: 'Weekly reward',
              description:
                'When you reach "Pro" level of partnership you will start to earn real money. We pay weekly.',
            },
          },
          levels: {
            title: 'Partnership levels',
            description:
              'All requirements are flexible, specific conditions will be discussed in private. \n Most active partners will get the most possible support we can provide!',
            level: (value: string): string => `The level of ${value}`,
            junior: {
              title: 'Beginner',
              requirement: '30+ daily participants in your events',
              conditions:
                'Images to promote events/UC codes for monthly give-aways on your stream/Your channel will start to grow faster/We will promote you in our social networks time to time',
            },
            middle: {
              title: 'Advanced',
              requirement: '150+ daily participants in your events',
              conditions:
                'All Beginner bonuses/Verified account badge/UC codes for every custom room you organize/Daily promotions in our social networks',
            },
            senior: {
              title: 'Pro',
              requirement: '210+ daily participants in your events',
              conditions:
                'All Beginner + Advanced bonuses/Additional integrations and offers/Weekly payments in $',
            },
          },
          cooperations: {
            title: 'Our famous partners',
            followers: (value: string): string =>
              `${value} thousand subscribers`,
            subscribers: (value: string): string => `${value} subscribers`,
          },
        },
        howToBecome: {
          title: 'To begin partnership',
          downloadDocs: 'Download materials',
          participate: 'Participate',
          howItWorks: 'How it works',
          first: 'LF.Group overlay-banner on your screen',
          second: 'Configured chatbot and LF.Group link in the description',
          third: '5+ average viewers online',
          fourth: 'Wish to tryhard and grow your streaming channel',
          fifth:
            'Sincere desire to help the community PUBG Mobile - give \nEM Service to search for events in the game',
        },
        footer: {
          communityMatters: 'Your community matters.',
          termsAndPrivacy: 'Terms & privacy',
          place: '© 2020 - 2023 LF.Group, London UK.',
          ourGames: {
            title: 'Our Games',
          },
          product: {
            title: 'Product',
            findTeam: 'Find a team',
            findPlayers: 'Find a player',
            findGame: 'Find a game',
          },
          company: {
            title: 'Company',
            about: 'About',
            contactUs: 'Contact us',
            mediaKit: 'Media Kit',
          },
        },
      },
    },
    modalAddGame: {
      title: 'Add/Edit game',
      description:
        'Select your fav games to add them to your profile. It will help people to find you and invite you to their group',
      tagsLabel: 'Select your preferences',
      wow: {
        descriptionNotConnected:
          'Connect your Battle.net account to add your character to your profile. It will be easier to join the group and find the right one',
        descriptionConnected:
          'These are your max-level characters which we update daily that you can customize. If you do not see any of your max-level character, update this list with the button below',
        connectBattlenetAction: 'Connect Battle.net',
        updateBattlenetAction: 'Update list of characters',
        examples: 'Examples',
        deletedGames: 'Hidden',
        yourCharacters: 'Your characters',
      },
      wildRift: {
        descriptionNotConnected:
          'Add your Riot ID, Tagline, and Region for game stats. It will be easier to join the group and find the right one.',
        descriptionConnected:
          'We daily update your account statistics. If you see an error, click the button below.',
        actionConnectRiot: 'Connect',
        actionDisconnectRiot: 'Disconnect Riot',
        actionForceUpdate: 'Update info',
        successToast: 'Riot connected!\nLooking for your Wild Rift profile...',
        successUpdateToast: 'Info updated',
      },
      lostArk: {
        yourCharacters: 'Your characters',
        examples: 'Examples',
        addDescription: 'Add a comment..',
        characters: 'Сharacters',
        characterDescription:
          'You can add up to 6 characters. You will be able to change them anytime',
        addCharacter: 'Add character',
      },
    },
    modalAppLinks: {
      title:
        'Download the app, participate in activities, get notifications, and win!',
      titleOnJoin: 'Download the app to join this event!',
    },
    modalAuthOnboarding: {
      loginScenario: {
        mobile: {
          title: 'Your personal gaming calendar',
          description: 'Sign in to get personal event feed',
        },
        desc: 'Sign in to set up your gaming profile on LF.Group.',
        subDesc:
          'You`ll be able to join events, take part in the giveaways or create your own community!',
        actionOtherOptions: 'Other options',
        footer: {
          cards: {
            bestTeammates: 'Find new\nteammates',
            favGames: 'All your\nfav games',
            community: 'Friendly\ncommunity',
            friends: 'Make friends\nlike and follow',
            control: 'Control your\npreferences',
          },
        },
        slideBeforeBattlenet: {
          title: 'Before you will continue \nwith Battle.net',
          info: 'Please make sure you are giving all permisition to LF.Group. By giving them we will be able to get your WoW account',
          check1: 'Your Battle.net Account ID and BattleTag (Required)',
          check2: 'Your World of Warcraft profile',
          reconnect: 'Reconnect Battle.net',
        },
      },
    },
    modalEvent: {
      titleCreate: 'New event',
      titleEdit: 'Edit event',
      actionCreate: 'Create →',
      actionEdit: 'Edit →',
      actionDelete: 'Delete',
      actionClone: 'Repeat previous event',
      description: {
        riot: 'Enter your Riot ID to get a personal stats card that will help you to find the right group. We daily update your account statistics',
      },
      image: {
        size: '16x9, Max 5MB',
        action: 'Add cover',
        replaceAction: 'Click to replace',
        error: 'Failed to upload your image',
      },
      preview: {
        titlePlaceholder: 'Hi! We are LF.Group!',
        descriptionPlaceholder:
          'Add an event in your favorite game!\nLF.Group is designed to help you quickly find players, communities and interesting activities.',
      },
      gameOptions: {
        title: 'Event options',
      },
      time: {
        fromDate: 'Date Start',
        fromTime: 'From',
        toDate: 'Date End',
        toTime: 'Until',
        withEndDateCheck: 'Until',
      },
    },
    modalLaunchingGame: {
      title: 'Room ID is copied,\n launching the game...',
      subtitle:
        'If the game does not start automatically, click the button below.',
      notProvided: {
        title: 'Host has not provided \nroom ID and password yet',
        subtitle: 'Please wait for the host to provide the information',
      },
      standoff2: {
        title: 'Launching Standoff 2...',
        subtitle:
          'If the game does not start automatically, click the button below.',
        desktop: {
          scanQr: 'Scan QR code to join the room',
        },
        initeUrl: {
          label: 'Invite URL',
        },
        notProvided: {
          title: 'Host has not provided invite URL yet',
          subtitle: 'Please wait for the host to provide the information',
        },
      },
      server: {
        label: 'Room ID',
      },
      password: {
        label: 'Password',
      },
      button: 'Launch now',
      cancel: 'Cancel',
    },
    modalNewGroup: {
      title: 'New group',
      slideGame: {
        title: 'Pick a game',
      },
      slideInfo: {
        organization: 'an organization',
        group: 'a group',
        noWow: {
          desc: (value: string): string =>
            `Connect your Battle.net account to create ${value}`,
          actionAdd: 'Connect',
        },
        noWildRift: {
          desc: (value: string): string =>
            `Add your Wild Rift profile to create ${value}`,
          actionAdd: 'Connect',
        },
        noOther: {
          desc: (value: string, value2: string): string =>
            `Add your ${value} profile to create ${value2}`,
          actionAdd: 'Add',
        },
        forCommunity: 'Community group',
        sectionTitle: 'Game Info',
        inputGameLabel: 'Game',
        inputTitleLabel: 'Title of the group',
        inputDateLabel: 'Date',
        inputTimeLabel: 'Time',
        inputKarmaLabel: 'Karma',
        section2Title: 'Preferences',
        action: 'Next',
        gameMode: {
          any: 'Any mode',
        },
        inputWhoCanJoin: {
          label: 'Who can join',
          optionEveryone: 'Everyone',
          optionWaitlist: 'Waitlist',
        },
        formGuild: {
          label: 'My communities',
        },
        customQuestions: {
          label: 'Poll',
          addQuestions: 'Add questions',
          placeholder: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'questions'
                : amount > 1
                ? 'questions'
                : 'question'
            }`,
        },
      },
      language: {
        title: 'Language',
        english: 'English',
        russian: 'Russian',
      },
      slideSlots: {
        title: 'Slots',
      },
      actionCreate: 'Create',
      actionGoBackToInfo: 'Back to info',
      actionGoBackToGames: 'Back to games',
    },
    myGroups: {
      errors: {
        noGroups: 'No events yet',
      },
      meta: {
        title: 'My events | LF Group',
        routeTitle: 'My events',
      },
      filter: {
        type: 'Type',
        all: 'All',
        member: 'Member',
        owner: 'Owner',
        waitlist: 'Requested',
      },
      emptyBlock: {
        title: 'You do not have any active groups right now',
        exploreGroups: 'Explore all groups',
        browseMyHistory: 'Browse my history',
      },
    },
    newGroup: {
      meta: {
        title: 'Create a group | LF Group',
      },
      filter: {
        selectChar: 'Choose your character',
        date: 'Date',
        time: 'Time',
      },
      errors: {
        errorJoinNoChar:
          'You need to add a character to create an event for this game',
      },
      title: 'New group',
      actionButton: 'Create event',
    },
    onboarding: {
      welcome: {
        title: (value: string): string => `Welcome to ${value}`,
        description: {
          home: 'Welcome to LF.Group! We are a free tool for players, teams and communities. \nOur goal is to help everyone find the right teammates to play with. \nLog in with Discord and start exploring!',
          inviteDiscrord:
            'Welcome to LFG! We provide you with a secure and powerful tool for players, teams, and communities. \nGet instant access to organizing parties and different events in your discord server.',
          players:
            'Explore the list of players listed on LF.Group. \nAfter you log in with Discord — you will be able to follow them and invite to play together.',
          game: {
            wow: 'Tired of in-game WoW LFG? LF.Group offers next-level experience! \nLog in with Discord & BattleNet and set up a group that matches your role and skill level. \nPrivate Discord chat included!',
            wowTbc:
              'Playing WoW Classic without LFG is hard. LF.Group is your solution! \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
            csgo: 'CSGO team finder by LF.Group is the easiest way to find new players and teams. \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
            hearthstone:
              'The most comprehensive Hearthstone LFG tool by LF.Group. \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
            warzone:
              'Call of Duty Warzone LFG on LF.Group is the easiest way to find new players and form a dream team! \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
            lostArk:
              'The most comprehensive Lost Ark LFG tool by LF.Group. \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
            wildRift:
              'The most comprehensive Wild Rift LFG tool by LF.Group. \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
          },
        },
      },
      community: 'Community',
      actions: {
        accept: 'Accept',
        reject: 'Reject',
        addToServer: 'Add to server',
      },
      inviteYou: 'Invite you to play',
      noInvites: 'All done',
    },
    onboardingGames: {
      title: 'Which games do you play?',
      slidePickGame: {
        noGames: 'You have already added all currently supported games',
      },
      errors: {
        noLostArkCharacters: 'You need to add Lost Ark character first',
        lostArkInvalidInput: 'Fill in required fields',
        gameInvalidInput: 'You need to add a description or select tags',
      },
      slideGameAdded: {
        title: 'Game added to you profile',
        description:
          'Now you and people can see your characters in your profile. \nYou can share a direct link to them',
        actionAddMore: 'Add another game',
        actionBrowse: 'Browse groups',
      },
    },
    organization: {
      meta: {
        title: 'Communities - LF.Group',
        routeTitle: 'Communities',
        description: 'Communities on LF.Group',
      },
      organizationMeta: {
        title: (value: string, value2: string): string =>
          `${value} ${value2} Looking for players | LF.Group`,
        gameTitle: (value: string, value2: string, value3: string): string =>
          `${value} ${value2} in ${value3} Looking for players | LF.Group`,
        description: (value: string, value2: string): string =>
          `${value} ${value2} website`,
        gameDescription: (
          value: string,
          value2: string,
          value3: string
        ): string => `${value} ${value2} website in ${value3}`,
      },
      errors: {
        noGuilds: 'Communities not found',
        noGuild: 'Community not found',
        noGuildMembers: 'Members not found',
        memberNotDefined: 'Member is not defined',
        guildNotDefined: 'Community is not defined',
        required: 'This field is required',
        nameRequired: 'Name is required',
        aliasRequired: 'Alias is required',
        teamRequired: 'To add an event you need to create a team',
        longDescription: 'Description is too long',
        longName: 'Name is too long',
        longAlias: 'Alias is too long',
        longWebsite: 'Main Link URL is too long',
        longCustomButtonLabel: 'It is too long',
        longWebsiteTitle: 'Main Link title is too long',
        nameAndAliasRequired: 'Name and alias are required',
        createGuildFailed: 'Community is not created',
        createTeamFailed: 'Team is not created',
        chooseGame: 'Choose game',
        createGuild: 'Create community error',
        noMembersFound: 'Members not found',
        alreadyMember: (value: string): string =>
          `${value} is already member of this community`,
        justNumbers: 'Must be a number',
      },
      alerts: {
        guildUpdated: 'The community is updated',
        guildCreated: 'The community is created',
        guildDeleted: 'The community is deleted',
        guildMemberUpdated: 'The community member is updated',
        guildMemberCreated: 'The community member is created',
        guildMemberDeleted: 'The community member is deleted',
        guildMemberRoleUpdated: 'Community member role updated',
        guildTeamUpdated: 'Community team updated',
        guildTeamCreated: 'Community team created',
        guildTeamDeleted: 'Community team deleted',
      },
      modals: {
        deleteGuild: 'Are you sure you want to delete this community?',
        deleteGuildMember: 'Are you sure you want to delete this member?',
        leaveGuild: 'Are you sure you want to leave this community?',
        leaveTeam: 'Are you sure you want to leave this team?',
        closeSettings:
          'Are you sure you want to close this community`s settings? \nAll changes will be lost.',
        closeCreating:
          'Are you sure you want to leave? \nAll changes will be lost.',
      },
      page: {
        gameInfo: 'Game info',
        addGameParams: 'Add game parameters to preview team card',
        memberCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'members'
              : amount > 1
              ? 'members'
              : 'member'
          }`,
        joinedMemberCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'members'
              : amount > 1
              ? 'members'
              : 'member'
          }`,
        views: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0 ? 'views' : amount > 1 ? 'views' : 'view'
          }`,
        commonViews: (value: string): string => `${value} views`,
        vacancyCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'vacancies'
              : amount > 1
              ? 'vacancies'
              : 'vacancy'
          }`,
        member: 'Member',
        role: 'Role',
        classes: 'Classes',
        champions: 'Champions',
        roster: 'Team',
        events: 'Events',
        wiki: 'Links',
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'followers'
              : amount > 1
              ? 'followers'
              : 'follower'
          }`,
        addTeamInfo: 'Add your team info',
        toHelpPeople: 'to help people to find your team',
        teamCard: {
          descriptionPlug: {
            addDescription: 'Add description of the team',
            or: 'or',
            inviteMember: 'Invite a team member',
          },
        },
      },
      fields: {
        type: 'Type',
        team: 'Team',
        progress: 'Progress',
        language: 'Language',
        region: 'Region',
        server: 'Server',
        activity: 'Activity',
        mainActivity: 'Main activity',
        weeklyContribution: 'Weekly contribution',
        contract: 'Contract',
        players: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'players'
              : amount > 1
              ? 'players'
              : 'player'
          }`,
      },
      action: {
        createGuild: 'Create community',
        userCopied: 'User link copied',
        createTeam: 'Create team',
        edit: 'Edit',
        deleteGuild: 'Delete community',
        upload: 'Upload',
        leaveTeam: 'Leave team',
        leaveGuild: 'Leave community',
        follow: 'Follow',
        followed: 'Following',
      },
      slidoverEdit: {
        title: 'Edit community',
        teamTitle: 'Edit team',
        paramsTabTitle: 'Params',
        imagesTabTitle: 'Branding',
        membersTabTitle: 'Management',
        teamMembersTabTitle: 'Team members',
        gameSettingsTabTitle: 'Game settings',
        teamTabTitle: 'Team',
        linksTabTitle: 'Links',
      },
      slidoverNew: {
        createGuild: 'Create community',
        params: {
          title: 'New community',
          social: 'Social',
        },
        members: {
          titles: 'Members',
          adminRole: 'Admin',
          playerRole: 'Player',
        },
        images: {
          title: 'Images',
          settings: 'Branding',
          banner: 'Banner',
          avatar: 'Avatar',
          maxSize: 'Max size',
          maxResolution: 'Max resolution',
          ration: 'Ration',
          recommendedResolution: 'Recommended resolution',
        },
        teams: {
          title: 'Game info',
        },
        forms: {
          askQuestion: 'If you have a question, feel free to ask us in our ',
          guildName: 'Community name',
          guildAlias: 'Link',
          aliasPreview: 'Your guild will be available at ',
          guildDescription: 'Description',
          teamDescription: 'Roster description',
          discord: 'Discord URL',
          twitter: 'Twitter URL',
          twitch: 'Twitch URL',
          youtube: 'YouTube URL',
          facebook: 'Facebook URL',
          instagram: 'Instagram URL',
          website: 'URL',
          websiteTitle: 'Button title',
          teamName: 'Team name',
          moreThanSymbols: (value: string): string =>
            `Search by typing no less than ${value} characters`,
          searchByTyping: 'Search by typing',
          roles: 'Roles',
        },
      },
    },
    organizationData: {
      type: {
        guild: 'Guild',
        community: 'Community',
        static: 'Static',
        title: 'Type',
        any: 'Any',
      },
      role: {
        admin: 'Admin',
        player: 'Player',
        default: 'Default',
        any: 'Any',
      },
      shortLanguage: {
        english: 'En',
        russian: 'Ru',
        german: 'De',
        spanish: 'Es',
        french: 'Fr',
        portuguese: 'Pt',
        italian: 'It',
        dutch: 'Nl',
        polish: 'Pl',
        turkish: 'Tr',
        swedish: 'Se',
        any: 'Any language',
        title: 'Language',
      },
      voicePreferences: {
        any: 'Any voice preferences',
        title: 'Voice preferences',
        required: 'Required',
        notRequired: 'Not required',
      },
      playstyle: {
        any: 'Any playstyle',
        title: 'Playstyle',
      },
      contract: {
        any: 'Any contract',
        title: 'Contract',
      },
      weeklyContribution: {
        any: 'Any weekly contribution',
        title: 'Weekly contribution',
      },
      activities: {
        title: 'Activities',
        anyTitle: 'Any activity',
      },
      wow: {
        activity: {
          raids: 'Raids',
          mythicPlus: 'MythicPlus',
          rbg: 'RBG',
          arena2x2: 'Arena2x2',
          arena3x3: 'Arena3x3',
          casual: 'Casual',
        },
      },
      la: {
        activity: {
          raids: 'Raids',
          gvg: 'GVG',
          altGuild: 'Alt-Guild',
          gve: 'GVE',
          pve: 'PVE',
          other: 'Other',
        },
        weeklyContribution: {
          mandatory: 'Mandatory',
          optional: 'Optional',
          no: 'No',
        },
      },
      lol: {
        activity: {
          normals: 'Normals',
          rankedFlex: 'Ranked Flex',
          aram: 'ARAM',
          clash: 'Clash',
          casualTournaments: 'Casual Tournaments',
          semiProTournaments: 'Semi-Pro Tournaments',
          proTournaments: 'Pro Tournaments',
        },
      },
    },
    pagePlayers: {
      title: {
        anyGame: 'All players',
        gamePlayers: (value: string): string => `${value} Players`,
      },
      meta: {
        title: {
          anyGame: 'All LFG | LF.Group',
          gamePlayers: (value: string): string => `${value} LFG | LF.Group`,
        },
        og: {
          title: {
            anyGame: 'All LFG',
            gamePlayers: (value: string): string => `${value} LFG`,
          },
          description: '',
        },
      },
    },
    pageVacancy: {
      tabs: {
        home: 'Details',
        applications: 'Applications',
      },
      controls: {
        confirmArchive: {
          title:
            'Are you sure you want to archive this vacancy? It would not be visible in the feed and players would not be able to apply to it.',
          yes: 'Archive',
          no: 'Cancel',
        },
        edit: 'Edit vacancy',
        archive: 'Archive vacancy',
      },
      aboutPosition: {
        title: 'About Position',
      },
      pageDetails: {
        title: 'Vacancy',
        meta: {
          title: (value: string): string => `${value}| LF.Group`,
          og: {
            title: (value: string, value2: string): string =>
              `${value} ${value2} Looking for players | LF.Group`,
            gameTitle: (
              value: string,
              value2: string,
              value3: string
            ): string =>
              `${value} ${value2} in ${value3} Looking for players | LF.Group`,
          },
        },
        form: {
          title: 'Apply now',
          description: {
            placeholder: 'Tell about yourself',
          },
          apply: {
            title: 'Apply →',
          },
        },
        alreadyApplied: {
          title: 'Your application has been submitted!',
          description:
            'Our Discord bot will send you a message once the community leader reviews your application. Make sure to stay a member of LF.Group server and allow direct messages from non-friends.',
        },
      },
      pageApplications: {
        title: 'Applications',
        meta: {
          title: (value: string): string => `${value} Applications | LF.Group`,
          og: {
            title: (value: string): string => `${value} | LF.Group`,
          },
        },
        emptyState: {
          title: 'No applications yet',
          description: 'You will see all applications here',
        },
      },
      createModal: {
        title: {
          create: 'New position',
          update: 'Edit position',
        },
        action: {
          submit: {
            create: 'Create →',
            update: 'Update →',
          },
        },
        input: {
          title: {
            label: 'Title',
          },
          description: {
            label: 'Description',
          },
        },
        sectionRequirements: {
          title: 'Requirements',
        },
      },
    },
    preview: {
      notFound: 'Not found',
      search: {
        error: 'Cant load search',
      },
      profile: {
        error: 'Cant load profile',
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'followers'
              : amount > 1
              ? 'followers'
              : 'follower'
          }`,
      },
      organization: {
        members: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'members'
              : amount > 1
              ? 'members'
              : 'member'
          }`,
      },
      group: {
        error: 'Cant load group',
      },
      vacancy: {
        lookingForPlayers: 'Looking for players',
        activity: 'Activity',
        type: 'Type',
        lookingFor: 'Looking for',
      },
      game: {
        error: 'Cant load game',
        groups: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'groups'
              : amount > 1
              ? 'groups'
              : 'group'
          }`,
        allPlayers: (amount: number) =>
          `More than ${amount} ${
            amount > 5 || amount === 0
              ? 'players'
              : amount > 1
              ? 'players'
              : 'player'
          }`,
        players: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'players'
              : amount > 1
              ? 'players'
              : 'player'
          }`,
        findTeammate: 'Find a teammate for',
        lfg: {
          title: 'All in one solution \nfor gaming communities',
          allPlayers: (amount: number) =>
            `More than ${amount} ${
              amount > 5 || amount === 0
                ? 'players'
                : amount > 1
                ? 'players'
                : 'player'
            } worldwide`,
          players: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'players'
                : amount > 1
                ? 'players'
                : 'player'
            } worldwide`,
          backedBy: '$1M backed by GrishinRobotics',
        },
        wildrift: {
          win: (value: string): string => `Win ${value}`,
          kda: 'KDA',
          battles: (amount: number) => `${amount} Ranked Battles`,
          gMin: 'G/Min',
          games: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'Games'
                : amount > 1
                ? 'Game'
                : 'Game'
            }`,
        },
      },
    },
    pubgLadder: {
      pageTitle: 'PUBG Leaderboard',
      title: 'Leaderboard | LF.Group',
      meta: {
        title: 'PUBG Leaderboard | LF.Group',
        description:
          'Join our PUBG Mobile custom rooms leaderboard for a chance to win a share of 15,000 UC prizes. Show off your skills, beat the competition, and take home the ultimate prize!',
        og: {
          title: 'PUBG Mobile custom rooms Leaderboard',
          description:
            'Join our PUBG Mobile custom rooms leaderboard for a chance to win a share of 15,000 UC prizes. Show off your skills, beat the competition, and take home the ultimate prize!',
          image: 'https://preview.lf.group/search/pubg-mobile',
        },
      },
      table: {
        user: 'User',
        prize: 'Prize',
        points: 'Points',
      },
      info: {
        titleDesktop:
          'Join verified streamersʼ custom rooms on LF.Group and compete for a prize of 15 000 UC points.',
        descriptionDesktop:
          'Check out the participation rules and get ready to show off your skills!',
        rulesAction: 'What are the rules?!',
        previousWeekResults: 'Previous biweekly results',
      },
    },
    pubgRules: {
      title: 'Leaderboard rules & FAQ | LF.Group',
      ogTitle: 'PUBG Mobile custom rooms Leaderboard',
      ogDescription:
        'Join our PUBG Mobile custom rooms leaderboard for a chance to win a share of 15,000 UC in biweekly prizes. Show off your skills, beat the competition, and take home the ultimate prize!',
      ogImage: 'https://preview.lf.group/search/pubg-mobile',
      goToLeaderboard: 'Go to leaderboard',
      joinParagraph:
        'Join the PUBG Mobile Custom Rooms Leaderboard and compete to win biweekly prizes up to 15 000 UC! All you have to do is play in custom rooms created by verified streamers from LF.Group to earn points and climb the leaderboard.',
      prizesParagraph: 'The prizes are distributed as follows:',
      prize1st: '1st place: 1950 UC',
      prize2nd: '2nd to 5th places: 975 UC',
      prize6th: '6th to 10th places: 650 UC',
      prize11th: '11th to 20th places: 325 UC',
      prize21st: '21st to 50th places: 60 UC',
      eventsCalendar: 'PUBG Events Calendar',
      paragraph4:
        'Kindly note that you can receive 25 points for winning a custom room only once per day, per streamer. As example: If you emerge victorious in three consecutive custom rooms during a single streamer daily event, you will still only be rewarded with 25 points. However, if you manage to win three custom rooms in one day, with each win coming from a different streamer, then you will receive a total of 75 points.',
      paragraph5p1:
        'If you aim to dominate the leaderboard, improve your ranking, and earn more UC points, kindly refer to our',
      paragraph5p2: 'and take part in the various events we have available.',
      paragraph3:
        'Earning points to climb the leaderboard is easy. Simply play in custom rooms created by LF.Group verified streamers and complete the following actions:',
      action1:
        'Verified Event Participation: Join an in-game custom room created by a verified streamer and receive 1 point for participation. We will use the API to confirm that you have joined the custom room in-game and award your profile with one point.',
      action2:
        'Custom Room Victory: Win a game in a custom room created by a verified streamer and earn 25 points. Every day, we receive the list of winners from verified streamers and update the points of the victorious players.',
      paragraph6:
        'On every second Wednesday, we will temporarily freeze the leaderboard and archive the current standings for historical reference. The prizes for the winners will be distributed over the next ten days by topping up their accounts.',
      question1:
        'Q: I won several custom rooms created by verified streamers, but I donʼt see any received points for it.',
      answer1:
        'A: You need to message the streamer directly and find out if they provided your profile as a winner to us. Sometimes, it may take a couple of days to get an updated score if the streamer does not send us new data immediately after games.',
      question2: 'Q: How do I get the prize?',
      answer2:
        'A: We have your profile, which should include your PUBG ID. We will top up your PUBG ID directly with UC points.',
      question3:
        'Q: How many custom rooms can I attend per day to increase my chances?',
      answer3:
        'A: You can participate in any amount of custom rooms per day, but be aware that we check if you pressed the “Play” button to participate in an actual in-game custom room. Simply joining the event will not award you with 1 point. However, if you participate by copying the lobby ID & password and pressing the “Play” button, you will be awarded with 1 point.',
      question4:
        'Q: I am a streamer and want my viewers to participate in the leaderboard. How do I do it?',
      answer4:
        'A: We welcome new streamers and are happy to help them increase their live numbers. Pakistan streamers can message us via Discord to Pablo671#6613 and Nepalese streamers can message us via Discord to Gothiques#7156. Once you become our verified partner, you will be able to run official LF.Group custom rooms, award your winners with UC prizes, and enable them to participate in the Custom Rooms Leaderboard.',
    },
    referal: {
      title: 'Invite friends and get rewards!',
      friendsInvited: (amount: number) =>
        `${amount} ${
          amount > 5 || amount === 0
            ? 'friends'
            : amount > 1
            ? 'friends'
            : 'friend'
        } invited`,
      shareWithFriends: 'Share with friends',
      rules: 'Rules',
      inviteLinkCopied: 'Invite link copied',
      inviteNewMembers: 'Invite new members',
      copyLink: 'Copy link',
      toIviteFriends: 'to invite friends',
      gifts: {
        three: 'Masterwork Chest and Key Bundle',
        six: 'Mystery Skin',
        ten: 'Mystery Chest',
        twenty: 'Get any legendary skin of your choice!',
        fifty: '5750+ Riot Points gift',
      },
      pubg: {
        title: 'Invite friends and get Leaderboard points!',
        gifts: {
          one: '5 points',
          three: '10 points',
          five: '15 points',
        },
      },
    },
    rightColumn: {
      banner: {
        title: 'Need people to play with?',
        description:
          'LF.Group can help you to find the right people to play with. You can join any group or create your own',
        action: 'Create an event',
      },
      addCommentBanner: {
        lookingForTeam: 'Looking for team',
        newFeature: 'New feature',
        title: 'Describe yourself',
        description:
          'Express your personal preferences: activities, playtime, etc. so it is easier to connect with others!',
      },
      connectDiscrod: {
        title: 'Personal notifications',
        description:
          'Add discord to get updates about new giveaways and events',
        authDescription:
          'Log in via discord to get updates about new giveaways and events',
        action: 'Add Discord',
      },
      suggestions: {
        title: 'Who to follow',
        description: 'Subscribe to streamers and take part in events',
        onboardingDescription: (value: string): string =>
          `Subscribe to at least ${value} streamers and take part in events`,
      },
    },
    teamsFeed: {
      title: (value: string): string => `${value} Teams`,
      meta: {
        title: (value: string): string => `${value} Teams`,
        og: {
          title: (value: string, value2: string): string =>
            `${value} Teams - Recruitment tool for Teams in ${value2} | LF.Group`,
          description:
            'Create your own Guild page with full recruitment toolkit. Create vacancies and search for players who match to your in-game goals. Discord notifications. Try it out!',
        },
      },
      emptyState: {
        title: 'No teams match your request',
        description: 'Try another search to find all active teams',
      },
    },
    user: {
      meta: {
        title: (value: string): string =>
          `${value} gaming profile. Looking for play with | LF.Group`,
        descriptionWithGames: (value: string, value2: string): string =>
          `${value} gaming profile. Looking for play with in ${value2}.`,
        description: (value: string): string =>
          `${value} gaming profile. Looking for play with.`,
      },
      emptyState: {
        noProfiles: {
          description: (value: string): string =>
            `${value} does not have a gaming profile yet.`,
          descriptionPersonal:
            'You do not have a gaming profile yet. Add one now!',
          action: 'Add profile +',
        },
        noEvents: {
          description: (value: string): string => `${value} has no events yet.`,
          descriptionPersonal: 'You have no events yet.',
          action: 'Find events',
        },
      },
      gameCardMeta: {
        title: (value: string, value2: string): string =>
          `${value} is searching for teammates in ${value2} | LF Group`,
        description: (value: string, value2: string): string =>
          `Click the link to inspect ${value}’s profile in ${value2}.At LF.Group you can find player’s groups, calendar, Discord and in-game IDs.`,
      },
      followingMeta: {
        title: (value: string): string =>
          `Player ${value}: Subscriptions | LF Group`,
        routeTitle: (value: string): string => `${value} following`,
        description: (value: string): string =>
          `See what other players ${value} is following. Maybe you will find some new friends there?`,
      },
      followersMeta: {
        title: (value: string): string =>
          `Player ${value}: Followers | LF Group`,
        routeTitle: (value: string): string => `${value} followers`,
        description: (value: string, value2: string): string =>
          `Here you may see players who follow ${value}. Click the link and see if ${value2} is a super star!`,
      },
      navigation: {
        calendar: 'Calendar',
        invites: 'Invites',
        visitors: 'Visitors',
        links: 'Player wiki',
        games: 'Games',
        about: 'Player profile',
        events: 'Events',
        history: 'History',
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'followers'
              : amount > 1
              ? 'followers'
              : 'follower'
          }`,
        followingWithoutCount: 'Following',
        following: (amount: number) => `${amount} following`,
        userFollowing: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0 ? 'users' : amount > 1 ? 'users' : 'user'
          }`,
        leaderboard: 'Leaderboard',
        settings: 'Settings',
        logout: 'Logout',
        addFirstGame: 'Add your first game',
      },
      playing: 'Playing',
      social: 'Social',
      logoutModal: {
        text: 'Sure you want to log out?',
      },
      metaHistory: {
        title: (value: string): string =>
          `Player ${value}: Finished games | LF Group`,
        description: (value: string): string =>
          `Here you may find everything about ${value} profile on LF.Group. Make sure to follow him if you like!`,
      },
      errors: {
        404: {
          text: 'User not found',
          action: 'Go home',
        },
        noFutureEvents: 'User has no planned events',
        noPastEvents: 'No events played yet',
        errorNoFollowing: 'User does not follow others',
        errorNoFollows: 'User has no followers',
        errorNoVisitors: 'You have no visitors',
      },
      selectedGames: {
        title: 'Selected games',
      },
      mobile: {
        playing: 'I am playing',
        myLinks: 'My links',
      },
      unfollowConfirmModal: {
        title: (value: string): string =>
          `Are you sure you want to unfollow ${value}?`,
        yes: 'Unfollow',
        no: 'Cancel',
      },
    },
    userGames: {
      meta: {
        title: (value: string): string =>
          `Player ${value}: Favorite games | LF Group`,
        routeTitle: (value: string): string => `${value} games`,
        description: (value: string): string =>
          `See what games ${value} plays. Maybe you can be a good team?`,
      },
      inviteModal: {
        errorNoGroup: (value: string): string =>
          `Create a group to invite ${value}`,
        toastUserInvited: (value: string): string =>
          `${value} is invited to the group`,
      },
      sectionTitles: {
        myGames: 'My Games',
        myLinks: 'My Links',
        events: 'Events',
      },
      card: {
        actionInvite: 'Invite',
        toastLinkCopied: 'Link is copied 👌',
        toastLoginCopied: 'Login is copied 👌',
        toastInviteCopied: 'Invite is copied 👌',
        write: 'Let’s play',
        wildrift: {
          winRate: (value: string): string => `${value}% win rate`,
          rankedGoldPerMinute: 'Gold/min',
          rank: 'Rank',
        },
        wow: {
          arena: (value: string): string => `Arena ${value}`,
        },
        lostArk: {
          legacyLvl: (value: string): string => `Roster lvl ${value}`,
        },
        user: {
          he: 'he',
          she: 'she',
          age: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'years'
                : amount > 1
                ? 'years'
                : 'year'
            } old`,
        },
      },
      createCharModal: {
        title: 'Add a new account',
        game: {
          title: 'Game',
          placeholder: 'choose a game',
        },
        errorAlreadyExists: (value: string): string =>
          `You already have a ${value} account`,
        errorNoSocialAccount: (value: string): string => `Connect ${value}`,
      },
      createGame: {
        errors: {
          errorRequiredField: 'Required field',
          errorSymbolLimitExceeded: (value: string): string =>
            `More than ${value} characters entered`,
          errorMaxNumberLimitExceeded: (value: string): string =>
            `Max value – ${value}`,
          errorMinNumberLimitExceeded: (value: string): string =>
            `Min value – ${value}`,
        },
        infos: {
          symbolAvailability: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'symbols'
                : amount > 1
                ? 'symbols'
                : 'symbol'
            } remaining`,
        },
      },
      deleteGameModal: {
        title: 'Are you sure you want to delete the game?',
        deleteAction: 'Delete',
      },
      newGameModal: {
        title: 'New account',
        editTitle: 'Edit account',
        gameTitle: 'Title',
        errorRequredFields: 'Fill in the mandatory fields',
      },
    },
    userInvites: {
      meta: {
        title: 'Invites | LF Group',
      },
      errors: {
        errorNoInvites: 'No invites just now',
      },
      invniteModal: {
        toastSuccess: 'Invite sent',
        titleGroup: 'Choose your group',
        titleSlots: 'Choose a slot',
      },
      toastAcceptInvite: 'The group has been added to your calendar',
      toastDeleteInvite: 'Group deleted from your calendar',
    },
    userLinks: {
      meta: {
        title: 'Links | LF Group',
      },
      errorNoLinks: 'No links here just yet',
      addFirstAction: 'No links here just yet. Click to add your first',
      addLinkAction: 'Add new link',
      createAction: 'Create',
    },
    userNotifications: {
      meta: {
        title: 'Notifications | LF Group',
      },
      errors: {
        errorNoNotifications: 'No notifications just now',
      },
      tabs: {
        groups: 'Groups',
        all: 'All',
        following: 'Following',
        invites: 'Invites',
      },
      action: {
        accept: 'Accept',
        decline: 'Decline',
      },
      descriptions: {
        startFollow: (value: string): string =>
          `started following you. ${value}`,
        invite: (value: string, value2: string, value3: string): string =>
          `${value} your invite to "${value2}". ${value3}`,
        createdGroup: (value: string, value2: string): string =>
          `create a new group for ${value}. ${value2}`,
      },
    },
    userSearch: {
      inviteModal: {
        inviteUserToGroup: (value: string): string => `${value} is invited`,
        errorAuth: 'auth required',
        noGroupsOwned: (value: string): string =>
          `There are no groups you can invite ${value} to`,
      },
      meta: {
        routeTitle: 'All players',
        title: 'Find a player to become your teammate | LF Group',
        description:
          'Search for any player registered on LF.Group on this page. Play with filters and browse your potential teammates!',
      },
      filters: {
        inputPlaceholder: 'username',
        errorMinLength: 'Enter 3 symbols at least',
        hasBattlenet: 'Has Battletag',
      },
      noUsersFound: 'No users matching your search',
      card: {
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'followers'
              : amount > 1
              ? 'followers'
              : 'follower'
          }`,
        following: (amount: number) => `${amount} following`,
      },
      gameCards: {
        characters: 'Characters',
        class: 'Class',
        wow: {
          raid: 'Raid',
        },
        redirectToMore: (amount: number) =>
          `& ${amount} more ${
            amount > 5 || amount === 0
              ? 'characters'
              : amount > 1
              ? 'characters'
              : 'character'
          } on the user page`,
        wildRift: {
          champions: 'Champions',
          rank: 'Rank',
          playing: 'Playing',
          kda: 'Average KDA',
          winRate: 'Winrate',
        },
        lostArk: {
          gearScore: 'ilvl',
          builds: 'Builds',
        },
      },
    },
    userSettings: {
      page: {
        title: 'Settings',
        copyLink: 'Copy link',
        actionSave: 'Save changes',
        actionMobileSave: 'Save',
        changeRegionAction: 'Change content region',
        write: 'Let’s play',
        tabs: {
          user: 'User',
          games: 'Games',
          links: 'Links',
          server: 'Server',
          notification: 'Notifications',
          privacy: 'Privacy',
        },
      },
      regionModal: {
        title: 'Let us check your region',
        description:
          'We need to know where you are, so we can show you players and communities from your region.',
        action: 'Lets Gooo!',
        select: {
          label: 'Region',
          values: {
            en: 'World',
            ru: 'CIS',
            in: 'India 🇮🇳',
            pk: 'Pakistan 🇵🇰',
            sea: 'South East Asia (SEA)',
          },
        },
      },
      languageModal: {
        title: 'Choose language',
        action: 'Lets Gooo!',
        select: {
          label: 'Language',
        },
      },
      commonSettingsModal: {
        changeLanguage: 'Change language',
        changeRegion: 'Change region',
      },
      meta: {
        title: 'Profile settings | LF Group',
      },
      links: {
        addNewButton: {
          action: 'Add link',
          title: 'New link',
        },
        title: {
          placeholder: 'My favorite site',
        },
        link: {
          placeholder: 'https://lf.group',
        },
        description: {
          placeholder: 'Description (optional)',
        },
      },
      connectedServices: {
        title: 'Connected accounts',
      },
      primary: {
        title: 'Primary settings',
        username: 'Username',
      },
      botSettings: {
        title: 'Bot settings',
      },
      user: {
        settings: 'Settings',
        cancel: 'Cancel',
        deleteAccount: {
          title: 'Delete my account',
          logout: 'Delete my account',
          confirmLogout: 'Are you sure you want to delete this account?',
        },
        logout: {
          title: 'Log out',
          logout: 'Log out',
          confirmLogout: 'Are you sure you want to log out?',
        },
        exitFromSettings: {
          exit: 'Exit',
          confirmExit:
            'You have unsaved changes. Are you sure you want to exit?',
        },
        account: {
          title: 'Account',
          username: 'Username',
          email: 'Email',
          displayName: 'Display Name',
          description: 'Player profile',
        },
        connectedAccounts: {
          title: 'Connected Accounts',
          connected: 'Connected',
          connect: 'Connect',
          disconnect: 'Disconnect',
        },
        preferences: {
          title: 'Preferences',
          language: 'Language',
        },
      },
      server: {
        serverName: 'Server name',
        limits: {
          title: 'Limits',
          description:
            'Brief limits description. Users can create events in these channels only',
        },
      },
      notifications: {
        description:
          'We will always let you know about important changes, but you are free to select what else you want to hear about.',
        browser: {
          title: 'Browser notifications',
          newInvite: 'New invites',
          newJoiner: 'New players',
          newFollower: 'New followers',
          requestApproved: 'Request approved',
          description:
            'Allow LF.Group to send you browser notifications to stay connected with friends',
        },
        email: {
          title: 'Email notifications',
          newInvite: 'New invites',
          newJoiner: 'New players',
          newFollower: 'New followesr',
          requestApproved: 'Request approved',
          description:
            'Allow LF.Group to send you email notifications to stay connected with friends',
        },
      },
      privacy: {
        everyone: 'Everyone',
        onlyFollowers: 'Only followers',
        labels: {
          whoCanInviteMe: 'Who can invite me',
          whoCanJoinMe: 'Who can join me',
          whoCanSeeMyGames: 'Who can see my games',
          whoCanSeeMySocialAccounts: 'Who can see my socials',
        },
        groups: {
          title: 'Groups',
        },
        content: {
          title: 'Content',
        },
      },
      errors: {
        invalid: 'Some fields are incorrect',
        loginTaken: 'Username taken',
      },
      toastSuccess: 'Settings updated',
    },
    vacancyFeed: {
      title: (value: string): string => `${value} Guilds Vacancies`,
      meta: {
        title: (value: string): string => `${value} Guilds Vacancies`,
        og: {
          title: (value: string, value2: string): string =>
            `${value} Guilds - Looking for Guild in ${value2} | LF.Group`,
          description: (value: string): string =>
            `Find new guild and achieve your goals in ${value}. Search for guilds who match your in-game goals and available time to play. Website + Discord bot. Try it out!`,
        },
      },
      emptyState: {
        title: 'No vacancies match your request',
        description: 'Try another search to find all active vacancies',
      },
      card: {
        requests: (value: string): string => `${value} requests`,
        actions: {
          view: 'View',
          apply: 'Apply',
        },
      },
    },
    welcome: {
      pageTitle: 'Your personal gaming calendar.',
      pageDescription:
        'LF.Group is designed to help you quickly find players, communities and interesting activities.',
      noGameDescription: 'Check what is \nhappening right now',
      header: {
        events: 'Events',
        players: 'Players',
        teams: 'Teams',
        vacancies: 'Vacancies',
      },
      meta: {
        title: 'Find new teammates and gaming activities on LF.Group',
        description:
          'Find new teammates, communities and activities in different games. Search for players and vacancies that match your in-game goals, skill level and available time to play!',
        wow: {
          title: (value: string): string =>
            `WoW ${value} (Group Finder) - Looking for Group in World of Warcraft | LF.Group`,
          description:
            'Find new teammates, guilds and communities in WoW. Search for players and vacancies that match your in-game goals, skill level and available time to play!',
        },
        lol: {
          title: (value: string): string =>
            `LoL ${value} - League of Legends Looking for Group / Team Finder | LF.Group`,
          description:
            'Find new teammates and teams in LoL. Search for players that match your in-game goals, skill level and available time to play!',
        },
        la: {
          title: (value: string): string =>
            `Lost Ark ${value} (Group Finder) - Looking for Group in Lost Ark | LF.Group`,
          description:
            'Find new teammates, guilds and statics in Lost Ark. Search for players and communities that match your in-game goals, skill level and available time to play!',
        },
        wr: {
          title: (value: string): string =>
            `LoL Wild Rift ${value} - Wild Rift Looking for Group / Team Finder | LF.Group`,
          description:
            'Find new teammates and teams in LoL Wild Rift. Search for players that match your in-game goals, skill level and available time to play!',
        },
        pbgm: {
          title: (value: string): string =>
            `PUBG Mobile ${value} - PUBG Mobile Кастомки, турниры, праки / Поиск команды и игроков`,
          description:
            'Все про PUBG Mobile в одном месте. Подходящие фильтры и цели помогут выбрать крутых тиммейтов. Отправляйся в раздел «Мероприятия» и принимай участие в соревнованиях от лучших турнирных организаторов',
        },
        cod: {
          title: (value: string): string =>
            `COD MOBILE ${value} - COD MOBILE Кастомки, турниры, праки / Поиск команды и игроков`,
          description:
            'Все про COD MOBILE в одном месте. Подходящие фильтры и цели помогут выбрать крутых тиммейтов. Отправляйся в раздел «Мероприятия» и принимай участие в соревнованиях от лучших турнирных организаторов',
        },
        hs: {
          title: '',
          description: '',
        },
        csgo: {
          title: '',
          description: '',
        },
      },
      features: {
        default: {
          title: 'Features for players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create communities',
            description:
              'Customize your page, manage calendar and find new players. Notify your members via Discord DM about important events',
          },
          point3: {
            title: 'Discover new activities',
            description:
              'Gather groups with friends, join community events, see what tournaments and streams are going on and much more!',
          },
        },
        wow: {
          title: 'Features for WoW players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create communities',
            description:
              'Running a guild? Static group? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows guilds to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        lol: {
          title: 'Features for LoL players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create and manage teams',
            description:
              'Looking for semi-pro players to join your team? Creating a serious Clash community? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows teams to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        la: {
          title: 'Features for Lost Ark players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create communities',
            description:
              'Running a guild? Static group? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows guilds to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        wr: {
          title: 'Features for Wild Rift players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create and manage teams',
            description:
              'Looking for semi-pro players to join your team? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows teams to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        pbgm: {
          title: 'Features for PUBG Mobile players',
          point1: {
            title: 'Team Search',
            description:
              'New way to search for teammates, use filters, tags and text description to find the right players',
          },
          point2: {
            title: 'Create a community',
            description:
              'Are you a manager or team captain? Do you host tournaments or broadcasts? Use LF.GROUP to create your community page: manage training sessions and team calendar, find players, organize a competition or announce a broadcast',
          },
          point3: {
            title: 'Jobs',
            description:
              'A powerful set of job tools allows teams to process applications based on criteria: rank, region, K/D, age, tournament achievements and more! Create a team page, accept applications and ask questions to candidates',
          },
          point4: {
            title: 'Events',
            description:
              'Daily PUBG Mobile competitions. Participate in custom and esports tournaments alone or with your team',
          },
        },
        hs: {
          title: '',
          point1: {
            title: '',
            description: '',
          },
          point2: {
            title: '',
            description: '',
          },
          point3: {
            title: '',
            description: '',
          },
        },
        csgo: {
          title: '',
          point1: {
            title: '',
            description: '',
          },
          point2: {
            title: '',
            description: '',
          },
          point3: {
            title: '',
            description: '',
          },
        },
      },
      sections: {
        updatesTitle: 'Latest updates',
        features: 'LFG has amazing features',
        soon: 'Coming soon',
        howToCreateGroup: 'How to create an event?',
        groupsAndPlayers: (value: string, value2: string): string =>
          `${value} groups & ${value2} players`,
        players: (value: string): string => `${value} players`,
        groupDescription:
          'Create a group in a few clicks and sync with your new teammates via webchat',
        discordDescription:
          'Set up your profile once and use it both on LF.Group website and all connected partnered Discords',
        recruitingDescription:
          'Recruiting toolkit for everyone! Players will be able to search for a suitable static or guild',
      },
      auth: {
        title: 'Set up your LF.Group profile',
        wow: {
          description:
            'Sign in with Battle.net and all your characters will be automatically uploaded. Match easily with suitable teammates!',
        },
        lol: {
          description:
            'Sign in via Riot Games token and your general stats will be automatically uploaded. Match easily with suitable teammates!',
        },
        la: {
          description:
            'Sign in and specify your experience, interests and character details. Match easily with suitable teammates!',
        },
        wr: {
          description:
            'Sign in via Riot Games token and your general stats will be automatically uploaded. Match easily with suitable teammates!',
        },
        pbgm: {
          description:
            'Register on the site in two clicks and set up your profile from the proposed parameters. Your victories are near!',
        },
      },
      motivation: {
        title:
          'Join LF.Group to find interesting activities, players and friends',
        action: 'Join now',
      },
      tabs: {
        events: 'Events',
        players: 'Players',
        guilds: 'Guilds',
        teams: 'Teams',
        vacancies: 'Vacancies',
      },
    },
  },
  russian: {
    bpRules: {
      pubg: {
        title: 'Правила PUBG Mobile Battle Pass | LF.Group',
        ogTitle: 'PUBG Mobile таблица лидеров кастомок',
        ogDescription:
          'Присоединяйтесь к нашей таблице лидеров кастомных комнат в PUBG Mobile, чтобы получить шанс выиграть долю из 5 000 UC в еженедельных призах. Покажите свои навыки, побейте конкурентов и получите главный приз!',
        ogImage: 'https://preview.lf.group/search/pubg-mobile',
        goToLeaderboard: 'Вернуться в таблицу лидеров',
        joinParagraph:
          'Пригласи своих друзей присоединиться к LF.Group и заработай очки, чтобы продвигаться в таблице лидеров кастомных комнат PUBG Mobile.',
        prizesParagraph: 'Награды за приглашение друзей в LF.Group:',
        prize1st:
          'Один приглашенный друг даст тебе +5 очков в таблице лидеров.',
        prize2nd:
          'Два приглашенных друга добавят тебе +10 очков в таблице лидеров.',
        prize6th:
          'Три приглашенных друзей дадут тебе +15 очков в таблице лидеров.',
        paragraph2:
          'Чтобы начать этот процесс, перейди на Battle Pass панель и скопируй уникальную ссылку-приглашение. Затем поделись этой ссылкой с теми, кто ранее не регистрировался в LF.Group. Не забудь убедиться, чтобы они добавили описание и теги к своему PUBG Mobile профилю.',
        paragraph3:
          'Как только твой друг успешно зарегистрируется в LF.Group, ты получишь +1 к LF.Group Battle Pass прогрессу. Обрати внимание, что PUBG ID твоего друга не должен ранее использоваться в LF.Group, чтобы иметь право на получение вознаграждения.',
        paragraph4:
          'Твои награды будут добавляться каждый раз, когда ты пригласишь друга. Если тебе удастся пригласить трёх друзей, ты получите в общей сложности 30 очков таблицы лидеров, что является важным шагом на пути к победе в еженедельных призах.',
      },
    },
    calendar: {
      title: 'График',
      addModalTitle: 'Свободное игровое время',
      actions: {
        addEvent: 'Добавить событие',
        add: 'Добавить',
        cancel: 'Отмена',
        save: 'Сохранить',
      },
      forms: {
        title: 'Название',
        pickDay: 'Выберите день',
        from: 'От',
        to: 'До',
        recurring: 'Повторяющийся',
      },
      weekDays: {
        monday: {
          full: 'Понедельник',
          short: 'Пн',
          extraShort: 'Пн',
        },
        tuesday: {
          full: 'Вторник',
          short: 'Вт',
          extraShort: 'Вт',
        },
        wednesday: {
          full: 'Среда',
          short: 'Ср',
          extraShort: 'Ср',
        },
        thursday: {
          full: 'Четверг',
          short: 'Чт',
          extraShort: 'Чт',
        },
        friday: {
          full: 'Пятница',
          short: 'Пт',
          extraShort: 'Пт',
        },
        saturday: {
          full: 'Суббота',
          short: 'Сб',
          extraShort: 'Сб',
        },
        sunday: {
          full: 'Воскресенье',
          short: 'Вс',
          extraShort: 'Вс',
        },
      },
      errors: {
        title: 'Обязательное поле',
      },
    },
    chat: {
      title: 'Чат',
      networkStatus: {
        typing: 'печатает',
        connecting: 'подключение',
        updating: 'обновление',
      },
      errors: {
        authRequired: 'Создай аккаунт, чтобы общаться и играть с другими',
        loadingNewChat: 'Готовим твой чат...',
        notSelected: 'Выбери чат, чтобы начать общаться...',
        userBanned: {
          title: 'Тебя забанили',
          subtitle:
            'Aдмин этого чата забанил тебя, ты больше не можешь его просматривать',
        },
        selectRegion: {
          title: 'Выбери регион и сервер',
          subtitle: 'Выбери регион и сервер, чтобы общаться с другими игроками',
        },
        noAccess: {
          title: 'А тут чат для участников',
          subtitle: 'Ты сможешь общаться, когда станешь участником группы',
        },
        oldGroup: {
          title: 'А тут чата нет',
          subtitle:
            'Эту группу создали до того, как мы сделали чаты. \nТы можешь пообщаться в более новых группах',
        },
      },
      private: {
        invite: {
          title: 'Этот чат доступен только по ссылке',
          subtitle:
            'Тебя пригласили в этот чат. Нажми на кнопку, чтобы принять приглашение',
        },
      },
      writebox: {
        loginToStartChatting: 'Войди, чтобы написать сообщение',
        placeholder: 'Введи сообщение...',
        editingMessage: 'Редактирование сообщения',
        placeholderAddComment: 'Добавить комментарий...',
        messageMaxLengthOverflow:
          'Превышена максимальная длина сообщения — 3000 символов',
        popupDelete: 'Точно хочешь удалить это сообщение?',
      },
      header: {
        memberCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'участников'
              : amount > 1
              ? 'участника'
              : 'участник'
          }`,
        typingCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'пишут'
              : amount > 1
              ? 'пишут'
              : 'пишет'
          }`,
        userTyping: (value: string): string => `${value} пишет`,
      },
      message: {
        popup: {
          reply: 'Ответить',
          copyLink: 'Ссылка',
          report: 'Пожаловаться',
          forward: 'Переслать',
          deleteMessage: 'Удалить',
          openThread: 'Открыть тред',
          edit: 'Редактировать',
        },
        comment: 'Комментировать',
        repliesCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'ответов'
              : amount > 1
              ? 'ответа'
              : 'ответ'
          }`,
        viewsCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'просмотров'
              : amount > 1
              ? 'просмотра'
              : 'просмотр'
          }`,
        noMessages:
          'Пока здесь нет сообщений, напиши первым или пригласи друзей, чтобы сделать этот чат немного лучше',
        fetchBefore: 'Загрузить ещё',
        fetchAfter: 'Загрузить следующие',
      },
    },
    controls: {
      errors: {
        404: {
          text: 'Ничего не найдено',
          action: 'Обновить',
        },
        noActiveGroups: 'У тебя пока нет активных событий',
        mustAuth: 'Войди, чтобы продолжить',
      },
      loading: 'Загрузка...',
      actions: {
        yes: 'Да',
        no: 'Нет',
        ok: 'Хорошо 👌',
        cancel: 'Отмена',
        close: 'Закрыть',
        send: 'Отправить',
        save: 'Сохранить',
        share: 'Поделиться',
        add: 'Добавить',
        delete: 'Удалить',
        edit: 'Изменить',
        showMore: 'Показать больше',
        showLess: 'Показать меньше',
        leave: 'Выйти',
        later: 'Позже',
        create: 'Создать',
        up: 'Наверх',
        addGames: 'Добавить игры',
        addLinks: 'Редактировать ссылки',
        findFriends: 'Обновить',
        clickToUploadCover: 'Открой настройки, чтобы загрузить обложку',
        updateFeed: 'Обновить ленту',
        selectAnOption: 'Выбери вариант',
        editProfile: 'Изменить профиль',
      },
      followers: {
        badge: (amount: number) => `Подписчики ${amount}+`,
        actionFollow: 'Подписаться',
        actionUnfollow: 'Ты подписан',
      },
      invites: {
        actionInvite: 'Пригласить',
        successStatus: 'Приглашение отправлено',
      },
      list: {
        more: (amount: number) => `и еще ${amount}`,
      },
      modal: {
        closeWhilensaved: {
          title: 'Здесь есть несохраненные данные. Точно хочешь закрыть?',
          yes: 'Закрыть',
          no: 'Остаться',
        },
      },
      filters: {
        actionMore: {
          showMore: 'Все фильтры',
          showLess: 'Спрятать фильтры',
        },
      },
      navigation: {
        searchGroups: 'Поиск событий',
        myGroups: 'Мои события',
        notifications: 'Уведомления',
        players: 'Игроки',
        profile: 'Профиль',
        home: 'Главная',
        actionCreateGroup: 'Создать событие',
        yourGames: 'Твои игры',
        lfgGames: 'Игры LFG',
        chats: 'Сообщения',
        pendingRequests: (value: string): string =>
          `У тебя есть ${value} ожидающих запроса на вступление в вашу команду`,
      },
      loginModal: {
        title: 'Продолжить с',
        reconnection: (value: string): string => `Подключить ${value} снова`,
        action: 'Войти',
        signUp: 'Регистрация',
        signUpTitle: 'LFG поможет найти команду для игры',
        createGroupTitle:
          'Хочешь создать событие? \nВойди, чтобы присоединиться и разблокировать все функции',
        joinGroupTitle:
          'Хочешь вступить в ивент? \nВойди, чтобы присоединиться и разблокировать все функции',
        maybeLater: 'Не сейчас',
        bestTeammates: 'Лучшие товарищи \nпо команде',
        favGames: 'Все твои \nлюбимые игры',
        biggestCommunity: 'Самое большое \nсообщество',
        inControl: 'Все под \nтвоим контролем',
        gameSettings: {
          checkWhatsHappeningNow: 'Проверь, что происходит сейчас',
          battlenetConnectionError:
            'Мы не нашли ни одного персонажа в твоей учетной записи. \nЕсли ты не видишь своего персонажа, попробуй переподключить Battle.net.',
          loading:
            'Пожалуйста подожди. Идёт загрузка.. \nЭто может занять несколько минут',
          gameAdded: 'Игра добавлена в твой профиль',
          canShareGame:
            'Игровой профиль настроен. \nТы можешь присоединиться к различным событиям от других игроков и блогеров. \nТвой профиль можно найти в поиске, люди могут подписываться на тебя и отправлять сообщения.',
          yourCharacters: 'Твои персонажи',
          region: 'Регион',
          addComment: 'Добавь комментарий..',
          gameCommentPlaceholder: 'Расскажи об активностях, которые ты ищешь',
          browseGroups: 'Просмотреть события',
          goToProfile: 'Перейти в профиль',
          addMoreGames: 'Добавить еще игру',
          createGroup: 'Создать событие',
          findTeammates: 'Найти напарников',
        },
        actions: {
          back: 'Назад',
          continue: 'Продолжить',
          close: 'Закрыть',
          finish: 'Завершить',
        },
      },
      footer: {
        copyright: '© 2022 lf.group',
        joinDiscord: 'заходи в',
        joinChannels: 'заходи на наши каналы',
        menu: {
          about: 'О нас',
          help: 'Помощь',
          tos: 'Условия использования',
          privacy: 'Политика конфиденциальности',
          addBot: 'Добавить Discord бота',
        },
      },
      language: {
        english: 'Английский',
        russian: 'Русский',
      },
      date: {
        today: 'Сегодня',
        tomorrow: 'Завтра',
        yesterday: 'Вчера',
        days: {
          0: {
            nomShort: 'Вс',
          },
          1: {
            nomShort: 'Пон',
          },
          2: {
            nomShort: 'Вт',
          },
          3: {
            nomShort: 'Ср',
          },
          4: {
            nomShort: 'Чт',
          },
          5: {
            nomShort: 'Пят',
          },
          6: {
            nomShort: 'Сб',
          },
        },
        months: {
          0: {
            nom: 'Январь',
            dat: (value: string): string => `${value} Января`,
            datShort: (value: string): string => `${value} Янв`,
          },
          1: {
            nom: 'Февраль',
            dat: (value: string): string => `${value} Февраля`,
            datShort: (value: string): string => `${value} Фев`,
          },
          2: {
            nom: 'Март',
            dat: (value: string): string => `${value} Марта`,
            datShort: (value: string): string => `${value} Мар`,
          },
          3: {
            nom: 'Апрель',
            dat: (value: string): string => `${value} Апреля`,
            datShort: (value: string): string => `${value} Апр`,
          },
          4: {
            nom: 'Май',
            dat: (value: string): string => `${value} Мая`,
            datShort: (value: string): string => `${value} Май`,
          },
          5: {
            nom: 'Июнь',
            dat: (value: string): string => `${value} Июня`,
            datShort: (value: string): string => `${value} Июн`,
          },
          6: {
            nom: 'Июль',
            dat: (value: string): string => `${value} Июля`,
            datShort: (value: string): string => `${value} Июл`,
          },
          7: {
            nom: 'Август',
            dat: (value: string): string => `${value} Августа`,
            datShort: (value: string): string => `${value} Авг`,
          },
          8: {
            nom: 'Сентябрь',
            dat: (value: string): string => `${value} Сентября`,
            datShort: (value: string): string => `${value} Сен`,
          },
          9: {
            nom: 'Октябрь',
            dat: (value: string): string => `${value} Октября`,
            datShort: (value: string): string => `${value} Окт`,
          },
          10: {
            nom: 'Ноябрь',
            dat: (value: string): string => `${value} Ноября`,
            datShort: (value: string): string => `${value} Ноя`,
          },
          11: {
            nom: 'Декабрь',
            dat: (value: string): string => `${value} Декабря`,
            datShort: (value: string): string => `${value} Дек`,
          },
        },
      },
    },
    errors: {
      network: {
        timeout: 'Запрос обрабатывался слишком долго :(',
      },
      unknown: 'Произошла неизвестная ошибка',
      codes: {
        GroupJoinSlotHasUser: 'Слот занят',
        AuthRequired: 'Войди в свой аккаунт',
        InvalidUsername: 'Логин заполнен неверно',
        GroupDeleted: 'Ивент уже в архиве',
        GroupFull: 'Группа уже заполнена',
        GroupRoleFull: (value: string): string =>
          `Все слоты на роль ${value} уже заняты`,
        GroupNameEmpty: 'Название группы не может быть пустым',
        GroupOwnerSlotRequried: 'Займи любой слот, чтобы создать группу',
        GroupNameTooLong: 'Название слишком длинное',
        GroupSlotsLimitExceeded: 'Перебор со слотами, давай поменьше',
        GroupNotOwner: 'Это может сделать только хост',
        DeleteSocialAccountForbidden:
          'Нельзя удалить последний оставшийся аккаунт',
        NotFound: 'Не найдено',
        PermissionDenied: 'Доступ запрещён',
        ValidationError: 'Ошибка валидации',
        RuntimeError: 'Ошибка времени выполнения',
        RiotNotFound: 'Аккаунт Riot с таким ID не найден',
        ThirdPartyTimeout: 'Мы не смогли достучаться до Riot API',
        GroupJoinInvalidRole: 'Роль персонажа не подходит под слот',
        GroupJoinInvalidWowFaction:
          'Фракция твоего персонажа не подходит требованиям группы',
        GroupJoinInvalidRegion: 'Твой регион не подходит требованиям группы',
        GroupJoinNoSocial: 'Подключи Battle.net, чтобы войти в группу',
        GroupJoinLostArkLowGs: 'У тебя не хватает GearScore',
        GroupScheduledInPast: 'Нельзя запланировать прошлое :(',
        AliasAlreadyExists: 'Такой URL уже занят',
      },
      default: {
        createGroup: {
          title: 'Не получилось создать событие',
        },
        updateGroup: {
          title: 'Не получилось сохранить изменения',
        },
        connectBattlenet: {
          title: 'Не удалось привязать Battle.net',
        },
        connectTwitch: {
          title: 'Не удалось привязать Twitch',
        },
        connectDiscord: {
          title: 'Не удалось привязать Discord',
        },
        connectSteam: {
          title: 'Не удалось привязать Steam',
        },
        connectTwitter: {
          title: 'Не удалось привязать Twitter',
        },
        deleteTwitch: {
          title: 'Не удалось отвязать Twitch',
        },
        deleteBattlenet: {
          title: 'Не удалось отвязать Battle.net',
        },
        deleteDiscord: {
          title: 'Не удалось отвязать Discord',
        },
        deleteSteam: {
          title: 'Не удалось отвязать Steam',
        },
        deleteTwitter: {
          title: 'Не удалось отвязать Twitter',
        },
        deleteUserGame: {
          title: 'Не удалось скрыть персонажа',
        },
        restoreUserGame: {
          title: 'Не удалось вернуть персонажа',
        },
        updateUserGame: {
          title: 'Не удалось обновить информацию об игре',
        },
        followUser: {
          title: 'Не удалось подписаться на пользователя',
        },
        unfollowUser: {
          title: 'Не удалось отписаться от пользователя',
        },
      },
      clientErrors: {
        maxLegacyExceed: 'Наибольший Roster lvl — 250',
        mustConnectBattlenet:
          'Подключи свой Battle.net аккаунт, чтобы создать группу',
        mustHaveCharacter: (value: string): string =>
          `Добавь ${value} к себе в профиль, чтобы создать группу`,
        riot: {
          mustSelectRegion: 'Выбери свой регион Riot',
          invalidGameName: 'Неправильное игровое имя',
        },
        incorrectNumber: 'Неверное число',
        required: 'Обязательное',
        urlValidation: 'Невалидная ссылка',
        russianSymbols:
          'В группе с английским языком не может быть русского названия',
        inputRussianSymbols:
          'У тебя выбран англйский язык, параметр на русском языке может вызвать недопонимание',
        gameRequiredFields:
          'Если персонаж содержит пустые поля, он не будет обновлен',
        invalidCharacters: 'Недопустимые символы',
        isReserved: 'Это значение зарезервировано',
        tooShort: 'Недостаточно символов',
      },
    },
    event: {
      title: 'Предстоящее событие',
      host: 'Создатель',
      leaderboard: {
        label: 'Играй кастомки - выиграй 5000 UC',
        action: 'Проверь счёт в Лидерборде!',
      },
      layout: {
        progressBarFilling: (value: string, value2: string): string =>
          `${value} из ${value2} участников`,
      },
      actions: {
        standoff2: {
          playDescription: 'Играй,\nчтобы удвоить свои шансы!',
          playAction: 'Запустить Standoff 2',
        },
        shop: {
          shopAction: 'Открыть магазин',
          shopBannerTitle: (value: string, value2: string): string =>
            `${value} ${value2} Магазин`,
          shopBannerDescription: (value: string): string =>
            `Новый способ купить внутриигровые предметы и поддержать ${value}`,
          pubgShopBannerDescription: (value: string): string =>
            `Новый способ купить UC коды и поддержать ${value}`,
          authTitle: 'Войди, чтобы получить доступ в магазин',
        },
        join: {
          joinAction: 'Присоединиться',
          joined: 'Участвуешь',
        },
        play: {
          playAction: 'Играть',
          tooEarly: {
            playSoon: (value: string): string => `Начинаем через ${value}`,
            hour: (amount: number) =>
              `${amount} ${
                amount > 5 || amount === 0
                  ? 'часов'
                  : amount > 1
                  ? 'часа'
                  : 'час'
              }`,
            minute: (amount: number) =>
              `${amount} ${
                amount > 5 || amount === 0
                  ? 'минут'
                  : amount > 1
                  ? 'минуты'
                  : 'минуту'
              }`,
            second: (amount: number) =>
              `${amount} ${
                amount > 5 || amount === 0
                  ? 'секунд'
                  : amount > 1
                  ? 'секунды'
                  : 'секунду'
              }`,
          },
          tooLate: {
            playAction: 'Событие закончилось',
          },
        },
        leave: {
          leaveAction: 'Выйти',
        },
        yesAction: 'Да',
        viewAction: 'Открыть',
        maybeAction: 'Может быть',
        noAction: 'Нет',
        giveaway: {
          join: 'Принять участие',
          joined: 'Участвуешь',
          notify: 'Уведомить',
          subscribed: 'Уведомим о начале',
          followHost: 'Подписаться на создателя',
          unfollowHost: 'Отписаться от создателя',
        },
        link: {
          open: 'Открыть ссылку',
        },
        edit: {
          editAction: 'Редактировать',
        },
        shuffle: {
          shuffleAction: 'Распределить игроков',
        },
      },
      tabs: {
        members: (amount: number) => `${amount} участвует`,
        people: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'людей участвуют'
              : amount > 1
              ? 'человека участвуют'
              : 'человек участвует'
          }`,
        waitlist: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'хочет'
              : amount > 1
              ? 'хочет'
              : 'хотят'
          } присоединиться`,
        maybe: (amount: number) => `${amount} может быть`,
        declined: (amount: number) => `${amount} отклонено`,
        winners: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'победители'
              : amount > 1
              ? 'победители'
              : 'победитель'
          }`,
      },
      contextActions: {
        approve: 'Принять',
        makeWinner: 'Выбрать победителем',
        reject: 'Отклонить',
        kick: 'Выгнать',
        ban: 'Забанить',
      },
      emptyState: {
        noMembers: {
          waitlist: {
            title: 'Еще никто не присоединился к списку ожидания',
          },
          members: {
            title: 'Еще никто не присоединился к событию',
          },
          giveaway: {
            title: 'Еще никто не присоединился к розыгрышу',
            noWinners: 'Победители еще не выбраны',
            noWinnersDescription:
              'Победители будут выбраны случайным образом после времени окончания розыгрыша',
            viewJoinersAction: 'Посмотреть игроков в розыгрыше',
          },
        },
      },
      pubg: {
        copyIdAction: 'Скопировать ID',
        copyPasswordAction: 'Копировать пароль',
        successToast: 'Скопировано в буфер обмена 👌',
        server: 'Сервер',
        password: 'Пароль',
        notPublished: 'Пароль от лобби еще не опубликован',
      },
      card: {
        remainingDays: (amount: number) =>
          `Будет доступно через ${amount} ${
            amount > 5 || amount === 0 ? 'дней' : amount > 1 ? 'дня' : 'день'
          }`,
        remainingHours: 'Будет доступно через',
        notification:
          'Мы отправим уведомление в дискорд. Проверь, что он подключен в своем профиле',
      },
    },
    filters: {
      anyNumberValue: '0+',
      tagsPLaceholder: 'Выбери теги',
      tags: 'Теги',
    },
    formAddGame: {
      addNewGamesDesc:
        'Расскажи всем, во что ты играешь, тогда ты сможешь входить в группы и делиться своим профилем',
      actionAddGames: 'Добавить игру',
      actionRefresh: 'Обновить',
      actionDisconnect: 'Отключить',
      wow: {
        noSocial: {
          description:
            'Присоедини Battle.net аккаунт для загрузки персонажей в профиль. Это упростит поиск и присоединение к группе.',
          check1:
            'Ваш BattleTag и название записи Battle.net (Необходимо указать)',
          check2: 'Ваш профиль World of Warcraft',
        },
        params: {
          battlenetId: 'Battle.net ID',
          region: 'Регион',
          descriptionPlaceholder:
            'Расскажи всем, чем тебе хотелось бы заняться',
        },
        charactersEdit: {
          title: 'Выбери персонажей, которых хочешь показывать',
          yourCharactersTitle: 'Твои персонажи',
          hidePlaceholder: 'Скрыть персонажа',
          showPlaceholder: 'Вернуть персонажа',
          loadingDesc:
            'Пожалуйста, подожди. Ищем персонажей.. \nЭто займет около 10 секунд',
          notFoundDesc:
            'Мы не нашли ни одного персонажа в твоей учетной записи. \nЕсли ты не видишь своего персонажа, попробуй переподключить Battle.net.',
          actionReconnect: 'Переподключить Battle.net',
        },
      },
      lol: {
        description1:
          'Присоедините свой профиль Лиги Легенд через токен Riot Games. Всплывающее окно перенаправит вас на',
        description2:
          'и когда вы залогиньтесь там, мы получим токен, содержащий: ваш никнейм, KDA, игровую статистику и ваших самых популярных чемпионов.',
        description3:
          'Всё это укрепит возможности вашего личного профиля в Лиге Легенд и значительно облегчит поиск напарников.',
        description4:
          'Если вам любопытно или же вы опасаетесь токена Riot Games или Riot Sign On метода пожалуйста прочитайте эту',
        article: 'статью',
      },
      wildRift: {
        noSocial: {
          description:
            'Укажи свой Riot ID, чтобы получить свою карточку профиля и играть с другими. Мы ежедневно обновляем статистику для карточек',
          actionConnect: 'Подключить профиль',
          actionConnectLoading: 'Проверяем...',
        },
        characterEdit: {
          cardLoading: 'Ждем\nЗагрузка...',
          notFound: {
            title: 'Призыватель не найден',
            description:
              'Мы не нашли твой профиль LoL.\nПопробуй переподключить Riot или связаться с нами в ',
          },
        },
        params: {
          riotId: 'Riot ID',
          region: 'Регион',
          descriptionPlaceholder: 'Расскажи всем, что ты хочешь делать в игре',
        },
      },
      lostArk: {
        description:
          'Заполни параметры своего профиля в Lost Ark, чтобы получить свою карточку профиля и играть с другими.',
        createdSuccessfully: (value: string): string => `${value} добавлен`,
        updatedSuccessfully: (value: string): string => `${value} изменён`,
        deletedSuccessfully: (value: string): string => `${value} удалён`,
        character: 'персонаж',
        build: 'сборка',
        account: 'аккаунт',
      },
    },
    gameData: {
      event: {
        anyTitle: 'Любой тип',
        allEvents: 'Все события',
        teamPlay: 'Командный',
        game: 'Игра',
        type: 'Тип',
        title: 'Название',
        description: 'Описание',
        dateStart: 'Дата начала',
        from: 'С',
        until: 'По',
        privacy: 'Приватность',
        customButtonUrl: 'URL кастомной кнопки',
        customButtonLabel: 'Текст кнопки',
        postButtonUrl: 'URL кнопки сообщения',
        host: 'Организатор',
        Bet: 'Ставка',
        Event: 'Событие',
        Giveaway: 'Розыгрыш',
        Link: 'Ссылка',
        Post: 'Сообщение',
        Public: 'Публичный',
        Waitlist: 'По вейтлисту',
        Bets: 'Ставки',
        Events: 'События',
        Giveaways: 'Розыгрыши',
        Links: 'Ссылки',
        membersGoal: 'Цель по количеству участников',
        membersGoalDescription: 'Описание цели по участникам',
        forApp: 'Для приложения',
      },
      gameMode: {
        anyTitle: 'Любой режим',
        CSGO2v2: '2v2',
        CSGO5v5: '5v5',
        WowArena2v2: 'Арена 2x2',
        WowArena3v3: 'Арена 3x3',
        WowMythicPlus: 'Mythic+',
        WowRaid: 'Рейд',
        WowRBG: 'RBG',
        HearthstoneBattlegrounds: 'Поля сражений',
        HearthstoneBGLeague: 'Поля сражений: Лига',
        HearthstoneCustom: 'Custom',
        WowCustom: 'Custom',
        WarzoneBattleRoyale: 'Battle Royale',
        WarzonePlunder: 'Plunder',
        WowTBCArena5v5: 'Арена 5x5',
        WowTBCDungeon: 'Подземелье',
        HearthstoneLeagueSilvername: 'Лига Сильвернейма',
        HearthstoneLeagueTricksterinoKsedden: 'Лига Tricksterino и Ksedden',
        HearthstoneLeagueFriendlyTournament: 'Friendly турнир',
        LostArkRaidsOnGuardians: 'Guardian Raids',
        LostArkRaidsOnTheLordsOfFetrania: 'Raids On The Lords Of Fetrania',
        LostArkRaidsAbyss: 'Abyssal Raids',
        LostArkTrialsOfTheAbyss: 'Challenge Abyss Dungeons',
        LostArkGuardianHeroicRaids: 'Challenge/Trial Guardian Raids',
        LostArkAbyssDungeons: 'Abyss Dungeons',
        LostArkCube: 'Cube',
        LostArkBossRush: 'Boss Rush',
        LostArkOther: 'Other Activities',
        LostArkTestPath: 'Test Path',
        LostArkPlatinumFields: 'Platinum Fields',
        LostArkArena: 'Arena',
        LostArkAbyssalRaids: 'Abyssal Raids',
        LostArkLegionRaids: 'Legion Raids',
        PUBGMobileTournament: 'Турнир',
        PUBGMobileScrims: 'Скримы',
        PUBGMobileClassic: 'Классический режим',
        PUBGMobileArcade: 'Аркада',
        PUBGMobileGiveaway: 'Розыгрыш',
        PUBGMobileCustom: 'Кастом',
        PUBGMobilePractice: 'Практика',
        CODMobileTournament: 'Турнир',
        CODMobileScrims: 'Скримы',
        CODMobileClassic: 'Классический режим',
        CODMobileArcade: 'Аркада',
        CODMobileGiveaway: 'Розыгрыш',
        CODMobileCustom: 'Кастом',
        CODMobilePractice: 'Практика',
        WildRiftLFG: 'LFG',
        WildRiftRanked: 'Ранговый',
        WildRiftPVP: 'PvP',
        WildRiftARAM: 'А.Р.А.М.',
        WildRiftAI: 'ИИ',
        WildRiftUrf: 'У.Р.Ф.',
        WildRiftEsportsTeam: 'Esports Team',
        LolNormal: 'Обычная (режим выбора)',
        LolNormalBlind: 'Обычная (выбор вслепую)',
        LolAram: 'ARAM',
        LolDuo: 'Ранговая парная',
        LolFlex: 'Ранговая гибкая',
        LolClash: 'Клэш',
        LolCustom: 'Кастомная 5х5 - Ущелье Призывателей',
        LolCustomAram: 'Кастомная 5х5 - ARAM',
        LolFeatured: 'Временный режим (У.Р.Ф. и т.д.)',
      },
      gameModeTitle: {
        CSGO2v2: '2v2',
        CSGO5v5: '5v5',
        WowArena2v2: 'Арена',
        WowArena3v3: 'Арена',
        WowMythicPlus: 'Mythic Plus',
        WowRaid: 'Raid',
        WowRBG: 'RBG',
        WowCustom: '',
        HearthstoneBattlegrounds: 'Поля сражений',
        HearthstoneBGLeague: 'Поля сражений: Лига',
        HearthstoneCustom: 'Custom',
        WarzoneBattleRoyale: 'Battle Royale',
        WarzonePlunder: 'Plunder',
        WowTBCArena5v5: 'Арена 5x5',
        WowTBCDungeon: 'Подземелье',
        HearthstoneLeagueSilvername: 'Лига Сильвернейма',
        HearthstoneLeagueTricksterinoKsedden: 'Лига Tricksterino и Ksedden',
        HearthstoneLeagueFriendlyTournament: 'Friendly турнир',
        LostArkRaidsOnGuardians: 'Guardian Raids',
        LostArkRaidsOnTheLordsOfFetrania: 'Raids On The Lords Of Fetrania',
        LostArkRaidsAbyss: 'Abyssal Raids',
        LostArkTrialsOfTheAbyss: 'Challenge Abyss Dungeons',
        LostArkGuardianHeroicRaids: 'Challenge, Trial Guardian Raids',
        LostArkAbyssDungeons: 'Abyss Dungeons',
        LostArkCube: 'Cube',
        LostArkBossRush: 'Boss Rush',
        LostArkOther: 'Other Activities',
        LostArkTestPath: 'Test Path',
        LostArkPlatinumFields: 'Platinum Fields',
        LostArkArena: 'Арена',
        LostArkAbyssalRaids: 'Abyssal Raids',
        LostArkLegionRaids: 'Legion Raids',
        PUBGMobileTournament: 'Tournament',
        PUBGMobileScrims: 'Scrims',
        PUBGMobileClassic: 'Classic',
        PUBGMobileArcade: 'Arcade',
        PUBGMobileGiveaway: 'Giveaway',
        PUBGMobilePractice: 'Практика',
        PUBGMobileCustom: '',
        CODMobileTournament: 'Tournament',
        CODMobileScrims: 'Scrims',
        CODMobileClassic: 'Classic',
        CODMobileArcade: 'Arcade',
        CODMobileGiveaway: 'Giveaway',
        CODMobilePractice: 'Практика',
        CODMobileCustom: '',
        WildRiftLFG: 'LFG',
        WildRiftRanked: 'Ранговый',
        WildRiftPVP: 'PvP',
        WildRiftARAM: 'А.Р.А.М.',
        WildRiftAI: 'ИИ',
        WildRiftUrf: 'У.Р.Ф.',
        WildRiftEsportsTeam: 'Esports Team',
        LolNormal: 'Обычный',
        LolAram: 'ARAM',
        LolDuo: 'Дуо',
        LolFlex: 'Флекс',
        LolClash: 'Клэш',
        LolFeatured: 'Специальный',
        LolCustom: 'Кастом 5x5',
        LolCustomAram: 'Кастом 5x5 ARAM',
      },
      slotRole: {
        anyTitle: 'Любая роль',
        Unset: 'Любая роль',
        WowTank: 'Танк',
        WowHeal: 'Хил',
        WowDamage: 'ДД',
        CsgoSupport: 'Саппорт',
        CsgoawPer: 'AWP',
        CsgoLurker: 'Lurker',
        CsgoEntryFragger: 'Fragger',
        CsgoLeader: 'Leader',
        LolSolo: 'Топ',
        LolJungle: 'Лес',
        LolMid: 'Мид',
        LolDuo: 'Бот',
        LolSupport: 'Поддержка',
        LostArkDamage: 'ДД',
        LostArkSupport: 'Саппорт',
      },
      wow: {
        alias: {
          title: 'Battletag',
        },
        region: {
          US: 'США',
          Europe: 'Европа',
          APAC: 'Aзия',
        },
        activities: {
          title: 'Активности',
          anyTitle: 'Любая активность',
          placeholder: 'Выберите активности',
        },
        playstyle: {
          title: 'Стиль игры',
          anyTitle: 'Любой стиль',
        },
        minMythicRating: {
          title: 'Минимальный Mythic+',
        },
        minRbgRating: {
          title: 'Минимальный RBG',
        },
        minArenaRating: {
          title: 'Минимальный рейтинг арены',
        },
        wowRegion: {
          anyTitle: 'Любой регион',
          title: 'Регион',
          US: 'США',
          Europe: 'Европа',
          APAC: 'Aзия',
          China: 'Китай',
          Korea: 'Корея',
          Taiwan: 'Тайвань',
        },
        server: {
          title: 'Сервер',
          anyForm: 'Любой сервер',
        },
        role: {
          title: 'Роль',
          placeholder: '',
          tank: 'Танк',
          heal: 'Хил',
          damage: 'ДД',
        },
        level: {
          title: 'Уровень',
          placeholder: 'любой',
        },
        mythicPlusScore: {
          title: 'M+',
          placeholder: 'любой',
        },
        sfoNormalKilled: {
          title: 'SFO N убито',
          placeholder: 'любое',
        },
        sfoHeroicKilled: {
          title: 'SFO H убито',
          placeholder: 'любое',
        },
        sfoMythicKilled: {
          title: 'SFO M убито',
          placeholder: 'любое',
        },
        arena2x2: {
          title: '2x2',
          placeholder: 'любой',
        },
        arena3x3: {
          title: '3x3',
          placeholder: 'любой',
        },
        class: {
          title: 'Класс',
          any: 'Любой класс',
          placeholder: '',
          unknown: 'Класс не выбран',
          Paladin: 'Паладин',
          Warrior: 'Воин',
          DeathKnight: 'Рыцарь смерти',
          Hunter: 'Охотник',
          Shaman: 'Шаман',
          Druid: 'Друид',
          Rogue: 'Разбойник',
          Monk: 'Монах',
          DemonHunter: 'Охотник на демонов',
          Priest: 'Жрец',
          Mage: 'Маг',
          Warlock: 'Чернокнижник',
        },
        race: {
          title: 'Раса',
          placeholder: '',
          human: 'Человек',
          dwarf: 'Дворф',
          nightElf: 'Ночной эльф',
          gnome: 'Гном',
          draenei: 'Дреней',
          worgen: 'Ворген',
          pandaren: 'Пандарен',
          orc: 'Орк',
          undead: 'Нежить',
          tauren: 'Таурен',
          troll: 'Тролль',
          bloodElf: 'Эльф крови',
          goblin: 'Гоблин',
        },
        specialization: {
          title: 'Специализация',
          any: 'Любая спека',
          unknown: 'Cпециализация не выбрана',
          DeathKnightBlood: 'Кровь Рыцарь смерти',
          DeathKnightFrost: 'Лед Рыцарь смерти',
          DeathKnightUnholy: 'Нечестивость Рыцарь смерти',
          DemonHunterHavoc: 'Истребление Охотник на демонов',
          DemonHunterVengeance: 'Месть Охотник на демонов',
          DruidBalance: 'Баланс Друид',
          DruidFeral: 'Сила зверя Друид',
          DruidGuardian: 'Страж Друид',
          DruidRestoration: 'Исцеление Друид',
          HunterBeastMastery: 'Повелитель зверей Охотник',
          HunterMarksmanship: 'Стрельба Охотник',
          HunterSurvival: 'Выживание Охотник',
          MageArcane: 'Тайная магия Маг',
          MageFire: 'Огонь Маг',
          MageFrost: 'Лед Маг',
          MonkBrewmaster: 'Хмелевар Монах',
          MonkMistweaver: 'Ткач туманов Монах',
          MonkWindwalker: 'Танцующий с ветром Монах',
          PaladinHoly: 'Свет Паладин',
          PaladinProtection: 'Защита Паладин',
          PaladinRetribution: 'Воздаяние Паладин',
          PriestDiscipline: 'Послушание Жрец',
          PriestHoly: 'Свет Жрец',
          PriestShadow: 'Тьма Жрец',
          RogueAssassination: 'Ликвидация Разбойник',
          RogueOutlaw: 'Головорез Разбойник',
          RogueSubtlety: 'Скрытность Разбойник',
          ShamanElemental: 'Стихии Шаман',
          ShamanEnhancement: 'Совершенствование Шаман',
          ShamanRestoration: 'Исцеление Шаман',
          WarlockAffliction: 'Колдовство Чернокнижник',
          WarlockDemonology: 'Демонология Чернокнижник',
          WarlockDestruction: 'Разрушение Чернокнижник',
          WarriorArms: 'Оружие Воин',
          WarriorFury: 'Неистовство Воин',
          WarriorProtection: 'Защита Воин',
        },
        faction: {
          any: 'Любая фракция',
          title: 'Фракция',
          horde: 'Орда',
          alliance: 'Альянс',
        },
        raid: {
          title: 'Рейд',
          anyTitle: 'Любой рейд',
          SepulcherOfTheFirstOnes: 'Гробница Предвечных',
          SanctumOfDomination: 'Святилище Господства',
        },
        raidDifficulty: {
          title: 'Сложность',
          anyTitle: 'Любая сложность',
          Normal: 'Нормальный',
          Heroic: 'Героический',
          Mythic: 'Мифический',
        },
        dungeons: {
          anyTitle: 'Any dungeon',
          HallsOfAtonement: 'Чертоги Покаяния',
          SanguineDepths: 'Кровавые катакомбы',
          Plaguefall: 'Чумные каскады',
          TheNecroticWake: 'Смертельная тризна',
          DeOtherSide: 'Та Сторона',
          MistsOfTirnaScithe: 'Туманы Тирна Скитта',
          SpiresOfAscension: 'Шпили Перерождения',
          TheaterOfPain: 'Театр Боли',
          TazaveshSoleahsGambit: 'Тазавеш: Гамбит Со’леи',
          TazaveshStreetsOfWonder: 'Тазавеш: улицы Чудес',
          TBCHellfireRamparts: 'Бастионы Адского Пламени',
          TBCTheBloodFurnace: 'Кузня Крови',
          TBCTheShatteredHalls: 'Разрушенные залы',
          TBCTheSlavePens: 'Узилище',
          TBCTheUnderbog: 'Нижетопь',
          TBCTheSteamvault: 'Паровое подземелье',
          TBCOldHillsbradFoothills: 'Старые предгорья Хилсбрада',
          TBCTheBlack: 'Черные топи',
          TBCMorass: 'Черные топи',
          TBCTheMechanar: 'Механар',
          TBCTheBotanica: 'Ботаника',
          TBCTheArcatraz: 'Аркатрац',
          TBCManaTombs: 'Гробницы маны',
          TBCAuchenaiCrypts: 'Аукенайские гробницы',
          TBCSethekkHalls: 'Сетеккские залы',
          TBCShadowLabyrinth: 'Темный лабиринт',
          OperationMechagonJunkyard: 'Операция «Мехагон» — свалка',
          OperationMechagonWorkshop: 'Операция «Мехагон» — мастерская',
          ReturnToKarazhanLower: 'Возвращение в Каражан: нижний ярус',
          ReturnToKarazhanUpper: 'Возвращение в Каражан: верхний ярус',
          GrimrailDepot: 'Депо Мрачных Путей',
          IronDocks: 'Железные доки',
        },
        fields: {
          login: 'Имя персонажа',
          description: 'Описание',
          level: 'Уровень',
          rating: 'Рейтинг',
          region: 'Регион',
          realm: 'Сервер',
          arenaScore: 'Рейтинг арены',
          guild: 'Гильдия',
          comment: 'Комментарий',
          specialization: 'Специализация',
          covenant: 'Ковенант',
          raidSepulcher: 'SFO',
          arena2x2: '2x2',
          arena3x3: '3x3',
          mPlusPrevious: 'Mythic+ s2',
          mPlusCurrent: 'Mythic+',
        },
      },
      hearthstone: {
        alias: {
          title: 'Battletag',
        },
        region: {
          title: 'Регион',
          any: 'Любой регион',
          Unset: 'Регион',
          Asia: 'Азия',
          China: 'Китай',
          Europe: 'Европа',
          US: 'США',
          Americas: 'Америка',
        },
        fields: {
          description: 'Описание',
          ratingBg: 'Рейтинг',
        },
      },
      pubg: {
        nickname: {
          title: 'PUBG Никнейм',
        },
        kd: {
          title: 'K/D',
        },
        region: {
          title: 'Регион',
          any: 'Любой регион',
          CIS: 'СНГ',
          EU: 'Европа',
          Turkey: 'Турция',
          SEA: 'Южно-восточная Азия (SEA)',
          SouthAsia: 'Южная Азия',
          SA: 'Южная Америка',
          Arabian: 'Ближний Восток',
        },
        rank: {
          title: 'Ранг',
          any: 'Любой ранг',
          NoRank: 'Без ранга',
          Bronze: 'Бронза',
          Silver: 'Серебро',
          Gold: 'Золото',
          Platinum: 'Платина',
          Diamond: 'Алмаз',
          Crown: 'Корона',
          Ace: 'Ас',
          AceMaster: 'Ас-Мастер',
          AceDominator: 'Ас-Доминатор',
          Conqueror: 'Завоеватель',
        },
      },
      cod: {
        nickname: {
          title: 'Ник',
        },
        kd: {
          title: 'K/D',
        },
        region: {
          title: 'Регион',
          any: 'Любой регион',
          NA: 'Сев. Америка',
          SA: 'Южная Америка',
          SEA: 'Юго-восточная Азия',
          SAM: 'Южная Азия и Ближ. восток',
          EU: 'Европа',
          OC: 'Океания',
          AF: 'Африка',
        },
        rank: {
          title: 'Ранг',
          any: 'Любой ранг',
          NoRank: 'Без ранга',
          Rookie: 'Новичок I-V',
          Veteran: 'Ветеран I-V',
          Elite: 'Элита I-V',
          Pro: 'Про I-V',
          Master: 'Мастер',
          Grandmaster: 'Грандмастер',
          Legendary: 'Легенда',
        },
      },
      standoff2: {
        nickname: {
          title: 'Ник',
        },
        gameId: {
          title: 'ID',
        },
        region: {
          title: 'Регион',
          any: 'Любой регион',
          RU: 'RU',
        },
        rank: {
          title: 'Ранг',
          any: 'Любой ранг',
          NoRank: 'Без ранга',
          Bronze: 'Бронза I-V',
          Silver: 'Серебро I-V',
          Gold: 'Золото I-V',
          Phoenix: 'Феникс',
          Ranger: 'Рейнджер',
          Champion: 'Чемпион',
          Master: 'Мастер',
          Elite: 'Элита',
          Legendary: 'Легенда',
        },
      },
      custom: {
        region: {
          title: 'Регион',
          any: 'Любой регион',
          CIS: 'СНГ',
          EU: 'Европа',
          NA: 'США',
          Asia: 'Азия',
        },
      },
      ggd: {
        region: {
          title: 'Регион',
          any: 'Любой регион',
          CIS: 'СНГ',
          EU: 'Европа',
          NA: 'США',
          Asia: 'Азия',
        },
        alias: {
          title: 'Ник',
        },
      },
      steam: {
        region: {
          title: 'Регион',
          any: 'Любой регион',
          EU: 'Европа',
          NA: 'США',
          SA: 'Южная Америка',
          CIS: 'СНГ',
          Asia: 'Азия',
          WesternEurope: 'Западная Европа',
          EasternEurope: 'Восточная Европа',
          NorthAmerica: 'Северная Америка',
          SouthAmerica: 'Южная Америка',
        },
      },
      warzone: {
        region: {
          any: 'Любой регион',
          USAndCanada: 'США и Канада',
          LatinAmerica: 'Латинская Америка',
          EU: 'ЕС',
          Germany: 'Германия',
          Russia: 'Россия',
          MiddleEast: 'Ближний Восток',
          Africa: 'Африка',
          Australia: 'Австралия',
          Asia: 'Азия',
          Pacific: 'Тихий океан',
          Japan: 'Япония',
        },
        fields: {
          login: 'Логин',
          description: 'Описание',
          region: 'Регион',
        },
      },
      lol: {
        region: {
          title: 'Регион',
          any: 'Любой регион',
          Brazil1: 'BR (Бразилия)',
          EuNorth1: 'EUNE (Северная Европа)',
          EuWest1: 'EUW (Западная Европа)',
          Japan1: 'JP (Япония)',
          Korea: 'KR (Корея)',
          NorthAmerica1: 'NA (Северная Америка)',
          LatinAmerica1: 'LAN (Латинская Америка 1)',
          LatinAmerica2: 'LAS (Латинская Америка 2)',
          Oceania1: 'OC (Океания)',
          Russia: 'RU (Россия)',
          Turkey1: 'TR (Турция)',
        },
        activities: {
          title: 'Активности',
          anyTitle: 'Любая активность',
          placeholder: 'Выберите активности',
        },
        playstyle: {
          title: 'Стиль игры',
          anyTitle: 'Любой стиль',
        },
        rank: {
          solo: {
            short: 'SoloQ',
            full: 'SoloQ Ранг',
          },
          flex: {
            short: 'FlexQ',
            full: 'FlexQ Ранг',
          },
        },
        category: {
          title: 'Категория',
          any: 'Любая категория',
          Controller: 'Controller',
          Fighter: 'Воин',
          Mage: 'Маг',
          Marksman: 'Стрелок',
          Slayer: 'Убийца',
          Specialist: 'Поддержка',
          Tank: 'Танк',
        },
        kda: 'Сред KDA',
        creepScore: 'Сред CS',
        minWinRate: {
          title: 'Минимальный винрейт',
        },
        winRate: {
          title: {
            short: (amount: number) => `Win ${amount}%`,
            full: 'Матчи',
          },
          wins: {
            short: (amount: number) => `W${amount}`,
            full: (amount: number) => `Win ${amount}`,
          },
          loses: {
            short: (amount: number) => `L${amount}`,
            full: (amount: number) => `Los ${amount}`,
          },
        },
      },
      wildRift: {
        region: {
          title: 'Регион',
          Europe: 'Европа',
          Americas: 'Америки',
          Asia: 'Азия',
        },
        riotId: {
          title: 'Riot ID',
        },
        tagLine: {
          title: 'Тэг',
        },
        preferredLine: {
          title: 'Роль',
          primaryTitle: 'Главная роль',
          secondaryTitle: 'Вторая роль',
          placeholder: 'Любая роль',
        },
        rank: {
          title: 'Ранг',
          any: 'Любой ранг',
          None: 'Нет ранга',
          Iron4: 'Железо IV',
          Iron3: 'Железо III',
          Iron2: 'Железо II',
          Iron1: 'Железо I',
          Bronze4: 'Бронза IV',
          Bronze3: 'Бронза III',
          Bronze2: 'Бронза II',
          Bronze1: 'Бронза I',
          Silver4: 'Серебро IV',
          Silver3: 'Серебро III',
          Silver2: 'Серебро II',
          Silver1: 'Серебро I',
          Gold4: 'Золото IV',
          Gold3: 'Золото III',
          Gold2: 'Золото II',
          Gold1: 'Золото I',
          Platinum4: 'Платина IV',
          Platinum3: 'Платина III',
          Platinum2: 'Платина II',
          Platinum1: 'Платина I',
          Emerald4: 'Изумруд IV',
          Emerald3: 'Изумруд III',
          Emerald2: 'Изумруд II',
          Emerald1: 'Изумруд I',
          Diamond4: 'Алмаз IV',
          Diamond3: 'Алмаз III',
          Diamond2: 'Алмаз II',
          Diamond1: 'Алмаз I',
          Master: 'Мастер',
          Grandmaster: 'Грандмастер',
          Challenger: 'Претендент',
        },
      },
      csgo: {
        alias: {
          title: 'Steam ID',
        },
        faceitLvl: {
          title: 'FACEIT Lvl',
          any: 'Без lvl',
        },
        region: {
          title: 'Регион',
          EuEast: 'Восточная Европа',
          EuWest: 'Западная Европа',
          Eu: 'Европа',
          Asia: 'Азия',
          US: 'США',
        },
        rank: {
          any: 'Без ранга',
          title: 'Ранг',
          Silver1: 'Серебро I',
          Silver2: 'Серебро II',
          Silver3: 'Серебро III',
          Silver4: 'Серебро IV',
          SilverElite: 'Серебро Элита',
          SilverEliteMaster: 'Серебро Великий Магистр',
          GoldNova1: 'Золотая Звезда I',
          GoldNova2: 'Золотая Звезда II',
          GoldNova3: 'Золотая Звезда III',
          GoldNovaMaster: 'Золотая Звезда Магистр',
          MasterGuardian1: 'Магистр Хранитель I',
          MasterGuardian2: 'Магистр Хранитель II',
          MasterGuardianElite: 'Магистр Хранитель Элита',
          DistinguishedMasterGuardian: 'Заслуженный Магистр Хранитель',
          LegendaryEagle: 'Легендарный Беркут',
          LegendaryEagleMaster: 'Легендарный Беркут Магистр',
          SupremeMasterFirstClass: 'Великий Магистр Высшего ранка',
          GlobalElite: 'Всемирная Элита',
        },
        role: {
          Support: 'Support',
          AWPer: 'AWPer',
          Lurker: 'Lurker',
          EntryFragger: 'Entry Fragger',
          IGL: 'IGL',
          Rifler: 'Rifler',
        },
        fields: {
          description: 'Описание',
          region: 'Регион',
          rank: 'Ранг',
          role: 'Роль',
        },
      },
      lostArk: {
        region: {
          title: 'Регион',
          any: 'Любой регион',
          USWest: 'Запад США',
          USEast: 'Восток США',
          EUCentral: 'Центральная Европа',
          RU: 'Россия',
          uknown: 'Неизвестный регион',
        },
        activities: {
          title: 'Активности',
          anyTitle: 'Любая активность',
          placeholder: 'Выберите активности',
        },
        playstyle: {
          title: 'Стиль игры',
          anyTitle: 'Любой стиль',
        },
        guardian: {
          title: 'Хранители',
          any: 'Любой хранитель',
          Urnil: 'Урнил',
          Lumerus: 'Люмерион',
          IcyLegoros: 'Ледяной Легиорос',
          Vertus: 'Беритас',
          Chromanium: 'Хроманиум',
          Nacrasena: 'Некрасена',
          FlameFoxYoho: 'Красная Лисица',
          Tytalos: 'Тайталос',
          DarkLegoros: 'Сумрачный Легиорос',
          Helgaia: 'Хелгая',
          Calventus: 'Кальвентус',
          Achates: 'Achates',
          FrostHelgaia: 'Ледяная Челгая',
          LavaChromanium: 'Вулканический Хроманиум',
          Levanos: 'Реванос',
          Alberhastic: 'Alberhastic',
          ArmoredNacrasena: 'Бронированная Некрасена',
          Igrexion: 'Игресион',
          NightFoxYoho: 'Night Fox Yoho',
          Velganos: 'Бельганус',
          Descaluda: 'Дэскалуда',
        },
        abyssDungeonsAbyss: {
          title: 'Бездна',
          any: 'Любая бездна',
          RuinsOfElberia: 'Руины Эльберии',
          AbodeOfDreams: 'Обитель снов',
          CrucibleOfSouls: 'Горнило душ',
          GreatGate: 'Великие Врата',
          HeartOfOreya: 'Сердце Орейи',
        },
        abyssDungeonsDungeon: {
          title: 'Подземелье Бездны',
          any: 'Любое подземелье бездны',
          DemonBeastCanyon: 'Обитель смерти',
          NecromancersOrigin: 'Утес Некроманта',
          HallOfTheTwistedWarlord: 'Цитадель иллюзий',
          HildebrandtPalace: 'Дворец Аврельсуд',
          RoadOfLament: 'Дорога скорби',
          ForgottenForge: 'Забытая кузня',
          SeaOfIndolence: 'Море забвения',
          TranquilKarkosa: 'Гибельная пучина',
          AlaricsSanctuary: 'Подводное святилище',
          AirasOculus: 'Обезумевший лес',
          OrehaPreveza: 'Гниющая поляна',
        },
        cubeDungeon: {
          title: 'Подземелье куба',
          any: 'Любое подземелье куба',
          Cube: 'Куб',
          EliteCube: 'Elite Cube',
          DimensionalCube: 'Dimensional Cube',
        },
        bossRush: {
          title: 'Boss Rush',
          any: 'Любой boss rush',
          HallOfSilence: 'Hall Of Silence',
          HallOfTheSun: 'Hall Of The Sun',
        },
        platinumFieldsField: {
          title: 'Platinum Field',
          any: 'Любой platinum field',
          NahunsDomain: 'Nahuns Domain',
          OldYudianCanal: 'Old Yudian Canal',
        },
        difficulty: {
          title: 'Сложность',
          any: 'Любая',
          anyForm: 'Любая сложность',
          Normal: 'Нормально',
          Hard: 'Сложно',
          Hell: 'Ад',
          Dejavu: 'Де-джавю',
        },
        otherActivities: {
          title: 'Активности',
          anyForm: 'Любая активность',
          any: 'Любая',
          SecretMap: 'Secret Map',
          Misc: 'Misc.',
        },
        alias: {
          title: 'Username',
        },
        server: {
          title: 'Cервер',
          anyForm: 'Любой сервер',
        },
        legacyLvl: {
          title: 'Roster lvl',
          anyForm: 'Any Roster lvl',
        },
        gearScore: {
          title: 'ilvl',
          anyForm: 'Any ilvl',
        },
        arena: {
          title: 'Арена',
          anyForm: 'Любая арена',
          any: 'Любая',
          NormalMatch: 'Обычный матч',
          CoopBattle: 'Командный матч',
          CustomGame: 'Свой матч',
        },
        arenaMode: {
          title: 'Режим арены',
          anyForm: 'Любой режим арены',
          any: 'Любой',
          TeamDeathmatch: 'Team Deathmatch',
          TeamElimination: 'Team Elimination',
        },
        raid: {
          title: 'Набег',
          anyForm: 'Любой набег',
          any: 'Любой',
          Argos: 'Argos',
          Valtan: 'Valtan',
          Brelshaza: 'Brelshaza',
          Vykas: 'Vykas',
          KakulSaydon: 'Kakul-Saydon',
        },
        phase: {
          title: 'Фаза',
          anyForm: 'Любая фаза',
          any: 'Любая',
          Phase1: 'Phase 1',
          Phase2: 'Phase 2',
          Phase3: 'Phase 3',
        },
        class: {
          title: 'Класс',
          anyForm: 'Любой классs',
          any: 'Любой',
          Berserker: 'Berserker',
          Paladin: 'Paladin',
          Gunlancer: 'Gunlancer',
          Striker: 'Striker',
          Wardancer: 'Wardancer',
          Scrapper: 'Scrapper',
          Soulfist: 'Soulfist',
          Glaivier: 'Glaivier',
          Gunslinger: 'Gunslinger',
          Machinist: 'Machinist',
          Destroyer: 'Destroyer',
          Artillerist: 'Artillerist',
          Deadeye: 'Deadeye',
          Sharpshooter: 'Sharpshooter',
          Bard: 'Bard',
          Arcanist: 'Arcanist',
          Summoner: 'Summoner',
          Sorceress: 'Sorceress',
          Shadowhunter: 'Shadowhunter',
          Deathblade: 'Deathblade',
          Reaper: 'Reaper',
        },
        build: {
          name: 'Название сборки',
          link: 'Ссылка',
        },
        card: {
          title: 'Карта',
          anyForm: 'Любая карта',
          placeholder: 'Выбери карты',
          any: 'Выбери карту',
        },
        zone: {
          title: 'Зона',
          anyForm: 'Любая зона',
          any: 'Любая',
        },
        gift: {
          title: 'Подарок',
          anyForm: 'Любой подарок',
          any: 'Выбери подарок',
          placeholder: 'Выбери подарки',
        },
        continent: {
          title: 'Континент',
          anyForm: 'Любой континент',
          any: 'Любой',
        },
      },
      lfg: {
        tags: {
          twoByTwo: '2v2',
          threeByThree: '3v3',
          rbg: 'RBG',
          mythicPlus: 'Mythic +',
          pveRaid: 'PvE Raid',
          lookingForGuild: 'Ищу гильдию',
          raidLeader: 'Рейд лидер',
          learning: 'Учусь играть',
          casual: 'Казуал',
          tryhard: 'Трайхаржу',
          awc: 'AWC',
          mdi: 'MDI',
          voice: 'С микрофоном',
          streamer: 'Стример',
          mentor: 'Наставник',
          duo: 'Duo',
          flex: 'Flex',
          rift: 'Rift',
          aram: 'ARAM',
          normal: 'Нормалы',
          ranked: 'Ранкеды',
          clash: 'Клэш',
          teamCaptain: 'Team Captain',
          semiPro: 'Semi-Pro',
          pve: 'PvE',
          pvp: 'PvP',
          de: 'DE',
          uk: 'UK',
          us: 'US',
          es: 'ES',
          mx: 'MX',
          fr: 'FR',
          br: 'BR',
          en: 'EN',
          it: 'IT',
          pl: 'PL',
          swe: 'SWE',
          tr: 'TR',
          argos: 'Argos',
          valtan: 'Valtan',
          brelshaza: 'Brelshaza',
          vykas: 'Vykas',
          KakulSaydon: 'Kakul-Saydon',
          orehasWell: 'Oreha`s Well',
          lookingForStaticGroup: 'Looking for Static group',
          semiHardcore: 'Полу-хардкор',
          hardcore: 'Хардкор',
          LoadsFreeTime: 'Много свободного времени',
          LookingForFriends: 'Ищу друзей',
          LookingForTeam: 'Ищу команду',
          Pro: 'Про-игрок',
          TFT: 'TFT',
          FamilyPerson: 'Семейный человек',
          Teenager: 'Тинейджер',
          Tournaments: 'Любительские Турниры',
          Age2030: '20-30 лет',
          Age3040: '30-40 лет',
          Age40Plus: '40+ лет',
          NoVoice: 'Без микрофона',
          Coach: 'Тренер',
          LookingForMale: 'Ищу парня',
          LookingForFemale: 'Ищу девушку',
          Female: 'Девушка',
          Male: 'Парень',
          coaching: 'Тренеруем',
          fun: 'По фану',
          nightGames: 'Играем ночью',
          casualTournaments: 'Любительские турниры',
          twentyThirtyYears: '20-30 лет',
          thirtyFourtyYears: '30-40 лет',
          fourtyPlusYears: '40+ лет',
          hellmode: 'Hellmode',
          gvg: 'GvG',
          abyssDungeons: 'Abyss Dungeons',
          lookingForRaid: 'Ищу рейд',
          lookingForCodriver: 'Ищу копилота',
          lookingForClan: 'Ищу клан',
          lookingForGirl: 'Ищу девушку',
          lookingForMan: 'Ищу парня',
          moddedServers: 'Сервера с модами',
          vanillaServers: 'Ванилла сервера',
          skilledShooter: 'Хороший стрелок',
          builder: 'Строитель',
          girl: 'Девушка',
          man: 'Парень',
          fullSupport: 'Full-Support',
          semiSupport: 'Semi-Support',
          offlane: 'Offlane',
          mid: 'Mid',
          carry: 'Carry',
          smurf: 'Smurf',
          turbo: 'Turbo',
          lurker: 'Lurker',
          sniper: 'Sniper',
          support: 'Support',
          faceit: 'Faceit',
          nonBinary: 'Non-binary',
          freeAgent: 'Свободный агент',
          tdm: 'TDM',
          customGames: 'Кастомки',
          captain: 'Капитан',
          igl: 'IGL',
          manager: 'Менеджер',
          newbie: 'Новичок',
          morningPrimeTime: 'Утренний прайм-тайм',
          daytimePrimeTime: 'Дневной прайм-тайм',
          eveningPrimeTime: 'Вечерний прайм-тайм',
          raid: 'Рейды',
          arena: 'Арена',
          fps30Plus: '30+ fps',
          fps60Plus: '60+ fps',
          fps90Plus: '90+ fps',
          lookingForPlayers: 'Ищу игроков',
          age1114: '11-14 лет',
          age1519: '15-19 лет',
          age2024: '20-24 года',
          age2530: '25-30 лет',
          ge30Plus: '30+ лет',
        },
      },
    },
    group: {
      slots: 'Слоты',
      pasha: {
        info: {
          when: 'Когда',
          memberSince: (value: string): string => `Участник с ${value}`,
          waitlist: {
            title: 'Вейтлист',
            yes: 'Есть',
            no: 'Нет',
          },
        },
        title: (value: string, value2: string): string =>
          `В группе ${value} из ${value2}`,
      },
      event: {
        createdTitle: (value: string): string => `Создал группу ${value}.`,
        card: {
          slots: {
            joinAs: 'Места:',
            team: 'Команда:',
          },
        },
      },
      meta: {
        title: (value: string, value2: string): string =>
          `${value} (${value2}) | LF.Group`,
        description: (value: string): string =>
          `В событие по игре ${value} нужно больше игроков. Открой ссылку, чтобы узнать подробнее.`,
      },
      slot: {
        noAccounts: 'Нет аккаунтов',
        actionInvite: 'Пригласить',
        actionJoin: 'Войти',
        actionViewCustomAnswers: 'Ответы',
        waitingToApprove: 'Waiting to approve',
        waitingToAccept: 'Waiting to accept',
        waitingToChooseSlote: 'В ожидании выбора слота',
        lostArkSlot: {
          seeBuilds: 'Посмотреть сборки',
        },
      },
      balance: {
        action: 'Сбалансировать',
        notAllowed: {
          title: 'Прежде чем балансировать комманды заполни все слоты',
        },
        success: {
          toast: 'Слоты сбалансированы',
        },
      },
      password: {
        example: '********',
        toastSuccess: 'Пароль скопирован 👌',
      },
      card: {
        status: {
          started: 'Уже идет',
          ended: 'Закончилась',
        },
        typeWaitlist: (amount: number) => `Вейтлист (${amount})`,
        customFilters: 'форма',
        minKarma: (amount: number) => `карма: ${amount}`,
        joined: 'Присоединён',
        actionView: 'Посмотреть',
        actionJoin: 'Присоединиться',
        actionLeave: 'Выйти',
        actionApproveInvite: 'Принять',
        actionDeclineInvite: 'Отклонить',
      },
      controls: {
        actionRatePlayers: 'Оценить игроков',
        actionLeave: 'Покинуть группу',
        actionSaveChanges: 'Сохранить',
        actionEditGroup: 'Изменить',
        actionDelete: 'Удалить группу',
        actionStart: 'Начать',
        actionFinish: 'Закончить',
      },
      removeGroupModal: {
        text: 'Решено, удаляем группу?',
      },
      ratePlayersModal: {
        title: 'Оцени игроков группы',
        toastSuccess: 'Оценки отправлены',
        actionSkip: 'Пропустить',
        actionSubmit: 'Оценить и завершить',
      },
      customFilters: {
        title: 'Вопросы',
        actionAddQuestion: 'Добавить вопрос',
        checkboxIsRequired: 'Обязательные вопросы',
        questionPlaceholder: 'Твой вопрос...',
        optionPlaceholder: (amount: number) => `Вариант ${amount}`,
        actionAddOption: 'Добавить вариант',
        fillInputs: 'Заполни все поля',
        questionType: {
          input: 'Текстовое поле',
          selectMany: 'Выбор нескольких ответов',
          select: 'Выбор одного ответа',
          text: 'Длинный ответ',
        },
      },
      waitlist: {
        title: 'Запросы',
        count: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'запросов'
              : amount > 1
              ? 'запроса'
              : 'запрос'
          }`,
        invites: 'Приглашения',
        usersInTheSlots: 'Все слоты распределены между игроками',
        actions: {
          viewAll: 'Смотреть все',
          shuffle: 'Перемешать',
          accept: 'Принять',
          decline: 'Отклонить',
          viewForm: 'Ответы',
          deleteInvite: 'Отмена',
          pickRandomly: 'Выбрать случайных',
        },
      },
      editing: {
        title: 'Редактирование группы',
        groupInfo: 'Информация о группе',
        fields: {
          name: 'Название',
        },
        actions: {
          delete: 'Удалить группу',
          save: 'Сохранить',
          cancel: 'Отменить',
        },
      },
      actionDiscordInvite: 'Инвайт на сервер',
      actionCardLink: 'Ссылка на карточку',
      actionChannelLink: 'Ссылка на канал',
      actionGoBack: 'Вернуться к списку',
      actionEditGroup: 'Редактировать',
      errors: {
        400: {
          text: 'Не удалось открыть группу',
        },
        404: {
          text: 'Такой группы нет :(',
        },
        errorNoGame: 'Выберите игру',
        errorNoTitle: 'Назови группу',
        errorJoinNoChar: 'Добавь персонажа, чтобы присоединиться к событию',
        errorCreateNoChar: 'Добавь или выбери персонажа, чтобы создать событие',
        wow: {
          errHighIlvl: 'ilvl превышает допустимое значение - 300',
        },
      },
      status: {
        gameStarted: 'Игра началась',
        gameEnded: 'Игра закончилась',
      },
      params: {
        header: 'Параметры группы',
        titlePlaceholder: 'Название',
        description: 'Описание',
        waitlistCheckbox: 'Вейтлист',
        scheduledDateTime: 'Дата и время',
        minKarma: 'Мин. карма',
        actionAddCustomFilters: 'Добавить фильтры',
        game: 'Игра',
        gameMode: 'Режим',
        region: 'Регион',
        language: 'Язык',
        anyValue: 'Любой',
        wow: {
          mythicPlusRating: 'Мин. M+ рейтинг',
          dungeon: 'Подземелье',
          ilvl: 'ilvl',
          keyLevel: 'Ключ',
          faction: 'Фракция',
          arenaRating: 'Рейтинг арены',
          tbcHeroic: 'Героический',
        },
        lostArk: {
          guardianRaidsGuardian: 'Хранитель',
          abyssDungeonsAbyss: 'Бездна',
          bossRush: 'Boss Rush',
          platinumFieldsField: 'Platinum Field',
          otherActivity: 'Другие активности',
          difficulty: 'Сложность',
          dungeon: 'Подземелье',
          arena: 'Арена',
          arenaServer: 'Сервер',
          arenaMode: 'Режим арены',
        },
        hearthstone: {
          minBgRating: 'Мин. рейтинг',
        },
        csgo: {
          rank: 'Ранг',
        },
        lol: {
          password: 'Пароль',
        },
      },
    },
    home: {
      pasha: {
        tabs: {
          groups: 'Группы',
          users: 'Пользователи',
        },
        search: {
          placeholder: 'Поиск игроков',
        },
      },
      meta: {
        routeTitle: 'Все группы',
        activities: 'активности',
        title: 'LFG — поиск игроков в твою команду | LF Group',
        titleTemplate: (
          value: string,
          value2: string,
          value3: string
        ): string =>
          `${value} ${value2}LFG - ${value3} Поиск Команды | LF.Group`,
        description:
          'LF.Group — самый классный способ найти новых напарников и создавать группы в любых играх. А еще у нас есть свой ЛФГ бот!',
        descriptionTemplate: (value: string, value2: string): string =>
          `Найди новых напарников и планируй ${value} вместе в ${value2}. Ищи игроков, которые соответствуют твоим игровым целям, прогрессу персонажа и доступному времени для игры. Сайт + Discord бот. Попробуй!`,
      },
      page404: {
        title: 'Тут ничего нет. Честно!',
        actionGoToMain: 'На главную!',
        gif: 'https://media1.giphy.com/media/QTDNiVcupJGy2Ur0pY/giphy.gif?cid=ecf05e471k8d08a7b422q197nziehje0mgn4diva3anul6ue&rid=giphy.gif',
      },
      navigation: {
        events: 'Cобытия',
        players: 'Игроки',
        vacancies: 'Вакансии',
        teams: 'Команды',
        suggestions: 'Рекомендации для тебя',
        howToCreateGroup: 'Как создать событие?',
      },
      errors: {
        errorNoGroups: 'Нет событий с такими параметрами',
        subtext:
          'Попробуй другой поиск или выбери игру, чтобы найти все активные группы',
      },
      filters: {
        more: 'Больше',
        less: 'Меньше',
        when: {
          title: 'Когда',
          any: 'Любое время',
          today: 'Ближайшие',
          thisWeek: 'Эта неделя',
          thisMonth: 'Этот месяц',
        },
        language: {
          title: 'Язык',
          placeholder: 'Любой язык',
          english: 'English',
          russian: 'Русский',
          turkish: 'Турецкий',
          swedish: 'Шведский',
          polish: 'Польский',
          german: 'Немецкий',
          french: 'Французский',
          spanish: 'Испанский',
          italian: 'Итальянский',
          dutch: 'Нидерландский',
          portuguese: 'Португальский',
        },
        actionReset: 'Сбросить фильтры',
        selectGame: {
          title: 'Игра',
          placeholder: 'Любая игра',
        },
        selectTag: {
          title: 'Тег',
          placeholder: 'Любой тег',
        },
        selectGameMode: {
          title: 'Режим',
          placeholder: 'Любой режим',
        },
        region: {
          title: 'Регион',
          placeholder: 'Любой регион',
        },
        server: {
          title: 'Сервер',
          placeholder: 'Любой сервер',
        },
        role: {
          title: 'Роль',
          placeholder: 'Любая роль',
        },
        hearthstone: {
          ratingBg: {
            title: 'Рейтинг',
            placeholder: 'Любой рейтинг',
          },
        },
        wow: {
          dungeon: {
            title: 'Подземелье',
            placeholder: 'Любое подземелье',
          },
          faction: {
            title: 'Фракция',
            placeholder: 'Любая фракция',
          },
          ilvl: {
            title: 'ilvl',
            placeholder: 'Любой ilvl',
          },
          rioScore: {
            title: 'RIO',
            placeholder: 'Любой RIO',
          },
          arenaRating: {
            title: 'Рейтинг арены',
            placeholder: 'Любой рейтинг',
          },
          mythicPlusRating: {
            title: 'M+ Рейтинг',
            placeholder: 'Любой рейтинг',
          },
          keyLevel: {
            title: 'Уровень ключа',
            placeholder: 'Любой уровень',
          },
          character: {
            title: 'Персонаж',
            placeholder: 'Без персонажа',
          },
          tbcHeroicCheckbox: 'Героический',
        },
        csgo: {
          rank: {
            title: 'Ранг',
            placeholder: 'Любой ранг',
          },
          faceitLvl: {
            title: 'FACEIT Lvl',
            placeholder: 'Любой lvl',
          },
        },
      },
    },
    homeFeed: {
      title: 'Твой личный игровой календарь',
      followingsTitle: 'Твои подписки',
      recommendationsTitle: 'Рекомендуем',
      meta: {
        title: 'Главная | LF.Group',
        og: {
          title: 'Лаунчер для мобильных игр',
          description:
            'LF.Group разработано для того, чтобы помочь тебе быстро найти, чем заняться и с кем поиграть в твои любимые игры.',
        },
      },
      tabs: {
        feed: 'Лента',
      },
      emptyState: {
        title: 'А тут будут игроки, с которыми можно поиграть',
        description:
          'Присоедени свои игровые профили, чтобы мы могли помочь тебе найти новых напарников',
        action: 'Подключить игры',
      },
      errors: {
        noGames: 'Добавь интересущие тебя игры и принимай участие в событиях',
        nothingHappening:
          'У нас нет событий подходящих под твой профиль, но ты можешь найти с кем поиграть!',
        nothingHappeningBut: 'Посмотри на список игроков -',
        nothingHappeningButWithoutGame:
          'Посмотри на список игроков по интересующей тебя игре',
        nothingHappeningBut2: 'или создай событие',
      },
      actions: {
        addGameProfile: 'Добавить игровой профиль',
        createEvent: 'Создать событие',
      },
    },
    landings: {
      lfg: {
        title:
          'LF.Group поможет найти новых напарников и запланировать совместную сессию в твоей любимой игре',
        firstSection: {
          firstDescription:
            'Ищи людей с общими целями, уровнем игры и доступным временем',
          secondDescription:
            'Собери группу в нужный режим, а LF.Group создаст для вас приватный чат в Disc',
          activePlayers: 'Активных игроков',
          groupsEveryDay: 'Групп ежедневно',
          findMates: 'Найти друзей',
          browseAllGroups: 'Просмотреть все LFG игры',
        },
      },
      wow: {
        title:
          'LF.Group поможет найти новых напарников и запланировать совместную игру в WoW',
        tryIt: 'Попробовать',
        advantages: {
          battlenetData:
            'Подключи API Battle.net и LF.Group получит все данные о твоих персонажах',
          searchForPlayers:
            'Ищи людей с общими целями, уровнем игры и доступным временем',
          shedule:
            'Собери группу в нужный режим, а LF.Group создаст для вас приватный чат в Disc',
          streamers:
            'Известные стримеры играют со зрителями через LF.Group. Шанс есть у каждого!',
        },
      },
      lostArk: {
        tryIt: 'Try it out',
        title:
          'LF.Group will help you find new teammates and plan aсtivities together in Lost Ark',
        advantages: {
          partnership:
            'Easily update your profile to apply to groups/guilds or share your it with others in Web & Disc',
          connect:
            'Set up your profile once and use it both on LF.Group website and all partnered Disc servers',
          searchForPlayers:
            'Search for players who match your in-game goals, skill level and available time to play',
          shedule:
            'Schedule groups for specific game modes, each comes with a private chat',
          recruting:
            'Recruiting toolkit for everyone! Players will be able to search for a suitable static or guild',
        },
        firstSection: {
          firstDescription:
            'Search for players who match your in-game goals, skill level and available time to play',
          secondDescription:
            'Schedule groups for specific game modes, each comes with a private chat',
          activePlayers: 'Active players',
          groupsEveryDay: 'Groups every day',
          findMates: 'Find mates',
          browseAllGroups: 'Browse all LFG games',
        },
        mainPart: {
          title: 'It is more than a website',
          description:
            'LF.Group also features an LFG Disc bot that serves as a multi-functional extension to the website. Everyone is welcome to add the bot to their server.',
          steps: {
            profiles: {
              title: 'Profiles feed',
              description:
                'Each actively looking for groups profile can be posted on connected to the bot servers. Any player can post his card by using /lfg command',
            },
            groups: {
              title: 'Groups feed',
              description:
                'You can create a group on your Disc server through the bot /create command.s',
            },
            setup: {
              title: 'Easy setup',
              description:
                'Just add the bot to your server, do not remove any permissions (!) and follow the instructions. Add LFG bot to your server',
              link: 'Add LFG bot to your server',
            },
          },
          footer: {
            title: 'All our toolkit is free to use!',
            description:
              'Sign in to LF.Group to unlock its full potential. Stay in touch with the team: we are constantly adding new features and support more games.',
            startExploring: 'Explore LF.Group',
          },
        },
      },
      mainPart: {
        title: 'Больше чем сайт',
        description:
          'LF.Group - то еще и LFG-бот в Disc, который расширяет возможности сайта. Поставить бота на сервер может каждый.',
        steps: {
          profiles: {
            title: 'Лента профилей',
            description:
              'Ищешь группу? Бот разместит твой профиль на всех связанных серверах. Любой игрок может разместить своего персонажа с помощью команды /lfg',
          },
          groups: {
            title: 'Лента групп',
            description:
              'Команда /create создает группу и размещает ее на всех подключенных к боту Disc-серверах',
          },
          setup: {
            title: 'Легкая настройка',
            description:
              'Просто добавь бота на сервер, не снимай (!) никакие галочки и следуй инструкции. Добавить LFG-бота на сервер',
            link: 'Добавь LFG бот на свой сервер',
          },
        },
        footer: {
          title: 'Весь наш проект абсолютно бесплатный!',
          description:
            'Авторизуйся на LF.Group, чтобы раскрыть весь потенциал. Будь на связи с нами: мы регулярно добавляем новые фичи и поддерживаем все больше игр.',
          startExploring: 'Исследовать LF.Group',
        },
      },
      csgo: {
        actions: {
          continue: 'Продолжить',
          setUpProfile: 'Настрой свой CS:GO профиль',
          joinCommunity: 'Присоединяйся к сообществу Skin.Club',
        },
        title:
          'Найди новых CS:GO тиммейтов \n& присоединяйся к сообществу Skin.Club \nв LF.Group!',
        aboutTitle: 'Что такое LF.Group?',
        aboutDescription1:
          'LF.Group – это игровой календарь, который помогает игрокам CS:GO найти с кем поиграть и чем заняться в игре.',
        aboutDescription2:
          'Проект был создан одним из соучредителей Skin.Club.',
        aboutDescription3:
          'Он быстро растет, и план развития полностью основан на сообществе.',
        giveawayTitle:
          'Присоединившись к Skin.Club сообществу \nна странице LF.Group ты автоматически \nпринимаешь участие в Giveaway.',
        giveawayDescription:
          'Победители будут выбраны случайным образом 31 марта в прямом эфире. \nРезультаты будут опубликованы во всех социальных сетях Skin.Club, включая \nстраницу на LF.Group!',
        conclusionTitle:
          'Отслеживай все важные \nSkin.Club события и обновления',
        conclusionDescription:
          'После регистрации через Discord и после подписки на страницу Skin.Club в LF.Group ты автоматически будешь получать уведомления в Discord DM обо всех важных событиях в календаре. \n\nНе пропускай бесплатные раздачи предметов, новые промокодов, различные моменты и другие крутые вещи!',
        instructions: {
          first: 'Заполни свой CS:GO профиль',
          second: 'Подпишись на Skin.Club в LF.Group',
          third: 'Выиграй один из 50 призов в нашем розыгрыше!',
        },
        form: {
          title: 'Настрой свой \nCS:GO профиль в LF.Group',
          actions: {
            first: 'Выбери свои теги',
            second: 'Выбери свой регион',
            third: 'Расскажи нам о своём рейтинге MM',
          },
        },
        header: {
          whatIs: 'Что такое LF.Group',
          setUpProfile: 'Настрой свой LFG профиль',
          giveaway: 'Giveaway',
          calendar: 'Skin.Club календарь',
        },
        footer: {
          communityMatters: 'Ваше сообщество имеет значение.',
          termsAndPrivacy: 'Условия и конфиденциальность',
          place: '© 2020 - 2022 LF.Group, London UK.',
        },
      },
      pubg: {
        title:
          'Партнерская программа \nPUBG Mobile \nРасти свой канал и получай \nбонусы вместе с LF.Group',
        beginNow: 'Начни уже сейчас',
        description: {
          first: ' игроков PUBG Mobile уже зарегистрированы на LF.Group',
          second: ' кастомок проводят у нас блогеры каждый месяц',
          third: ' игроков ежедневно принимают участие в кастомках на сайте',
        },
        streamers: {
          title: 'кому мы уже помогли вырасти',
          withoutUs: 'Без нас',
        },
        partners: {
          cards: {
            title: 'что получают все партнеры',
            first: {
              title: 'Гарантированное пересечение аудитории',
              description:
                'Более 30 блогеров регулярно играют со зрителями на LF.Group. \nИх аудитория обратит внимание и на тебя!',
            },
            second: {
              title: 'Бесплатные наборы UC для проведения кастомок',
              description:
                'Мы готовы выдавать неограниченное кол-во UC. Все зависит только от тебя.',
            },
            third: {
              title: 'Гарантированный рост канала',
              description:
                'LF.Group хорошо индексируется в Google. Мы даем трафик, который иными способами получить невозможно.',
            },
            fourth: {
              title: 'Еженедельное вознаграждение',
              description:
                'Дорастешь до крупного партнера - сможешь зарабатывать деньги за проведение кастомок ;)',
            },
          },
          levels: {
            title: 'Уровни партнеров',
            description:
              'Здесь указаны лишь примерные ориентиры. Конкретные условия обсуждаются лично. \nСамым активным партнерам мы поможем всем, чем сможем!',
            level: (value: string): string => `Уровень ${value}`,
            junior: {
              title: 'Новичок',
              requirement:
                'Требуется от 30 присоединившихся \nучастников в каждый ивент',
              conditions:
                'Брендированные кастомки на сайте/Ежемесячный конкурс для подписчиков канала/Гарантированный рост канала/Периодические размещения анонсов ваших трансляций в соц. сетях',
            },
            middle: {
              title: 'Бывалый',
              requirement: 'Требуется 1000 просмотров \nв среднем',
              conditions:
                'Все условия уроня Новичок/Брендированные кастомки на сайте и верифицированный аккаунт/Оплата призовых для ваших кастомок/Ежедневные анонсы трансляций в наших соц.сетях',
            },
            senior: {
              title: 'Батя',
              requirement:
                'Требуется от 210 присоединившихся \nучастников в каждый ивент',
              conditions:
                'Все условия уровня Новичок/Все условия уровня Бывалый/Еженедельные денежные вознаграждения/Дополнительные интеграции',
            },
          },
          cooperations: {
            title: 'с нами сотрудничают',
            followers: (value: string): string => `${value} тыс. подписчиков`,
            subscribers: (value: string): string => `${value} подписчиков`,
          },
        },
        howToBecome: {
          title: 'ДЛЯ НАЧАЛА \nРАБОТЫ НУЖНЫ',
          downloadDocs: 'скачать материалы',
          participate: 'Принять участие',
          howItWorks: 'How it works',
          first: 'Баннер LF.Group на вашей трансляции',
          second: 'Настроенный чат-бот и ссылка в описании',
          third: 'Среднее количество зрителей в прямом эфире от 5',
          fourth: 'Или от 100 просмотров роликов на Ютубе',
          fifth:
            'Искреннее желание помочь комьюнити PUBG Mobile — дать \nим сервис по поиску событий в игре',
        },
        footer: {
          communityMatters: 'Your community matters.',
          termsAndPrivacy: 'Terms & privacy',
          place: '© 2020 - 2023 LF.Group, London UK.',
          ourGames: {
            title: 'Our Games',
          },
          product: {
            title: 'Product',
            findTeam: 'Find a team',
            findPlayers: 'Find a player',
            findGame: 'Find a game',
          },
          company: {
            title: 'Company',
            about: 'About',
            contactUs: 'Contact us',
            mediaKit: 'Media Kit',
          },
        },
      },
    },
    modalAddGame: {
      title: 'Добавить/изменить игру',
      description:
        'Выбери свои любимые игры, чтобы добавить их в свой профиль. Это поможет людям найти тебя и пригласить в группу.',
      tagsLabel: 'Выбери свои предпочтения',
      wow: {
        descriptionNotConnected:
          'Присоедини Battle.net аккаунт для загрузки персонажей в профиль. Это упростит поиск и присоединение к группе.',
        descriptionConnected:
          'Это все твои персонажи, которых мы ежедневно обновляем. Если ты не видишь какого-либо твоего персонажа максимального уровня, нажми кнопку ниже',
        connectBattlenetAction: 'Подключить Battle.net',
        updateBattlenetAction: 'Обновить список персонажей',
        examples: 'Примеры',
        deletedGames: 'Скрытые',
        yourCharacters: 'Твои персонажи',
      },
      wildRift: {
        descriptionNotConnected:
          'Добавь Riot ID, Tagline и Region для получения игровой статистики. Это упростит поиск и присоединение к группе.',
        descriptionConnected:
          'Мы ежедневно обновляем статистику твоего аккаунта. Если ты видишь ошибку, то нажми кнопку ниже.',
        actionConnectRiot: 'Подключить',
        actionDisconnectRiot: 'Отключить Riot',
        actionForceUpdate: 'Обновить информацию',
        successToast: 'Riot успешно добавлен!\nИщем твой профиль Wild Rift',
        successUpdateToast: 'Информация обновлена',
      },
      lostArk: {
        yourCharacters: 'Твои персонажи',
        examples: 'Примеры',
        addDescription: 'Добавь комментарий..',
        characters: 'Персонажи',
        characterDescription:
          'Ты можешь добавить до 6 персонажей. Ты сможешь изменить их в любой момент',
        addCharacter: 'Добавить персонажа',
      },
    },
    modalAppLinks: {
      title:
        'Скачай приложение, участвуй в событиях, получай уведомления и выигрывай!',
      titleOnJoin: 'Скачай приложение, чтобы присоединиться в это событие!',
    },
    modalAuthOnboarding: {
      loginScenario: {
        mobile: {
          title: 'Your personal gaming calendar',
          description: 'Sign in to get personal event feed',
        },
        desc: 'Войди и настрой свой игровой профиль на LF.Group.',
        subDesc:
          'Ты сможешь присоединиться к событиям, принять участие в розыгрышах или создать свое собственное сообщество!',
        actionOtherOptions: 'Другие способы',
        footer: {
          cards: {
            bestTeammates: 'Найди новых\nнапарников',
            favGames: 'Все твои\nлюбимые игры',
            community: 'Дружелюбное\nсообщество',
            friends: 'Заводи друзей\nЛайкай, подписывайся',
            control: 'Управляй своими\nпредпочтениями',
          },
        },
        slideBeforeBattlenet: {
          title: 'Прежде, чем ты войдешь \nчерез Battle.net',
          info: 'После нажатия кнопки ниже проверь, что у тебя отмечены все галочки. LF.Group использует эти права, чтобы найти твоих WoW персонажей',
          check1:
            'Ваш BattleTag и название записи Battle.net (Необходимо указать)',
          check2: 'Ваш профиль World of Warcraft',
          reconnect: 'Переподключить Battle.net',
        },
      },
    },
    modalEvent: {
      titleCreate: 'Новое событие',
      titleEdit: 'Изменить событие',
      actionCreate: 'Создать →',
      actionEdit: 'Изменить →',
      actionDelete: 'Удалить',
      actionClone: 'Повторить прошлое событие',
      description: {
        riot: 'Введите свой Riot ID, чтобы получить личную карточку статистики, которая поможет вам найти нужную группу. Мы ежедневно обновляем статистику вашего аккаунта',
      },
      image: {
        size: '16x9, Макс. 5 МБ',
        action: 'Добавить обложку',
        replaceAction: 'Нажмите, чтобы заменить',
        error: 'Не удалось загрузить ваше изображение',
      },
      preview: {
        titlePlaceholder: 'Привет! Мы LF.Group!',
        descriptionPlaceholder:
          'Добавьте мероприятие в свою любимую игру!\nLF.Group поможет вам быстро найти игроков, сообщества и интересные мероприятия.',
      },
      gameOptions: {
        title: 'Варианты событий',
      },
      time: {
        fromDate: 'Дата начала',
        fromTime: 'C',
        toDate: 'Дата окончания',
        toTime: 'До',
        withEndDateCheck: 'До',
      },
    },
    modalLaunchingGame: {
      title: 'ID сервера скопирован ,\n запускаем игру...',
      subtitle: 'Если игра не откроется автоматически, нажми на кнопку ниже.',
      notProvided: {
        title: 'Хост еще не указал \nID сервера и пароль',
        subtitle: 'Пожалуйста, подождите, пока хост предоставит информацию',
      },
      standoff2: {
        title: 'Запускаем Standoff 2...',
        subtitle: 'Если игра не откроется автоматически, нажми на кнопку ниже.',
        desktop: {
          scanQr: 'Отсканируй QR код, чтобы присоединиться к серверу.',
        },
        initeUrl: {
          label: 'Ссылка',
        },
        notProvided: {
          title: 'Хост еще не указал ссылку для приглашения',
          subtitle: 'Пожалуйста, подождите, пока хост предоставит информацию',
        },
      },
      server: {
        label: 'Сервер',
      },
      password: {
        label: 'Пароль',
      },
      button: 'Запустить',
      cancel: 'Отмена',
    },
    modalNewGroup: {
      title: 'Новая группа',
      slideGame: {
        title: 'Выбери игру',
      },
      slideInfo: {
        organization: 'организацию',
        group: 'группу',
        noWow: {
          desc: (value: string): string =>
            `Подключи Battle.net, чтобы создать ${value}`,
          actionAdd: 'Подключить Battle.net',
        },
        noWildRift: {
          desc: (value: string): string =>
            `Подключи свой профиль Wild Rift, чтобы создать ${value}`,
          actionAdd: 'Подключить',
        },
        noOther: {
          desc: (value: string, value2: string): string =>
            `Добавь ${value} в свой профиль, чтобы создать ${value2}`,
          actionAdd: 'Добавить',
        },
        forCommunity: 'Группа сообщества',
        sectionTitle: 'Информация',
        inputGameLabel: 'Игра',
        inputTitleLabel: 'Название группы',
        inputDateLabel: 'Дата',
        inputTimeLabel: 'Время',
        inputKarmaLabel: 'Карма',
        section2Title: 'Параметры',
        action: 'Далее',
        gameMode: {
          any: 'Любой режим',
        },
        inputWhoCanJoin: {
          label: 'Кто сможет зайти',
          optionEveryone: 'Все',
          optionWaitlist: 'По вейтлисту',
        },
        formGuild: {
          label: 'Мои комьюнити',
        },
        customQuestions: {
          label: 'Вопросы',
          addQuestions: 'Добавить вопрос',
          placeholder: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'вопросов'
                : amount > 1
                ? 'вопроса'
                : 'вопрос'
            }`,
        },
      },
      language: {
        title: 'Язык',
        english: 'Английский',
        russian: 'Русский',
      },
      slideSlots: {
        title: 'Слоты',
      },
      actionCreate: 'Создать',
      actionGoBackToInfo: 'Назад к инфо',
      actionGoBackToGames: 'Назад к играм',
    },
    myGroups: {
      errors: {
        noGroups: 'Событий пока нет',
      },
      meta: {
        title: 'Мои события | LF Group',
        routeTitle: 'Мои события',
      },
      filter: {
        type: 'Тип',
        all: 'Все',
        member: 'Участник',
        owner: 'Создатель',
        waitlist: 'В вейтлисте',
      },
      emptyBlock: {
        title: 'У тебя сейчас нет активных событий',
        exploreGroups: 'Исследовать все группы',
        browseMyHistory: 'Просмотреть мою историю',
      },
    },
    newGroup: {
      meta: {
        title: 'Создание группы | LF Group',
      },
      filter: {
        selectChar: 'Выбери персонажа',
        date: 'Дата',
        time: 'Время',
      },
      errors: {
        errorJoinNoChar: 'Добавь персонажа, чтобы создать событие по этой игре',
      },
      title: 'Создание группы',
      actionButton: 'Создать событие',
    },
    onboarding: {
      welcome: {
        title: (value: string): string => `Добро пожаловать в ${value}`,
        description: {
          home: 'Приветствуем на LF.Group!\n Мы — бесплатный инструмент для игроков, команд и сообществ. Наша цель — помочь каждому найти подходящих напарников для совместной игры. \nЗаходи через Discord и знакомься с сервисом!',
          inviteDiscrord:
            'Добро пожаловать в ЛФГ! Мы предоставляем вам безопасный и мощный инструмент для игроков, команд и сообществ. \nПолучите мгновенный доступ к созданию различных мероприятий на вашем дискорд-сервере.',
          players:
            'Список игроков, которые зарегистрированы на LF.Group. \nЗайди через Discord, чтобы подписаться на человека или пригласить его в группу.',
          game: {
            wow: 'Устал от игрового поиска группы в WoW? LF.Group — это совсем другое дело! \nЗайди через Discord и BattleNet и создай группу, которая подходит тебе по роли и навыкам. \nВместе с приватным Discord-чатом!',
            wowTbc:
              'Играть в WoW классик без поиска группы тяжело. LF.Group решит все проблемы! \nЗайди через Discord и создай группу, которая подходит тебе по роли и навыкам. \nВместе с приватным Discord-чатом!',
            csgo: 'Поиск напарников в CSGO от LF.Group — простой способ найти новых игроков или команду. \nЗайди через Discord и создай группу, подходящую под твои цели. \nВместе с приватным Discord-чатом!',
            hearthstone:
              'Самый удобный поиск игроков  Hearthstone на LF.Group. \nЗайди через Discord и создай группу, подходящую под твои цели. \nВместе с приватным Discord-чатом!',
            warzone:
              'Поиск группы в Call of Duty Warzone — простой способ найти новых игроков или команду. \nЗайди через Discord и создай группу, подходящую под твои цели. \nВместе с приватным Discord-чатом!',
            lostArk:
              'Самый удобный поиск игроков Lost Ark на LF.Group. \nЗайди через Discord и создай группу, подходящую под твои цели. \nВместе с приватным Discord-чатом!',
            wildRift:
              'Самый удобный поиск игроков Wild Rift на LF.Group. \nЗайди через Discord и создай группу, подходящую под твои цели. \nВместе с приватным Discord-чатом!',
          },
        },
      },
      community: 'Комьюнити',
      actions: {
        accept: 'Принять',
        reject: 'Отклонить',
        addToServer: 'Добавить в сервер',
      },
      inviteYou: 'Приглашает тебя поиграть',
      noInvites: 'Всё просмотрено',
    },
    onboardingGames: {
      title: 'В какие игры ты играешь?',
      slidePickGame: {
        noGames: 'Ты уже добавил все игры, которые мы сейчас поддерживаем',
      },
      errors: {
        noLostArkCharacters: 'Добавь хотя бы одного Lost Ark персонажа',
        lostArkInvalidInput: 'Заполни обязательные поля',
        gameInvalidInput: 'Добавь описание или выбери теги',
      },
      slideGameAdded: {
        title: 'Твой профиль обновлен',
        description:
          'Теперь все смогут найти твоих новых персонажей. \nТы можешь скинуть свое превью из профиля',
        actionAddMore: 'Добавить другую игру',
        actionBrowse: 'Смотреть группы',
      },
    },
    organization: {
      meta: {
        title: 'Сообщества - LF.Group',
        routeTitle: 'Сообщества',
        description: 'Сообщества на LF.Group',
      },
      organizationMeta: {
        title: (value: string, value2: string): string =>
          `${value} ${value2} ищет игроков | LF.Group`,
        gameTitle: (value: string, value2: string, value3: string): string =>
          `${value} ${value2} в ${value3} ищет игроков | LF.Group`,
        description: (value: string, value2: string): string =>
          `${value} ${value2} сайт`,
        gameDescription: (
          value: string,
          value2: string,
          value3: string
        ): string => `${value} ${value2} сайт в ${value3}`,
      },
      errors: {
        noGuilds: 'Сообщества не найдены',
        noGuild: 'Сообщество не найдено',
        noGuildMembers: 'Участники не найдены',
        memberNotDefined: 'Участник не определен',
        guildNotDefined: 'Сообщество не определено',
        required: 'Это поле обязательно к заполнению',
        nameRequired: 'Имя требуется',
        aliasRequired: 'Псевдоним требуется',
        teamRequired: 'Чтобы добавить мероприятие, вам нужно создать команду',
        longDescription: 'Описание слишком длинное',
        longName: 'Имя слишком длинное',
        longAlias: 'Псевдоним слишком длинный',
        longWebsite: 'URL ссылки слишком длинный',
        longCustomButtonLabel: 'Слишком длинное',
        longWebsiteTitle: 'Название ссылки слишком длинное',
        nameAndAliasRequired: 'Имя и псевдоним требуются',
        createGuildFailed: 'Сообщество не создано',
        createTeamFailed: 'Команда не создана',
        chooseGame: 'Выберите игру',
        createGuild: 'Создать ошибку сообщества',
        noMembersFound: 'Участники не найдены',
        alreadyMember: (value: string): string =>
          `${value} уже является Участником этого сообщества`,
        justNumbers: 'Должно быть числом',
      },
      alerts: {
        guildUpdated: 'Сообщество обновляется',
        guildCreated: 'Сообщество создано',
        guildDeleted: 'Сообщество удалено',
        guildMemberUpdated: 'Участник сообщества обновляется',
        guildMemberCreated: 'Участник сообщества создан',
        guildMemberDeleted: 'Участник сообщества удален',
        guildMemberRoleUpdated: 'Роль Участника сообщества обновлена',
        guildTeamUpdated: 'Общественная команда обновлена',
        guildTeamCreated: 'Общественная команда создана',
        guildTeamDeleted: 'Общественная команда удалила',
      },
      modals: {
        deleteGuild: 'Вы уверены, что хотите удалить это сообщество?',
        deleteGuildMember: 'Вы уверены, что хотите удалить этого участника?',
        leaveGuild: 'Вы уверены, что хотите покинуть это сообщество?',
        leaveTeam: 'Вы уверены, что хотите покинуть эту команду?',
        closeSettings:
          'Вы уверены, что хотите закрыть настройки этого сообщества? Изменения \nбудут потеряны.',
        closeCreating:
          'Вы уверены, что хотите оставить? Изменения \nбудут потеряны.',
      },
      page: {
        gameInfo: 'Информация об игре',
        addGameParams:
          'Добавить параметры игры, чтобы предварительно просмотреть карту команды',
        memberCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'участников'
              : amount > 1
              ? 'участника'
              : 'участник'
          }`,
        joinedMemberCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'участников'
              : amount > 1
              ? 'участника'
              : 'участник'
          }`,
        views: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'просмотров'
              : amount > 1
              ? 'просмотра'
              : 'просмотр'
          }`,
        commonViews: (value: string): string => `${value} просмотров`,
        vacancyCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'вакансий'
              : amount > 1
              ? 'вакансии'
              : 'вакансия'
          }`,
        member: 'Участник',
        role: 'Роль',
        classes: 'Классы',
        champions: 'Чемпионы',
        roster: 'Команда',
        events: 'События',
        wiki: 'Ссылки',
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'подписчиков'
              : amount > 1
              ? 'подписчика'
              : 'подписчик'
          }`,
        addTeamInfo: 'Добавьте информацию вашей команды',
        toHelpPeople: 'Чтобы помочь людям найти вашу команду',
        teamCard: {
          descriptionPlug: {
            addDescription: 'Добавить описание команды',
            or: 'или',
            inviteMember: 'Пригласить участника команды',
          },
        },
      },
      fields: {
        type: 'Тип',
        team: 'Команда',
        progress: 'Прогресс',
        language: 'Язык',
        region: 'Регион',
        server: 'Сервер',
        activity: 'Мероприятия',
        mainActivity: 'Основная деятельность',
        weeklyContribution: 'Еженедельный вклад',
        contract: 'Договор',
        players: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'игроки'
              : amount > 1
              ? 'игроки'
              : 'игрок'
          }`,
      },
      action: {
        createGuild: 'Создать сообщество',
        userCopied: 'Пользовательская ссылка скопирована',
        createTeam: 'Создать команду',
        edit: 'Редактировать',
        deleteGuild: 'Удалить сообщество',
        upload: 'Загрузить',
        leaveTeam: 'Оставить команду',
        leaveGuild: 'Оставить сообщество',
        follow: 'Подписаться',
        followed: 'Ты подписан',
      },
      slidoverEdit: {
        title: 'Редактировать сообщество',
        teamTitle: 'Редактировать команду',
        paramsTabTitle: 'Параметры',
        imagesTabTitle: 'Брендинг',
        membersTabTitle: 'Управление',
        teamMembersTabTitle: 'Участники команды',
        gameSettingsTabTitle: 'Настройки игры',
        teamTabTitle: 'Команда',
        linksTabTitle: 'Ссылки',
      },
      slidoverNew: {
        createGuild: 'Создать сообщество',
        params: {
          title: 'Новое сообщество',
          social: 'Социальное',
        },
        members: {
          titles: 'Участники',
          adminRole: 'Администратор',
          playerRole: 'Игрок',
        },
        images: {
          title: 'Картинки',
          settings: 'Брендинг',
          banner: 'Баннер',
          avatar: 'Аватар',
          maxSize: 'Максимальный размер',
          maxResolution: 'Максимальное разрешение',
          ration: 'Соотношение',
          recommendedResolution: 'Рекомендуемое решение',
        },
        teams: {
          title: 'Информация об игре',
        },
        forms: {
          askQuestion:
            'Если у вас есть вопрос, не стесняйтесь спрашивать нас в наших',
          guildName: 'Название сообщества',
          guildAlias: 'Ссылка на сайт',
          aliasPreview: 'Ваша гильдия будет доступна в ',
          guildDescription: 'Описание',
          teamDescription: 'Описание списка',
          discord: 'URL',
          twitter: 'URL Twitter',
          twitch: 'Twitch URL',
          youtube: 'YouTube URL',
          facebook: 'URL Facebook',
          instagram: 'Instagram URL',
          website: 'URL',
          websiteTitle: 'Название кнопки',
          teamName: 'Название команды',
          moreThanSymbols: (value: string): string =>
            `Поиск, набрав не менее ${value} символов`,
          searchByTyping: 'Поиск путем набора текста',
          roles: 'Роли',
        },
      },
    },
    organizationData: {
      type: {
        guild: 'Гильдия',
        community: 'Сообщество',
        static: 'Статический',
        title: 'Тип',
        any: 'Любой',
      },
      role: {
        admin: 'Администратор',
        player: 'Игрок',
        default: 'По умолчанию',
        any: 'Любой',
      },
      shortLanguage: {
        english: 'En',
        russian: 'Ru',
        german: 'De',
        spanish: 'Es',
        french: 'Fr',
        portuguese: 'Pt',
        italian: 'It',
        dutch: 'Nl',
        polish: 'Pl',
        turkish: 'Tr',
        swedish: 'Se',
        any: 'Любой язык',
        title: 'Язык',
      },
      voicePreferences: {
        any: 'Любые голосовые предпочтения',
        title: 'Голосовые предпочтения',
        required: 'Необходимый',
        notRequired: 'Не требуется',
      },
      playstyle: {
        any: 'Любой стиль игры',
        title: 'Стиль игры',
      },
      contract: {
        any: 'Любой контракт',
        title: 'Договор',
      },
      weeklyContribution: {
        any: 'Любой еженедельный вклад',
        title: 'Еженедельный вклад',
      },
      activities: {
        title: 'Активности',
        anyTitle: 'Любая деятельность',
      },
      wow: {
        activity: {
          raids: 'Raids',
          mythicPlus: 'MythicPlus',
          rbg: 'RBG',
          arena2x2: 'Arena2x2',
          arena3x3: 'Arena3x3',
          casual: 'Casual',
        },
      },
      la: {
        activity: {
          raids: 'Raids',
          gvg: 'GVG',
          altGuild: 'Alt-Guild',
          gve: 'GVE',
          pve: 'PVE',
          other: 'Other',
        },
        weeklyContribution: {
          mandatory: 'Обязательно',
          optional: 'Опционально',
          no: 'Нет',
        },
      },
      lol: {
        activity: {
          normals: 'Normals',
          rankedFlex: 'Ranked Flex',
          aram: 'ARAM',
          clash: 'Clash',
          casualTournaments: 'Казуальные турниры',
          semiProTournaments: 'Полу-профессиональные турниры',
          proTournaments: 'Профессиональные турниры',
        },
      },
    },
    pagePlayers: {
      title: {
        anyGame: 'Все LFG',
        gamePlayers: (value: string): string => `${value} LFG`,
      },
      meta: {
        title: {
          anyGame: 'Все LFG | LF.Group',
          gamePlayers: (value: string): string => `${value} LFG | LF.Group`,
        },
        og: {
          title: {
            anyGame: 'Все LFG',
            gamePlayers: (value: string): string => `${value} LFG`,
          },
          description: '',
        },
      },
    },
    pageVacancy: {
      tabs: {
        home: 'Подробности',
        applications: 'Приложения',
      },
      controls: {
        confirmArchive: {
          title:
            'Вы уверены, что хотите архивировать эту вакансию? Это не было бы видно в корме, и игроки не смогут применить к нему.',
          yes: 'Архив',
          no: 'Отмена',
        },
        edit: 'Редактировать вакансию',
        archive: 'Архивировать вакансию',
      },
      aboutPosition: {
        title: 'О позиции',
      },
      pageDetails: {
        title: 'Вакансия',
        meta: {
          title: (value: string): string => `${value} | LF.Group`,
          og: {
            title: (value: string, value2: string): string =>
              `${value} ${value2} ищет игроков | LF.Group`,
            gameTitle: (
              value: string,
              value2: string,
              value3: string
            ): string =>
              `${value} ${value2} в ${value3} ищет игроков | LF.Group`,
          },
        },
        form: {
          title: 'Применить сейчас',
          description: {
            placeholder: 'Расскажи о себе',
          },
          apply: {
            title: 'Применить →',
          },
        },
        alreadyApplied: {
          title: 'Ваша заявка была подана!',
          description:
            'Наш бот Discord отправит вам сообщение, как только лидер сообщества рассмотрит вашу заявку. Обязательно оставьте участник сервера LF.Group и разрешили прямые сообщения от не подруг.',
        },
      },
      pageApplications: {
        title: 'Приложения',
        meta: {
          title: (value: string): string => `${value} приложения | LF.Group`,
          og: {
            title: (value: string): string => `${value} | LF.Group`,
          },
        },
        emptyState: {
          title: 'Пока нет заявок',
          description: 'Вы увидите все приложения здесь',
        },
      },
      createModal: {
        title: {
          create: 'Новая позиция',
          update: 'Редактировать позицию',
        },
        action: {
          submit: {
            create: 'Создать →',
            update: 'Обновление →',
          },
        },
        input: {
          title: {
            label: 'Заголовок',
          },
          description: {
            label: 'Описание',
          },
        },
        sectionRequirements: {
          title: 'Требования',
        },
      },
    },
    preview: {
      notFound: 'Не найдено',
      search: {
        error: 'Не удалось открыть игру',
      },
      profile: {
        error: 'Не удалось открыть профиль',
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'подписчиков'
              : amount > 1
              ? 'подписчика'
              : 'подписчик'
          }`,
      },
      organization: {
        members: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'участников'
              : amount > 1
              ? 'участника'
              : 'участник'
          }`,
      },
      group: {
        error: 'Не удалось открыть группу',
      },
      vacancy: {
        lookingForPlayers: 'В поиске игроков',
        activity: 'Активности',
        type: 'Тип',
        lookingFor: 'Ищет',
      },
      game: {
        error: 'Не удалось открыть игру',
        groups: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'групп'
              : amount > 1
              ? 'группы'
              : 'группа'
          }`,
        allPlayers: (amount: number) =>
          `Более ${amount} ${
            amount > 5 || amount === 0
              ? 'игроков'
              : amount > 1
              ? 'игрока'
              : 'игрок'
          }`,
        players: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'игроков'
              : amount > 1
              ? 'игрока'
              : 'игрок'
          }`,
        findTeammate: 'Найди сокомандников для',
        lfg: {
          title: 'All in one solution \nfor gaming communities',
          allPlayers: (amount: number) =>
            `Более ${amount} ${
              amount > 5 || amount === 0
                ? 'игроков'
                : amount > 1
                ? 'игрока'
                : 'игрок'
            } по всему миру`,
          players: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'игроков'
                : amount > 1
                ? 'игрока'
                : 'игрок'
            } по всему миру`,
          backedBy: '$1M при поддержке GrishinRobotics',
        },
        wildrift: {
          win: (value: string): string => `Победы ${value}`,
          kda: 'KDA',
          battles: (amount: number) => `${amount} Ранговые Бои`,
          gMin: 'З/Мин',
          games: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'Матчей'
                : amount > 1
                ? 'Матча'
                : 'Матч'
            }`,
        },
      },
    },
    pubgLadder: {
      pageTitle: 'PUBG Mobile таблица лидеров кастомок',
      title: 'Таблица лидеров | LF.Group',
      meta: {
        title: 'PUBG таблица лидеров | LF.Group',
        description:
          'Присоединяйтесь к нашей таблице лидеров кастомных комнат в PUBG Mobile, чтобы получить шанс выиграть долю из 5 000 UC в призах. Покажите свои навыки, побейте конкурентов и получите главный приз!',
        og: {
          title: 'PUBG Mobile таблица лидеров кастомок',
          description:
            'Присоединяйтесь к нашей таблице лидеров кастомных комнат в PUBG Mobile, чтобы получить шанс выиграть долю из 5 000 UC в призах. Покажите свои навыки, побейте конкурентов и получите главный приз!',
          image: 'https://preview.lf.group/search/pubg-mobile',
        },
      },
      table: {
        user: 'Игрок',
        prize: 'Приз',
        points: 'Очки',
      },
      info: {
        titleDesktop:
          'Присоединяйтесь к кастомкам верифицированных стримеров на LF.Group и участвуйте в таблице лидеров с призом в 5 000 UC очков.',
        descriptionDesktop:
          'Проверьте правила участия и приготовьтесь показать свои навыки!',
        rulesAction: 'Как это работает?!',
        previousWeekResults: 'Результаты прошлых недель',
      },
    },
    pubgRules: {
      title: 'Как работает таблица лидеров | LF.Group',
      ogTitle: 'PUBG Mobile таблица лидеров кастомок',
      ogDescription:
        'Присоединяйтесь к нашей таблице лидеров кастомных комнат в PUBG Mobile, чтобы получить шанс выиграть долю из 5 000 UC в призах. Покажите свои навыки, побейте конкурентов и получите главный приз!',
      ogImage: 'https://preview.lf.group/search/pubg-mobile',
      goToLeaderboard: 'Вернуться в таблицу лидеров',
      joinParagraph:
        'Присоединяйтесь к таблице лидеров кастомных комнат PUBG Mobile и соревнуйтесь за призы до 5 000 UC! Всё, что нужно сделать, это играть в кастомках, созданных верифицированными стримерами с LF.Group, чтобы заработать очки и подняться в таблице лидеров.',
      prizesParagraph: 'Призы распределяются следующим образом:',
      prize1st: '1 место: 975 UC',
      prize2nd: '2-5 места: 325 UC',
      prize6th: '6-10 места: 180 UC',
      prize11th: '11-20 места: 120 UC',
      prize21st: '21-30 места: 60 UC',
      eventsCalendar: 'календарю событий PUBG',
      paragraph4:
        'Обратите внимание, что вы можете получить 25 очков за победу в кастомке только один раз в день у одного стримера. Например: если вы выигрываете три раза подряд в рамках одного стрима, вы все равно будете вознаграждены только 25 очками. Однако, если вы сумеете победить в трех кастомках в один день, и каждая победа будет с разным стримером, то вы получите 75 очков.',
      paragraph5p1:
        'Если вы стремитесь к доминированию в лидерборде, улучшению своего ранга и заработку большего количества UC-очков, обращайтесь к нашему',
      paragraph5p2: 'и принимайте участие в различных доступных мероприятиях!',
      paragraph3:
        'Зарабатывать очки и подниматься в таблице лидеров легко, выполняя следующие действия:',
      action1:
        'Участие в верифицированных событиях: Присоединитесь к событию, созданному проверенным стримером (он имеет зелёную галочку-значок LF.Group), и получите 1 очко за участие. Мы используем API, чтобы проверить, что вы присоединились к пользовательской комнате в игре, и добавим вашему профилю одно очко.',
      action2:
        'Победа в пользовательской комнате: Выиграйте игру в кастомке, созданной проверенным стримером, и заработайте 25 очков. Каждый день мы получаем список победителей от проверенных стримеров и обновляем очки победивших игроков.',
      paragraph6:
        'Каждую вторую среду мы будем распределять призы, пополняя ваш аккаунт PUBG Mobile суммой UC, которую вы выиграли.',
      question1:
        'В: Я выиграл несколько кастомок, созданных проверенными стримерами, но не вижу за это полученных очков.',
      answer1:
        'О: Вам нужно написать стримеру личное сообщение и узнать, предоставил ли он ваш профиль нам в качестве победителя. Иногда может потребоваться несколько дней, чтобы получить обновленный счет, если стример не отправляет нам новые данные сразу после игр.',
      question2: 'В: Как я получу приз?',
      answer2:
        'О: У нас есть ваш профиль, в который должен быть включен ваш PUBG ID. Мы пополним ваш PUBG ID непосредственно UC-очками.',
      question3:
        'Q: Сколько пользовательских комнат я могу посетить в день, чтобы увеличить свои шансы?',
      answer3:
        'A: Вы можете участвовать в любом количестве пользовательских комнат в день, но обратите внимание, что мы проверяем, нажали ли вы кнопку "Играть", чтобы принять участие в реальной пользовательской комнате в игре. Просто присоединение к мероприятию не принесет вам 1 очко. Однако, если вы участвуете, скопировав идентификатор и пароль лобби и нажав кнопку "Играть", вам будет начислено 1 очко.',
      question4:
        'Q: Я стример и хочу, чтобы мои зрители участвовали в рейтинге лидеров. Как мне это сделать?',
      answer4:
        'A: Мы приветствуем новых стримеров и с радостью поможем им увеличить количество зрителей в прямом эфире. Стримеры из СНГ могут связаться с нами через дискорд менеджера региона - NeoDno#4747. Как только вы станете нашим проверенным партнером, вы сможете запускать официальные пользовательские комнаты LF.Group, награждать победителей UC-призами и позволять им участвовать в рейтинге лидеров пользовательских комнат.',
    },
    referal: {
      title: 'Пригласи друзей и получи награды!',
      friendsInvited: (amount: number) =>
        `${amount} ${
          amount > 5 || amount === 0
            ? 'друзей приглашено'
            : amount > 1
            ? 'друга приглашено'
            : 'друг приглашен'
        }`,
      shareWithFriends: 'Поделись',
      rules: 'Правила',
      inviteLinkCopied: 'Ссылка для приглашения скопирована',
      inviteNewMembers: 'Пригласи новых участников',
      copyLink: 'Скопируй ссылку',
      toIviteFriends: 'для друзей',
      gifts: {
        three: 'Сундук Шедевров и ключ',
        six: 'Случайный образ',
        ten: 'Случайный образ стоимостью 975+',
        twenty: 'Легендарный образ на выбор',
        fifty: '6500+ Riot очки подарком',
      },
      pubg: {
        title: 'Пригласи друзей и получи очки в Лидерборд!',
        gifts: {
          one: '5 oчков',
          three: '10 очков',
          five: '15 очков',
        },
      },
    },
    rightColumn: {
      banner: {
        title: 'Ищешь с кем поиграть?',
        description:
          'LF.Group поможет тебе найти подходящих людей для игры. Ты можешь присоединиться к любой группе или создать свою',
        action: 'Создать событие',
      },
      addCommentBanner: {
        lookingForTeam: 'Ищу команду',
        newFeature: 'Новая фича',
        title: 'Опиши себя',
        description:
          'Выразите свои личные предпочтения: действия, время игры и т.д., чтобы было проще общаться с другими!',
      },
      connectDiscrod: {
        title: 'Персональные уведомления',
        description:
          'Присоедини учетную запись в дискорд для получения обновлений о новых розыгрышах и событиях',
        authDescription:
          'Войди через дискорд для получения обновлений о новых розыгрышах и событиях',
        action: 'Добавить дискорд',
      },
      suggestions: {
        title: 'На кого подписаться',
        description: 'Подпишись на блогеров и принимай участие в событиях',
        onboardingDescription: (value: string): string =>
          `Подпишись минимум на ${value} блогеров и принимай участие в событиях`,
      },
    },
    teamsFeed: {
      title: (value: string): string => `${value} Команды`,
      meta: {
        title: (value: string): string => `${value} Команды`,
        og: {
          title: (value: string, value2: string): string =>
            `${value} Команды - Recruitment tool for Guilds in ${value2} | LF.Group`,
          description:
            'Create your own Guild page with full recruitment toolkit. Create vacancies and search for players who match to your in-game goals. Discord notifications. Try it out!',
        },
      },
      emptyState: {
        title: 'Таких команд нет',
        description: 'Попробуй другой запрос, чтобы найти текущие команды',
      },
    },
    user: {
      meta: {
        title: (value: string): string =>
          `${value} gaming profile. Looking for play with | LF.Group`,
        descriptionWithGames: (value: string, value2: string): string =>
          `${value} gaming profile. Looking for play with in ${value2}.`,
        description: (value: string): string =>
          `${value} gaming profile. Looking for play with.`,
      },
      emptyState: {
        noProfiles: {
          description: (value: string): string =>
            `У ${value} пока нет игровых профилей.`,
          descriptionPersonal: 'У тебя еще нет игровых профилей.',
          action: 'Добавить профиль +',
        },
        noEvents: {
          description: (value: string): string =>
            `У ${value} пока нет событий.`,
          descriptionPersonal: 'У тебя пока нет событий.',
          action: 'Найти события',
        },
      },
      gameCardMeta: {
        title: (value: string, value2: string): string =>
          `${value} ищет игроков в ${value2} | LF Group`,
        description: (value: string, value2: string): string =>
          `Перейди по ссылке, чтобы просмотреть профиль игрока ${value} в ${value2}.На LF.Group вы можете найти группы игроков, календарь, Discord и внутриигровые идентификаторы.`,
      },
      followingMeta: {
        title: (value: string): string =>
          `Игрок ${value}: Подписки на других игроков | LF Group`,
        routeTitle: (value: string): string => `Подписки ${value}`,
        description: (value: string): string =>
          `Посмотри, на кого подписан ${value}. Может, в этом списке твои будущие друзья?`,
      },
      followersMeta: {
        title: (value: string): string =>
          `Игрок ${value}: Подписчики | LF Group`,
        routeTitle: (value: string): string => `Подписчики ${value}`,
        description: (value: string, value2: string): string =>
          `Посмотри на игроков, которые подписались на ${value}. Жми на ссылку и узнаешь, стал ли ${value2} суперзвездой!`,
      },
      navigation: {
        calendar: 'Календарь',
        invites: 'Приглашения',
        visitors: 'Посетители',
        links: 'Wiki',
        games: 'Игры',
        about: 'Профиль',
        events: 'События',
        history: 'История',
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'подписчиков'
              : amount > 1
              ? 'подписчика'
              : 'подписчик'
          }`,
        followingWithoutCount: 'Подписки',
        following: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'подписок'
              : amount > 1
              ? 'подписки'
              : 'подписка'
          }`,
        userFollowing: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'пользователей'
              : amount > 1
              ? 'пользователя'
              : 'пользователь'
          }`,
        leaderboard: 'Таблица лидеров',
        settings: 'Настройки',
        logout: 'Выйти',
        addFirstGame: 'Добавить первую игру',
      },
      playing: 'Играет',
      social: 'Соцсети',
      logoutModal: {
        text: 'Вы точно хотите выйти?',
      },
      metaHistory: {
        title: (value: string): string =>
          `Игрок ${value}: Завершенные игры | LF Group`,
        description: (value: string): string =>
          `Здесь можно узнать всё о профиле ${value} на LF.Group. Подпишись на него, если он тебе приглянулся!`,
      },
      errors: {
        404: {
          text: 'Такого пользователя нет',
          action: 'На главную',
        },
        noFutureEvents: 'Пользователь пока не запланировал игр',
        noPastEvents: 'У этого пользователя нет законченных событий',
        errorNoFollowing: 'Этот пользователь ни на кого не подписан',
        errorNoFollows: 'На этого пользователя никто не подписан',
        errorNoVisitors: 'У вас нет посетителей',
      },
      selectedGames: {
        title: 'Selected games',
      },
      mobile: {
        playing: 'Я играю',
        myLinks: 'Мои ссылки',
      },
      unfollowConfirmModal: {
        title: (value: string): string =>
          `Ты уверен, что хочешь отписаться от ${value}?`,
        yes: 'Отписаться',
        no: 'Отменить',
      },
    },
    userGames: {
      meta: {
        title: (value: string): string =>
          `Игрок ${value}: Любимые игры | LF Group`,
        routeTitle: (value: string): string => `Игры ${value}`,
        description: (value: string): string =>
          `Посмотри, во что играет ${value}. Может из вас выйдет крутая команда?`,
      },
      inviteModal: {
        errorNoGroup: (value: string): string =>
          `Создай группу чтобы пригласить ${value}`,
        toastUserInvited: (value: string): string =>
          `${value} приглашен в группу`,
      },
      sectionTitles: {
        myGames: 'Мои игры',
        myLinks: 'Мои ссылки',
        events: 'События',
      },
      card: {
        actionInvite: 'Пригласить',
        toastLinkCopied: 'Ссылка скопирована 👌',
        toastLoginCopied: 'Логин скопирован 👌',
        toastInviteCopied: 'Инвайт скопирован 👌',
        write: 'Давай поиграем',
        wildrift: {
          winRate: (value: string): string => `${value}% win rate`,
          rankedGoldPerMinute: 'З/мин',
          rank: 'Ранг',
        },
        wow: {
          arena: (value: string): string => `Арена ${value}`,
        },
        lostArk: {
          legacyLvl: (value: string): string => `Roster lvl ${value}`,
        },
        user: {
          he: 'парень',
          she: 'девушка',
          age: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0 ? 'лет' : amount > 1 ? 'года' : 'год'
            }`,
        },
      },
      createCharModal: {
        title: 'Добавить учетную запись',
        game: {
          title: 'Игра',
          placeholder: 'выбери игру',
        },
        errorAlreadyExists: (value: string): string =>
          `У тебя уже есть аккаунт ${value}`,
        errorNoSocialAccount: (value: string): string => `Подключи ${value}`,
      },
      createGame: {
        errors: {
          errorRequiredField: 'Обязательное поле',
          errorSymbolLimitExceeded: (value: string): string =>
            `Введено более ${value} [символа|символов|символов]`,
          errorMaxNumberLimitExceeded: (value: string): string =>
            `Максимальное значение – ${value}`,
          errorMinNumberLimitExceeded: (value: string): string =>
            `Минимальное значение – ${value}`,
        },
        infos: {
          symbolAvailability: (amount: number) =>
            `${
              amount > 5 || amount === 0
                ? 'Доступно'
                : amount > 1
                ? 'Доступно'
                : 'Доступен'
            } ${amount}  ${
              amount > 5 || amount === 0
                ? 'символов'
                : amount > 1
                ? 'символа'
                : 'символ'
            }`,
        },
      },
      deleteGameModal: {
        title: 'Вы уверены, что хотите удалить игру?',
        deleteAction: 'Удалить',
      },
      newGameModal: {
        title: 'Добавление учетной записи',
        editTitle: 'Изменение учетной записи',
        gameTitle: 'Название игры',
        errorRequredFields: 'Заполни обязательные поля',
      },
    },
    userInvites: {
      meta: {
        title: 'Приглашения | LF Group',
      },
      errors: {
        errorNoInvites: 'Пока приглашений нет',
      },
      invniteModal: {
        toastSuccess: 'Приглашение отправлено',
        titleGroup: 'Выбери группу',
        titleSlots: 'Выбери слот',
      },
      toastAcceptInvite: 'Группа добавлена в ваш календарь',
      toastDeleteInvite: 'Группа удалена из календаря',
    },
    userLinks: {
      meta: {
        title: 'Ссылки | LF Group',
      },
      errorNoLinks: 'У пользователя пока нет ссылок',
      addFirstAction: 'У тебя пока нет ссылок. Нажми, чтобы добавить первую',
      addLinkAction: 'Добавить ссылку',
      createAction: 'Создать',
    },
    userNotifications: {
      meta: {
        title: 'Уведомления | LF Group',
      },
      errors: {
        errorNoNotifications: 'Пока уведомлений нет',
      },
      tabs: {
        groups: 'Группы',
        all: 'Все',
        following: 'Подписки',
        invites: 'Приглашения',
      },
      action: {
        accept: 'Принять',
        decline: 'Отклонить',
      },
      descriptions: {
        startFollow: (value: string): string => `подписался на тебя. ${value}`,
        invite: (value: string, value2: string, value3: string): string =>
          `${value} приглашение в "${value2}". ${value3}`,
        createdGroup: (value: string, value2: string): string =>
          `создал новую группу по ${value}. ${value2}`,
      },
    },
    userSearch: {
      inviteModal: {
        inviteUserToGroup: (value: string): string => `Мы позвали ${value}`,
        errorAuth: 'требуется авторизация',
        noGroupsOwned: (value: string): string =>
          `У тебя нет групп, куда ты можешь пригласить ${value}`,
      },
      meta: {
        routeTitle: 'Все игроки',
        title: 'Найди игрока, который станет твоим напарником | LF Group',
        description:
          'Ищи любого игрока, у которого есть профиль на LF.Group. Попробуй разные фильтры, чтобы найти напарников!',
      },
      filters: {
        inputPlaceholder: 'юзернейм',
        errorMinLength: 'Введи хотя бы 3 символа',
        hasBattlenet: 'Есть Battletag',
      },
      noUsersFound: 'Таких пользователей нет',
      card: {
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'подписчиков'
              : amount > 1
              ? 'подписчика'
              : 'подписчик'
          }`,
        following: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'подписок'
              : amount > 1
              ? 'подписки'
              : 'подписка'
          }`,
      },
      gameCards: {
        characters: 'Персонажи',
        class: 'Класс',
        wow: {
          raid: 'Рейд',
        },
        redirectToMore: (amount: number) =>
          `и еще ${amount} ${
            amount > 5 || amount === 0
              ? 'персонажей'
              : amount > 1
              ? 'персонажа'
              : 'персонаж'
          } на странице пользователя`,
        wildRift: {
          champions: 'Чемпионы',
          rank: 'Ранг',
          playing: 'Играет на',
          kda: 'Average KDA',
          winRate: 'Winrate',
        },
        lostArk: {
          gearScore: 'ilvl',
          builds: 'Сборки',
        },
      },
    },
    userSettings: {
      page: {
        title: 'Настройки',
        copyLink: 'Копировать ссылку',
        actionSave: 'Сохранить изменения',
        actionMobileSave: 'Сохранить',
        changeRegionAction: 'Изменить регион контента',
        write: 'Давай поиграем',
        tabs: {
          user: 'Пользователь',
          games: 'Games',
          links: 'Links',
          server: 'Сервер',
          notification: 'Уведомления',
          privacy: 'Конфиденциальность',
        },
      },
      regionModal: {
        title: 'Давай проверим твой регион',
        description:
          'Нам нужно знать, где ты находишься, чтобы показать тебе игроков и сообщества из твоего региона.',
        action: 'Поехали!',
        select: {
          label: 'Регион',
          values: {
            en: 'Мир',
            ru: 'СНГ',
            in: 'Индия 🇮🇳',
            pk: 'Пакистан 🇵🇰',
            sea: 'Южная азия (SEA)',
          },
        },
      },
      languageModal: {
        title: 'Выбери язык',
        action: 'Поехали!',
        select: {
          label: 'Язык',
        },
      },
      commonSettingsModal: {
        changeLanguage: 'Изменить язык',
        changeRegion: 'Изменить регион',
      },
      meta: {
        title: 'Настройки профиля | LF Group',
      },
      links: {
        addNewButton: {
          action: 'Добавить ссылку',
          title: 'Новая ссылка',
        },
        title: {
          placeholder: 'Мой любимый сайт',
        },
        link: {
          placeholder: 'https://lf.group',
        },
        description: {
          placeholder: 'Описание (дополнительно)',
        },
      },
      connectedServices: {
        title: 'Подключенные сервисы',
      },
      primary: {
        title: 'Основные настройки',
        username: 'Логин',
      },
      botSettings: {
        title: 'Параметры бота',
      },
      user: {
        settings: 'Настройки',
        cancel: 'Отменить',
        deleteAccount: {
          title: 'Удалите мой аккаунт',
          logout: 'Удалите мой аккаунт',
          confirmLogout: 'Ты точно хочешь удалить этот аккаунт?',
        },
        logout: {
          title: 'Выйти из аккаунта',
          logout: 'Выйти',
          confirmLogout: 'Вы точно хотите выйти?',
        },
        exitFromSettings: {
          exit: 'Выйти',
          confirmExit:
            'У тебя есть несохраненные изменения. Ты точно хочешь выйти?',
        },
        account: {
          title: 'Аккаунт',
          username: 'Логин',
          email: 'Email',
          displayName: 'Отоброжаемое имя',
          description: 'Профиль игрока',
        },
        connectedAccounts: {
          title: 'Подключенные аккаунты',
          connected: 'Подключено',
          connect: 'Подключи',
          disconnect: 'Отвязать',
        },
        preferences: {
          title: 'Настройки',
          language: 'Язык',
        },
      },
      server: {
        serverName: 'Имя сервера',
        limits: {
          title: 'Ограничения',
          description:
            'Небольшое описание ограничений. Пользователи могут создавать группы только в этих каналах',
        },
      },
      notifications: {
        description:
          'Мы всегда будем сообщать вам о важных изменениях, но вы выбираете, о чем еще хотите услышать.',
        browser: {
          title: 'Уведомления в браузере',
          newInvite: 'Новые приглашения',
          newJoiner: 'Новые игроки',
          newFollower: 'Новые подписчики',
          requestApproved: 'Запрос одобрен',
          description:
            'Разрешите LF.Group отправлять вам уведомления в браузере, чтобы оставаться на связи с друзьями.',
        },
        email: {
          title: 'Уведомления email',
          newInvite: 'Новые приглашения',
          newJoiner: 'Новые игроки',
          newFollower: 'Новые подписчики',
          requestApproved: 'Запрос одобрен',
          description:
            'Разрешите LF.Group отправлять вам уведомления по email, чтобы оставаться на связи с друзьями.',
        },
      },
      privacy: {
        everyone: 'Все',
        onlyFollowers: 'Только подписчики',
        labels: {
          whoCanInviteMe: 'Кто может приглашать меня',
          whoCanJoinMe: 'Кто может присоединяться ко мне',
          whoCanSeeMyGames: 'Кто может видеть мои игры',
          whoCanSeeMySocialAccounts: 'Кто может видеть мои учетки в соцсетях',
        },
        groups: {
          title: 'Группы',
        },
        content: {
          title: 'Контент',
        },
      },
      errors: {
        invalid: 'Поля заполнены неправильно',
        loginTaken: 'Логин занят',
      },
      toastSuccess: 'Настройки обновлены',
    },
    vacancyFeed: {
      title: (value: string): string => `Вакансии гильдий ${value}`,
      meta: {
        title: (value: string): string => `Вакансии гильдий ${value}`,
        og: {
          title: (value: string, value2: string): string =>
            `${value} Guilds - Looking for Guild in ${value2} | LF.Group`,
          description: (value: string): string =>
            `Find new guild and achieve your goals in ${value}. Search for guilds who match your in-game goals and available time to play. Website + Discord bot. Try it out!`,
        },
      },
      emptyState: {
        title: 'Таких вакансий нет',
        description: 'Попробуй другой запрос, чтобы найти больше вакансий',
      },
      card: {
        requests: (value: string): string => `${value} заявок`,
        actions: {
          view: 'Посмотреть',
          apply: 'Откликнуться',
        },
      },
    },
    welcome: {
      pageTitle: 'Твой личный игровой календарь.',
      pageDescription:
        'LF.Group создан, чтобы помочь тебе быстро найти игроков, сообщества и интересные мероприятия.',
      noGameDescription: 'Ближайшие события',
      header: {
        events: 'События',
        players: 'Игроки',
        teams: 'Команды',
        vacancies: 'Вакансии',
      },
      meta: {
        title: 'Find new teammates and gaming activities on LF.Group',
        description:
          'Find new teammates, communities and activities in different games. Search for players and vacancies that match your in-game goals, skill level and available time to play!',
        wow: {
          title: (value: string): string =>
            `WoW ${value} (Group Finder) - Looking for Group in World of Warcraft | LF.Group`,
          description:
            'Find new teammates, guilds and communities in WoW. Search for players and vacancies that match your in-game goals, skill level and available time to play!',
        },
        lol: {
          title: (value: string): string =>
            `LoL ${value} - League of Legends Looking for Group / Team Finder | LF.Group`,
          description:
            'Find new teammates and teams in LoL. Search for players that match your in-game goals, skill level and available time to play!',
        },
        la: {
          title: (value: string): string =>
            `Lost Ark ${value} (Group Finder) - Looking for Group in Lost Ark | LF.Group`,
          description:
            'Find new teammates, guilds and statics in Lost Ark. Search for players and communities that match your in-game goals, skill level and available time to play!',
        },
        wr: {
          title: (value: string): string =>
            `LoL Wild Rift ${value} - Wild Rift Looking for Group / Team Finder | LF.Group`,
          description:
            'Find new teammates and teams in LoL Wild Rift. Search for players that match your in-game goals, skill level and available time to play!',
        },
        pbgm: {
          title: (value: string): string =>
            `PUBG Mobile ${value} - PUBG Mobile Кастомки, турниры, праки / Поиск команды и игроков`,
          description:
            'Все про PUBG Mobile в одном месте. Подходящие фильтры и цели помогут выбрать крутых тиммейтов. Отправляйся в раздел «Мероприятия» и принимай участие в соревнованиях от лучших турнирных организаторов',
        },
        cod: {
          title: (value: string): string =>
            `COD MOBILE ${value} - COD MOBILE Кастомки, турниры, праки / Поиск команды и игроков`,
          description:
            'Все про COD MOBILE в одном месте. Подходящие фильтры и цели помогут выбрать крутых тиммейтов. Отправляйся в раздел «Мероприятия» и принимай участие в соревнованиях от лучших турнирных организаторов',
        },
        hs: {
          title: '',
          description: '',
        },
        csgo: {
          title: '',
          description: '',
        },
      },
      features: {
        default: {
          title: 'Features for players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create communities',
            description:
              'Customize your page, manage calendar and find new players. Notify your members via Discord DM about important events',
          },
          point3: {
            title: 'Discover new activities',
            description:
              'Gather groups with friends, join community events, see what tournaments and streams are going on and much more!',
          },
        },
        wow: {
          title: 'Features for WoW players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create communities',
            description:
              'Running a guild? Static group? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows guilds to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        lol: {
          title: 'Features for LoL players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create and manage teams',
            description:
              'Looking for semi-pro players to join your team? Creating a serious Clash community? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows teams to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        la: {
          title: 'Features for Lost Ark players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create communities',
            description:
              'Running a guild? Static group? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows guilds to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        wr: {
          title: 'Features for Wild Rift players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create and manage teams',
            description:
              'Looking for semi-pro players to join your team? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows teams to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        pbgm: {
          title: 'Особенности сообщества PUBG Mobile',
          point1: {
            title: 'Поиск команды',
            description:
              'Новый способ поиска тиммейтов, используй фильтры, теги и текстовое описание для поиска подходящих игроков',
          },
          point2: {
            title: 'Создай сообщество',
            description:
              'Являешься менеджером или капитаном команды? Проводишь турниры или трансляции? Воспользуйся LF.GROUP, чтобы создать страницу своего сообщества: управляй тренировками и командным календарем, найди игроков, организуй соревнование или анонсируй трансляцию',
          },
          point3: {
            title: 'Вакансии',
            description:
              'Мощный набор инструментов вакансий позволяет командам обрабатывать заявки по критериям: ранг, регион, KD, возраст, турнирные достижения и не только! Создай страницу команду, принимай заявки и задавай вопросы кандидатам',
          },
          point4: {
            title: 'Мероприятия',
            description:
              'Ежедневные соревнования по PUBG Mobile. Участвуй в кастомках и киберспортивных турнирах в одиночку или вместе со своей командой',
          },
        },
        hs: {
          title: '',
          point1: {
            title: '',
            description: '',
          },
          point2: {
            title: '',
            description: '',
          },
          point3: {
            title: '',
            description: '',
          },
        },
        csgo: {
          title: '',
          point1: {
            title: '',
            description: '',
          },
          point2: {
            title: '',
            description: '',
          },
          point3: {
            title: '',
            description: '',
          },
        },
      },
      sections: {
        updatesTitle: 'Последние обновления',
        features: 'У LFG есть замечательные функции',
        soon: 'Скоро',
        howToCreateGroup: 'Как создать событие?',
        groupsAndPlayers: (value: string, value2: string): string =>
          `${value} групп & ${value2} игроков`,
        players: (value: string): string => `${value} игроков`,
        groupDescription:
          'Создай группу в несколько кликов и общайся с напарниками через веб-чат.',
        discordDescription:
          'Настрой свой профиль один раз и используй его как на веб-сайте LF.Group, так и на всех подключенных партнерских дискордах.',
        recruitingDescription:
          'Рекрутинговый набор для всех! Игроки смогут искать подходящие гильдии.',
      },
      auth: {
        title: 'Set up your LF.Group profile',
        wow: {
          description:
            'Sign in with Battle.net and all your characters will be automatically uploaded. Match easily with suitable teammates!',
        },
        lol: {
          description:
            'Sign in via Riot Games token and your general stats will be automatically uploaded. Match easily with suitable teammates!',
        },
        la: {
          description:
            'Sign in and specify your experience, interests and character details. Match easily with suitable teammates!',
        },
        wr: {
          description:
            'Sign in via Riot Games token and your general stats will be automatically uploaded. Match easily with suitable teammates!',
        },
        pbgm: {
          description:
            'Register on the site in two clicks and set up your profile from the proposed parameters. Your victories are near!',
        },
      },
      motivation: {
        title: 'Участвуй в турнирах, кастомках, розыгрышах на LF.Group',
        action: 'Войти',
      },
      tabs: {
        events: 'События',
        players: 'Игроки',
        guilds: 'Гильдии',
        teams: 'Команды',
        vacancies: 'Вакансии',
      },
    },
  },
  arabic: {
    bpRules: {
      pubg: {
        title: 'PUBG Mobile Battle Pass rules | LF.Group',
        ogTitle: 'PUBG Mobile custom rooms Leaderboard Battle Pass',
        ogDescription:
          'Join our PUBG Mobile custom rooms leaderboard for a chance to win a share of 15,000 UC in weekly prizes. Show off your skills, beat the competition, and take home the ultimate prize!',
        ogImage: 'https://preview.lf.group/search/pubg-mobile',
        goToLeaderboard: 'Go to leaderboard',
        joinParagraph:
          'Invite your friends to join LF.Group and earn points to advance on the PUBG Mobile custom rooms Leaderboard.',
        prizesParagraph:
          'The rewards for inviting your friends to LF.Group are as follows:',
        prize1st: 'One invited friend will grant you +5 Leaderboard points.',
        prize2nd:
          'Two invited friends will add +10 Leaderboard points for you.',
        prize6th:
          'Three invited friends will provide you with +15 Leaderboard points.',
        paragraph2:
          'To initiate this process, please navigate to the Battle Pass panel and copy the unique invite link. Then, share this link with those who havenʼt previously registered for an LF.Group account and ensure that they complete their PUBG Mobile profile description and tags.',
        paragraph3:
          'Once your friend successfully registers with LF.Group, youʼll receive a +1 in the LF.Group Battle Pass progress. Please note that your friendʼs PUBG ID must not have been used on LF.Group previously to be eligible for the rewards.',
        paragraph4:
          'Your rewards will be added every time you invite a friend. If you manage to invite three friends, youʼll get 30 Leaderboard points in total, which is a significant step towards winning the weekly prizes.',
      },
    },
    calendar: {
      title: 'Timetable',
      addModalTitle: 'New free slot/playing time',
      actions: {
        addEvent: 'Add event',
        add: 'Add',
        cancel: 'Cancel',
        save: 'Save',
      },
      forms: {
        title: 'Title',
        pickDay: 'Pick day',
        from: 'From',
        to: 'To',
        recurring: 'Recurring',
      },
      weekDays: {
        monday: {
          full: 'Monday',
          short: 'Mon',
          extraShort: 'Mo',
        },
        tuesday: {
          full: 'Tuesday',
          short: 'Tue',
          extraShort: 'Tu',
        },
        wednesday: {
          full: 'Wednesday',
          short: 'Wed',
          extraShort: 'We',
        },
        thursday: {
          full: 'Thursday',
          short: 'Thu',
          extraShort: 'Th',
        },
        friday: {
          full: 'Friday',
          short: 'Fri',
          extraShort: 'Fr',
        },
        saturday: {
          full: 'Saturday',
          short: 'Sat',
          extraShort: 'Sa',
        },
        sunday: {
          full: 'Sunday',
          short: 'Sun',
          extraShort: 'Su',
        },
      },
      errors: {
        title: 'Required field',
      },
    },
    chat: {
      title: 'Chat',
      networkStatus: {
        typing: 'typing',
        connecting: 'connecting',
        updating: 'updating',
      },
      errors: {
        authRequired: 'Create an account to chat and play with others',
        loadingNewChat: 'Preparing your chat...',
        notSelected: 'Select a chat to start chatting...',
        userBanned: {
          title: 'You are banned',
          subtitle:
            'Admin of this chat blocked you, and you cannot stay in this chat anymore',
        },
        selectRegion: {
          title: 'Select a region and server',
          subtitle:
            'You can chat with other players, once you select your region and server',
        },
        noAccess: {
          title: 'Become a member to start chatting',
          subtitle:
            'Once you are a member of this group you will be able to chat here',
        },
        oldGroup: {
          title: 'There is no chat here',
          subtitle:
            'This group was created before we made chats. \nYou may find chats in a newer group',
        },
      },
      private: {
        invite: {
          title: 'This is invite only chat',
          subtitle:
            'You are joining the private chat. Here are just members who were invited by the owner',
        },
      },
      writebox: {
        loginToStartChatting: 'Log In and start chatting',
        placeholder: 'Type a message...',
        editingMessage: 'Editing message',
        placeholderAddComment: 'Add comment...',
        messageMaxLengthOverflow: 'Max message length is 3000 symbols',
        popupDelete: 'Are you sure you want to delete this message?',
      },
      header: {
        memberCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'members'
              : amount > 1
              ? 'members'
              : 'member'
          }`,
        typingCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0 ? 'are' : amount > 1 ? 'are' : 'is'
          } typing`,
        userTyping: (value: string): string => `${value} is typing`,
      },
      message: {
        popup: {
          reply: 'Reply',
          copyLink: 'Copy Link',
          report: 'Report',
          forward: 'Forward',
          deleteMessage: 'Delete',
          openThread: 'Open thread',
          edit: 'Edit',
        },
        comment: 'Comment',
        repliesCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'replies'
              : amount > 1
              ? 'replies'
              : 'reply'
          }`,
        viewsCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0 ? 'views' : amount > 1 ? 'views' : 'view'
          }`,
        noMessages:
          'Write a first message or invite friends to make chat even better',
        fetchBefore: 'Load before',
        fetchAfter: 'Load next',
      },
    },
    controls: {
      errors: {
        404: {
          text: 'Not found',
          action: 'Refresh',
        },
        noActiveGroups: 'No active groups yet',
        mustAuth: 'Log in first',
      },
      loading: 'Loading...',
      actions: {
        yes: 'Yes',
        no: 'No',
        ok: 'Ok 👌',
        cancel: 'Cancel',
        close: 'Close',
        send: 'Send',
        save: 'Save',
        share: 'Share',
        add: 'Add',
        delete: 'Delete',
        edit: 'Change',
        showMore: 'Show more',
        showLess: 'Show less',
        leave: 'Leave',
        later: 'Later',
        create: 'Create',
        up: 'Up',
        addGames: 'Add games',
        addLinks: 'Edit links',
        findFriends: 'Update profile',
        clickToUploadCover: 'Open settings to upload cover',
        updateFeed: 'Update feed',
        selectAnOption: 'Select an option',
        editProfile: 'Edit profile',
      },
      followers: {
        badge: (amount: number) => `Followers ${amount}+`,
        actionFollow: 'Follow',
        actionUnfollow: 'Following',
      },
      invites: {
        actionInvite: 'Invite',
        successStatus: 'Invite sent',
      },
      list: {
        more: (amount: number) => `& ${amount} more`,
      },
      modal: {
        closeWhilensaved: {
          title:
            'There is an unsaved progress. Are you sure you want to leave?',
          yes: 'Close',
          no: 'Stay',
        },
      },
      filters: {
        actionMore: {
          showMore: 'All filters',
          showLess: 'Hide filters',
        },
      },
      navigation: {
        searchGroups: 'Find a group',
        myGroups: 'My events',
        notifications: 'Notifications',
        players: 'Players',
        profile: 'Profile',
        home: 'Home',
        actionCreateGroup: 'Create a group',
        yourGames: 'Your games',
        lfgGames: 'LF.Games',
        chats: 'Chats',
        pendingRequests: (value: string): string =>
          `You have ${value} pending request to join your team`,
      },
      loginModal: {
        title: 'Continue with',
        reconnection: (value: string): string => `Connect ${value} again`,
        action: 'Log in',
        signUp: 'Sign up',
        signUpTitle: 'LFG will help you find some good teammates',
        createGroupTitle:
          'Want to create a group? \nLog in to join and unlock all features',
        joinGroupTitle:
          'Want to join the group? \nLog in to join and unlock all features',
        maybeLater: 'Maybe later',
        bestTeammates: 'Best \nteammates',
        favGames: 'All your \nfav games',
        biggestCommunity: 'Biggest \ncommunity',
        inControl: 'Everything \nin your control',
        gameSettings: {
          checkWhatsHappeningNow: 'Check what’s happening now',
          battlenetConnectionError:
            'We were not able to find a character in your account. \nIf you cannot see your character try to connect Battle.net again',
          loading: 'Please wait. Loading.. \nIt can take up to a few minutes',
          gameAdded: 'Game added to you profile',
          canShareGame:
            'Now you and people can see your characters in your profile. \nYou can share a direct link to them',
          yourCharacters: 'Your characters',
          region: 'Region',
          addComment: 'Add a comment..',
          gameCommentPlaceholder:
            'Tell people what activities you are looking for',
          browseGroups: 'Browse groups',
          goToProfile: 'Go to profile',
          addMoreGames: 'Add another game',
          createGroup: 'Create event',
          findTeammates: 'Find new teammates',
        },
        actions: {
          back: 'Back',
          continue: 'Continue',
          close: 'Close',
          finish: 'Finish',
        },
      },
      footer: {
        copyright: '© 2022 lf.group',
        joinDiscord: 'join us in',
        joinChannels: 'join our channels',
        menu: {
          about: 'About',
          help: 'Help',
          tos: 'Terms of Service',
          privacy: 'Privacy Policy',
          addBot: 'Add Discord Bot',
        },
      },
      language: {
        english: 'English',
        russian: 'Russian',
      },
      date: {
        today: 'Today',
        tomorrow: 'Tomorrow',
        yesterday: 'Yesterday',
        days: {
          0: {
            nomShort: 'Sun',
          },
          1: {
            nomShort: 'Mon',
          },
          2: {
            nomShort: 'Tue',
          },
          3: {
            nomShort: 'Wed',
          },
          4: {
            nomShort: 'Thu',
          },
          5: {
            nomShort: 'Fri',
          },
          6: {
            nomShort: 'Sat',
          },
        },
        months: {
          0: {
            nom: 'January',
            dat: (value: string): string => `${value} January`,
            datShort: (value: string): string => `${value} Jan`,
          },
          1: {
            nom: 'February',
            dat: (value: string): string => `${value} February`,
            datShort: (value: string): string => `${value} Feb`,
          },
          2: {
            nom: 'March',
            dat: (value: string): string => `${value} March`,
            datShort: (value: string): string => `${value} Mar`,
          },
          3: {
            nom: 'April',
            dat: (value: string): string => `${value} April`,
            datShort: (value: string): string => `${value} Apr`,
          },
          4: {
            nom: 'May',
            dat: (value: string): string => `${value} May`,
            datShort: (value: string): string => `${value} May`,
          },
          5: {
            nom: 'June',
            dat: (value: string): string => `${value} June`,
            datShort: (value: string): string => `${value} Jun`,
          },
          6: {
            nom: 'July',
            dat: (value: string): string => `${value} July`,
            datShort: (value: string): string => `${value} Jul`,
          },
          7: {
            nom: 'August',
            dat: (value: string): string => `${value} August`,
            datShort: (value: string): string => `${value} Aug`,
          },
          8: {
            nom: 'September',
            dat: (value: string): string => `${value} September`,
            datShort: (value: string): string => `${value} Sep`,
          },
          9: {
            nom: 'October',
            dat: (value: string): string => `${value} October`,
            datShort: (value: string): string => `${value} Oct`,
          },
          10: {
            nom: 'November',
            dat: (value: string): string => `${value} November`,
            datShort: (value: string): string => `${value} Nov`,
          },
          11: {
            nom: 'December',
            dat: (value: string): string => `${value} December`,
            datShort: (value: string): string => `${value} Dec`,
          },
        },
      },
    },
    errors: {
      network: {
        timeout: 'Request timed out :(',
      },
      unknown: 'Unknown error occured. Please, retry',
      codes: {
        GroupJoinSlotHasUser: 'Slot already occupied',
        AuthRequired: 'Log in first',
        InvalidUsername: 'Login is not valid',
        GroupDeleted: 'Group has been archived',
        GroupFull: 'Group is full',
        GroupRoleFull: (value: string): string =>
          `All slots for role ${value} are occupied`,
        GroupNameEmpty: 'Group title cannot be empty',
        GroupOwnerSlotRequried: 'You need to join a slot to create a group',
        GroupNameTooLong: 'Title is too big',
        GroupSlotsLimitExceeded: 'Too much slots, man',
        GroupNotOwner: 'Only group owner can do this',
        DeleteSocialAccountForbidden:
          'You cannot delete your last connected account',
        NotFound: 'Not found',
        PermissionDenied: 'Permission denied',
        ValidationError: 'Validation error',
        RuntimeError: 'Runtime error',
        RiotNotFound: 'Riot Profile with such game name not found',
        ThirdPartyTimeout: 'External API timed out',
        GroupJoinInvalidRole: 'Character role does not match with this slot',
        GroupJoinInvalidWowFaction:
          'Your faction does not match the requirements',
        GroupJoinInvalidRegion: 'Your region does not match the requirements',
        GroupJoinNoSocial:
          'You need to connect your Battle.net to join this group',
        GroupJoinLostArkLowGs: 'Your Gearscore is too low',
        GroupScheduledInPast: 'You cannot schedule an event in the past :(',
        AliasAlreadyExists: 'This URL already exists',
      },
      default: {
        createGroup: {
          title: 'Failed to create event',
        },
        updateGroup: {
          title: 'Failed to save edits',
        },
        connectBattlenet: {
          title: 'Failed to connect Battle.net',
        },
        connectTwitch: {
          title: 'Failed to connect Twitch',
        },
        connectDiscord: {
          title: 'Failed to connect Discord',
        },
        connectSteam: {
          title: 'Failed to connect Steam',
        },
        connectTwitter: {
          title: 'Failed to connect Twitter',
        },
        deleteTwitch: {
          title: 'Failed to disconnect Twitch',
        },
        deleteBattlenet: {
          title: 'Failed to disconnect Battle.net',
        },
        deleteDiscord: {
          title: 'Failed to disconnect Discord',
        },
        deleteSteam: {
          title: 'Failed to disconnect Steam',
        },
        deleteTwitter: {
          title: 'Failed to disconnect Twitter',
        },
        deleteUserGame: {
          title: 'Failed to hide a character',
        },
        restoreUserGame: {
          title: 'Failed to restore a character',
        },
        updateUserGame: {
          title: 'Failed to update a game info',
        },
        followUser: {
          title: 'Failed to follow a user',
        },
        unfollowUser: {
          title: 'Failed to unfollow from a user',
        },
      },
      clientErrors: {
        maxLegacyExceed: 'Maximum Roster lvl is 250',
        mustConnectBattlenet:
          'Connect your Battle.net account to create a group',
        mustHaveCharacter: (value: string): string =>
          `Add ${value} character to create a group`,
        riot: {
          mustSelectRegion: 'Choose the Riot region first',
          invalidGameName: 'Invalid game name',
        },
        incorrectNumber: 'Incorrect number',
        required: 'Requred',
        urlValidation: 'Url is not valid',
        russianSymbols:
          'Your group title contains russian, which may be misunderstood by others',
        inputRussianSymbols:
          'Your param contains russian, which may be misunderstood by others',
        gameRequiredFields:
          'If a character contains empty fields, it will not be updated',
        invalidCharacters: 'Invalid characters',
        isReserved: 'This value is reserved',
        tooShort: 'Too short',
      },
    },
    event: {
      title: 'Upcoming Event',
      host: 'Host',
      leaderboard: {
        label: 'Play custom rooms to win 15 000 UC',
        action: 'Check your score in Leaderboard',
      },
      layout: {
        progressBarFilling: (value: string, value2: string): string =>
          `${value} out of ${value2} members joined`,
      },
      actions: {
        standoff2: {
          playDescription: 'Play\nto double your chances to win!',
          playAction: 'Launch Standoff 2',
        },
        shop: {
          shopAction: 'Open Shop',
          shopBannerTitle: (value: string, value2: string): string =>
            `${value} ${value2} Shop`,
          shopBannerDescription: (value: string): string =>
            `A new way to buy in-game items and support ${value}`,
          pubgShopBannerDescription: (value: string): string =>
            `A new way to buy UC codes and support ${value}`,
          authTitle: 'Sign in to get access to the shop',
        },
        join: {
          joinAction: 'Join',
          joined: 'Joined',
        },
        play: {
          playAction: 'Play',
          tooEarly: {
            playSoon: (value: string): string => `Starts in ${value}`,
            hour: (amount: number) =>
              `${amount} ${
                amount > 5 || amount === 0 ? 'hrs' : amount > 1 ? 'hrs' : 'hour'
              }`,
            minute: (amount: number) =>
              `${amount} ${
                amount > 5 || amount === 0
                  ? 'mins'
                  : amount > 1
                  ? 'mins'
                  : 'minute'
              }`,
            second: (amount: number) =>
              `${amount} ${
                amount > 5 || amount === 0
                  ? 'secs'
                  : amount > 1
                  ? 'secs'
                  : 'sec'
              }`,
          },
          tooLate: {
            playAction: 'Event has ended',
          },
        },
        leave: {
          leaveAction: 'Leave',
        },
        yesAction: 'Yes',
        viewAction: 'View',
        maybeAction: 'Maybe',
        noAction: 'No',
        giveaway: {
          join: 'Take part',
          joined: 'Joined',
          notify: 'Notify',
          subscribed: 'Subscribed',
          followHost: 'Follow host',
          unfollowHost: 'Unfollow host',
        },
        link: {
          open: 'Open link',
        },
        edit: {
          editAction: 'Edit',
        },
        shuffle: {
          shuffleAction: 'Shuffle',
        },
      },
      tabs: {
        members: (amount: number) => `${amount} joined`,
        people: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'members'
              : amount > 1
              ? 'members'
              : 'member'
          }`,
        waitlist: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0 ? 'want' : amount > 1 ? 'want' : 'wants'
          } to join`,
        maybe: (amount: number) => `${amount} maybe`,
        declined: (amount: number) => `${amount} declined`,
        winners: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'winners'
              : amount > 1
              ? 'winners'
              : 'winner'
          }`,
      },
      contextActions: {
        approve: 'Approve',
        makeWinner: 'Select a winner',
        reject: 'Reject',
        kick: 'Kick',
        ban: 'BAN',
      },
      emptyState: {
        noMembers: {
          waitlist: {
            title: 'No one has joined this waitlist just yet',
          },
          members: {
            title: 'No one has joined this event just yet',
          },
          giveaway: {
            title: 'No one has joined this giveaway just yet',
            noWinners: 'Winners have not been selected yet',
            noWinnersDescription:
              'Winners will be selected randomly after the giveaway ends',
            viewJoinersAction: 'View joined players',
          },
        },
      },
      pubg: {
        copyIdAction: 'Copy ID',
        copyPasswordAction: 'Copy password',
        successToast: 'Copied to clipboard 👌',
        server: 'Server',
        password: 'Password',
        notPublished: 'Lobby password has not been published yet',
      },
      card: {
        remainingDays: (amount: number) =>
          `Available in ${amount} ${
            amount > 5 || amount === 0 ? 'days' : amount > 1 ? 'days' : 'day'
          }`,
        remainingHours: 'Available in',
        notification:
          'We will send a discord notification. Check discord is connected to your profile.',
      },
    },
    filters: {
      anyNumberValue: '0+',
      tagsPLaceholder: 'Choose tags',
      tags: 'Tags',
    },
    formAddGame: {
      addNewGamesDesc:
        'Add games to your profile to help people to find and play with you or just to browse your groups.',
      actionAddGames: 'Add games',
      actionRefresh: 'Refresh',
      actionDisconnect: 'Disconnect',
      wow: {
        noSocial: {
          description:
            'Connect your Battle.net account to add your character to your profile. It will be easier to join the group and find the right one',
          check1: 'Your Battle.net Account ID and BattleTag (Required)',
          check2: 'Your World of Warcraft profile',
        },
        params: {
          battlenetId: 'Battle.net ID',
          region: 'Region',
          descriptionPlaceholder:
            'Tell people what activities youre looking for',
        },
        charactersEdit: {
          title: 'Choose characters you want to display',
          yourCharactersTitle: 'Your characters',
          hidePlaceholder: 'Hide character',
          showPlaceholder: 'Show character again',
          loadingDesc: 'Please wait. Loading.. \nIt takes around 10 seconds',
          notFoundDesc:
            'We were not able to find a character in your account. \nIf you cannot see your character try to connect Battle.net again',
          actionReconnect: 'Reconnect Battle.net',
        },
      },
      lol: {
        description1:
          'Add your League of Legends profile via Riot Games token. Pop-up window will redirect you to',
        description2:
          'and when you login there we will receive a token that contains your profile nickname, KDA, game stats and most played champions.',
        description3:
          'All that will empower your personal LoL profile and will make it much easier to find amazing teammates.',
        description4:
          'If you are curious or cautious about the Riot Games token and Riot Sign On method please read this',
        article: 'article',
      },
      wildRift: {
        noSocial: {
          description:
            'Connect your Riot ID to get a personal stats card that will help you to find the right group. We daily update your account statistics',
          actionConnect: 'Connect Riot',
          actionConnectLoading: 'Checking details...',
        },
        characterEdit: {
          cardLoading: 'Please wait\nLoading...',
          notFound: {
            title: 'Summoner not found',
            description:
              'We have not found your LoL profile.\nTry to refresh Riot or contact us via',
          },
        },
        params: {
          riotId: 'Riot ID',
          region: 'Region',
          descriptionPlaceholder:
            'Tell people what activities youre looking for',
        },
      },
      lostArk: {
        description:
          'Enter information about your Lost Ark game profile that will help you to find the group.',
        createdSuccessfully: (value: string): string =>
          `${value} create success`,
        updatedSuccessfully: (value: string): string =>
          `${value} update success`,
        deletedSuccessfully: (value: string): string =>
          `${value} delete success`,
        character: 'character',
        build: 'build',
        account: 'account',
      },
    },
    gameData: {
      event: {
        anyTitle: 'Any type',
        allEvents: 'All Events',
        teamPlay: 'Team Play',
        game: 'Game',
        type: 'Type',
        title: 'Title',
        description: 'Description',
        dateStart: 'Date Start',
        from: 'From',
        until: 'Until',
        privacy: 'Privacy',
        customButtonUrl: 'Custom button URL',
        postButtonUrl: 'Message button URL',
        customButtonLabel: 'Button label',
        host: 'Host',
        Bet: 'Bet',
        Event: 'Event',
        Giveaway: 'Giveaway',
        Post: 'Message',
        Link: 'Link',
        Public: 'Public',
        Waitlist: 'Waitlist',
        Bets: 'Bets',
        Events: 'Events',
        Giveaways: 'Giveaways',
        Links: 'Links',
        membersGoal: 'Members count goal',
        membersGoalDescription: 'Members goal description',
        forApp: 'For the application',
      },
      gameMode: {
        anyTitle: 'Any mode',
        CSGO2v2: '2v2',
        CSGO5v5: '5v5',
        WowArena2v2: 'Arena 2x2',
        WowArena3v3: 'Arena 3x3',
        WowMythicPlus: 'Mythic+',
        WowRaid: 'Raid',
        WowRBG: 'RBG',
        HearthstoneBattlegrounds: 'Battlegrounds',
        HearthstoneBGLeague: 'Battlegrounds League',
        HearthstoneCustom: 'Custom',
        WowCustom: 'Custom',
        WarzoneBattleRoyale: 'Battle Royale',
        WarzonePlunder: 'Plunder',
        WowTBCArena5v5: 'Arena 5x5',
        WowTBCDungeon: 'Dungeon',
        HearthstoneLeagueSilvername: 'Silvername League',
        HearthstoneLeagueTricksterinoKsedden: 'Tricksterino & Ksedden League',
        HearthstoneLeagueFriendlyTournament: 'Hearthstone Friendly Tournament',
        LostArkRaidsOnGuardians: 'Guardian Raids',
        LostArkRaidsOnTheLordsOfFetrania: 'Raids On The Lords Of Fetrania',
        LostArkRaidsAbyss: 'Abyssal Raids',
        LostArkTrialsOfTheAbyss: 'Challenge Abyss Dungeons',
        LostArkGuardianHeroicRaids: 'Challenge/Trial Guardian Raids',
        LostArkAbyssDungeons: 'Abyss Dungeons',
        LostArkCube: 'Cube',
        LostArkBossRush: 'Boss Rush',
        LostArkOther: 'Other Activities',
        LostArkTestPath: 'Test Path',
        LostArkPlatinumFields: 'Platinum Fields',
        LostArkArena: 'Arena',
        LostArkAbyssalRaids: 'Abyssal Raids',
        LostArkLegionRaids: 'Legion Raids',
        PUBGMobileTournament: 'Tournament',
        PUBGMobileScrims: 'Scrims',
        PUBGMobileClassic: 'Classic',
        PUBGMobileArcade: 'Arcade',
        PUBGMobileGiveaway: 'Giveaway',
        PUBGMobileCustom: 'Custom Rooms',
        PUBGMobilePractice: 'Practice',
        CODMobileTournament: 'Tournament',
        CODMobileScrims: 'Scrims',
        CODMobileClassic: 'Classic',
        CODMobileArcade: 'Arcade',
        CODMobileGiveaway: 'Giveaway',
        CODMobileCustom: 'Custom',
        CODMobilePractice: 'Practice',
        WildRiftLFG: 'LFG',
        WildRiftRanked: 'Ranked',
        WildRiftPVP: 'PvP',
        WildRiftARAM: 'ARAM',
        WildRiftAI: 'AI',
        WildRiftUrf: 'U.R.F.',
        WildRiftEsportsTeam: 'Esports Team',
        LolNormal: 'Normal Draft',
        LolNormalBlind: 'Normal Blind',
        LolAram: 'ARAM',
        LolDuo: 'Ranked Duo',
        LolFlex: 'Ranked Flex',
        LolClash: 'Clash',
        LolCustom: 'Custom 5x5',
        LolCustomAram: 'Custom 5x5 ARAM',
        LolFeatured: 'Featured Mode (U.R.F. etc)',
      },
      gameModeTitle: {
        CSGO2v2: '2v2',
        CSGO5v5: '5v5',
        WowArena2v2: 'Arena',
        WowArena3v3: 'Arena',
        WowMythicPlus: 'Mythic Plus',
        WowRaid: 'Raid',
        WowRBG: 'RBG',
        WowCustom: '',
        HearthstoneBattlegrounds: 'Battlegrounds',
        HearthstoneBGLeague: 'Battlegrounds League',
        HearthstoneCustom: 'Custom',
        WarzoneBattleRoyale: 'Battle Royale',
        WarzonePlunder: 'Plunder',
        WowTBCArena5v5: 'Arena 5x5',
        WowTBCDungeon: 'Dungeon',
        HearthstoneLeagueSilvername: 'Silvername League',
        HearthstoneLeagueTricksterinoKsedden: 'Tricksterino & Ksedden League',
        HearthstoneLeagueFriendlyTournament: 'Hearthstone Friendly Tournament',
        LostArkRaidsOnGuardians: 'Guardian Raids',
        LostArkRaidsOnTheLordsOfFetrania: 'Raids On The Lords Of Fetrania',
        LostArkRaidsAbyss: 'Abyssal Raids',
        LostArkTrialsOfTheAbyss: 'Challenge Abyss Dungeons',
        LostArkGuardianHeroicRaids: 'Challenge, Trial Guardian Raids',
        LostArkAbyssDungeons: 'Abyss Dungeons',
        LostArkCube: 'Cube',
        LostArkBossRush: 'Boss Rush',
        LostArkOther: 'Other Activities',
        LostArkTestPath: 'Test Path',
        LostArkPlatinumFields: 'Platinum Fields',
        LostArkArena: 'Arena',
        LostArkAbyssalRaids: 'Abyssal Raids',
        LostArkLegionRaids: 'Legion Raids',
        PUBGMobileTournament: 'Tournament',
        PUBGMobileScrims: 'Scrims',
        PUBGMobileClassic: 'Classic',
        PUBGMobileArcade: 'Arcade',
        PUBGMobileGiveaway: 'Giveaway',
        PUBGMobilePractice: 'Practice',
        PUBGMobileCustom: '',
        CODMobileTournament: 'Tournament',
        CODMobileScrims: 'Scrims',
        CODMobileClassic: 'Classic',
        CODMobileArcade: 'Arcade',
        CODMobileGiveaway: 'Giveaway',
        CODMobilePractice: 'Practice',
        CODMobileCustom: '',
        WildRiftLFG: 'LFG',
        WildRiftRanked: 'Ranked',
        WildRiftPVP: 'PvP',
        WildRiftARAM: 'ARAM',
        WildRiftAI: 'AI',
        WildRiftUrf: 'U.R.F.',
        WildRiftEsportsTeam: 'Esports Team',
        LolNormal: 'Normal',
        LolAram: 'ARAM',
        LolDuo: 'Duo',
        LolFlex: 'Flex',
        LolClash: 'Clash',
        LolFeatured: 'Featured',
        LolCustom: 'Custom 5x5',
        LolCustomAram: 'Custom 5x5 ARAM',
      },
      slotRole: {
        anyTitle: 'Any role',
        Unset: 'Any role',
        WowTank: 'Tank',
        WowHeal: 'Healer',
        WowDamage: 'DPS',
        CsgoSupport: 'Support',
        CsgoawPer: 'AWP',
        CsgoLurker: 'Lurker',
        CsgoEntryFragger: 'Fragger',
        CsgoLeader: 'Leader',
        LolSolo: 'Top',
        LolJungle: 'Jungle',
        LolMid: 'Mid',
        LolDuo: 'Bot (ADC)',
        LolSupport: 'Support',
        LostArkDamage: 'DPS',
        LostArkSupport: 'Support',
      },
      wow: {
        alias: {
          title: 'Battletag',
        },
        region: {
          US: 'US',
          Europe: 'EU',
          APAC: 'APAC',
        },
        activities: {
          title: 'Activities',
          anyTitle: 'Any activity',
          placeholder: 'Choose activities',
        },
        playstyle: {
          title: 'Playstyle',
          anyTitle: 'Any playstyle',
        },
        minMythicRating: {
          title: 'Min Mythic+',
        },
        minRbgRating: {
          title: 'Min RBG rating',
        },
        minArenaRating: {
          title: 'Min arena rating',
        },
        wowRegion: {
          anyTitle: 'Any region',
          title: 'Region',
          US: 'US',
          Europe: 'EU',
          APAC: 'APAC',
          China: 'China',
          Korea: 'Korea',
          Taiwan: 'Taiwan',
        },
        server: {
          title: 'Server',
          anyForm: 'Any server',
        },
        role: {
          title: 'Role',
          placeholder: '',
          tank: 'Tank',
          heal: 'Healer',
          damage: 'DPS',
        },
        level: {
          title: 'Level',
          placeholder: 'any',
        },
        mythicPlusScore: {
          title: 'M+',
          placeholder: 'any',
        },
        sfoNormalKilled: {
          title: 'SFO N killed',
          placeholder: 'any',
        },
        sfoHeroicKilled: {
          title: 'SFO H killed',
          placeholder: 'any',
        },
        sfoMythicKilled: {
          title: 'SFO M killed',
          placeholder: 'any',
        },
        arena2x2: {
          title: '2x2',
          placeholder: 'any',
        },
        arena3x3: {
          title: '3x3',
          placeholder: 'any',
        },
        class: {
          title: 'Class',
          any: 'Any class',
          placeholder: 'some',
          unknown: 'Сlass not set',
          Paladin: 'Paladin',
          Warrior: 'Warrior',
          DeathKnight: 'Death knight',
          Hunter: 'Hunter',
          Shaman: 'Shaman',
          Druid: 'Druid',
          Rogue: 'Rogue',
          Monk: 'Monk',
          DemonHunter: 'Demon hunter',
          Priest: 'Priest',
          Mage: 'Mage',
          Warlock: 'Warlock',
        },
        race: {
          title: 'Race',
          placeholder: 'some',
          human: 'Human',
          dwarf: 'Dwarf',
          nightElf: 'Night elf',
          gnome: 'Gnome',
          draenei: 'Draenei',
          worgen: 'Worgen',
          pandaren: 'Pandaren',
          orc: 'Orc',
          undead: 'Undead',
          tauren: 'Tauren',
          troll: 'Troll',
          bloodElf: 'Blood elf',
          goblin: 'Goblin',
        },
        specialization: {
          title: 'Specialization',
          any: 'Any spec',
          unknown: 'Spec not set',
          DeathKnightBlood: 'Blood Death Knight',
          DeathKnightFrost: 'Frost Death Knight',
          DeathKnightUnholy: 'Unholy Death Knight',
          DemonHunterHavoc: 'Havoc Demon Hunter',
          DemonHunterVengeance: 'Vengeance Demon Hunter',
          DruidBalance: 'Balance Druid',
          DruidFeral: 'Feral Druid',
          DruidGuardian: 'Guardian Druid',
          DruidRestoration: 'Restoration Druid',
          HunterBeastMastery: 'Mastery Beast Hunter',
          HunterMarksmanship: 'Marksmanship Hunter',
          HunterSurvival: 'Survival Hunter',
          MageArcane: 'Arcane Mage',
          MageFire: 'Fire Mage',
          MageFrost: 'Frost Mage',
          MonkBrewmaster: 'Brewmaster Monk',
          MonkMistweaver: 'Mistweaver Monk',
          MonkWindwalker: 'Windwalker Monk',
          PaladinHoly: 'Holy Paladin',
          PaladinProtection: 'Protection Paladin',
          PaladinRetribution: 'Retribution Paladin',
          PriestDiscipline: 'Discipline Priest',
          PriestHoly: 'Holy Priest',
          PriestShadow: 'Shadow Priest',
          RogueAssassination: 'Assassination Rogue',
          RogueOutlaw: 'Outlaw Rogue',
          RogueSubtlety: 'Subtlety Rogue',
          ShamanElemental: 'Elemental Shaman',
          ShamanEnhancement: 'Enhancement Shaman',
          ShamanRestoration: 'Restoration Shaman',
          WarlockAffliction: 'Affliction Warlock',
          WarlockDemonology: 'Demonology Warlock',
          WarlockDestruction: 'Destruction Warlock',
          WarriorArms: 'Arms Warrior',
          WarriorFury: 'Fury Warrior',
          WarriorProtection: 'Protection Warrior',
        },
        faction: {
          any: 'Any faction',
          title: 'Faction',
          horde: 'Horde',
          alliance: 'Alliance',
        },
        raid: {
          title: 'Raid',
          anyTitle: 'Any raid',
          SepulcherOfTheFirstOnes: 'Sepulcher of the First Ones',
          SanctumOfDomination: 'Sanctum of Domination',
        },
        raidDifficulty: {
          title: 'Difficulty',
          anyTitle: 'Any difficulty',
          Normal: 'Normal',
          Heroic: 'Heroic',
          Mythic: 'Mythic',
        },
        dungeons: {
          anyTitle: 'Any dungeon',
          HallsOfAtonement: 'Halls Of Atonement',
          SanguineDepths: 'Sanguine Depths',
          Plaguefall: 'Plaguefall',
          TheNecroticWake: 'The Necrotic Wake',
          DeOtherSide: 'De Other Side',
          MistsOfTirnaScithe: 'Mists Of Tirna Scithe',
          SpiresOfAscension: 'Spires Of Ascension',
          TheaterOfPain: 'Theater Of Pain',
          TazaveshSoleahsGambit: 'Tazavesh: So’leah’s Gambit',
          TazaveshStreetsOfWonder: 'Tazavesh: Streets of Wonder',
          TBCHellfireRamparts: 'Hellfire Ramparts',
          TBCTheBloodFurnace: 'The Blood Furnace',
          TBCTheShatteredHalls: 'The Shattered Halls',
          TBCTheSlavePens: 'The Slave Pens',
          TBCTheUnderbog: 'The Underbog',
          TBCTheSteamvault: 'The Steamvault',
          TBCOldHillsbradFoothills: 'Old Hillsbrad Foothills',
          TBCTheBlack: 'TheBlack',
          TBCMorass: 'Morass',
          TBCTheMechanar: 'The Mechanar',
          TBCTheBotanica: 'The Botanica',
          TBCTheArcatraz: 'The Arcatraz',
          TBCManaTombs: 'Mana Tombs',
          TBCAuchenaiCrypts: 'Auchenai Crypts',
          TBCSethekkHalls: 'Sethekk Halls',
          TBCShadowLabyrinth: 'Shadow Labyrinth',
          OperationMechagonJunkyard: 'Operation Mechagon: Junkyard',
          OperationMechagonWorkshop: 'Operation Mechagon: Workshop',
          ReturnToKarazhanLower: 'Return to Karazhan: Lower',
          ReturnToKarazhanUpper: 'Return to Karazhan: Upper',
          GrimrailDepot: 'Grimrail Depot',
          IronDocks: 'Iron Docks',
        },
        fields: {
          login: 'Character name',
          description: 'Description',
          level: 'Level',
          rating: 'Rating',
          region: 'Region',
          realm: 'Realm',
          arenaScore: 'Arena score',
          guild: 'Guild',
          comment: 'Comment',
          specialization: 'Specialization',
          covenant: 'Covenant',
          raidSepulcher: 'SFO',
          arena2x2: '2x2',
          arena3x3: '3x3',
          mPlusPrevious: 'Mythic+ s2',
          mPlusCurrent: 'Mythic+',
        },
      },
      hearthstone: {
        alias: {
          title: 'Battletag',
        },
        region: {
          title: 'Region',
          any: 'Any region',
          Unset: 'Unset',
          Asia: 'Asia',
          China: 'China',
          Europe: 'Europe',
          US: 'US',
          Americas: 'Americas',
        },
        fields: {
          description: 'Описание',
          ratingBg: 'Рейтинг',
        },
      },
      pubg: {
        nickname: {
          title: 'Nickname',
        },
        kd: {
          title: 'K/D',
        },
        region: {
          title: 'Region',
          any: 'Any region',
          CIS: 'CIS',
          EU: 'EU',
          Turkey: 'Turkey',
          SEA: 'SEA',
          SouthAsia: 'South Asia',
          SA: 'South America',
          Arabian: 'Arabian',
        },
        rank: {
          title: 'Rank',
          any: 'Any rank',
          NoRank: 'No rank',
          Bronze: 'Bronze',
          Silver: 'Silver',
          Gold: 'Gold',
          Platinum: 'Platinum',
          Diamond: 'Diamond',
          Crown: 'Crown',
          Ace: 'Ace',
          AceMaster: 'Ace-Master',
          AceDominator: 'Ace-Dominator',
          Conqueror: 'Conqueror',
        },
      },
      cod: {
        nickname: {
          title: 'Nickname',
        },
        kd: {
          title: 'K/D',
        },
        region: {
          title: 'Region',
          any: 'Any region',
          NA: 'North America',
          SA: 'South America',
          SEA: 'South East Asia',
          SAM: 'South Asia & Middle East',
          EU: 'Europe',
          OC: 'Oceania',
          AF: 'Africa',
        },
        rank: {
          title: 'Rank',
          any: 'Any rank',
          NoRank: 'No rank',
          Rookie: 'Rookie I-V',
          Veteran: 'Veteran I-V',
          Elite: 'Elite I-V',
          Pro: 'Pro I-V',
          Master: 'Master',
          Grandmaster: 'Grandmaster',
          Legendary: 'Legendary',
        },
      },
      standoff2: {
        nickname: {
          title: 'Nickname',
        },
        gameId: {
          title: 'ID',
        },
        region: {
          title: 'Region',
          any: 'Any region',
          RU: 'RU',
        },
        rank: {
          title: 'Rank',
          any: 'Any rank',
          NoRank: 'No rank',
          Bronze: 'Bronze I-V',
          Silver: 'Silver I-V',
          Gold: 'Gold I-V',
          Phoenix: 'Phoenix',
          Ranger: 'Ranger',
          Champion: 'Champion',
          Master: 'Master',
          Elite: 'Elite',
          Legendary: 'Legendary',
        },
      },
      custom: {
        region: {
          title: 'Region',
          any: 'Any region',
          CIS: 'CIS',
          EU: 'EU',
          NA: 'NA',
          Asia: 'Asia',
        },
      },
      ggd: {
        region: {
          title: 'Region',
          any: 'Any region',
          CIS: 'CIS',
          EU: 'EU',
          NA: 'NA',
          Asia: 'Asia',
        },
        alias: {
          title: 'Nickname',
        },
      },
      steam: {
        region: {
          title: 'Region',
          any: 'Any region',
          EU: 'EU',
          NA: 'NA',
          SA: 'SA',
          CIS: 'CIS',
          Asia: 'Asia',
          WesternEurope: 'Western Europe',
          EasternEurope: 'Eastern Europe',
          NorthAmerica: 'North America',
          SouthAmerica: 'South America',
        },
      },
      warzone: {
        region: {
          any: 'Any region',
          USAndCanada: 'US and Canada',
          LatinAmerica: 'Latin America and Caribbean',
          EU: 'European Economic Area',
          Germany: 'Germany',
          Russia: 'Russia',
          MiddleEast: 'Middle East',
          Africa: 'Africa',
          Australia: 'Australia',
          Asia: 'New Zealand and other Asia',
          Pacific: 'Pacific',
          Japan: 'Japan',
        },
        fields: {
          login: 'Login',
          description: 'Description',
          region: 'Region',
        },
      },
      lol: {
        region: {
          title: 'Region',
          any: 'Any region',
          Brazil1: 'BR (Brazil)',
          EuNorth1: 'EUNE (Europe Nordic & East)',
          EuWest1: 'EUW (Europe West)',
          Japan1: 'JP (Japan)',
          Korea: 'KR (Korea)',
          NorthAmerica1: 'NA (North America)',
          LatinAmerica1: 'LAN (Latin America 1)',
          LatinAmerica2: 'LAS (Latin America 2)',
          Oceania1: 'OC (Oceania)',
          Russia: 'RU (Russia)',
          Turkey1: 'TR (Turkey)',
        },
        activities: {
          title: 'Activities',
          anyTitle: 'Any activity',
          placeholder: 'Choose activities',
        },
        playstyle: {
          title: 'Playstyle',
          anyTitle: 'Any playstyle',
        },
        rank: {
          solo: {
            short: 'SoloQ',
            full: 'SoloQ Rank',
          },
          flex: {
            short: 'FlexQ',
            full: 'FlexQ Rank',
          },
        },
        category: {
          title: 'Category',
          any: 'Any category',
          Controller: 'Controller',
          Fighter: 'Fighter',
          Mage: 'Mage',
          Marksman: 'Marksman',
          Slayer: 'Slayer',
          Specialist: 'Specialist',
          Tank: 'Tank',
        },
        kda: 'Avg KDA',
        creepScore: 'Avg CS',
        minWinRate: {
          title: 'Min winrate',
        },
        winRate: {
          title: {
            short: (amount: number) => `Winrate ${amount}%`,
            full: 'Games',
          },
          wins: {
            short: (amount: number) => `W${amount}`,
            full: (amount: number) => `Win ${amount}`,
          },
          loses: {
            short: (amount: number) => `L${amount}`,
            full: (amount: number) => `Los ${amount}`,
          },
        },
      },
      wildRift: {
        region: {
          title: 'Region',
          Europe: 'EU',
          Americas: 'Americas',
          Asia: 'Asia',
        },
        riotId: {
          title: 'Riot ID',
        },
        tagLine: {
          title: 'Tagline',
        },
        preferredLine: {
          title: 'Role',
          primaryTitle: 'Primary role',
          secondaryTitle: 'Secondary role',
          placeholder: 'Any role',
        },
        rank: {
          title: 'Rank',
          any: 'Any rank',
          None: 'Unranked',
          Iron4: 'Iron IV',
          Iron3: 'Iron III',
          Iron2: 'Iron II',
          Iron1: 'Iron I',
          Bronze4: 'Bronze IV',
          Bronze3: 'Bronze III',
          Bronze2: 'Bronze II',
          Bronze1: 'Bronze I',
          Silver4: 'Silver IV',
          Silver3: 'Silver III',
          Silver2: 'Silver II',
          Silver1: 'Silver I',
          Gold4: 'Gold IV',
          Gold3: 'Gold III',
          Gold2: 'Gold II',
          Gold1: 'Gold I',
          Platinum4: 'Platinum IV',
          Platinum3: 'Platinum III',
          Platinum2: 'Platinum II',
          Platinum1: 'Platinum I',
          Emerald4: 'Emerald IV',
          Emerald3: 'Emerald III',
          Emerald2: 'Emerald II',
          Emerald1: 'Emerald I',
          Diamond4: 'Diamond IV',
          Diamond3: 'Diamond III',
          Diamond2: 'Diamond II',
          Diamond1: 'Diamond I',
          Master: 'Master',
          Grandmaster: 'Grandmaster',
          Challenger: 'Challenger',
        },
      },
      csgo: {
        alias: {
          title: 'Steam ID',
        },
        faceitLvl: {
          title: 'FACEIT Lvl',
          any: 'No lvl',
        },
        region: {
          title: 'Region',
          EuEast: 'EU East',
          EuWest: 'EU West',
          Eu: 'EU',
          Asia: 'Asia',
          US: 'US',
        },
        rank: {
          any: 'No rank',
          title: 'Rank',
          Silver1: 'Silver I',
          Silver2: 'Silver II',
          Silver3: 'Silver III',
          Silver4: 'Silver IV',
          SilverElite: 'Silver Elite',
          SilverEliteMaster: 'Silver Elite Master',
          GoldNova1: 'Gold Nova I',
          GoldNova2: 'Gold Nova II',
          GoldNova3: 'Gold Nova III',
          GoldNovaMaster: 'Gold Nova Master',
          MasterGuardian1: 'Master Guardian I',
          MasterGuardian2: 'Master Guardian II',
          MasterGuardianElite: 'Master Guardian Elite',
          DistinguishedMasterGuardian: 'Distinguished Master Guardian',
          LegendaryEagle: 'Legendary Eagle',
          LegendaryEagleMaster: 'Legendary Eagle Master',
          SupremeMasterFirstClass: 'Supreme Master First Class',
          GlobalElite: 'Global Elite',
        },
        role: {
          Support: 'Support',
          AWPer: 'AWPer',
          Lurker: 'Lurker',
          EntryFragger: 'Entry Fragger',
          IGL: 'IGL',
          Rifler: 'Rifler',
        },
        fields: {
          description: 'Description',
          region: 'Region',
          rank: 'Rank',
          role: 'Role',
        },
      },
      lostArk: {
        region: {
          title: 'Region',
          any: 'Any region',
          USWest: 'US West',
          USEast: 'US East',
          EUCentral: 'EU Central',
          RU: 'Russia',
          uknown: 'Unknown region',
        },
        activities: {
          title: 'Activities',
          anyTitle: 'Any activity',
          placeholder: 'Choose activities',
        },
        playstyle: {
          title: 'Playstyle',
          anyTitle: 'Any playstyle',
        },
        guardian: {
          title: 'Guardian',
          any: 'Any guardian',
          Urnil: 'UrNil',
          Lumerus: 'Lumerus',
          IcyLegoros: 'Icy Legoros',
          Vertus: 'Vertus',
          Chromanium: 'Chromanium',
          Nacrasena: 'Nacrasena',
          FlameFoxYoho: 'Flame Fox Yoho',
          Tytalos: 'Tytalos',
          DarkLegoros: 'Dark Legoros',
          Helgaia: 'Helgaia',
          Calventus: 'Calventus',
          Achates: 'Achates',
          FrostHelgaia: 'Frost Helgaia',
          LavaChromanium: 'Lava Chromanium',
          Levanos: 'Levanos',
          Alberhastic: 'Alberhastic',
          ArmoredNacrasena: 'Armored Nacrasena',
          Igrexion: 'Igrexion',
          NightFoxYoho: 'Night Fox Yoho',
          Velganos: 'Velganos',
          Descaluda: 'Descaluda',
        },
        abyssDungeonsAbyss: {
          title: 'Abyss',
          any: 'Any abyss',
          RuinsOfElberia: 'Ancient Elveria',
          AbodeOfDreams: 'Phantom Palace',
          CrucibleOfSouls: 'Ark of Arrogance',
          GreatGate: 'Gate of Paradise',
          HeartOfOreya: 'Orehas Well',
        },
        abyssDungeonsDungeon: {
          title: 'Abyss Dungeon',
          any: 'Any abyss dungeon',
          DemonBeastCanyon: 'Demon Beast Canyon',
          NecromancersOrigin: 'Necromancers Origin',
          HallOfTheTwistedWarlord: 'Hall of the Twisted Warlord',
          HildebrandtPalace: 'Hildebrandt Palace',
          RoadOfLament: 'Road of Lament',
          ForgottenForge: 'Forge of Fallen Pride',
          SeaOfIndolence: 'Sea of Indolence',
          TranquilKarkosa: 'Tranquil Karkosa',
          AlaricsSanctuary: 'Alarics Sanctuary',
          AirasOculus: 'Airas Oculus',
          OrehaPreveza: 'Oreha Preveza',
        },
        cubeDungeon: {
          title: 'Cube Dungeon',
          any: 'Any cube dungeon',
          Cube: 'Cube',
          EliteCube: 'Elite Cube',
          DimensionalCube: 'Dimensional Cube',
        },
        bossRush: {
          title: 'Boss Rush',
          any: 'Any boss rush',
          HallOfSilence: 'Hall Of Silence',
          HallOfTheSun: 'Hall Of The Sun',
        },
        platinumFieldsField: {
          title: 'Platinum Field',
          any: 'Any platinum field',
          NahunsDomain: 'Nahuns Domain',
          OldYudianCanal: 'Old Yudian Canal',
        },
        difficulty: {
          title: 'Difficulty',
          any: 'Any',
          anyForm: 'Any difficulty',
          Normal: 'Normal',
          Hard: 'Hard',
          Hell: 'Hell',
          Dejavu: 'Dejavu',
        },
        otherActivities: {
          title: 'Activities',
          anyForm: 'Any activities',
          any: 'Any',
          SecretMap: 'Secret Map',
          Misc: 'Misc.',
        },
        alias: {
          title: 'Username',
        },
        server: {
          title: 'Server',
          anyForm: 'Any server',
        },
        legacyLvl: {
          title: 'Roster lvl',
          anyForm: 'Any Roster lvl',
        },
        gearScore: {
          title: 'ilvl',
          anyForm: 'Any ilvl',
        },
        arena: {
          title: 'Arena',
          anyForm: 'Any arena',
          any: 'Any',
          NormalMatch: 'Normal Match',
          CoopBattle: 'Co-op Battle',
          CustomGame: 'Custom Game',
        },
        arenaMode: {
          title: 'Arena Mode',
          anyForm: 'Any arena mode',
          any: 'Any',
          TeamDeathmatch: 'Team Deathmatch',
          TeamElimination: 'Team Elimination',
        },
        raid: {
          title: 'Raid',
          anyForm: 'Any raid',
          any: 'Any',
          Argos: 'Argos',
          Valtan: 'Valtan',
          Brelshaza: 'Brelshaza',
          Vykas: 'Vykas',
          KakulSaydon: 'Kakul-Saydon',
        },
        phase: {
          title: 'Phase',
          anyForm: 'Any phase',
          any: 'Any',
          Phase1: 'Phase 1',
          Phase2: 'Phase 2',
          Phase3: 'Phase 3',
        },
        class: {
          title: 'Class',
          anyForm: 'Any class',
          any: 'Any',
          Berserker: 'Berserker',
          Paladin: 'Paladin',
          Gunlancer: 'Gunlancer',
          Striker: 'Striker',
          Wardancer: 'Wardancer',
          Scrapper: 'Scrapper',
          Soulfist: 'Soulfist',
          Glaivier: 'Glaivier',
          Gunslinger: 'Gunslinger',
          Machinist: 'Machinist',
          Destroyer: 'Destroyer',
          Artillerist: 'Artillerist',
          Deadeye: 'Deadeye',
          Sharpshooter: 'Sharpshooter',
          Bard: 'Bard',
          Arcanist: 'Arcanist',
          Summoner: 'Summoner',
          Sorceress: 'Sorceress',
          Shadowhunter: 'Shadowhunter',
          Deathblade: 'Deathblade',
          Reaper: 'Reaper',
        },
        build: {
          name: 'Build name',
          link: 'Link',
        },
        card: {
          title: 'Card',
          anyForm: 'Any card',
          placeholder: 'Choose cards',
          any: 'Choose card',
        },
        zone: {
          title: 'Zone',
          anyForm: 'Any zone',
          any: 'Any',
        },
        gift: {
          title: 'Gift',
          anyForm: 'Any gift',
          any: 'Choose gift',
          placeholder: 'Choose gifts',
        },
        continent: {
          title: 'Continent',
          anyForm: 'Any continent',
          any: 'Any',
        },
      },
      lfg: {
        tags: {
          twoByTwo: '2v2',
          threeByThree: '3v3',
          rbg: 'RBG',
          mythicPlus: 'Mythic +',
          pveRaid: 'PvE Raid',
          lookingForGuild: 'Looking for Guild',
          raidLeader: 'Raid Leader',
          learning: 'Learning',
          casual: 'Casual',
          tryhard: 'Tryhard',
          awc: 'AWC',
          mdi: 'MDI',
          voice: 'Voice',
          streamer: 'Streamer',
          mentor: 'Mentor',
          duo: 'Duo',
          flex: 'Flex',
          rift: 'Rift',
          aram: 'ARAM',
          normal: 'Normal',
          ranked: 'Ranked',
          clash: 'Clash',
          teamCaptain: 'Team Captain',
          semiPro: 'Semi-Pro',
          pve: 'PvE',
          pvp: 'PvP',
          de: 'DE',
          uk: 'UK',
          us: 'US',
          es: 'ES',
          mx: 'MX',
          fr: 'FR',
          br: 'BR',
          en: 'EN',
          it: 'IT',
          pl: 'PL',
          swe: 'SWE',
          tr: 'TR',
          argos: 'Argos',
          valtan: 'Valtan',
          brelshaza: 'Brelshaza',
          vykas: 'Vykas',
          KakulSaydon: 'Kakul-Saydon',
          orehasWell: 'Oreha`s Well',
          lookingForStaticGroup: 'Looking for Static group',
          semiHardcore: 'Semi-hardcore',
          hardcore: 'Hardcore',
          LoadsFreeTime: 'Loads of free time',
          LookingForFriends: 'Looking for friends',
          LookingForTeam: 'Looking for team',
          Pro: 'Pro player',
          TFT: 'TFT',
          FamilyPerson: 'Family Person',
          Teenager: 'Teenager',
          Tournaments: 'Casual Tournaments',
          Age2030: '20-30 years old',
          Age3040: '30-40 years old',
          Age40Plus: '40+ years old',
          NoVoice: 'No voice',
          Coach: 'Coach',
          LookingForMale: 'Looking for man',
          LookingForFemale: 'Looking for girl',
          Female: 'Girl',
          Male: 'Man',
          coaching: 'Coaching',
          fun: 'Fun',
          nightGames: 'NightGames',
          casualTournaments: 'Casual Tournaments',
          twentyThirtyYears: '20-30 years old',
          thirtyFourtyYears: '30-40 years old',
          fourtyPlusYears: '40+ years old',
          hellmode: 'Hellmode',
          gvg: 'GvG',
          abyssDungeons: 'Abyss Dungeons',
          lookingForRaid: 'Looking for Raid',
          lookingForCodriver: 'Looking for Co-driver',
          lookingForClan: 'Looking For Clan',
          lookingForGirl: 'Looking For Girl',
          lookingForMan: 'Looking For Man',
          moddedServers: 'Modded Servers',
          vanillaServers: 'Vanilla Servers',
          skilledShooter: 'Skilled Shooter',
          builder: 'Builder',
          girl: 'Girl',
          man: 'Man',
          fullSupport: 'Full-Support',
          semiSupport: 'Semi-Support',
          offlane: 'Offlane',
          mid: 'Mid',
          carry: 'Carry',
          smurf: 'Smurf',
          turbo: 'Turbo',
          lurker: 'Lurker',
          sniper: 'Sniper',
          support: 'Support',
          faceit: 'Faceit',
          nonBinary: 'Non-binary',
          freeAgent: 'Free Agent',
          tdm: 'TDM',
          customGames: 'Custom Games',
          captain: 'Captain',
          igl: 'IGL',
          manager: 'Manager',
          newbie: 'Newbie',
          morningPrimeTime: 'Morning prime time',
          daytimePrimeTime: 'Daytime prime time',
          eveningPrimeTime: 'Evening prime time',
          raid: 'Raid',
          arena: 'Arena',
          fps30Plus: '30+ fps',
          fps60Plus: '60+ fps',
          fps90Plus: '90+ fps',
          lookingForPlayers: 'Looking for players',
          age1114: '11-14 years old',
          age1519: '15-19 years old',
          age2024: '20-24 years old',
          age2530: '25-30 years old',
          ge30Plus: '30+ years old',
        },
      },
    },
    group: {
      slots: 'Slots',
      pasha: {
        info: {
          when: 'When',
          memberSince: (value: string): string => `Member since ${value}`,
          waitlist: {
            title: 'Waitlist',
            yes: 'Yes',
            no: 'No',
          },
        },
        title: (value: string, value2: string): string =>
          `Group ${value} of ${value2}`,
      },
      event: {
        createdTitle: (value: string): string => `Created a group ${value}.`,
        card: {
          slots: {
            joinAs: 'Join as:',
            team: 'Team:',
          },
        },
      },
      meta: {
        title: (value: string, value2: string): string =>
          `${value} in ${value2} | LF.Group`,
        description: (value: string): string =>
          `${value} is waiting for players. Open the link to check out more info`,
      },
      slot: {
        noAccounts: 'No accounts',
        actionInvite: 'Invite',
        actionJoin: 'Join',
        actionViewCustomAnswers: 'Answers',
        waitingToApprove: 'Waiting to approve',
        waitingToAccept: 'Waiting to accept',
        waitingToChooseSlote: 'Waiting to choose slot',
        lostArkSlot: {
          seeBuilds: 'See builds',
        },
      },
      balance: {
        action: 'Balance',
        notAllowed: {
          title: 'To balance teams fill all the slots',
        },
        success: {
          toast: 'Slots are now balanced',
        },
      },
      password: {
        example: '********',
        toastSuccess: 'Password is in your buffer 👌',
      },
      card: {
        status: {
          started: 'Started',
          ended: 'Finished',
        },
        typeWaitlist: (amount: number) => `Waitlist (${amount})`,
        customFilters: 'form',
        minKarma: (amount: number) => `karma: ${amount}`,
        joined: 'Joined',
        actionView: 'View',
        actionJoin: 'Join',
        actionLeave: 'Leave',
        actionApproveInvite: 'Approve',
        actionDeclineInvite: 'Decline',
      },
      controls: {
        actionRatePlayers: 'Rate teammates',
        actionLeave: 'Leave group',
        actionSaveChanges: 'Save',
        actionEditGroup: 'Edit',
        actionDelete: 'Delete group',
        actionStart: 'Start',
        actionFinish: 'Finish',
      },
      removeGroupModal: {
        text: 'Are you sure you want to delete this group?',
      },
      ratePlayersModal: {
        title: 'Rate your teammates',
        toastSuccess: 'Rates are saved',
        actionSkip: 'Skip',
        actionSubmit: 'Submit & finish',
      },
      customFilters: {
        title: 'Questions',
        actionAddQuestion: 'Add a question',
        checkboxIsRequired: 'Mandatory questions',
        questionPlaceholder: 'Your question...',
        optionPlaceholder: (amount: number) => `Option ${amount}`,
        actionAddOption: 'Add option',
        fillInputs: 'Fill all the inputs',
        questionType: {
          input: 'Text input',
          selectMany: 'Multiple choice',
          select: 'Single choice',
          text: 'Long answer',
        },
      },
      waitlist: {
        title: 'Requests',
        count: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'requests'
              : amount > 1
              ? 'requests'
              : 'request'
          }`,
        invites: 'Invited',
        usersInTheSlots: 'Players are already in the slots',
        actions: {
          viewAll: 'View All',
          shuffle: 'Shuffle',
          accept: 'Approve',
          decline: 'Remove',
          viewForm: 'Answers',
          deleteInvite: 'Remove',
          pickRandomly: 'Pick randomly',
        },
      },
      editing: {
        title: 'Edit group',
        groupInfo: 'Group info',
        fields: {
          name: 'Name',
        },
        actions: {
          delete: 'Delete group',
          save: 'Save',
          cancel: 'Cancel',
        },
      },
      actionDiscordInvite: 'Server invite',
      actionCardLink: 'Card link',
      actionChannelLink: 'Channel link',
      actionGoBack: 'Go back to list',
      actionEditGroup: 'Edit',
      errors: {
        400: {
          text: 'Failed to open the group',
        },
        404: {
          text: 'Group not found',
        },
        errorNoGame: 'Choose a game',
        errorNoTitle: 'Give group a name',
        errorJoinNoChar: 'You need to add a character to join the event',
        errorCreateNoChar:
          'You need to add or choose a character to create the event',
        wow: {
          errHighIlvl: 'ilvl exceeds the max treshold - 300',
        },
      },
      status: {
        gameStarted: 'Game started',
        gameEnded: 'Game ended',
      },
      params: {
        header: 'Group settings',
        titlePlaceholder: 'Title',
        description: 'Description',
        waitlistCheckbox: 'Waitlist',
        scheduledDateTime: 'Date and time',
        minKarma: 'Min karma',
        actionAddCustomFilters: 'Custom filters',
        game: 'Game',
        gameMode: 'Mode',
        region: 'Region',
        language: 'Language',
        anyValue: 'Any',
        wow: {
          mythicPlusRating: 'Min M+ rating',
          dungeon: 'Dungeon',
          ilvl: 'ilvl',
          keyLevel: 'Key',
          faction: 'Faction',
          arenaRating: 'Arena rating',
          tbcHeroic: 'Heroic',
        },
        lostArk: {
          guardianRaidsGuardian: 'Guardian',
          abyssDungeonsAbyss: 'Abyss',
          bossRush: 'Boss Rush',
          platinumFieldsField: 'Platinum Field',
          otherActivity: 'Other activity',
          difficulty: 'Difficulty',
          dungeon: 'Dungeon',
          arena: 'Arena',
          arenaServer: 'Server',
          arenaMode: 'Arena mode',
        },
        hearthstone: {
          minBgRating: 'Min rating',
        },
        csgo: {
          rank: 'Rank',
        },
        lol: {
          password: 'Password',
        },
      },
    },
    home: {
      pasha: {
        tabs: {
          groups: 'Groups',
          users: 'Users',
        },
        search: {
          placeholder: 'Search players',
        },
      },
      meta: {
        routeTitle: 'All groups',
        activities: 'activities',
        title: 'LFG — find players for your team | LF Group',
        titleTemplate: (
          value: string,
          value2: string,
          value3: string
        ): string => `${value} ${value2}LFG - ${value3} Team Finder | LF.Group`,
        description:
          'LF.Group is the ultimate way to find new teammates and create events in any game. We also have our own LFG bot, check it out!',
        descriptionTemplate: (value: string, value2: string): string =>
          `Find new teammates and plan ${value} together in ${value2}. Search for players who match your in-game goals, character progress and available time to play. Website + Discord bot. Try it out!`,
      },
      page404: {
        title: 'Is this a page?',
        actionGoToMain: 'Go home!',
        gif: 'https://media1.giphy.com/media/QTDNiVcupJGy2Ur0pY/giphy.gif?cid=ecf05e471k8d08a7b422q197nziehje0mgn4diva3anul6ue&rid=giphy.gif',
      },
      navigation: {
        events: 'Events',
        players: 'Players',
        vacancies: 'Vacancies',
        teams: 'Teams',
        suggestions: 'Recommendations for you',
        howToCreateGroup: 'How to create a group?',
      },
      errors: {
        errorNoGroups: 'No events match your request',
        subtext: 'Try another search or pick a game to find all active groups',
      },
      filters: {
        more: 'More',
        less: 'Less',
        when: {
          title: 'When',
          any: 'Any time',
          today: 'Upcoming',
          thisWeek: 'This week',
          thisMonth: 'This month',
        },
        language: {
          title: 'Language',
          placeholder: 'Any language',
          english: 'English',
          russian: 'Russian',
          turkish: 'Turkish',
          swedish: 'Swedish',
          polish: 'Polish',
          german: 'German',
          french: 'French',
          spanish: 'Spanish',
          italian: 'Italian',
          dutch: 'Dutch',
          portuguese: 'Portuguese',
        },
        actionReset: 'Reset filters',
        selectGame: {
          title: 'Game',
          placeholder: 'Any game',
        },
        selectTag: {
          title: 'Tag',
          placeholder: 'Any tag',
        },
        selectGameMode: {
          title: 'Mode',
          placeholder: 'Any mode',
        },
        region: {
          title: 'Region',
          placeholder: 'Any region',
        },
        server: {
          title: 'Server',
          placeholder: 'Any server',
        },
        role: {
          title: 'Role',
          placeholder: 'Any role',
        },
        hearthstone: {
          ratingBg: {
            title: 'Rating',
            placeholder: 'Any rating',
          },
        },
        wow: {
          dungeon: {
            title: 'Dungeon',
            placeholder: 'Any dungeon',
          },
          faction: {
            title: 'Faction',
            placeholder: 'Any faction',
          },
          ilvl: {
            title: 'ilvl',
            placeholder: 'Any ilvl',
          },
          rioScore: {
            title: 'RIO',
            placeholder: 'Any RIO',
          },
          arenaRating: {
            title: 'Arena rating',
            placeholder: 'Any rating',
          },
          mythicPlusRating: {
            title: 'M+ Rating',
            placeholder: 'Any rating',
          },
          keyLevel: {
            title: 'Key Level',
            placeholder: 'Any lvl',
          },
          character: {
            title: 'Character',
            placeholder: 'No char',
          },
          tbcHeroicCheckbox: 'Heroic',
        },
        csgo: {
          rank: {
            title: 'Rank',
            placeholder: 'Any rank',
          },
          faceitLvl: {
            title: 'FACEIT Lvl',
            placeholder: 'Any lvl',
          },
        },
      },
    },
    homeFeed: {
      title: 'Your personal gaming calendar',
      followingsTitle: 'You following',
      recommendationsTitle: 'Recommendation',
      meta: {
        title: 'Home | LF.Group',
        og: {
          title: 'Mobile game launcher',
          description:
            'LF.Group is designed to help you quickly find what to do and whom to play with in their favourite games.',
        },
      },
      tabs: {
        feed: 'Feed',
      },
      emptyState: {
        title: 'Here will be players looking to play with you',
        description:
          'Connect your game profiles, so we can help you find new teammates for you',
        action: 'Connect your games',
      },
      errors: {
        noGames:
          'Add your personal gaming preferences for finding out about new events',
        nothingHappening: 'There is nothing happening right now.',
        nothingHappeningBut:
          'But you can find with whom to play today! Take a look',
        nothingHappeningButWithoutGame:
          'But you can find with whom to play today',
        nothingHappeningBut2: 'or create an event',
      },
      actions: {
        addGameProfile: 'Add game profile',
        createEvent: 'Create event',
      },
    },
    landings: {
      lfg: {
        title:
          'LF.Group will help you find new teammates and plan aсtivities together in your favorite online games',
        firstSection: {
          firstDescription:
            'Search for players who match your in-game goals, skill level and available time to play',
          secondDescription:
            'Schedule groups for specific game modes, each comes with a private Disc text & voice chat',
          activePlayers: 'Active players',
          groupsEveryDay: 'Groups every day',
          findMates: 'Find mates',
          browseAllGroups: 'Browse all LFG games',
        },
      },
      wow: {
        title:
          'LF.Group will help you find new teammates and plan aсtivities together in WoW',
        tryIt: 'Try it out',
        advantages: {
          battlenetData:
            'Connect the account to Battle.net API and LF.Group will fetch all data about your characters',
          searchForPlayers:
            'Search for players who match your in-game goals, skill level and available time to play',
          shedule:
            'Schedule groups for specific game modes, each comes with a private Disc text & voice chat',
          streamers:
            'Popular streamers use LF.Group to play with their viewers. Every player has a chance to get in!',
        },
      },
      lostArk: {
        tryIt: 'Try it out',
        title:
          'LF.Group will help you find new teammates and plan aсtivities together in Lost Ark',
        advantages: {
          partnership:
            'Easily update your profile to apply to groups/guilds or share your it with others in Web & Disc',
          connect:
            'Set up your profile once and use it both on LF.Group website and all partnered Disc servers',
          searchForPlayers:
            'Search for players who match your in-game goals, skill level and available time to play',
          shedule:
            'Schedule groups for specific game modes, each comes with a private chat',
          recruting:
            'Recruiting toolkit for everyone! Players will be able to search for a suitable static or guild',
        },
        firstSection: {
          firstDescription:
            'Search for players who match your in-game goals, skill level and available time to play',
          secondDescription:
            'Schedule groups for specific game modes, each comes with a private chat',
          activePlayers: 'Active players',
          groupsEveryDay: 'Groups every day',
          findMates: 'Find mates',
          browseAllGroups: 'Browse all LFG games',
        },
        mainPart: {
          title: 'It is more than a website',
          description:
            'LF.Group also features an LFG Disc bot that serves as a multi-functional extension to the website. Everyone is welcome to add the bot to their server.',
          steps: {
            profiles: {
              title: 'Profiles feed',
              description:
                'Each actively looking for groups profile can be posted on connected to the bot servers. Any player can post his card by using /lfg command',
            },
            groups: {
              title: 'Groups feed',
              description:
                'You can create a group on your Disc server through the bot /create command.s',
            },
            setup: {
              title: 'Easy setup',
              description:
                'Just add the bot to your server, do not remove any permissions (!) and follow the instructions. Add LFG bot to your server',
              link: 'Add LFG bot to your server',
            },
          },
          footer: {
            title: 'All our toolkit is free to use!',
            description:
              'Sign in to LF.Group to unlock its full potential. Stay in touch with the team: we are constantly adding new features and support more games.',
            startExploring: 'Explore LF.Group',
          },
        },
      },
      mainPart: {
        title: 'It is more than a website',
        description:
          'LF.Group also features an LFG Disc bot that serves as a multi-functional extension to the website. Everyone is welcome to add the bot to their server.',
        steps: {
          profiles: {
            title: 'Profiles feed',
            description:
              'Each actively looking for groups profile can be posted on connected to the bot servers. Any player can post his card by using /lfg command',
          },
          groups: {
            title: 'Groups feed',
            description:
              'You can create a group on your Disc server through the bot /create command.s',
          },
          setup: {
            title: 'Easy setup',
            description:
              'Just add the bot to your server, do not remove any permissions (!) and follow the instructions. Add LFG bot to your server',
            link: 'Add LFG bot to your server',
          },
        },
        footer: {
          title: 'All our toolkit is free to use!',
          description:
            'Sign in to LF.Group to unlock its full potential. Stay in touch with the team: we are constantly adding new features and support more games.',
          startExploring: 'Explore LF.Group',
        },
      },
      csgo: {
        actions: {
          continue: 'Continue',
          setUpProfile: 'Set up your CS:GO profile',
          joinCommunity: 'Join Skin.Club community',
        },
        title:
          'Find new CS:GO teammates \n& join the Skin.Club community on LF.Group!',
        aboutTitle: 'What is \nLF.Group about?',
        aboutDescription1:
          'LF.Group is a social gaming calendar that helps CS:GO players find who to play with and what to do in the game.',
        aboutDescription2:
          'The project was created by one of the Skin.Club co-founders.',
        aboutDescription3:
          'It is growing quickly and the development plan is completely community-driven.',
        giveawayTitle:
          'By joining Skin.Club page on \nLF.Group you automatically take \npart in a Giveaway.',
        giveawayDescription:
          'The winners will be randomly selected February, 20 on the live stream. \nThe results will be posted across all Skin.Club social media, including brand \nnew LF.Group page!',
        conclusionTitle: 'Keep track of all important \nSkin.Club updates',
        conclusionDescription:
          'After signing in with Discord and following the Skin.Club page on LF.Group you will automatically receive notifications in Discord DM about all important calendar events. \n\nNever miss the free case reset, promocode giveaways, new cases and other cool stuff!',
        instructions: {
          first: 'Set up your CS:GO player profile',
          second: 'Follow Skin.Club page on LF.Group',
          third: 'Win one of 50 skins in our giveaway!',
        },
        form: {
          title: 'Set up your LF.Group \nCS:GO profile',
          actions: {
            first: 'Pick your tags',
            second: 'Pick your region',
            third: 'Tell us your MM rating',
          },
        },
        header: {
          whatIs: 'What is LF.Group',
          setUpProfile: 'Set up your LFG profile',
          giveaway: 'Giveaway',
          calendar: 'Skin.Club calendar',
        },
        footer: {
          communityMatters: 'Your community matters.',
          termsAndPrivacy: 'Terms & privacy',
          place: '© 2020 - 2022 LF.Group, London UK.',
        },
      },
      pubg: {
        title:
          'برنامج الشراكة \nببجي للجوال\nقم بتطوير قناتك واحصل على البوانص \nبالشراكة مع مجموعة LF',
        beginNow: 'ابدأ الآن',
        description: {
          first: ' لاعبو ببجي للجوال مسجلون بالفعل على مجموعة LF ',
          second:
            'يتم إنشاء غرف خاصة شهرياً كفعاليات على مجموعة LF في جميع المناطق ',
          third: ' يشارك اللاعبون في فعاليات خاصة باللعبة يومياً ',
        },
        streamers: {
          title: 'وهؤلاء المشاركون مستفيدون بالفعل من الشراكة معنا',
          withoutUs: 'قبل مجموعة LF',
        },
        partners: {
          cards: {
            title: 'يحصل شركاؤنا على',
            first: {
              title: 'الترقية',
              description:
                'لدينا مشاركون مشهورون كشركاء من منطقتك. \n وسيصبح جمهورهم جمهوراً لك أيضاً!',
            },
            second: {
              title: 'أكواد UC',
              description:
                'سنقوم بتزويدك بأكواد UC لكل غرفة خاصة تشغلها من خلال مجموعة LF',
            },
            third: {
              title: 'طريقة فريدة للنمو',
              description:
                'يمكن العثور على فعاليات مجموعة LF في غوغل، وهذا يمنحك مستخدمين فريدين',
            },
            fourth: {
              title: 'جائزة أسبوعية',
              description:
                'عندما تصل إلى مستوى "محترف" في الشراكة فستبدأ بالحصول على أموال حقيقية. نقوم بالدفع أسبوعياً',
            },
          },
          levels: {
            title: 'مستويات الشراكة',
            description:
              'جميع المتطلبات مرنة، وستتم مناقشة بعض الشروط بشكل خاص. \n يحصل غالبية الشركاء النشطين على أكبر دعم يمكننا تقديمه!',
            level: (value: string): string => `مستوى ${value}`,
            junior: {
              title: 'مبتدئ',
              requirement: 'أكثر من 30 مشارك يومياً في فعالياتك',
              conditions:
                'صور لتحسين الفعاليات/أكواد UC من أجل الكشف الشهري على حسابك/تبدأ قناتك بنمو أسرع/نقوم بالترويج لك في شبكاتنا على منصات التواصل الاجتماعي من وقت لآخر',
            },
            middle: {
              title: 'متقدم',
              requirement: 'أكثر من 150 مشارك يومياً في فعالياتك',
              conditions:
                'جميع بوانص المبتدئ/وسام حساب موثق/أكواد UC لكل غرفة مخصصة تنظمها/ترويج يومي في شبكاتنا على منصات التواصل الاجتماعي',
            },
            senior: {
              title: 'محترف',
              requirement: 'أكثر من 210 مشارك يومياً في فعالياتك',
              conditions:
                'جميع بوانص المبتدئ والمتقدم/عروض وعمليات توحيد إضافية/دفعات أسبوعية بالدولار الأمريكي',
            },
          },
          cooperations: {
            title: 'أشيع شركائنا',
            followers: (value: string): string => `${value} ألف مشترك`,
            subscribers: (value: string): string => `مشتركين ${value}`,
          },
        },
        howToBecome: {
          title: 'هيا لنبدأ الشراكة',
          downloadDocs: 'تنزيل المواد',
          participate: 'المشاركة',
          howItWorks: 'كيف تتم العملية',
          first: 'شريط بانر مجموعة LF على شاشتك',
          second: 'روبوت دردشة مدمج، ورابط مجموعة LF في الوصف',
          third: 'أكثر من 5 ألف مشاهد وسطياً عبر الإنترنت',
          fourth: 'نأمل أن تجربه وتوسع قناتك',
          fifth:
            'رغبة مخلصة في مساعدة مجتمع ببجي للجوال - \n نقدم خدمة EM للبحث عن الفعاليات في اللعبة',
        },
        footer: {
          communityMatters: 'مجتمعك يهمنا.',
          termsAndPrivacy: 'الشروط والخصوصية',
          place:
            'جميع الحقوق محفوظة لصالح مجموعة LF، لندن، المملكة المتحدة 2020-2023',
          ourGames: {
            title: 'ألعابنا',
          },
          product: {
            title: 'المنتج',
            findTeam: 'العثور على فريق',
            findPlayers: 'العثور على لاعب',
            findGame: 'العثور على لعبة',
          },
          company: {
            title: 'الشركة',
            about: 'من نحن',
            contactUs: 'تواصل معنا',
            mediaKit: 'المجموعة الإعلامية',
          },
        },
      },
    },
    modalAddGame: {
      title: 'Add/Edit game',
      description:
        'Select your fav games to add them to your profile. It will help people to find you and invite you to their group',
      tagsLabel: 'Select your preferences',
      wow: {
        descriptionNotConnected:
          'Connect your Battle.net account to add your character to your profile. It will be easier to join the group and find the right one',
        descriptionConnected:
          'These are your max-level characters which we update daily that you can customize. If you do not see any of your max-level character, update this list with the button below',
        connectBattlenetAction: 'Connect Battle.net',
        updateBattlenetAction: 'Update list of characters',
        examples: 'Examples',
        deletedGames: 'Hidden',
        yourCharacters: 'Your characters',
      },
      wildRift: {
        descriptionNotConnected:
          'Add your Riot ID, Tagline, and Region for game stats. It will be easier to join the group and find the right one.',
        descriptionConnected:
          'We daily update your account statistics. If you see an error, click the button below.',
        actionConnectRiot: 'Connect',
        actionDisconnectRiot: 'Disconnect Riot',
        actionForceUpdate: 'Update info',
        successToast: 'Riot connected!\nLooking for your Wild Rift profile...',
        successUpdateToast: 'Info updated',
      },
      lostArk: {
        yourCharacters: 'Your characters',
        examples: 'Examples',
        addDescription: 'Add a comment..',
        characters: 'Сharacters',
        characterDescription:
          'You can add up to 6 characters. You will be able to change them anytime',
        addCharacter: 'Add character',
      },
    },
    modalAppLinks: {
      title:
        'Download the app, participate in activities, get notifications, and win!',
      titleOnJoin: 'Download the app to join this event!',
    },
    modalAuthOnboarding: {
      loginScenario: {
        mobile: {
          title: 'Your personal gaming calendar',
          description: 'Sign in to get personal event feed',
        },
        desc: 'LF.Group will help you to find\nthe right team to play with. Join now!',
        subDesc:
          'You`ll be able to join events, take part in the giveaways or create your own community!',
        actionOtherOptions: 'Other options',
        footer: {
          cards: {
            bestTeammates: 'Find new\nteammates',
            favGames: 'All your\nfav games',
            community: 'Friendly\ncommunity',
            friends: 'Make friends\nlike and follow',
            control: 'Control your\npreferences',
          },
        },
        slideBeforeBattlenet: {
          title: 'Before you will continue \nwith Battle.net',
          info: 'Please make sure you are giving all permisition to LF.Group. By giving them we will be able to get your WoW account',
          check1: 'Your Battle.net Account ID and BattleTag (Required)',
          check2: 'Your World of Warcraft profile',
          reconnect: 'Reconnect Battle.net',
        },
      },
    },
    modalEvent: {
      titleCreate: 'New event',
      titleEdit: 'Edit event',
      actionCreate: 'Create →',
      actionEdit: 'Edit →',
      actionDelete: 'Delete',
      actionClone: 'Repeat previous event',
      description: {
        riot: 'Enter your Riot ID to get a personal stats card that will help you to find the right group. We daily update your account statistics',
      },
      image: {
        size: '16x9, Max 5MB',
        action: 'Add cover',
        replaceAction: 'Click to replace',
        error: 'Failed to upload your image',
      },
      preview: {
        titlePlaceholder: 'Hi! We are LF.Group!',
        descriptionPlaceholder:
          'Add an event in your favorite game!\nLF.Group is designed to help you quickly find players, communities and interesting activities.',
      },
      gameOptions: {
        title: 'Event options',
      },
      time: {
        fromDate: 'Date Start',
        fromTime: 'From',
        toDate: 'Date End',
        toTime: 'Until',
        withEndDateCheck: 'Until',
      },
    },
    modalLaunchingGame: {
      title: 'Room ID is copied,\n launching the game...',
      subtitle:
        'If the game does not start automatically, click the button below.',
      notProvided: {
        title: 'Host has not provided \nroom ID and password yet',
        subtitle: 'Please wait for the host to provide the information',
      },
      standoff2: {
        title: 'Launching Standoff 2...',
        subtitle:
          'If the game does not start automatically, click the button below.',
        desktop: {
          scanQr: 'Scan QR code to join the room',
        },
        initeUrl: {
          label: 'Invite URL',
        },
        notProvided: {
          title: 'Host has not provided invite URL yet',
          subtitle: 'Please wait for the host to provide the information',
        },
      },
      server: {
        label: 'Room ID',
      },
      password: {
        label: 'Password',
      },
      button: 'Launch now',
      cancel: 'Cancel',
    },
    modalNewGroup: {
      title: 'New group',
      slideGame: {
        title: 'Pick a game',
      },
      slideInfo: {
        organization: 'an organization',
        group: 'a group',
        noWow: {
          desc: (value: string): string =>
            `Connect your Battle.net account to create ${value}`,
          actionAdd: 'Connect',
        },
        noWildRift: {
          desc: (value: string): string =>
            `Add your Wild Rift profile to create ${value}`,
          actionAdd: 'Connect',
        },
        noOther: {
          desc: (value: string, value2: string): string =>
            `Add your ${value} profile to create ${value2}`,
          actionAdd: 'Add',
        },
        forCommunity: 'Community group',
        sectionTitle: 'Game Info',
        inputGameLabel: 'Game',
        inputTitleLabel: 'Title of the group',
        inputDateLabel: 'Date',
        inputTimeLabel: 'Time',
        inputKarmaLabel: 'Karma',
        section2Title: 'Preferences',
        action: 'Next',
        gameMode: {
          any: 'Any mode',
        },
        inputWhoCanJoin: {
          label: 'Who can join',
          optionEveryone: 'Everyone',
          optionWaitlist: 'Waitlist',
        },
        formGuild: {
          label: 'My communities',
        },
        customQuestions: {
          label: 'Poll',
          addQuestions: 'Add questions',
          placeholder: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'questions'
                : amount > 1
                ? 'questions'
                : 'question'
            }`,
        },
      },
      language: {
        title: 'Language',
        english: 'English',
        russian: 'Russian',
      },
      slideSlots: {
        title: 'Slots',
      },
      actionCreate: 'Create',
      actionGoBackToInfo: 'Back to info',
      actionGoBackToGames: 'Back to games',
    },
    myGroups: {
      errors: {
        noGroups: 'No events yet',
      },
      meta: {
        title: 'My events | LF Group',
        routeTitle: 'My events',
      },
      filter: {
        type: 'Type',
        all: 'All',
        member: 'Member',
        owner: 'Owner',
        waitlist: 'Requested',
      },
      emptyBlock: {
        title: 'You do not have any active groups right now',
        exploreGroups: 'Explore all groups',
        browseMyHistory: 'Browse my history',
      },
    },
    newGroup: {
      meta: {
        title: 'Create a group | LF Group',
      },
      filter: {
        selectChar: 'Choose your character',
        date: 'Date',
        time: 'Time',
      },
      errors: {
        errorJoinNoChar:
          'You need to add a character to create a group for this game',
      },
      title: 'New group',
      actionButton: 'Create event',
    },
    onboarding: {
      welcome: {
        title: (value: string): string => `Welcome to ${value}`,
        description: {
          home: 'Welcome to LF.Group! We are a free tool for players, teams and communities. \nOur goal is to help everyone find the right teammates to play with. \nLog in with Discord and start exploring!',
          inviteDiscrord:
            'Welcome to LFG! We provide you with a secure and powerful tool for players, teams, and communities. \nGet instant access to organizing parties and different events in your discord server.',
          players:
            'Explore the list of players listed on LF.Group. \nAfter you log in with Discord — you will be able to follow them and invite to play together.',
          game: {
            wow: 'Tired of in-game WoW LFG? LF.Group offers next-level experience! \nLog in with Discord & BattleNet and set up a group that matches your role and skill level. \nPrivate Discord chat included!',
            wowTbc:
              'Playing WoW Classic without LFG is hard. LF.Group is your solution! \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
            csgo: 'CSGO team finder by LF.Group is the easiest way to find new players and teams. \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
            hearthstone:
              'The most comprehensive Hearthstone LFG tool by LF.Group. \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
            warzone:
              'Call of Duty Warzone LFG on LF.Group is the easiest way to find new players and form a dream team! \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
            lostArk:
              'The most comprehensive Lost Ark LFG tool by LF.Group. \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
            wildRift:
              'The most comprehensive Wild Rift LFG tool by LF.Group. \nLog in with Discord and set up a group that matches your goals. \nPrivate Discord chat included!',
          },
        },
      },
      community: 'Community',
      actions: {
        accept: 'Accept',
        reject: 'Reject',
        addToServer: 'Add to server',
      },
      inviteYou: 'Invite you to play',
      noInvites: 'All done',
    },
    onboardingGames: {
      title: 'Which games do you play?',
      slidePickGame: {
        noGames: 'You have already added all currently supported games',
      },
      errors: {
        noLostArkCharacters: 'You need to add Lost Ark character first',
        lostArkInvalidInput: 'Fill in required fields',
        gameInvalidInput: 'You need to add a description or select tags',
      },
      slideGameAdded: {
        title: 'Game added to you profile',
        description:
          'Now you and people can see your characters in your profile. \nYou can share a direct link to them',
        actionAddMore: 'Add another game',
        actionBrowse: 'Browse groups',
      },
    },
    organization: {
      meta: {
        title: 'Communities - LF.Group',
        routeTitle: 'Communities',
        description: 'Communities on LF.Group',
      },
      organizationMeta: {
        title: (value: string, value2: string): string =>
          `${value} ${value2} Looking for players | LF.Group`,
        gameTitle: (value: string, value2: string, value3: string): string =>
          `${value} ${value2} in ${value3} Looking for players | LF.Group`,
        description: (value: string, value2: string): string =>
          `${value} ${value2} website`,
        gameDescription: (
          value: string,
          value2: string,
          value3: string
        ): string => `${value} ${value2} website in ${value3}`,
      },
      errors: {
        noGuilds: 'Communities not found',
        noGuild: 'Community not found',
        noGuildMembers: 'Members not found',
        memberNotDefined: 'Member is not defined',
        guildNotDefined: 'Community is not defined',
        required: 'This field is required',
        nameRequired: 'Name is required',
        aliasRequired: 'Alias is required',
        teamRequired: 'To add an event you need to create a team',
        longDescription: 'Description is too long',
        longName: 'Name is too long',
        longAlias: 'Alias is too long',
        longWebsite: 'Main Link URL is too long',
        longCustomButtonLabel: 'It is too long',
        longWebsiteTitle: 'Main Link title is too long',
        nameAndAliasRequired: 'Name and alias are required',
        createGuildFailed: 'Community is not created',
        createTeamFailed: 'Team is not created',
        chooseGame: 'Choose game',
        createGuild: 'Create community error',
        noMembersFound: 'Members not found',
        alreadyMember: (value: string): string =>
          `${value} is already member of this community`,
        justNumbers: 'Must be a number',
      },
      alerts: {
        guildUpdated: 'The community is updated',
        guildCreated: 'The community is created',
        guildDeleted: 'The community is deleted',
        guildMemberUpdated: 'The community member is updated',
        guildMemberCreated: 'The community member is created',
        guildMemberDeleted: 'The community member is deleted',
        guildMemberRoleUpdated: 'Community member role updated',
        guildTeamUpdated: 'Community team updated',
        guildTeamCreated: 'Community team created',
        guildTeamDeleted: 'Community team deleted',
      },
      modals: {
        deleteGuild: 'Are you sure you want to delete this community?',
        deleteGuildMember: 'Are you sure you want to delete this member?',
        leaveGuild: 'Are you sure you want to leave this community?',
        leaveTeam: 'Are you sure you want to leave this team?',
        closeSettings:
          'Are you sure you want to close this community`s settings? \nAll changes will be lost.',
        closeCreating:
          'Are you sure you want to leave? \nAll changes will be lost.',
      },
      page: {
        gameInfo: 'Game info',
        addGameParams: 'Add game parameters to preview team card',
        memberCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'members'
              : amount > 1
              ? 'members'
              : 'member'
          }`,
        joinedMemberCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'members'
              : amount > 1
              ? 'members'
              : 'member'
          }`,
        views: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0 ? 'views' : amount > 1 ? 'views' : 'view'
          }`,
        commonViews: (value: string): string => `${value} views`,
        vacancyCount: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'vacancies'
              : amount > 1
              ? 'vacancies'
              : 'vacancy'
          }`,
        member: 'Member',
        role: 'Role',
        classes: 'Classes',
        champions: 'Champions',
        roster: 'Team',
        events: 'Events',
        wiki: 'Links',
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'followers'
              : amount > 1
              ? 'followers'
              : 'follower'
          }`,
        addTeamInfo: 'Add your team info',
        toHelpPeople: 'to help people to find your team',
        teamCard: {
          descriptionPlug: {
            addDescription: 'Add description of the team',
            or: 'or',
            inviteMember: 'Invite a team member',
          },
        },
      },
      fields: {
        type: 'Type',
        team: 'Team',
        progress: 'Progress',
        language: 'Language',
        region: 'Region',
        server: 'Server',
        activity: 'Activity',
        mainActivity: 'Main activity',
        weeklyContribution: 'Weekly contribution',
        contract: 'Contract',
        players: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'players'
              : amount > 1
              ? 'players'
              : 'player'
          }`,
      },
      action: {
        createGuild: 'Create community',
        userCopied: 'User link copied',
        createTeam: 'Create team',
        edit: 'Edit',
        deleteGuild: 'Delete community',
        upload: 'Upload',
        leaveTeam: 'Leave team',
        leaveGuild: 'Leave community',
        follow: 'Follow',
        followed: 'Following',
      },
      slidoverEdit: {
        title: 'Edit community',
        teamTitle: 'Edit team',
        paramsTabTitle: 'Params',
        imagesTabTitle: 'Branding',
        membersTabTitle: 'Management',
        teamMembersTabTitle: 'Team members',
        gameSettingsTabTitle: 'Game settings',
        teamTabTitle: 'Team',
        linksTabTitle: 'Links',
      },
      slidoverNew: {
        createGuild: 'Create community',
        params: {
          title: 'New community',
          social: 'Social',
        },
        members: {
          titles: 'Members',
          adminRole: 'Admin',
          playerRole: 'Player',
        },
        images: {
          title: 'Images',
          settings: 'Branding',
          banner: 'Banner',
          avatar: 'Avatar',
          maxSize: 'Max size',
          maxResolution: 'Max resolution',
          ration: 'Ration',
          recommendedResolution: 'Recommended resolution',
        },
        teams: {
          title: 'Game info',
        },
        forms: {
          askQuestion: 'If you have a question, feel free to ask us in our',
          guildName: 'Community name',
          guildAlias: 'Link',
          aliasPreview: 'Your guild will be available at ',
          guildDescription: 'Description',
          teamDescription: 'Roster description',
          discord: 'Discord URL',
          twitter: 'Twitter URL',
          twitch: 'Twitch URL',
          youtube: 'YouTube URL',
          facebook: 'Facebook URL',
          instagram: 'Instagram URL',
          website: 'URL',
          websiteTitle: 'Button title',
          teamName: 'Team name',
          moreThanSymbols: (value: string): string =>
            `Search by typing no less than ${value} characters`,
          searchByTyping: 'Search by typing',
          roles: 'Roles',
        },
      },
    },
    organizationData: {
      type: {
        guild: 'Guild',
        community: 'Community',
        static: 'Static',
        title: 'Type',
        any: 'Any',
      },
      role: {
        admin: 'Admin',
        player: 'Player',
        default: 'Default',
        any: 'Any',
      },
      shortLanguage: {
        english: 'En',
        russian: 'Ru',
        german: 'De',
        spanish: 'Es',
        french: 'Fr',
        portuguese: 'Pt',
        italian: 'It',
        dutch: 'Nl',
        polish: 'Pl',
        turkish: 'Tr',
        swedish: 'Se',
        any: 'Any language',
        title: 'Language',
      },
      voicePreferences: {
        any: 'Any voice preferences',
        title: 'Voice preferences',
        required: 'Required',
        notRequired: 'Not required',
      },
      playstyle: {
        any: 'Any playstyle',
        title: 'Playstyle',
      },
      contract: {
        any: 'Any contract',
        title: 'Contract',
      },
      weeklyContribution: {
        any: 'Any weekly contribution',
        title: 'Weekly contribution',
      },
      activities: {
        title: 'Activities',
        anyTitle: 'Any activity',
      },
      wow: {
        activity: {
          raids: 'Raids',
          mythicPlus: 'MythicPlus',
          rbg: 'RBG',
          arena2x2: 'Arena2x2',
          arena3x3: 'Arena3x3',
          casual: 'Casual',
        },
      },
      la: {
        activity: {
          raids: 'Raids',
          gvg: 'GVG',
          altGuild: 'Alt-Guild',
          gve: 'GVE',
          pve: 'PVE',
          other: 'Other',
        },
        weeklyContribution: {
          mandatory: 'Mandatory',
          optional: 'Optional',
          no: 'No',
        },
      },
      lol: {
        activity: {
          normals: 'Normals',
          rankedFlex: 'Ranked Flex',
          aram: 'ARAM',
          clash: 'Clash',
          casualTournaments: 'Casual Tournaments',
          semiProTournaments: 'Semi-Pro Tournaments',
          proTournaments: 'Pro Tournaments',
        },
      },
    },
    pagePlayers: {
      title: {
        anyGame: 'All players',
        gamePlayers: (value: string): string => `${value} Players`,
      },
      meta: {
        title: {
          anyGame: 'All LFG | LF.Group',
          gamePlayers: (value: string): string => `${value} LFG | LF.Group`,
        },
        og: {
          title: {
            anyGame: 'All LFG',
            gamePlayers: (value: string): string => `${value} LFG`,
          },
          description: '',
        },
      },
    },
    pageVacancy: {
      tabs: {
        home: 'Details',
        applications: 'Applications',
      },
      controls: {
        confirmArchive: {
          title:
            'Are you sure you want to archive this vacancy? It would not be visible in the feed and players would not be able to apply to it.',
          yes: 'Archive',
          no: 'Cancel',
        },
        edit: 'Edit vacancy',
        archive: 'Archive vacancy',
      },
      aboutPosition: {
        title: 'About Position',
      },
      pageDetails: {
        title: 'Vacancy',
        meta: {
          title: (value: string): string => `${value}| LF.Group`,
          og: {
            title: (value: string, value2: string): string =>
              `${value} ${value2} Looking for players | LF.Group`,
            gameTitle: (
              value: string,
              value2: string,
              value3: string
            ): string =>
              `${value} ${value2} in ${value3} Looking for players | LF.Group`,
          },
        },
        form: {
          title: 'Apply now',
          description: {
            placeholder: 'Tell about yourself',
          },
          apply: {
            title: 'Apply →',
          },
        },
        alreadyApplied: {
          title: 'Your application has been submitted!',
          description:
            'Our Discord bot will send you a message once the community leader reviews your application. Make sure to stay a member of LF.Group server and allow direct messages from non-friends.',
        },
      },
      pageApplications: {
        title: 'Applications',
        meta: {
          title: (value: string): string => `${value} Applications | LF.Group`,
          og: {
            title: (value: string): string => `${value} | LF.Group`,
          },
        },
        emptyState: {
          title: 'No applications yet',
          description: 'You will see all applications here',
        },
      },
      createModal: {
        title: {
          create: 'New position',
          update: 'Edit position',
        },
        action: {
          submit: {
            create: 'Create →',
            update: 'Update →',
          },
        },
        input: {
          title: {
            label: 'Title',
          },
          description: {
            label: 'Description',
          },
        },
        sectionRequirements: {
          title: 'Requirements',
        },
      },
    },
    preview: {
      notFound: 'Not found',
      search: {
        error: 'Cant load search',
      },
      profile: {
        error: 'Cant load profile',
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'followers'
              : amount > 1
              ? 'followers'
              : 'follower'
          }`,
      },
      organization: {
        members: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'members'
              : amount > 1
              ? 'members'
              : 'member'
          }`,
      },
      group: {
        error: 'Cant load group',
      },
      vacancy: {
        lookingForPlayers: 'Looking for players',
        activity: 'Activity',
        type: 'Type',
        lookingFor: 'Looking for',
      },
      game: {
        error: 'Cant load game',
        groups: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'groups'
              : amount > 1
              ? 'groups'
              : 'group'
          }`,
        allPlayers: (amount: number) =>
          `More than ${amount} ${
            amount > 5 || amount === 0
              ? 'players'
              : amount > 1
              ? 'players'
              : 'player'
          }`,
        players: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'players'
              : amount > 1
              ? 'players'
              : 'player'
          }`,
        findTeammate: 'Find a teammate for',
        lfg: {
          title: 'All in one solution \nfor gaming communities',
          allPlayers: (amount: number) =>
            `More than ${amount} ${
              amount > 5 || amount === 0
                ? 'players'
                : amount > 1
                ? 'players'
                : 'player'
            } worldwide`,
          players: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'players'
                : amount > 1
                ? 'players'
                : 'player'
            } worldwide`,
          backedBy: '$1M backed by GrishinRobotics',
        },
        wildrift: {
          win: (value: string): string => `Win ${value}`,
          kda: 'KDA',
          battles: (amount: number) => `${amount} Ranked Battles`,
          gMin: 'G/Min',
          games: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'Games'
                : amount > 1
                ? 'Game'
                : 'Game'
            }`,
        },
      },
    },
    pubgLadder: {
      pageTitle: 'PUBG Leaderboard',
      title: 'Leaderboard | LF.Group',
      meta: {
        title: 'PUBG Leaderboard | LF.Group',
        description:
          'Join our PUBG Mobile custom rooms leaderboard for a chance to win a share of 15,000 UC prizes. Show off your skills, beat the competition, and take home the ultimate prize!',
        og: {
          title: 'PUBG Mobile custom rooms Leaderboard',
          description:
            'Join our PUBG Mobile custom rooms leaderboard for a chance to win a share of 15,000 UC prizes. Show off your skills, beat the competition, and take home the ultimate prize!',
          image: 'https://preview.lf.group/search/pubg-mobile',
        },
      },
      table: {
        user: 'User',
        prize: 'Prize',
        points: 'Points',
      },
      info: {
        titleDesktop:
          'Join verified streamersʼ custom rooms on LF.Group and compete for a prize of 15 000 UC points.',
        descriptionDesktop:
          'Check out the participation rules and get ready to show off your skills!',
        rulesAction: 'What are the rules?!',
        previousWeekResults: 'Previous biweekly results',
      },
    },
    pubgRules: {
      title: 'Leaderboard rules & FAQ | LF.Group',
      ogTitle: 'PUBG Mobile custom rooms Leaderboard',
      ogDescription:
        'Join our PUBG Mobile custom rooms leaderboard for a chance to win a share of 15,000 UC in biweekly prizes. Show off your skills, beat the competition, and take home the ultimate prize!',
      ogImage: 'https://preview.lf.group/search/pubg-mobile',
      goToLeaderboard: 'Go to leaderboard',
      joinParagraph:
        'Join the PUBG Mobile Custom Rooms Leaderboard and compete to win biweekly prizes up to 15 000 UC! All you have to do is play in custom rooms created by verified streamers from LF.Group to earn points and climb the leaderboard.',
      prizesParagraph: 'The prizes are distributed as follows:',
      prize1st: '1st place: 1950 UC',
      prize2nd: '2nd to 5th places: 975 UC',
      prize6th: '6th to 10th places: 650 UC',
      prize11th: '11th to 20th places: 325 UC',
      prize21st: '21st to 50th places: 60 UC',
      eventsCalendar: 'PUBG Events Calendar',
      paragraph4:
        'Kindly note that you can receive 25 points for winning a custom room only once per day, per streamer. As example: If you emerge victorious in three consecutive custom rooms during a single streamer daily event, you will still only be rewarded with 25 points. However, if you manage to win three custom rooms in one day, with each win coming from a different streamer, then you will receive a total of 75 points.',
      paragraph5p1:
        'If you aim to dominate the leaderboard, improve your ranking, and earn more UC points, kindly refer to our',
      paragraph5p2: 'and take part in the various events we have available.',
      paragraph3:
        'Earning points to climb the leaderboard is easy. Simply play in custom rooms created by LF.Group verified streamers and complete the following actions:',
      action1:
        'Verified Event Participation: Join an in-game custom room created by a verified streamer and receive 1 point for participation. We will use the API to confirm that you have joined the custom room in-game and award your profile with one point.',
      action2:
        'Custom Room Victory: Win a game in a custom room created by a verified streamer and earn 25 points. Every day, we receive the list of winners from verified streamers and update the points of the victorious players.',
      paragraph6:
        'On every second Wednesday, we will temporarily freeze the leaderboard and archive the current standings for historical reference. The prizes for the winners will be distributed over the next ten days by topping up their accounts.',
      question1:
        'Q: I won several custom rooms created by verified streamers, but I donʼt see any received points for it.',
      answer1:
        'A: You need to message the streamer directly and find out if they provided your profile as a winner to us. Sometimes, it may take a couple of days to get an updated score if the streamer does not send us new data immediately after games.',
      question2: 'Q: How do I get the prize?',
      answer2:
        'A: We have your profile, which should include your PUBG ID. We will top up your PUBG ID directly with UC points.',
      question3:
        'Q: How many custom rooms can I attend per day to increase my chances?',
      answer3:
        'A: You can participate in any amount of custom rooms per day, but be aware that we check if you pressed the “Play” button to participate in an actual in-game custom room. Simply joining the event will not award you with 1 point. However, if you participate by copying the lobby ID & password and pressing the “Play” button, you will be awarded with 1 point.',
      question4:
        'Q: I am a streamer and want my viewers to participate in the leaderboard. How do I do it?',
      answer4:
        'A: We welcome new streamers and are happy to help them increase their live numbers. Pakistan streamers can message us via Discord to Pablo671#6613 and Nepalese streamers can message us via Discord to Gothiques#7156. Once you become our verified partner, you will be able to run official LF.Group custom rooms, award your winners with UC prizes, and enable them to participate in the Custom Rooms Leaderboard.',
    },
    referal: {
      title: 'Invite friends and get rewards!',
      friendsInvited: (amount: number) =>
        `${amount} ${
          amount > 5 || amount === 0
            ? 'friends'
            : amount > 1
            ? 'friends'
            : 'friend'
        } invited`,
      shareWithFriends: 'Share with friends',
      rules: 'Rules',
      inviteLinkCopied: 'Invite link copied',
      inviteNewMembers: 'Invite new members',
      toIviteFriends: 'to invite friends',
      copyLink: 'Copy link',
      gifts: {
        three: 'Masterwork Chest and Key Bundle',
        six: 'Mystery Skin',
        ten: 'Mystery Chest',
        twenty: 'Get any legendary skin of your choice!',
        fifty: '5750+ Riot Points gift',
      },
      pubg: {
        title: 'Invite friends and get Leaderboard points!',
        gifts: {
          one: '5 points',
          three: '10 points',
          five: '15 points',
        },
      },
    },
    rightColumn: {
      banner: {
        title: 'Need people to play with?',
        description:
          'LF.Group can help you to find the right people to play with. You can join any group or create your own',
        action: 'Create a group',
      },
      addCommentBanner: {
        lookingForTeam: 'Looking for team',
        newFeature: 'New feature',
        title: 'Describe yourself',
        description:
          'Express your personal preferences: activities, playtime, etc. so it is easier to connect with others!',
      },
      connectDiscrod: {
        title: 'Personal notifications',
        description:
          'Add discord to get updates about new giveaways and events',
        authDescription:
          'Log in via discord to get updates about new giveaways and events',
        action: 'Add Discord',
      },
      suggestions: {
        title: 'Who to follow',
        description: 'Subscribe to streamers and take part in events',
        onboardingDescription: (value: string): string =>
          `Subscribe to at least ${value} streamers and take part in events`,
      },
    },
    teamsFeed: {
      title: (value: string): string => `${value} Teams`,
      meta: {
        title: (value: string): string => `${value} Teams`,
        og: {
          title: (value: string, value2: string): string =>
            `${value} Teams - Recruitment tool for Teams in ${value2} | LF.Group`,
          description:
            'Create your own Guild page with full recruitment toolkit. Create vacancies and search for players who match to your in-game goals. Discord notifications. Try it out!',
        },
      },
      emptyState: {
        title: 'No teams match your request',
        description: 'Try another search to find all active teams',
      },
    },
    user: {
      meta: {
        title: (value: string): string =>
          `${value} gaming profile. Looking for play with | LF.Group`,
        descriptionWithGames: (value: string, value2: string): string =>
          `${value} gaming profile. Looking for play with in ${value2}.`,
        description: (value: string): string =>
          `${value} gaming profile. Looking for play with.`,
      },
      emptyState: {
        noProfiles: {
          description: (value: string): string =>
            `${value} does not have a gaming profile yet.`,
          descriptionPersonal:
            'You do not have a gaming profile yet. Add one now!',
          action: 'Add profile +',
        },
        noEvents: {
          description: (value: string): string => `${value} has no events yet.`,
          descriptionPersonal: 'You have no events yet.',
          action: 'Find events',
        },
      },
      gameCardMeta: {
        title: (value: string, value2: string): string =>
          `${value} is searching for teammates in ${value2} | LF Group`,
        description: (value: string, value2: string): string =>
          `Click the link to inspect ${value}’s profile in ${value2}.At LF.Group you can find player’s groups, calendar, Discord and in-game IDs.`,
      },
      followingMeta: {
        title: (value: string): string =>
          `Player ${value}: Subscriptions | LF Group`,
        routeTitle: (value: string): string => `${value} following`,
        description: (value: string): string =>
          `See what other players ${value} is following. Maybe you will find some new friends there?`,
      },
      followersMeta: {
        title: (value: string): string =>
          `Player ${value}: Followers | LF Group`,
        routeTitle: (value: string): string => `${value} followers`,
        description: (value: string, value2: string): string =>
          `Here you may see players who follow ${value}. Click the link and see if ${value2} is a super star!`,
      },
      navigation: {
        calendar: 'Calendar',
        invites: 'Invites',
        visitors: 'Visitors',
        links: 'Player wiki',
        games: 'Games',
        about: 'Player profile',
        events: 'Events',
        history: 'History',
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'followers'
              : amount > 1
              ? 'followers'
              : 'follower'
          }`,
        followingWithoutCount: 'Following',
        following: (amount: number) => `${amount} following`,
        userFollowing: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0 ? 'users' : amount > 1 ? 'users' : 'user'
          }`,
        leaderboard: 'Leaderboard',
        settings: 'Settings',
        logout: 'Logout',
        addFirstGame: 'Add your first game',
      },
      playing: 'Playing',
      social: 'Social',
      logoutModal: {
        text: 'Sure you want to log out?',
      },
      metaHistory: {
        title: (value: string): string =>
          `Player ${value}: Finished games | LF Group`,
        description: (value: string): string =>
          `Here you may find everything about ${value} profile on LF.Group. Make sure to follow him if you like!`,
      },
      errors: {
        404: {
          text: 'User not found',
          action: 'Go home',
        },
        noFutureEvents: 'User has no planned events',
        noPastEvents: 'No events played yet',
        errorNoFollowing: 'User does not follow others',
        errorNoFollows: 'User has no followers',
        errorNoVisitors: 'You have no visitors',
      },
      selectedGames: {
        title: 'Selected games',
      },
      mobile: {
        playing: 'I am playing',
        myLinks: 'My links',
      },
      unfollowConfirmModal: {
        title: (value: string): string =>
          `Are you sure you want to unfollow ${value}?`,
        yes: 'Unfollow',
        no: 'Cancel',
      },
    },
    userGames: {
      meta: {
        title: (value: string): string =>
          `Player ${value}: Favorite games | LF Group`,
        routeTitle: (value: string): string => `${value} games`,
        description: (value: string): string =>
          `See what games ${value} plays. Maybe you can be a good team?`,
      },
      inviteModal: {
        errorNoGroup: (value: string): string =>
          `Create a group to invite ${value}`,
        toastUserInvited: (value: string): string =>
          `${value} is invited to the group`,
      },
      sectionTitles: {
        myGames: 'My Games',
        myLinks: 'My Links',
        events: 'Events',
      },
      card: {
        actionInvite: 'Invite',
        toastLinkCopied: 'Link is copied 👌',
        toastLoginCopied: 'Login is copied 👌',
        toastInviteCopied: 'Invite is copied 👌',
        write: 'Let’s play',
        wildrift: {
          winRate: (value: string): string => `${value}% win rate`,
          rankedGoldPerMinute: 'Gold/min',
          rank: 'Rank',
        },
        wow: {
          arena: (value: string): string => `Arena ${value}`,
        },
        lostArk: {
          legacyLvl: (value: string): string => `Roster lvl ${value}`,
        },
        user: {
          he: 'he',
          she: 'she',
          age: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'years'
                : amount > 1
                ? 'years'
                : 'year'
            } old`,
        },
      },
      createCharModal: {
        title: 'Add a new account',
        game: {
          title: 'Game',
          placeholder: 'choose a game',
        },
        errorAlreadyExists: (value: string): string =>
          `You already have a ${value} account`,
        errorNoSocialAccount: (value: string): string => `Connect ${value}`,
      },
      createGame: {
        errors: {
          errorRequiredField: 'Required field',
          errorSymbolLimitExceeded: (value: string): string =>
            `More than ${value} characters entered`,
          errorMaxNumberLimitExceeded: (value: string): string =>
            `Max value – ${value}`,
          errorMinNumberLimitExceeded: (value: string): string =>
            `Min value – ${value}`,
        },
        infos: {
          symbolAvailability: (amount: number) =>
            `${amount} ${
              amount > 5 || amount === 0
                ? 'symbols'
                : amount > 1
                ? 'symbols'
                : 'symbol'
            } remaining`,
        },
      },
      deleteGameModal: {
        title: 'Are you sure you want to delete the game?',
        deleteAction: 'Delete',
      },
      newGameModal: {
        title: 'New account',
        editTitle: 'Edit account',
        gameTitle: 'Title',
        errorRequredFields: 'Fill in the mandatory fields',
      },
    },
    userInvites: {
      meta: {
        title: 'Invites | LF Group',
      },
      errors: {
        errorNoInvites: 'No invites just now',
      },
      invniteModal: {
        toastSuccess: 'Invite sent',
        titleGroup: 'Choose your group',
        titleSlots: 'Choose a slot',
      },
      toastAcceptInvite: 'The group has been added to your calendar',
      toastDeleteInvite: 'Group deleted from your calendar',
    },
    userLinks: {
      meta: {
        title: 'Links | LF Group',
      },
      errorNoLinks: 'No links here just yet',
      addFirstAction: 'No links here just yet. Click to add your first',
      addLinkAction: 'Add new link',
      createAction: 'Create',
    },
    userNotifications: {
      meta: {
        title: 'Notifications | LF Group',
      },
      errors: {
        errorNoNotifications: 'No notifications just now',
      },
      tabs: {
        groups: 'Groups',
        all: 'All',
        following: 'Following',
        invites: 'Invites',
      },
      action: {
        accept: 'Accept',
        decline: 'Decline',
      },
      descriptions: {
        startFollow: (value: string): string =>
          `started following you. ${value}`,
        invite: (value: string, value2: string, value3: string): string =>
          `${value} your invite to "${value2}". ${value3}`,
        createdGroup: (value: string, value2: string): string =>
          `create a new group for ${value}. ${value2}`,
      },
    },
    userSearch: {
      inviteModal: {
        inviteUserToGroup: (value: string): string => `${value} is invited`,
        errorAuth: 'auth required',
        noGroupsOwned: (value: string): string =>
          `There are no groups you can invite ${value} to`,
      },
      meta: {
        routeTitle: 'All players',
        title: 'Find a player to become your teammate | LF Group',
        description:
          'Search for any player registered on LF.Group on this page. Play with filters and browse your potential teammates!',
      },
      filters: {
        inputPlaceholder: 'username',
        errorMinLength: 'Enter 3 symbols at least',
        hasBattlenet: 'Has Battletag',
      },
      noUsersFound: 'No users matching your search',
      card: {
        followers: (amount: number) =>
          `${amount} ${
            amount > 5 || amount === 0
              ? 'followers'
              : amount > 1
              ? 'followers'
              : 'follower'
          }`,
        following: (amount: number) => `${amount} following`,
      },
      gameCards: {
        characters: 'Characters',
        class: 'Class',
        wow: {
          raid: 'Raid',
        },
        redirectToMore: (amount: number) =>
          `& ${amount} more ${
            amount > 5 || amount === 0
              ? 'characters'
              : amount > 1
              ? 'characters'
              : 'character'
          } on the user page`,
        wildRift: {
          champions: 'Champions',
          rank: 'Rank',
          playing: 'Playing',
          kda: 'Average KDA',
          winRate: 'Winrate',
        },
        lostArk: {
          gearScore: 'ilvl',
          builds: 'Builds',
        },
      },
    },
    userSettings: {
      page: {
        title: 'Settings',
        copyLink: 'Copy link',
        actionSave: 'Save changes',
        actionMobileSave: 'Save',
        changeRegionAction: 'Change content region',
        write: 'Let’s play',
        tabs: {
          user: 'User',
          games: 'Games',
          links: 'Links',
          server: 'Server',
          notification: 'Notifications',
          privacy: 'Privacy',
        },
      },
      regionModal: {
        title: 'Let us check your region',
        description:
          'We need to know where you are, so we can show you players and communities from your region.',
        action: 'Lets Gooo!',
        select: {
          label: 'Region',
          values: {
            en: 'World',
            ru: 'CIS',
            in: 'India 🇮🇳',
            pk: 'Pakistan 🇵🇰',
            sea: 'South East Asia (SEA)',
          },
        },
      },
      languageModal: {
        title: 'Choose language',
        action: 'Lets Gooo!',
        select: {
          label: 'Language',
        },
      },
      commonSettingsModal: {
        changeLanguage: 'Change language',
        changeRegion: 'Change region',
      },
      meta: {
        title: 'Profile settings | LF Group',
      },
      links: {
        addNewButton: {
          action: 'Add link',
          title: 'New link',
        },
        title: {
          placeholder: 'My favorite site',
        },
        link: {
          placeholder: 'https://lf.group',
        },
        description: {
          placeholder: 'Description (optional)',
        },
      },
      connectedServices: {
        title: 'Connected accounts',
      },
      primary: {
        title: 'Primary settings',
        username: 'Username',
      },
      botSettings: {
        title: 'Bot settings',
      },
      user: {
        settings: 'Settings',
        cancel: 'Cancel',
        deleteAccount: {
          title: 'Delete my account',
          logout: 'Delete my account',
          confirmLogout: 'Are you sure you want to delete this account?',
        },
        logout: {
          title: 'Log out',
          logout: 'Log out',
          confirmLogout: 'Are you sure you want to log out?',
        },
        exitFromSettings: {
          exit: 'Exit',
          confirmExit:
            'You have unsaved changes. Are you sure you want to exit?',
        },
        account: {
          title: 'Account',
          username: 'Username',
          email: 'Email',
          displayName: 'Display Name',
          description: 'Player profile',
        },
        connectedAccounts: {
          title: 'Connected Accounts',
          connected: 'Connected',
          connect: 'Connect',
          disconnect: 'Disconnect',
        },
        preferences: {
          title: 'Preferences',
          language: 'Language',
        },
      },
      server: {
        serverName: 'Server name',
        limits: {
          title: 'Limits',
          description:
            'Brief limits description. Users can create events in these channels only',
        },
      },
      notifications: {
        description:
          'We will always let you know about important changes, but you are free to select what else you want to hear about.',
        browser: {
          title: 'Browser notifications',
          newInvite: 'New invites',
          newJoiner: 'New players',
          newFollower: 'New followers',
          requestApproved: 'Request approved',
          description:
            'Allow LF.Group to send you browser notifications to stay connected with friends',
        },
        email: {
          title: 'Email notifications',
          newInvite: 'New invites',
          newJoiner: 'New players',
          newFollower: 'New followesr',
          requestApproved: 'Request approved',
          description:
            'Allow LF.Group to send you email notifications to stay connected with friends',
        },
      },
      privacy: {
        everyone: 'Everyone',
        onlyFollowers: 'Only followers',
        labels: {
          whoCanInviteMe: 'Who can invite me',
          whoCanJoinMe: 'Who can join me',
          whoCanSeeMyGames: 'Who can see my games',
          whoCanSeeMySocialAccounts: 'Who can see my socials',
        },
        groups: {
          title: 'Groups',
        },
        content: {
          title: 'Content',
        },
      },
      errors: {
        invalid: 'Some fields are incorrect',
        loginTaken: 'Username taken',
      },
      toastSuccess: 'Settings updated',
    },
    vacancyFeed: {
      title: (value: string): string => `${value} Guilds Vacancies`,
      meta: {
        title: (value: string): string => `${value} Guilds Vacancies`,
        og: {
          title: (value: string, value2: string): string =>
            `${value} Guilds - Looking for Guild in ${value2} | LF.Group`,
          description: (value: string): string =>
            `Find new guild and achieve your goals in ${value}. Search for guilds who match your in-game goals and available time to play. Website + Discord bot. Try it out!`,
        },
      },
      emptyState: {
        title: 'No vacancies match your request',
        description: 'Try another search to find all active vacancies',
      },
      card: {
        requests: (value: string): string => `${value} requests`,
        actions: {
          view: 'View',
          apply: 'Apply',
        },
      },
    },
    welcome: {
      pageTitle: 'Your personal gaming calendar.',
      pageDescription:
        'LF.Group is designed to help you quickly find players, communities and interesting activities.',
      noGameDescription: 'Check what is \nhappening right now',
      header: {
        events: 'Events',
        players: 'Players',
        teams: 'Teams',
        vacancies: 'Vacancies',
      },
      meta: {
        title: 'Find new teammates and gaming activities on LF.Group',
        description:
          'Find new teammates, communities and activities in different games. Search for players and vacancies that match your in-game goals, skill level and available time to play!',
        wow: {
          title: (value: string): string =>
            `WoW ${value} (Group Finder) - Looking for Group in World of Warcraft | LF.Group`,
          description:
            'Find new teammates, guilds and communities in WoW. Search for players and vacancies that match your in-game goals, skill level and available time to play!',
        },
        lol: {
          title: (value: string): string =>
            `LoL ${value} - League of Legends Looking for Group / Team Finder | LF.Group`,
          description:
            'Find new teammates and teams in LoL. Search for players that match your in-game goals, skill level and available time to play!',
        },
        la: {
          title: (value: string): string =>
            `Lost Ark ${value} (Group Finder) - Looking for Group in Lost Ark | LF.Group`,
          description:
            'Find new teammates, guilds and statics in Lost Ark. Search for players and communities that match your in-game goals, skill level and available time to play!',
        },
        wr: {
          title: (value: string): string =>
            `LoL Wild Rift ${value} - Wild Rift Looking for Group / Team Finder | LF.Group`,
          description:
            'Find new teammates and teams in LoL Wild Rift. Search for players that match your in-game goals, skill level and available time to play!',
        },
        pbgm: {
          title: (value: string): string =>
            `PUBG Mobile ${value} - PUBG Mobile Кастомки, турниры, праки / Поиск команды и игроков`,
          description:
            'Все про PUBG Mobile в одном месте. Подходящие фильтры и цели помогут выбрать крутых тиммейтов. Отправляйся в раздел «Мероприятия» и принимай участие в соревнованиях от лучших турнирных организаторов',
        },
        cod: {
          title: (value: string): string =>
            `COD MOBILE ${value} - COD MOBILE Кастомки, турниры, праки / Поиск команды и игроков`,
          description:
            'Все про COD MOBILE в одном месте. Подходящие фильтры и цели помогут выбрать крутых тиммейтов. Отправляйся в раздел «Мероприятия» и принимай участие в соревнованиях от лучших турнирных организаторов',
        },
        hs: {
          title: '',
          description: '',
        },
        csgo: {
          title: '',
          description: '',
        },
      },
      features: {
        default: {
          title: 'Features for players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create communities',
            description:
              'Customize your page, manage calendar and find new players. Notify your members via Discord DM about important events',
          },
          point3: {
            title: 'Discover new activities',
            description:
              'Gather groups with friends, join community events, see what tournaments and streams are going on and much more!',
          },
        },
        wow: {
          title: 'Features for WoW players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create communities',
            description:
              'Running a guild? Static group? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows guilds to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        lol: {
          title: 'Features for LoL players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create and manage teams',
            description:
              'Looking for semi-pro players to join your team? Creating a serious Clash community? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows teams to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        la: {
          title: 'Features for Lost Ark players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create communities',
            description:
              'Running a guild? Static group? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows guilds to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        wr: {
          title: 'Features for Wild Rift players',
          point1: {
            title: 'Find teammates',
            description:
              'Use filters, tags and text description to find your perfect match. Message them directly on the website',
          },
          point2: {
            title: 'Create and manage teams',
            description:
              'Looking for semi-pro players to join your team? Use LF.Group to customize your page, add events and hire players',
          },
          point3: {
            title: 'Apply for vacancies',
            description:
              'Powerful recruiting toolkit allows teams to handle all vacancies: description, requirements, questions and chat with candidates. All in one place!',
          },
        },
        pbgm: {
          title: 'Features for PUBG Mobile players',
          point1: {
            title: 'Team Search',
            description:
              'New way to search for teammates, use filters, tags and text description to find the right players',
          },
          point2: {
            title: 'Create a community',
            description:
              'Are you a manager or team captain? Do you host tournaments or broadcasts? Use LF.GROUP to create your community page: manage training sessions and team calendar, find players, organize a competition or announce a broadcast',
          },
          point3: {
            title: 'Jobs',
            description:
              'A powerful set of job tools allows teams to process applications based on criteria: rank, region, K/D, age, tournament achievements and more! Create a team page, accept applications and ask questions to candidates',
          },
          point4: {
            title: 'Events',
            description:
              'Daily PUBG Mobile competitions. Participate in custom and esports tournaments alone or with your team',
          },
        },
        hs: {
          title: '',
          point1: {
            title: '',
            description: '',
          },
          point2: {
            title: '',
            description: '',
          },
          point3: {
            title: '',
            description: '',
          },
        },
        csgo: {
          title: '',
          point1: {
            title: '',
            description: '',
          },
          point2: {
            title: '',
            description: '',
          },
          point3: {
            title: '',
            description: '',
          },
        },
      },
      sections: {
        updatesTitle: 'Latest updates',
        features: 'LFG has amazing features',
        soon: 'Coming soon',
        howToCreateGroup: 'How to create a group?',
        groupsAndPlayers: (value: string, value2: string): string =>
          `${value} groups & ${value2} players`,
        players: (value: string): string => `${value} players`,
        groupDescription:
          'Create a group in a few clicks and sync with your new teammates via webchat',
        discordDescription:
          'Set up your profile once and use it both on LF.Group website and all connected partnered Discords',
        recruitingDescription:
          'Recruiting toolkit for everyone! Players will be able to search for a suitable static or guild',
      },
      auth: {
        title: 'Set up your LF.Group profile',
        wow: {
          description:
            'Sign in with Battle.net and all your characters will be automatically uploaded. Match easily with suitable teammates!',
        },
        lol: {
          description:
            'Sign in via Riot Games token and your general stats will be automatically uploaded. Match easily with suitable teammates!',
        },
        la: {
          description:
            'Sign in and specify your experience, interests and character details. Match easily with suitable teammates!',
        },
        wr: {
          description:
            'Sign in via Riot Games token and your general stats will be automatically uploaded. Match easily with suitable teammates!',
        },
        pbgm: {
          description:
            'Register on the site in two clicks and set up your profile from the proposed parameters. Your victories are near!',
        },
      },
      motivation: {
        title:
          'Join LF.Group to find interesting activities, players and friends',
        action: 'Join now',
      },
      tabs: {
        events: 'Events',
        players: 'Players',
        guilds: 'Guilds',
        teams: 'Teams',
        vacancies: 'Vacancies',
      },
    },
  },
};

export type TranslationsMap = typeof translations;
export type Translations = typeof translations['english'];
