import React from 'react';

import Head from 'next/head';
import Script from 'next/script';

import * as gtag from 'analyticsHelpers';
import { ModalProvider } from 'components/ContextModal';

import { HreflangMap } from '../Language/HreflangMap';
import { ToastProvider } from '../Toast';

interface AppContent {
  children: React.ReactNode;

  disableScripts: boolean;
  isMobile: boolean;
}

export const AppContent: React.FC<AppContent> = ({
  children,
  disableScripts,
  isMobile,
}) => {
  if (disableScripts) {
    return <>{children}</>;
  }

  // Iubenda is a cookie consent banner provider
  // dont renderiubenda on mobile app & in development
  const renderIubenda = () => {
    if (
      process.env.NEXT_PUBLIC_MOBILE_APP === 'true' ||
      process.env.NODE_ENV === 'development'
    ) {
      return null;
    }

    return (
      <>
        <Script id="cookie-script">
          {`var _iub = _iub || []; _iub.csConfiguration = {"ccpaAcknowledgeOnDisplay":true,"consentOnContinuedBrowsing":false,"countryDetection":true,"enableCcpa":true,"floatingPreferencesButtonDisplay":"anchored-center-right","gdprAppliesGlobally":false,"invalidateConsentWithoutLog":true,"lang":"en","perPurposeConsent":true,"siteId":2418043,"whitelabel":false,"cookiePolicyId":65660123, "banner":{ "acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"bottom","rejectButtonDisplay":true }};`}
        </Script>
        <Script src="https://cdn.iubenda.com/cs/ccpa/stub.js" />
        <Script async src="https://cdn.iubenda.com/cs/iubenda_cs.js" />
      </>
    );
  };

  return (
    <ToastProvider>
      <ModalProvider>
        <>
          {renderIubenda()}
          {process.env.NODE_ENV !== 'development' && (
            <>
              {/*Google Tag Manager (Google Analytics)*/}
              <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${gtag.TRACKING_ID}`}
              />
              <Script id="gtag-script">
                {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtag.TRACKING_ID}')`}
              </Script>
              {/* Ya.Metrika */}
              <Script id="ya-metrika-script">
                {`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
          ym(87540063, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:false });`}
              </Script>
              <noscript>
                <iframe
                  height="0"
                  src="https://www.googletagmanager.com/ns.html?id=GTM-NBPSFGG"
                  style={{ display: 'none', visibility: 'hidden' }}
                  width="0"
                />
              </noscript>
              <noscript>
                <div>
                  <img
                    alt=""
                    src="https://mc.yandex.ru/watch/87540063"
                    style={{ position: 'absolute', left: -9999 }}
                  />
                </div>
              </noscript>
            </>
          )}

          <HreflangMap />

          <Head>
            <title>LFG — find your team</title>
            {isMobile ? (
              <meta
                content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
                id="lfg-viewport"
                name="viewport"
              />
            ) : (
              <meta
                content="width=1280, initial-scale=1.0"
                id="lfg-viewport"
                name="viewport"
              />
            )}
            <meta content="strict-origin" name="referrer" />
            <meta content="e65f6d20233c52df" name="yandex-verification" />
            <meta
              content="f4b82996d0e50dacab674050a37dffce0aca5339"
              name="naver-site-verification"
            />
          </Head>

          {children}
        </>
      </ModalProvider>
    </ToastProvider>
  );
};
