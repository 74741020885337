import { ParsedUrlQuery } from 'querystring';

import * as api from 'graphql/generated/index';

import { QUERY_KEY_GAME_SLUG } from './url/pageIndex';

import type { SupportedGameId } from 'api/types';
import type { UrlObject } from 'url';

export const QUERY_KEY_IGNORE_WELCOME = 'no_welcome';

export const GAME_SLUGS: Record<SupportedGameId, string> = {
  WorldOfWarcraft: 'wow',
  LeagueOfLegends: 'lol',
  LostArkEn: 'lost-ark',
  CSGO: 'csgo',
  Hearthstone: 'hs',
  PUBGMobile: 'pubg-mobile',
  CODMobile: 'cod-mobile',
  Standoff2: 'standoff2',
  GooseGooseDuck: 'ggd',
  ShadowFight3: 'shadow-fight-3',
  ShadowFight4Arena: 'shadow-fight-4-arena',
  DawnOfZombiesSurvival: 'dawn-of-zombies-survival',
  Crossfire: 'crossout-mobile',
  NewStateMobile: 'new-state-mobile',
  RiseOfEmpires: 'rise-of-empires',
  ModernStrikeOnlinePvPFPS: 'modern-strike',
  RiseOfKingdomsLostCrusade: 'rise-of-kingdoms',
  IdleHeroes: 'idle-heroes',
  LastDayOnEarthSurvival: 'last-day-on-earth-survival',
  MobileLegendsAdventure: 'mobile-legends-adventure',
  STALKER2HeartOfChornobyl: 'stalker-2-hoc',
  Highrise: 'highrise',
  AFKArena: 'afk-arena',
  Custom: 'other',
};

export function getIsOnboardingCompletedKey(game: api.Maybe<api.GameId>): string {
  if (!game) {
    return 'lfg/onboarding-completed';
  }

  return `lfg/onboarding-completed/${game}`;
}

export function getGameSlugPath(
  gameId: api.GameId,
  mode?: api.GameMode,
): { path: string | undefined; deleteModeQuery?: boolean } {
  const redirects = gameRedirects.filter(redirect => redirect?.gameId === gameId);

  const redirect = redirects[0];

  if (mode) {
    const modeRedirect = redirects.find(redirect => redirect.gameMode === mode);

    if (modeRedirect) {
      return { path: modeRedirect.source, deleteModeQuery: true };
    }

    return { path: redirect?.source, deleteModeQuery: false };
  }

  return { path: redirect?.source, deleteModeQuery: true };
}

export interface GameRedirect {
  source: string;
  gameId?: SupportedGameId;
  gameMode?: api.GameMode;
}

/**
 * !IMPORTANT
 * After changing this you need to reinit NextJS balancer.
 *
 * Run `ts-node scripts/game_redirects.ts` and follow instructions
 */
export const gameRedirects: GameRedirect[] = [
  { source: 'lol', gameId: api.GameId.LeagueOfLegends },
  {
    source: 'lol/aram',
    gameId: api.GameId.LeagueOfLegends,
    gameMode: api.GameMode.LolAram,
  },
  {
    gameId: api.GameId.Hearthstone,
    source: 'hs',
  },
  {
    gameId: api.GameId.Csgo,
    source: 'csgo',
  },
  {
    gameId: api.GameId.Custom,
    source: 'other',
  },
  {
    gameId: api.GameId.PubgMobile,
    source: 'pubg-mobile',
  },
  {
    gameId: api.GameId.CodMobile,
    source: 'cod-mobile',
  },
  {
    gameId: api.GameId.Standoff2,
    source: 'standoff2',
  },
  {
    gameId: api.GameId.GooseGooseDuck,
    source: 'ggd',
  },
  { gameId: api.GameId.ShadowFight3, source: 'shadow-fight-3' },
  { gameId: api.GameId.ShadowFight4Arena, source: 'shadow-fight-4-arena' },
  { gameId: api.GameId.DawnOfZombiesSurvival, source: 'dawn-of-zombies-survival' },
  { gameId: api.GameId.Crossfire, source: 'crossout-mobile' },
  { gameId: api.GameId.NewStateMobile, source: 'new-state-mobile' },
  { gameId: api.GameId.RiseOfEmpires, source: 'rise-of-empires' },
  {
    gameId: api.GameId.ModernStrikeOnlinePvPfps,
    source: 'modern-strike',
  },
  {
    gameId: api.GameId.RiseOfKingdomsLostCrusade,
    source: 'rise-of-kingdoms',
  },
  { gameId: api.GameId.IdleHeroes, source: 'idle-heroes' },
  { gameId: api.GameId.LastDayOnEarthSurvival, source: 'last-day-on-earth-survival' },
  { gameId: api.GameId.MobileLegendsAdventure, source: 'mobile-legends-adventure' },
  { gameId: api.GameId.Stalker2HeartOfChornobyl, source: 'stalker-2-hoc' },
  { gameId: api.GameId.Highrise, source: 'highrise' },
  { gameId: api.GameId.AfkArena, source: 'afk-arena' },
  {
    source: 'lol/duo',
    gameId: api.GameId.LeagueOfLegends,
    gameMode: api.GameMode.LolDuo,
  },
  {
    source: 'lol/clash',
    gameId: api.GameId.LeagueOfLegends,
    gameMode: api.GameMode.LolClash,
  },
  {
    source: 'lol/featured',
    gameId: api.GameId.LeagueOfLegends,
    gameMode: api.GameMode.LolFeatured,
  },
  {
    source: 'lol/normal',
    gameId: api.GameId.LeagueOfLegends,
    gameMode: api.GameMode.LolNormal,
  },
  {
    source: 'lol/5v5',
    gameId: api.GameId.LeagueOfLegends,
    gameMode: api.GameMode.LolCustom,
  },
  {
    source: 'lol/5v5-aram',
    gameId: api.GameId.LeagueOfLegends,
    gameMode: api.GameMode.LolCustomAram,
  },

  { source: 'wow', gameId: api.GameId.WorldOfWarcraft },
  { source: 'wow-lp', gameId: api.GameId.WorldOfWarcraft },
  { source: 'wow-tbc', gameId: api.GameId.WorldOfWarcraft },
  {
    source: 'wow/mythic-plus',
    gameId: api.GameId.WorldOfWarcraft,
    gameMode: api.GameMode.WowMythicPlus,
  },
  {
    source: 'wow/raid',
    gameId: api.GameId.WorldOfWarcraft,
    gameMode: api.GameMode.WowRaid,
  },
  {
    source: 'wow/rbg',
    gameId: api.GameId.WorldOfWarcraft,
    gameMode: api.GameMode.WowRbg,
  },
  {
    source: 'wow/custom',
    gameId: api.GameId.WorldOfWarcraft,
    gameMode: api.GameMode.WowCustom,
  },
  {
    source: 'wow/arena-2x2',
    gameId: api.GameId.WorldOfWarcraft,
    gameMode: api.GameMode.WowArena2v2,
  },
  {
    source: 'wow/arena-3x3',
    gameId: api.GameId.WorldOfWarcraft,
    gameMode: api.GameMode.WowArena3v3,
  },
  { source: 'lost-ark', gameId: api.GameId.LostArkEn },
  {
    source: 'lost-ark/abyss',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkAbyssDungeons,
  },
  {
    source: 'lost-ark/rush',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkBossRush,
  },
  {
    source: 'lost-ark/cube',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkCube,
  },
  {
    source: 'lost-ark/platinum-fields',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkPlatinumFields,
  },
  {
    source: 'lost-ark/arena',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkArena,
  },
  {
    source: 'lost-ark/guardian',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkRaidsOnGuardians,
  },
  {
    source: 'lost-ark/guardian-raids',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkGuardianHeroicRaids,
  },
  {
    source: 'lost-ark/guardian-raids',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkGuardianHeroicRaids,
  },
  {
    source: 'lost-ark/legion-raids',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkLegionRaids,
  },
  {
    source: 'lost-ark/abyss-trials',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkTrialsOfTheAbyss,
  },
  {
    source: 'lost-ark/abyss-trials',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkTrialsOfTheAbyss,
  },
  {
    source: 'lost-ark/other',
    gameId: api.GameId.LostArkEn,
    gameMode: api.GameMode.LostArkOther,
  },
  // { source: 'hearthstone', gameId: api.GameId.Hearthstone },
  // { source: 'csgo', gameId: api.GameId.Csgo },
  // { source: 'warzone', gameId: api.GameId.Warzone },
];

export function parseGameIdFromQuery(
  query: ParsedUrlQuery,
): api.Maybe<SupportedGameId> {
  const slug = query[QUERY_KEY_GAME_SLUG];

  const redirect = gameRedirects.find(redirect => redirect.source === slug);

  return redirect?.gameId;
}

export function getUrlFromGame(
  path: string,
  gameId: api.GameId,
  mode?: api.Maybe<api.GameMode>,
): UrlObject | string {
  const redirect = getGameSlugPath(gameId, mode);

  return {
    pathname: path + redirect.path,
  };
}

export function parseSlugToGame(slug: string | string[]): api.Maybe<SupportedGameId> {
  const redirect = gameRedirects.find(
    redirect => !redirect.gameMode && redirect.source === slug,
  );

  return redirect?.gameId;
}

interface StaticGamePathsOptions {
  param: string;
  withMode?: boolean;
}
export function getStaticGamePaths<P extends ParsedUrlQuery>(
  options: StaticGamePathsOptions,
): Array<string | { params: P; locale?: string }> {
  return gameRedirects
    .filter(redirect => options.withMode || !redirect.gameMode)
    .map(redirect => {
      return { params: { [options.param]: redirect.source } as P };
    });
}
