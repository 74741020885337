import * as api from 'graphql/generated';
import { generateIdByDate } from 'utils/main';

export function deleteUserGame(game: api.Game): api.DeleteUserGameMutation {
  return {
    deleteUserGame: { __typename: game.__typename, id: game.id, hidden: true },
  };
}

export function likeEvent(event: api.Event): api.LikeEventMutation {
  if (!event.isLiked) {
    return {
      likeEvent: {
        __typename: event.__typename,
        id: event.id,
        likesCount: (event.likesCount || 0) + 1,
        isLiked: true,
      },
    };
  }

  return {
    likeEvent: {
      __typename: event.__typename,
      id: event.id,
      likesCount: event.likesCount && event.likesCount - 1,
      isLiked: false,
    },
  };
}

export function restoreUserGame(game: api.Game): api.RestoreUserGameMutation {
  return {
    restoreUserGame: { __typename: game.__typename, id: game.id, hidden: false },
  };
}

export function updateUserGame(
  game: api.Game,
  input: api.GameInput,
): api.UpdateUserGameMutation {
  const previous = { ...game };

  const getUpdatedGame = (): api.Game => {
    switch (previous.__typename) {
      case 'GameLol':
        const lol: api.GameLol = {
          ...previous,
          ...input.lol,
          champions: previous.champions,
        };

        return lol;
      case 'LostArk':
        // const la: api.LostArk = {
        //   ...previous,
        //   ...input.lostArk,
        // };

        return { ...previous };
      case 'Wow':
        const wow: api.Wow = {
          ...previous,
          ...input.wow,
        };

        return wow;
      default:
        return previous;
    }
  };
  const newGame = getUpdatedGame();

  return { updateUserGame: newGame };
}

export function updateUserUsername(
  me: api.User,
  username: string,
): api.UpdateUserUsernameMutation {
  return {
    updateUserUsername: { ...me, username },
  };
}
export function createLink(
  me: api.User,
  input: api.UserLinkInput,
): api.CreateLinkMutation {
  return {
    createUserLink: {
      id: me.id,
      links: [
        ...(me.links || []),
        { id: generateIdByDate(), url: input.url || '', ...input },
      ],
    },
  };
}
export function updateLink(
  link: api.UserLink,
  input: api.UserLinkInput,
): api.UpdateLinkMutation {
  return {
    updateUserLink: { ...link, ...input },
  };
}

export function deleteLink(me: api.User, id: string): api.DeleteLinkMutation {
  const res = {
    deleteUserLink: {
      id: me.id,
      links: me.links?.filter(link => link.id !== id),
    },
  };

  return res;
}

export function updateUserDescription(
  me: api.User,
  description: string,
): api.UpdateUserDescriptionMutation {
  return {
    updateUserDescription: { ...me, description },
  };
}

export function followUser(user: api.User): api.FollowUserMutation {
  return {
    followUser: {
      ...user,
      ...(user.followersCount && { followersCount: user.followersCount + 1 }),
      isFollowing: true,
    },
  };
}

export function unfollowUser(user: api.User): api.UnfollowUserMutation {
  return {
    unfollowUser: {
      ...user,
      ...(user.followersCount && { followersCount: user.followersCount - 1 }),
      isFollowing: false,
    },
  };
}

// export function updateGroup(
//   event: api.Event,
//   input: api.EventInput,
// ): api.UpdateEventMutation {
//   return {
//     updateEvent: {
//       ...event,
//       ...input,
//       ...(event.wow && { wow: { ...event.wow, ...input.wow } }),
//       ...(event.lol && { lol: { ...event.lol, ...input.lol } }),
//     },
//   };
// }

// export function deleteGroup(event: api.Event): api.DeleteEventMutation {
//   return {
//     deleteEvent: {
//       // remake ok
//       ok: true,
//     },
//   };
// }
