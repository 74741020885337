import { COOKIE_REGION_KEY } from 'const';
import { cookie } from 'utils/main';

export const INDIA_POSTFIX = '-IN';
export const PAKISTAN_LANGS = [
  '-pk',
  '-PK',
  'ur', // urdu
];

export const RU_LANGUAGES = [
  'ru' /* Russian */,
  'uk' /* Ukrainian */,
  'uz' /* Uzbek */,
  'be' /* Belarusian */,
  'kk' /* Kazakh */,
  'tt' /* Tatar */,
  'tg' /* Tajik */,
  'ky' /* Kyrgyz */,
];
export const SEA_LANGUAGES = [
  '-TH', // Thai
  '-MM', // Burmese
  '-VN', // Vietnamese
  '-LA', // Lao ?
  '-ID', // Indonesian
  '-MY', // Malay
  '-PH', // Filipino
  '-KH', // Khmer
  '-BD', // Bangla ?

  '-SG', // Singapore
  '-BN', // Brunei
  '-TL', // Timor-Leste
  '-BN', // Brunei
];

export type ClusterRegion = 'en' | 'ru' | 'sea';

export const values: ClusterRegion[] = ['en', 'ru', 'sea'];

export function getClusterRegionByAcceptLanguage(
  acceptLanguage: string,
): ClusterRegion {
  if (SEA_LANGUAGES.find(value => acceptLanguage.includes(value))) {
    return 'sea';
  }
  // if (PAKISTAN_LANGS.find(value => acceptLanguage.includes(value))) {
  //   return 'pk';
  // }

  // if (acceptLanguage.includes(INDIA_POSTFIX)) {
  //   return 'in';
  // }

  const rusMatch = RU_LANGUAGES.find(value => acceptLanguage.includes(value));
  if (rusMatch) {
    return 'ru';
  }

  return 'en';
}

export function getCurrentRegion(acceptLanguage: string): ClusterRegion {
  const cookieRegion = cookie.get(COOKIE_REGION_KEY);
  // console.log('cookie region', cookieRegion, 'accept language', acceptLanguage);

  if (cookieRegion) {
    return cookieRegion as ClusterRegion;
  }
  const headerRegion = getClusterRegionByAcceptLanguage(acceptLanguage);
  // console.log('header region', headerRegion);

  return headerRegion;
}
