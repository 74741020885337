import { FieldPolicy, InMemoryCache, FieldFunctionOptions } from '@apollo/client';

import { api } from 'api';

import { TypedTypePolicies } from 'graphql/generated/helpers';
import introspection from 'graphql/generated/introspection';
import { uniqueNodes } from 'utils/main';

const searchEvents: FieldPolicy<
  api.Maybe<api.SearchEventsIterator>,
  api.SearchEventsIterator,
  api.Maybe<api.SearchEventsIterator>,
  FieldFunctionOptions<api.QuerySearchEventsArgs, api.SearchEventsQueryVariables>
> = {
  keyArgs: ['filter'],
  merge: (existing, incoming, options) => {
    if (!existing) {
      return incoming;
    }
    const offset = options.variables?.iteratorOptions?.offset || 0;

    if (offset >= existing.data.length) {
      const values = [...existing.data, ...incoming.data];
      const data = uniqueNodes(values, value => value as any);

      return { ...incoming, data };
    }

    // place in the right offset
    const values = [...existing.data];
    values.splice(offset, 0, ...incoming.data);
    const data = uniqueNodes(values, value => value as any);

    return { ...incoming, data };
  },
};

const getLadder: FieldPolicy<
  api.Maybe<api.LadderIterator>,
  api.LadderIterator,
  api.Maybe<api.LadderIterator>,
  FieldFunctionOptions<api.QueryGetLadderArgs, api.LadderQueryVariables>
> = {
  keyArgs: [],
  merge: (existing, incoming, options) => {
    if (!existing) {
      return incoming;
    }
    const offset = options.variables?.iteratorOptions?.offset || 0;

    if (offset >= existing.data.length) {
      const values = [...existing.data, ...incoming.data];
      const data = uniqueNodes(values, value => value as any);

      return { ...incoming, data };
    }

    // place in the right offset
    const values = [...existing.data];
    values.splice(offset, 0, ...incoming.data);
    const data = uniqueNodes(values, value => value as any);

    return { ...incoming, data };
  },
};

const getEventMembers: FieldPolicy<
  api.Maybe<api.EventMembersIterator>,
  api.EventMembersIterator,
  api.Maybe<api.EventMembersIterator>,
  FieldFunctionOptions<
    api.QueryGetEventMembersArgs,
    api.GetEventMembersQueryVariables
  >
> = {
  keyArgs: ['data'],
  merge: (existing, incoming, options) => {
    if (!existing) {
      return incoming;
    }
    const offset = options.variables?.iteratorOptions?.offset || 0;

    if (offset >= existing.data.length) {
      const values = [...existing.data, ...incoming.data];
      const data = uniqueNodes(values, value => value as any);

      return { ...incoming, data };
    }

    // place in the right offset
    const values = [...existing.data];
    values.splice(offset, 0, ...incoming.data);
    const data = uniqueNodes(values, value => value as any);

    return { ...incoming, data };
  },
};

const getMyFeed: FieldPolicy<
  api.Maybe<api.SearchPlayersIterator>,
  api.SearchPlayersIterator,
  api.Maybe<api.SearchPlayersIterator>,
  FieldFunctionOptions<api.QueryGetMyFeedTempArgs, api.GetMyFeedQueryVariables>
> = {
  keyArgs: [],
  merge: (existing, incoming, options) => {
    if (!existing) {
      return incoming;
    }
    const offset = options.variables?.iteratorOptions?.offset || 0;

    if (offset >= existing.data.length) {
      const values = [...existing.data, ...incoming.data];
      const data = uniqueNodes(values, value => value as any);

      return { ...incoming, data };
    }

    // place in the right offset
    const values = [...existing.data];
    values.splice(offset, 0, ...incoming.data);
    const data = uniqueNodes(values, value => value as any);

    return { ...incoming, data };
  },
};

const searchPlayers: FieldPolicy<
  api.Maybe<api.SearchPlayersIterator>,
  api.SearchPlayersIterator,
  api.Maybe<api.SearchPlayersIterator>,
  FieldFunctionOptions<api.QuerySearchPlayersArgs, api.SearchPlayersQueryVariables>
> = {
  keyArgs: ['filter'],
  merge: (existing, incoming, options) => {
    if (!existing) {
      return incoming;
    }
    const offset = options.variables?.iteratorOptions?.offset || 0;

    if (offset >= existing.data.length) {
      const values = [...existing.data, ...incoming.data];
      const data = uniqueNodes(values, value => value as any);

      return { ...incoming, data };
    }

    // place in the right offset
    const values = [...existing.data];
    values.splice(offset, 0, ...incoming.data);
    const data = uniqueNodes(values, value => value as any);

    // const validGameIds = [...existing.validGameIds, ...incoming.validGameIds];

    return { ...incoming, data };
  },
};

// const getEventById: FieldPolicy<
//   api.Maybe<api.Event>,
//   api.Event,
//   Reference,
//   FieldFunctionOptions<api.QueryGetEventByIdArgs, api.GetEventByIdQueryVariables>
// > = {
//   read(_, { args, toReference }) {
//     return toReference({
//       __typename: 'Event',
//       id: args?.eventId,
//     });
//   },
// };

const typePolicies: TypedTypePolicies = {
  User: { keyFields: ['id'] },
  Lfg: { keyFields: ['id'] },
  Me: { keyFields: [] },
  Event: {
    fields: {
      owner: {
        keyArgs: ['id', 'username'],
        merge: true,
      },
    },
  },
  Slot: {
    fields: {
      user: {
        keyArgs: ['id', 'username'],
        merge: true,
      },
    },
  },
  UserBattlenet: { keyFields: ['battletag'] },
  OrganizationTeam: {
    fields: {
      /*
       * Wow is non-normalizable object, ignore safe-merge
       * @see {@link https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects}
       */
      wow: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
    },
  },
  Vacancy: {
    fields: {
      /*
       * Games are non-normalizable object, ignore safe-merge
       * @see {@link https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects}
       */
      lol: {
        merge: (existing, incoming, { mergeObjects }) =>
          mergeObjects(existing, incoming),
      },
      lostArk: {
        merge: (existing, incoming, { mergeObjects }) =>
          mergeObjects(existing, incoming),
      },
      wow: {
        merge: (existing, incoming, { mergeObjects }) =>
          mergeObjects(existing, incoming),
      },
    },
  },

  Query: {
    fields: {
      getMyFeedTemp: getMyFeed as FieldPolicy<any>,
      searchEvents: searchEvents as FieldPolicy<any>,
      searchPlayers: searchPlayers as FieldPolicy<any>,
      getEventMembers: getEventMembers as FieldPolicy<any>,
      getLadder: getLadder as FieldPolicy<any>,
      // getEventById: getEventById as FieldPolicy<any>,
    },
  },
};

export const getCache = (): InMemoryCache => {
  const cache = new InMemoryCache({
    typePolicies,
    possibleTypes: introspection.possibleTypes,
  });

  return cache;
};
