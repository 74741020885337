/*
 * Client language is kept in ContextApp (components/ContextApp)
 */

import { api } from '../api';

import { cookie } from './main';

import type { NextPageContext } from 'next';

export enum ClientLanguage {
  English = 'en',
  Russian = 'ru',
  Spanish = 'es',
  Thai = 'th',
  Arabic = 'ar',
}

export function IS_RTL(language: ClientLanguage): boolean {
  return language === ClientLanguage.Arabic;
}

export const SUPPORTED_CLIENT_LANGUAGES = [
  ClientLanguage.English,
  ClientLanguage.Russian,
  ClientLanguage.Spanish,
  ClientLanguage.Thai,
  ClientLanguage.Arabic,
];

// If user's browser language is not supported, use FALLBACK_LANGUAGE
const FALLBACK_LANGUAGE = ClientLanguage.English;
export const COOKIE_PREFERRED_LANGUAGE_KEY = 'x-preferred-language';
// You can force set another preferred language by requesting a page with this query
export const QUERY_PREFERRED_LANGUAGE_KEY = 'lang';

export function getClientLanguageFromRequest(
  acceptLanguage: string | undefined,
  preferredLanguage: string | undefined,
  queryLanguage: string | undefined,
): ClientLanguage {
  if (queryLanguage) {
    const lang = deserializePreferredLanguage(queryLanguage as string);
    if (lang) {
      return lang;
    }
    console.warn('Failed to deserialize preferred language from URL query', {
      acceptLanguage,
      preferredLanguage,
      queryLanguage,
    });
  }

  if (preferredLanguage) {
    const lang = deserializePreferredLanguage(preferredLanguage);
    if (lang) {
      return lang;
    }
    // Failed to deserialize preferred language from cookie. Should not happen
    console.warn('Failed to deserialize preferred language from cookie', {
      acceptLanguage,
      preferredLanguage,
    });
  }

  if (acceptLanguage) {
    const lang = deserializeAcceptLanguage(acceptLanguage);
    if (lang) {
      return lang;
    }

    console.warn(
      `Site doesn't support browser language. Enforcing fallback: ${FALLBACK_LANGUAGE}.`,
      { acceptLanguage },
    );
  }

  return FALLBACK_LANGUAGE;
}

// Deserializes language from cookie. It is designed as stated in func serializePreferredLanguage
function deserializePreferredLanguage(
  preferredLanguage: string,
): ClientLanguage | undefined {
  // 'english' and 'russian' string values are deprecated

  switch (preferredLanguage) {
    case 'th':
      return ClientLanguage.Thai;
    case ClientLanguage.English:
    case 'english':
      return ClientLanguage.English;
    case ClientLanguage.Russian:
    case 'russian':
      return ClientLanguage.Russian;
    case ClientLanguage.Spanish:
      return ClientLanguage.Spanish;
    case ClientLanguage.Arabic:
      return ClientLanguage.Arabic;
    default:
      return undefined;
  }
}

export function serializePreferredLanguage(language: ClientLanguage): string {
  return language.toString();
}

export function setPreferredLanguageCookie(language: ClientLanguage) {
  const value = serializePreferredLanguage(language);
  cookie.set(COOKIE_PREFERRED_LANGUAGE_KEY, value);
}

function deserializeAcceptLanguage(
  acceptLanguage: string | undefined,
): ClientLanguage | undefined {
  // console.log('[deserializeAcceptLanguage]: accept-language', acceptLanguage);
  if (!acceptLanguage) {
    return undefined;
  }
  const AR_LANG = ['ar'];
  for (const lang of AR_LANG) {
    if (acceptLanguage.includes(lang)) {
      return ClientLanguage.Arabic;
    }
  }

  const TH_LANGS = ['th'];
  for (const lang of TH_LANGS) {
    if (acceptLanguage.includes(lang)) {
      return ClientLanguage.Thai;
    }
  }

  const RU_LANGS = ['ru', 'uz', 'uk'];
  for (const lang of RU_LANGS) {
    if (acceptLanguage.includes(lang)) {
      return ClientLanguage.Russian;
    }
  }

  const ES_LANGS = ['es'];
  for (const lang of ES_LANGS) {
    if (acceptLanguage.includes(lang)) {
      return ClientLanguage.Spanish;
    }
  }

  const EN_LANGS = ['en'];
  for (const lang of EN_LANGS) {
    if (acceptLanguage.includes(lang)) {
      return ClientLanguage.English;
    }
  }

  return undefined;
}

export function getPreferredLanguageFromCtx(
  ctx: NextPageContext,
): ClientLanguage | undefined {
  const request = ctx.req;
  if (!request) {
    return undefined;
  }

  const languageStr = cookie.get(
    COOKIE_PREFERRED_LANGUAGE_KEY,
    request.headers.cookie,
  );

  if (!languageStr) {
    return undefined;
  }

  return deserializePreferredLanguage(languageStr);
}

export function getPreferredLanguageFromCtxOrCookie(
  ctx?: NextPageContext,
): ClientLanguage | undefined {
  const request = ctx?.req;

  const languageStr = cookie.get(
    COOKIE_PREFERRED_LANGUAGE_KEY,
    request?.headers.cookie,
  );

  if (!languageStr) {
    return undefined;
  }

  return deserializePreferredLanguage(languageStr);
}

export function getPreferredLanguageFromCookie(): ClientLanguage | undefined {
  const languageStr = cookie.get(COOKIE_PREFERRED_LANGUAGE_KEY);

  if (!languageStr) {
    return undefined;
  }

  return deserializePreferredLanguage(languageStr);
}

export function getAcceptLanguageFromCtx(
  ctx: NextPageContext,
): ClientLanguage | undefined {
  const request = ctx.req;
  if (!request) {
    return undefined;
  }

  const acceptLanguage = request.headers['accept-language'];
  if (!acceptLanguage) {
    return undefined;
  }

  return deserializeAcceptLanguage(acceptLanguage);
}

export function getQueryLanguageFromCtx(
  ctx: NextPageContext,
): ClientLanguage | undefined {
  const query = ctx.query;
  if (!query) {
    return undefined;
  }

  const languageStr = query[QUERY_PREFERRED_LANGUAGE_KEY] as string;
  if (!languageStr) {
    return undefined;
  }

  return deserializePreferredLanguage(languageStr);
}

export function toUserLanguage(
  language: ClientLanguage | undefined,
): api.Language | undefined {
  if (!language) {
    return undefined;
  }

  switch (language) {
    case ClientLanguage.English:
      return api.Language.En;
    case ClientLanguage.Russian:
      return api.Language.Ru;
    case ClientLanguage.Spanish:
      return api.Language.Es;
  }
}
