import React from 'react';

import { ApolloError } from '@apollo/client';
import { DocumentNode } from 'graphql';

export type ToastType = 'info' | 'warning' | 'error';

export interface ToastInput {
  message: string;
  duration?: number;
  type?: ToastType;
  position?: 'left' | 'center';
}

export interface Toast {
  id: number;
  message: string | Error;
  duration: number;
  type: ToastType;
  position: 'center' | 'left';
}

export interface ToastCtx {
  showError: (error: ApolloError, node: DocumentNode) => void;
  alert: (toast: ToastInput) => void;
  catchToToast: <T>(value: T | Promise<T>) => Promise<boolean>; // returns true if success
  remove: (id: Toast['id']) => void;
  error: (message: string, options?: Omit<ToastInput, 'message' | 'type'>) => void;
  toasts: Toast[];
}

const ctx: ToastCtx = {
  alert: () => undefined,
  showError: () => undefined,
  toasts: [],
  remove: () => undefined,
  error: () => undefined,
  catchToToast: async () => false,
};

export const ToastContext = React.createContext(ctx);

export function useToasts(): Omit<ToastCtx, 'toasts'> {
  const toasts = React.useContext(ToastContext);

  return {
    showError: toasts.showError,
    alert: toasts.alert,
    remove: toasts.remove,
    catchToToast: toasts.catchToToast,
    error: toasts.error,
  };
}
