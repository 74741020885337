export const REGIONS = ['NA', 'SA', 'SEA', 'SAM', 'EU', 'OC', 'AF'];

export const RANKS = [
  'Rookie I-V',
  'Veteran I-V',
  'Elite I-V',
  'Pro I-V',
  'Master',
  'Grandmaster',
  'Legendary',
];
