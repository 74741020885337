export const REGIONS = ['CIS', 'EU', 'Turkey', 'SEA', 'SA', 'Arabian', 'SouthAsia'];

export const RANKS = [
  'Bronze',
  'Silver',
  'Gold',
  'Platinum',
  'Diamond',
  'Crown',
  'Ace',
  'AceMaster',
  'AceDominator',
  'Conqueror',
];

export const RANKS_IMGS: Record<(typeof RANKS)[0], string> = {
  Bronze: 'Bronze',
  Silver: 'Silver',
  Gold: 'Gold',
  Platinum: 'Platinum',
  Diamond: 'Diamond',
  Crown: 'Crown',
  Ace: 'Ace',
  AceMaster: 'AceMaster',
  AceDominator: 'AceDominator',
  Conqueror: 'Conqueror',
};
