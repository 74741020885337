import React, { useCallback, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { DocumentNode } from 'graphql';

import { api } from 'api';

import { useTranslations, useText } from '../Language';

import { Toast, ToastContext, ToastInput } from './context';
import { ToastList } from './ToastList';

export const ToastProvider: React.FC<{
  children?: JSX.Element | JSX.Element[] | null | string;
}> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const text = useText(state => state.errors);
  const translations = useTranslations();

  const alert = useCallback(
    (toast: ToastInput) => {
      const id = Math.round(Math.random() * 10000);

      setToasts(toasts => [
        {
          id,
          message: toast.message,
          duration: toast.duration || 3500,
          type: toast.type || 'info',
          position: toast.position || 'center',
        },
        ...toasts,
      ]);
    },
    [toasts, setToasts],
  );

  const catchToToast = async <T,>(value: T | Promise<T>): Promise<boolean> => {
    try {
      await value;

      return true;
    } catch (error) {
      alert({ message: error as string, type: 'error' });

      return false;
    }
  };

  function remove(id: number) {
    setToasts(toasts => toasts.filter(toast => toast.id !== id));
  }

  function error(
    message: string,
    options: Omit<ToastInput, 'message' | 'type'> = {},
  ) {
    return alert({
      message,
      type: 'error',
      ...options,
    });
  }

  function showError(error: ApolloError, node: DocumentNode) {
    if (error.networkError) {
      console.warn(JSON.stringify(error.networkError));

      const status: number | undefined = (error.networkError as any)?.statusCode;
      switch (status) {
        case 408:
          return alert({ type: 'error', message: text.network.timeout });
        default:
          return alert({
            type: 'error',
            message: 'NETWORK: ' + error.networkError.message,
          });
      }
    }
    // GQL Error
    if (error.graphQLErrors.length === 0) {
      console.warn('unknown error:', JSON.stringify(error));

      return alert({ type: 'error', message: text.unknown });
    }
    const gqlError = error.graphQLErrors[0];
    const errorCode = gqlError.extensions?.code as api.Maybe<api.ErrorCode>;

    if (!errorCode || !Object.values(api.ErrorCode).includes(errorCode)) {
      console.warn('unmet error code:', JSON.stringify(error));

      const defaultError = translations.error.default(node);

      return alert({ type: 'error', message: defaultError || gqlError.message });
    }

    const localizedError = translations.error.code(errorCode);
    if (!localizedError) {
      console.warn('unmet error code:', JSON.stringify(error));

      return alert({ type: 'error', message: gqlError.message });
    }

    alert({ type: 'error', message: localizedError });
  }

  return (
    <ToastContext.Provider
      value={{ toasts, alert, remove, catchToToast, showError, error }}
    >
      {children}
      <ToastList toasts={toasts} onRequestClose={remove} />
    </ToastContext.Provider>
  );
};
