/* eslint-disable @typescript-eslint/ban-types */
import { useState, createContext, useMemo, useTransition, useCallback } from 'react';

import { ANIMATION_DURATION, ModalAdditionalProps } from './helpers';

type Component<T extends {}> = React.ComponentType<T & ModalAdditionalProps>;

export interface ContextModalType {
  open: <T extends {}>(content: Component<T>, modalProps: T) => void;
  close: () => void;
}

export const ContextModal = createContext<ContextModalType>({
  open: () => {
    console.warn('Calling open before MODAL PROVIDER is READY');
  },
  close: () => {
    console.warn('Calling close before MODAL PROVIDER is READY');
  },
});

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isPending, startTransition] = useTransition();

  const [Content, setContent] = useState<Component<any> | null>(null);
  const [props, setProps] = useState<any>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = useCallback(() => {
    console.log('Closing modal from ModalProvider');
    startTransition(() => {
      setIsOpen(false);
    });

    setTimeout(() => {
      startTransition(() => {
        setContent(null);
        setProps({});
      });
    }, ANIMATION_DURATION);
  }, []);

  const open = useCallback(<T extends {}>(content: Component<T>, modalProps: T) => {
    startTransition(() => {
      setProps(modalProps);
      setContent(() => content); // Do not use content directly, it will be null
    });

    startTransition(() => {
      setIsOpen(true);
    });
  }, []);

  const context = useMemo<ContextModalType>(() => ({ open, close }), [open, close]);

  return (
    <ContextModal.Provider value={context}>
      {children}
      {Content && <Content {...props} close={close} isOpen={isOpen} />}
    </ContextModal.Provider>
  );
};
