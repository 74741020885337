import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { NextPageContext } from 'next';
import { withApollo as withApolloDecorator } from 'next-apollo';

import { api } from 'api';

import { COOKIE_REGION_KEY } from 'const';
import { cookie } from 'utils/main';

import { PageGloabalProps } from '../next';

import { getCache } from './cache';
import { schema } from './localState';

export * from './pagination';
export * from './cache';

export const AUTH_TOKEN = 'x-token';
export const HEADER_TIMEZONE = 'x-timezone-offset';

export const setToken = (token: string): void => cookie.set(AUTH_TOKEN, token);

export const cookieGetToken = (
  cookieHeader: string | undefined = undefined,
): string | undefined => {
  // while server side get cookie header
  return cookie.get(AUTH_TOKEN, cookieHeader);
};

const host = process.env.NEXT_PUBLIC_API || 'api.lf.group';
const protocol = process.env.NEXT_PUBLIC_PROTOCOL || 'https';
export const BACKEND_ENDPOINT = protocol + `://${host}/graphql`;

const namedLink = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: `${BACKEND_ENDPOINT}?${operation.operationName}`,
  }));

  return forward ? forward(operation) : null;
});

export const createApolloLink = (
  token: string | undefined = undefined,
  params: {
    acceptLanguage?: string;
    cluster?: string;
  } = {},
): ApolloLink => {
  const httpLink = createHttpLink({ uri: BACKEND_ENDPOINT });

  const authLink = setContext((_, req) => {
    const headers = {
      ...req.headers,
      [AUTH_TOKEN]: token,
      [HEADER_TIMEZONE]: new Date().getTimezoneOffset(),
    };

    if (params.acceptLanguage) {
      headers['Accept-Language'] = params.acceptLanguage;
    }

    if (params.cluster) {
      headers[COOKIE_REGION_KEY] = params.cluster;
    }

    return { headers };
  });
  // Sets timeout for query for SSR and client
  const getTimeout = (): number => {
    if (typeof window === 'undefined') {
      return 3000;
    }

    return 10000;
  };
  const timeoutLink = new ApolloLinkTimeout(getTimeout());

  const link = timeoutLink.concat(authLink.concat(httpLink));

  return ApolloLink.from([namedLink, link]);
};

export async function setApolloRegionCluster(): Promise<void> {}

export async function setApolloCluster(
  client: ApolloClient<unknown>,
  token: string,
  cluster: string,
): Promise<void> {
  const link = createApolloLink(token, { cluster });

  client.setLink(link);
}

export async function setApolloToken(
  client: ApolloClient<unknown>,
  token: string,
): Promise<void> {
  setToken(token);
  const link = createApolloLink(token, { cluster: cookie.get(COOKIE_REGION_KEY) });

  client.setLink(link);

  await client.refetchQueries({
    include: [api.GetMeDocument, api.GetMyGamesDocument],
  });

  return;
}

export function initApollo(
  ctx: NextPageContext | undefined,
): ApolloClient<NormalizedCacheObject> {
  // @see https://github.com/zeit/next.js/issues/5354#issuecomment-520305040

  // if url contains token query param, use it for authentication
  // otherwise use token from cookie if exists
  const token = getToken(ctx);

  // console.log('headers ', ctx?.req?.headers);
  const link = createApolloLink(token, {
    acceptLanguage: ctx?.req?.headers['accept-language'],
    cluster: cookie.get(COOKIE_REGION_KEY, ctx?.req?.headers.cookie),
  });
  const cache = getCache();
  // https://github.com/eturino/apollo-link-scalars deal with datetime object
  const apolloClient = new ApolloClient({
    link,
    cache,
    ssrMode: true,
  });

  return apolloClient;
}

export function initServersideApollo(): ApolloClient<NormalizedCacheObject> {
  const token = undefined;
  const link = createApolloLink(token);
  const cache = getCache();

  return new ApolloClient({
    link,
    cache,
    ssrMode: typeof window === 'undefined',
    typeDefs: schema,
  });
}

export const withApollo = withApolloDecorator<PageGloabalProps & any, any>(ctx => {
  return initApollo(ctx);
});

const getTokenFromUrl = (url: string): string | null => {
  try {
    const parsed = new URL(url);
    const token = parsed.searchParams.get('token');

    return token;
  } catch (error) {
    return null;
  }
};

const getToken = (ctx: NextPageContext | undefined): string | undefined => {
  const path = ctx?.req?.url;

  // try to obtain token from "token" query param on clientside
  if (typeof window !== 'undefined') {
    const url = window.location.href;
    const token = getTokenFromUrl(url);
    if (token) {
      // set token on client side if not set
      setToken(token);

      return token;
    }
  }

  if (path !== '') {
    const url = `https://lf.group${path}`;

    const token = getTokenFromUrl(url);
    if (token) {
      return token;
    }
  }

  const cookieHeader = ctx?.req?.headers.cookie;
  const token = cookieGetToken(cookieHeader);

  return token;
};
