import React, { useMemo } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import { useApp } from 'components/ContextApp';
import {
  SUPPORTED_CLIENT_LANGUAGES,
  serializePreferredLanguage,
  QUERY_PREFERRED_LANGUAGE_KEY,
} from 'utils/language';

const tags = SUPPORTED_CLIENT_LANGUAGES.map(serializePreferredLanguage);

export const HreflangMap: React.FC = () => {
  const router = useRouter();
  const app = useApp();

  const url = useMemo(
    () => new URL('https://lf.group' + router.asPath),
    [router.asPath],
  );

  const renderList: React.ReactNode = useMemo(() => {
    return (
      <>
        {tags.map((tag, index) => {
          const langUrl = new URL(url);
          if (tag !== app.language?.toString()) {
            langUrl.searchParams.append(QUERY_PREFERRED_LANGUAGE_KEY, tag);
          }

          return (
            <link
              key={index}
              href={langUrl.toString()}
              hrefLang={tag}
              rel="alternate"
            />
          );
        })}
      </>
    );
  }, [router.pathname]);

  return (
    <Head>
      {renderList}
      <link href={url.toString()} hrefLang="x-default" rel="alternate" />
    </Head>
  );
};
