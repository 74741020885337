/* eslint-disable @typescript-eslint/ban-types */
import { useContext } from 'react';

import { ContextModal, ContextModalType } from './ContextModal';

export const ANIMATION_DURATION = 500;

export interface ModalAdditionalProps {
  isOpen: boolean;
  close: () => void;
}

export type ModalProps<T extends Object = {}> = T & ModalAdditionalProps;
export type ModalComponent<T extends Object = {}> = React.FC<ModalProps<T>>;

export function useModal(): ContextModalType {
  const context = useContext(ContextModal);

  return context;
}
