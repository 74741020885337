import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { Icon } from '../Icons';
import { Text } from '../Text';

import { Toast as ToastType } from './context';

import css from './Toast.module.css';

interface ToastProps {
  toast: ToastType;
  onRequestClose?: (id: ToastType['id']) => void;
}

export const Toast = React.memo<ToastProps>(
  ({ toast, onRequestClose = () => undefined }) => {
    const [rendered, setIsRendered] = useState<boolean>(false);
    const dropRef = useRef<(() => void) | null>(null);
    const appearedOn = useRef<Date | null>(null);
    const [timeLeft, setTimeLeft] = useState<number>(toast.duration);

    const breakFunction = () => {
      setIsRendered(false);
      setTimeout(() => onRequestClose(toast.id), 100);
    };

    useEffect(() => {
      setIsRendered(true);
      appearedOn.current = new Date();
      dropRef.current = breakFunction;

      // const timeoutAnimation = setTimeout(
      //   () => setIsRendered(false),
      //   toast.duration - 150,
      // );
      // const timeout = setTimeout(() => onRequestClose(toast.id), toast.duration);
      setTimeout(() => dropRef.current && dropRef.current(), timeLeft);

      return () => {
        // clearTimeout(timeout);
        // clearTimeout(timeoutAnimation);
      };
    }, []);

    const text =
      toast.message instanceof Error ? toast.message.message : toast.message;

    return (
      <div
        className={cx(css.container, {
          [css.containerVisible]: rendered,
          [css.error]: toast.type === 'error',
          [css.warning]: toast.type === 'warning',
        })}
        onMouseLeave={() => {
          if (!dropRef.current) {
            dropRef.current = breakFunction;
            setTimeout(() => dropRef.current && dropRef.current(), timeLeft);
          }
        }}
        onMouseOver={() => {
          if (dropRef.current) {
            dropRef.current = null;
            const now = new Date();

            if (appearedOn.current) {
              const remained = now.getTime() - appearedOn.current.getTime();

              if (toast.duration - remained > 0) {
                setTimeLeft(toast.duration - remained);
              }
            }
          }
        }}
      >
        <div className={css.iconWrapper}>
          {toast.type === 'error' ? (
            <Icon.Alert />
          ) : (
            <Icon.Notification stroke="var(--green)" />
          )}
        </div>
        <Text.Regular size={2}>{text}</Text.Regular>
      </div>
    );
  },
);
