import React, { memo } from 'react';

import cx from 'classnames';

type TitleProps<T extends keyof JSX.IntrinsicElements> = JSX.IntrinsicElements[T] & {
  Tag?: T;
} & ({ bold?: true; semibold?: false } | { semibold?: true; bold?: false });

export type TextProps<T extends keyof JSX.IntrinsicElements> = TitleProps<T> & {
  size?: 1 | 2 | 3 | 4 | 5;
};

export function H1<T extends keyof JSX.IntrinsicElements = 'h1'>({
  Tag = 'h1' as any,
  children,
  bold = false,
  semibold = false,
  className,
  ...props
}: TitleProps<T>) {
  return (
    <Tag
      className={cx(
        'text-3xl tracking-[0.36px]',
        { 'font-bold': bold, 'font-semibold': semibold },
        className,
      )}
      style={{ color: props.color }}
      {...(props as any)}
    >
      {children}
    </Tag>
  );
}
export const H1Memo = memo(H1);

export function H2<T extends keyof JSX.IntrinsicElements = 'h2'>({
  Tag = 'h2' as any,
  children,
  bold = false,
  semibold = false,
  className,
  ...props
}: TitleProps<T>) {
  return (
    <Tag
      className={cx('text-2xl font-bold tracking-[0.38px]', className, {
        'font-semibold': semibold,
      })}
      {...(props as any)}
    >
      {children}
    </Tag>
  );
}
export const H2Memo = memo(H2);

function isWindows() {
  if (typeof navigator === 'undefined') {
    return false;
  }

  return navigator.platform.indexOf('Win') > -1;
}
export function Subtitle<T extends keyof JSX.IntrinsicElements = 'h4'>({
  Tag = 'h4' as any,
  children,
  className,
  ...props
}: TitleProps<T>) {
  return (
    <Tag
      className={cx(
        'text-[10px]/[12px] font-extrabold uppercase',
        { 'font-bold': isWindows() },
        className,
      )}
      {...(props as any)}
    >
      {children}
    </Tag>
  );
}

export const R1 = <T extends keyof JSX.IntrinsicElements = 'div'>({
  Tag = 'div' as any,
  children,
  bold = false,
  semibold = false,
  className,
  ...props
}: TitleProps<T>) => {
  return (
    <Tag
      className={cx(
        'text-xl tracking-[-0.32px]',
        { 'font-bold': bold, 'font-semibold': semibold },
        className,
      )}
      {...(props as any)}
    >
      {children}
    </Tag>
  );
};
export const R1Memo = memo(R1);

export const R2 = <T extends keyof JSX.IntrinsicElements = 'div'>({
  Tag = 'div' as any,
  children,
  bold = false,
  semibold = false,
  className,
  ...props
}: TitleProps<T>) => {
  return (
    <Tag
      className={cx('text-lg tracking-[-0.154px]', className, {
        'font-bold': bold,
        'font-semibold': semibold,
      })}
      {...(props as any)}
    >
      {children}
    </Tag>
  );
};
export const R2Memo = memo(R2);

export const R3 = <T extends keyof JSX.IntrinsicElements = 'div'>({
  Tag = 'div' as any,
  children,
  bold = false,
  semibold = false,
  className,
  ...props
}: TitleProps<T>) => {
  return (
    <Tag
      className={cx(
        'text-base tracking-[-0.078px]',
        { 'font-bold': bold, 'font-semibold': semibold },
        className,
      )}
      {...(props as any)}
    >
      {children}
    </Tag>
  );
};
export const R3Memo = memo(R3);

export const R4 = <T extends keyof JSX.IntrinsicElements = 'div'>({
  Tag = 'div' as any,
  children,
  bold = false,
  semibold = false,
  className,
  ...props
}: TitleProps<T>) => {
  return (
    <Tag
      className={cx(
        'text-sm tracking-[0.12px]',
        { 'font-bold': bold, 'font-semibold': semibold },
        className,
      )}
      {...(props as any)}
    >
      {children}
    </Tag>
  );
};
export const R4Memo = memo(R4);

export const R5 = <T extends keyof JSX.IntrinsicElements = 'div'>({
  Tag = 'div' as any,
  children,
  bold = false,
  semibold = false,
  className,
  ...props
}: TitleProps<T>) => {
  return (
    <Tag
      className={cx(
        'text-[11px]/[13px] tracking-[0.12px]',
        { 'font-bold': bold, 'font-semibold': semibold },
        className,
      )}
      {...(props as any)}
    >
      {children}
    </Tag>
  );
};
export const R5Memo = memo(R5);

/** @deprecated */
export function Regular<T extends keyof JSX.IntrinsicElements = 'p'>({
  Tag = 'div' as any,
  children,
  size = 2,
  bold = false,
  semibold = false,
  className,
  ...props
}: TextProps<T>) {
  return (
    <Tag
      className={cx(
        {
          'text-xl': size === 1,
          'text-lg': size === 2,
          'text-base': size === 3,
          'text-sm': size === 4,
          'text-[10px]/[12px] uppercase': size === 5,
        },
        className,
        { 'font-bold': bold, 'font-semibold': semibold },
      )}
      {...(props as any)}
    >
      {children}
    </Tag>
  );
}
