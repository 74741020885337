
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Game": [
      "CSGO",
      "GameAny",
      "GameLol",
      "Hearthstone",
      "LostArk",
      "Warzone",
      "Wow",
      "WowBurningCrusade"
    ],
    "GameInterface": [
      "GameAny",
      "GameLol",
      "Hearthstone",
      "LostArk",
      "Warzone"
    ],
    "Notification": [
      "NotificationEventCreated",
      "NotificationNewFollower",
      "NotificationPromo"
    ],
    "NotificationBasic": [
      "NotificationEventCreated",
      "NotificationNewFollower",
      "NotificationPromo"
    ],
    "WowGame": [
      "Wow",
      "WowBurningCrusade"
    ]
  }
};
      export default result;
    