import type * as api from '../../graphql/generated/index';

import type { SupportedGameId } from 'api/types';

/*
 * ---------------------------------
 * Aliases represent how enum values
 * look like in a url query
 * ---------------------------------
 */

export const gameId: Record<SupportedGameId, string> = {
  WorldOfWarcraft: 'wow',
  LostArkEn: 'lost-ark',
  LeagueOfLegends: 'lol',
  Hearthstone: 'hs',
  Custom: 'other',
  CSGO: 'csgo',
  PUBGMobile: 'pubg-mobile',
  CODMobile: 'cod-mobile',
  Standoff2: 'standoff2',
  GooseGooseDuck: 'ggd',
  ShadowFight3: 'shadow-fight-3',
  ShadowFight4Arena: 'shadow-fight-4-arena',
  DawnOfZombiesSurvival: 'dawn-of-zombies-survival',
  Crossfire: 'crossout-mobile',
  NewStateMobile: 'new-state-mobile',
  RiseOfEmpires: 'rise-of-empires',
  ModernStrikeOnlinePvPFPS: 'modern-strike',
  RiseOfKingdomsLostCrusade: 'rise-of-kingdoms',
  IdleHeroes: 'idle-heroes',
  LastDayOnEarthSurvival: 'last-day-on-earth-survival',
  MobileLegendsAdventure: 'mobile-legends-adventure',
  STALKER2HeartOfChornobyl: 'stalker-2-hoc',
  Highrise: 'highrise',
  AFKArena: 'afk-arena',
};

export const wowRegion: Record<api.WowRegion, string> = {
  US: 'us',
  Europe: 'eu',
  China: 'ch',
  Korea: 'kr',
  Taiwan: 'tw',
};
export const csgoRegion: Record<api.CsgoRegion, string> = {
  Asia: 'asia',
  Eu: 'eu',
  EuEast: 'eu-east',
  EuWest: 'eu-west',
  US: 'us',
  Unset: 'unset',
};
export const wowFaction: Record<api.WowFaction, string> = {
  Alliance: 'a',
  Horde: 'h',
};

export const language: Record<api.Language, string> = {
  En: 'en',
  Ru: 'ru',
  De: 'de',
  Es: 'es',
  It: 'it',
  Fr: 'fr',
  Nl: 'nl',
  Pl: 'pl',
  Pt: 'pt',
  Se: 'se',
  Tr: 'tr',
};
export const lolRank: Record<api.LolRank, string> = {
  None: 'none',
  Iron1: 'ir-1',
  Iron2: 'ir-2',
  Iron3: 'ir-3',
  Iron4: 'ir-4',
  Bronze1: 'br-1',
  Bronze2: 'br-2',
  Bronze3: 'br-3',
  Bronze4: 'br-4',
  Challenger: 'challenger',
  Diamond1: 'diam-1',
  Diamond2: 'diam-2',
  Diamond3: 'diam-3',
  Diamond4: 'diam-4',
  Emerald1: 'em-1',
  Emerald2: 'em-2',
  Emerald3: 'em-3',
  Emerald4: 'em-4',
  Gold1: 'g-1',
  Gold2: 'g-2',
  Gold3: 'g-3',
  Gold4: 'g-4',
  Grandmaster: 'grandmaster',
  Master: 'master',
  Platinum1: 'pl-1',
  Platinum2: 'pl-2',
  Platinum3: 'pl-3',
  Platinum4: 'pl-4',
  Silver1: 'si-1',
  Silver2: 'si-2',
  Silver3: 'si-3',
  Silver4: 'si-4',
};

export const lolRole: Partial<Record<api.SlotRole, string>> = {
  LolDuo: 'adk',
  LolJungle: 'jungle',
  LolMid: 'mid',
  LolSolo: 'top',
  LolSupport: 'support',
};

export const riotRegion: Record<api.RiotRegion, string> = {
  Americas: 'us',
  Asia: 'asia',
  Europe: 'eu',
};
export const lolRegion: Record<api.LolRegion, string> = {
  Unset: '',
  Brazil1: 'br',
  EuNorth1: 'eun',
  EuWest1: 'euw',
  Japan1: 'jp',
  Korea: 'kr',
  LatinAmerica1: 'la',
  LatinAmerica2: 'la2',
  NorthAmerica1: 'na',
  Oceania1: 'oc',
  Russia: 'ru',
  Turkey1: 'tr',
};

export const laClass: Record<api.LostArkArenaClass, string> = {
  Artillerist: 'artillerist',
  Bard: 'bard',
  Arcanist: 'arcanist',
  Summoner: 'summoner',
  Berserker: 'berserker',
  Deadeye: 'deadeye',
  Deathblade: 'deathblade',
  Reaper: 'reaper',
  Gunlancer: 'gunlancer',
  Glaivier: 'glaivier',
  Gunslinger: 'gunslinger',
  Destroyer: 'destroyer',
  Machinist: 'machinist',
  Paladin: 'paladin',
  Scrapper: 'scrapper',
  Shadowhunter: 'shadowhunter',
  Sharpshooter: 'sharpshooter',
  Sorceress: 'sorceress',
  Soulfist: 'soulfist',
  Striker: 'striker',
  Wardancer: 'wardancer',
};

export const laRegion: Record<api.LostArkRegion, string> = {
  Unset: '',
  EUCentral: 'eu-central',
  EUWest: 'eu-west',
  RU: 'ru',
  USEast: 'us-east',
  USWest: 'us-west',
  SA: 'sa',
};

export const laServer: Record<string, string> = {
  Arthetine: 'arthetine',
  Blackfang: 'blackfang',
  Kazeros: 'kazeros',
  Akkan: 'akkan',
  Bergstrom: 'bergstrom',
  Enviska: 'enviska',
  Mari: 'mari',
  Rohendel: 'rohendel',
  Shandi: 'shandi',
  Valtan: 'valtan',
  Adrinne: 'adrinne',
  Aldebaran: 'aldebaran',
  Avesta: 'avesta',
  Azena: 'azena',
  Danube: 'danube',
  Elzowin: 'elzowin',
  Galatur: 'galatur',
  Karta: 'karta',
  Kharmine: 'kharmine',
  Ladon: 'ladon',
  Regulus: 'regulus',
  Sasha: 'sasha',
  Una: 'una',
  Vykas: 'vykas',
  Zosma: 'zosma',
  Nia: 'nia',
  Ealyn: 'ealyn',
  Antares: 'antares',
  Armen: 'armen',
  Asta: 'asta',
  Calvasus: 'calvasus',
  Evergrace: 'evergrace',
  Ezrebet: 'ezrebet',
  Kadan: 'kadan',
  Lazenith: 'lazenith',
  Mokoko: 'mokoko',
  Neria: 'neria',
  Slen: 'slen',
  Thirain: 'thirain',
  Trixion: 'trixion',
  Wei: 'wei',
  Zinnervale: 'zinnervale',
};

export const wowRace: Record<api.WowRace, string> = {
  bloodElf: 'blood-elf',
  broken: 'broken',
  darkIronDwarf: 'darkiron-dwarf',
  draenei: 'draenei',
  dwarf: 'dwarf',
  felOrc: 'fel-orc',
  forestTroll: 'forest-troll',
  gilnean: 'gilnean',
  gnome: 'gnome',
  goblin: 'goblin',
  highmountainTauren: 'highmountain-tauren',
  human: 'human',
  iceTroll: 'ice-troll',
  kulTiran: 'kul-tiran',
  lightforgedDraenei: 'lightforged-draenei',
  magharOrc: 'maghar-orc',
  mechagnome: 'mechagnome',
  naga: 'naga',
  nightElf: 'night-elf',
  nightborne: 'nightborne',
  orc: 'orc',
  pandaren: 'pandaren',
  skeleton: 'skeleton',
  taunka: 'taunka',
  tauren: 'tauren',
  troll: 'troll',
  tuskarr: 'tuskarr',
  undead: 'undead',
  voidElf: 'void-elf',
  vrykul: 'vrykul',
  vulpera: 'vulpera',
  worgen: 'worgen',
  zandalariTroll: 'zandalari-troll',
};
export const wowSpec: Record<api.WowSpecialization, string> = {
  DeathKnightBlood: 'blood',
  DeathKnightFrost: 'dk-frost',
  DeathKnightUnholy: 'unholy',
  DemonHunterHavoc: 'havoc',
  DemonHunterVengeance: 'vengeance',
  DruidBalance: 'balance',
  DruidFeral: 'feral',
  DruidGuardian: 'guardian',
  DruidRestoration: 'd-restoration',
  HunterBeastMastery: 'beast-mastery',
  HunterMarksmanship: 'marksmanship',
  HunterSurvival: 'survival',
  MageArcane: 'arcane',
  MageFire: 'fire',
  MageFrost: 'm-frost',
  MonkBrewmaster: 'brewmaster',
  MonkMistweaver: 'mistweaver',
  MonkWindwalker: 'windwalker',
  PaladinHoly: 'pal-holy',
  PaladinProtection: 'p-protection',
  PaladinRetribution: 'retribution',
  PriestDiscipline: 'discipline',
  PriestHoly: 'pr-holy',
  PriestShadow: 'shadow',
  RogueAssassination: 'assassination',
  RogueOutlaw: 'outlaw',
  RogueSubtlety: 'subtlety',
  ShamanElemental: 'elemental',
  ShamanEnhancement: 'enhancement',
  ShamanRestoration: 'sh-restoration',
  WarlockAffliction: 'affliction',
  WarlockDemonology: 'demonology',
  WarlockDestruction: 'destruction',
  WarriorArms: 'arms',
  WarriorFury: 'fury',
  WarriorProtection: 'w-protection',
  WotlkDeathKnightLichborne: 'lichborne',
  WotlkDeathKnightRuneblade: 'runeblade',
  WotlkDruidWarden: 'warden',
  WotlkPaladinJusticar: 'justicar',
  WotlkWarriorChampion: 'champion',
  WotlkWarriorGladiator: 'gladiator',
};
export const wowClass: Record<api.WowClass, string> = {
  DeathKnight: 'dk',
  DemonHunter: 'dh',
  Druid: 'druid',
  Hunter: 'hun',
  Mage: 'mage',
  Monk: 'monk',
  Paladin: 'pal',
  Priest: 'priest',
  Rogue: 'rog',
  Shaman: 'sha',
  Warlock: 'warlock',
  Warrior: 'warrior',
};
export const battlenetRegion: Record<api.BattlenetRegion, string> = {
  APAC: 'apac',
  Europe: 'eu',
  US: 'us',
};
