/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';

import nextDynamic, { DynamicOptions } from 'next/dynamic';

type ComponentModule<P = {}> = {
  default: React.ComponentType<P>;
};

export type LoaderComponent<P = {}> = Promise<
  React.ComponentType<P> | ComponentModule<P>
>;
export type Loader<P = {}> = () => LoaderComponent<P>;

interface DynamicResponse<P = {}> {
  Component: React.ComponentType<P>;
  prefetch: () => Promise<void>;
}
export function dynamic<P = {}>(importFn: Loader<P>): DynamicResponse<P> {
  return {
    Component: nextDynamic(importFn, { ssr: false } as DynamicOptions<P>),
    async prefetch() {
      try {
        await importFn();
      } catch (error) {
        console.error(
          'Failed to import dynamic component',
          ...importFn.arguments,
          error,
        );
        if (confirm('New version available, reload?')) {
          location.reload();
        }
      }
    },
  };
}
